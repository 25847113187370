import type {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> &
  { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Built-in scalar for map-like structures */
  Map_String_StringScalar: any;
  /** Built-in scalar representing a local date */
  LocalDate: any;
  /** Built-in scalar representing a local time */
  LocalTime: any;
  /** Built-in scalar for map-like structures */
  HashMap_String_StringScalar: any;
  /** Use SPQR's SchemaPrinter to remove this from SDL */
  UNREPRESENTABLE: any;
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  /** Queries related to access booking detail pages */
  accessBooking: AccessBookingContext;
  account: AccountContext;
  /** Agency detail page queries */
  agency: Agency;
  /** Agency contact information queries */
  agencyContact: AgencyContact;
  /** Agency overview page queries */
  agencyOverview: AgencyOverviewPageContext;
  authAccessToken?: Maybe<Scalars['String']>;
  authB2BOverlay: Scalars['Boolean'];
  authChannelType: AuthChannelType;
  /** Blog Article queries */
  blogArticle: BlogArticle;
  /** Blog article overview page */
  blogOverview: BlogOverview;
  /** Get booking by cancellation token */
  bookingAfterCancellation: BookingCancellationResultResponse;
  /** Get booking after payment redirection with payment token */
  bookingAfterPayment: BookingPaymentResultResponse;
  bookmark: BookmarkContext;
  bookmarkPage: BookmarkPage;
  boosters: Array<Booster>;
  /** Catalog page queries */
  catalogOverview: CatalogOverviewContext;
  /** Get bookmark status, bookmark true/false */
  checkBookmarkStatus: Scalars['Boolean'];
  /** Load dynamic components */
  components: Array<DynamicComponent>;
  /** Contact page queries */
  contact: ContactPageContext;
  /** Load cookie disclaimer */
  cookieDisclaimer: CookieDisclaimer;
  /** Email advertising revocation page queries */
  emailAdvertisingRevocation: EmailAdvertisingRevocationPageContext;
  /** Email advertising revocation confirmation page queries */
  emailAdvertisingRevocationConfirmation: EmailAdvertisingRevocationConfirmationPageContext;
  flightHome: FlightHomeContext;
  flightSrl: FlightSrlContext;
  flightWaitingScreen: WaitingScreen;
  geo: GeoContext;
  geoOverview: GeoOverviewContext;
  helpOverlayData: HelpOverlayData;
  history: HistoryContext;
  holidayFinder: HolidayFinderPageContext;
  /** Home page queries */
  home: HomeContext;
  initialORLUrl?: Maybe<Scalars['String']>;
  metaSearcherSearchParam?: Maybe<Scalars['String']>;
  myAccountWaitingScreen: WaitingScreen;
  /** News Article page queries */
  newsArticlePage: NewsArticlePage;
  /** News Articles overview page queries */
  newsArticlesOverview: NewsArticlesOverviewPage;
  /** Newsletter confirmation page queries */
  newsletterConfirmation: NewsletterConfirmationContext;
  /** Newsletter finalization page queries */
  newsletterFinalization: NewsletterFinalizationContext;
  /** Newsletter subscription page queries */
  newsletterSubscription: NewsletterSubscriptionContext;
  /** Newsletter unsubscription page queries */
  newsletterUnsubscription: NewsletterUnsubscriptionContext;
  /** Newsletter unsubscription finalization page queries */
  newsletterUnsubscriptionFinalization: NewsletterUnsubscriptionFinalizationContext;
  notificationData: Array<Notification>;
  notificationInfoData: NotificationInfo;
  organization: Organization;
  orl: OrlContext;
  pageB2BLogin: PageB2BLoginData;
  /** Load page footer data */
  pageFooter: PageFooterData;
  /** Load page header data */
  pageHeader: PageHeaderData;
  pageNotFound404: PageNotFound404Data;
  pdp: PdpContext;
  popUpMenu: PopUpMenuData;
  productWaitingScreen: WaitingScreen;
  /** Get floating request button */
  requestButton?: Maybe<RequestButtonComponent>;
  /** Search overlay */
  searchOverlay: SearchOverlay;
  /** SRL page queries */
  srl: SrlContext;
  /** Static page queries */
  static: StaticContext;
  theme: ThemeContext;
  themeGeo: ThemeGeoContext;
  themeOverview: ThemeOverviewContext;
  wishlist: WishlistContext;
  wishlistItem?: Maybe<WishlistItem>;
};

/** Query root */
export type QueryAccessBookingArgs = {
  context: RequestContextInput;
};

/** Query root */
export type QueryAccountArgs = {
  context: RequestContextInput;
};

/** Query root */
export type QueryAgencyArgs = {
  context?: InputMaybe<RequestContextInput>;
  id: Scalars['String'];
};

/** Query root */
export type QueryAgencyContactArgs = {
  context?: InputMaybe<RequestContextInput>;
  id: Scalars['String'];
};

/** Query root */
export type QueryAgencyOverviewArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryBlogArticleArgs = {
  context: RequestContextInput;
  id: Scalars['String'];
};

/** Query root */
export type QueryBlogOverviewArgs = {
  context: RequestContextInput;
};

/** Query root */
export type QueryBookingAfterCancellationArgs = {
  request: BookingCancellationResultRequestInput;
};

/** Query root */
export type QueryBookingAfterPaymentArgs = {
  bookingPaymentResultRequest: BookingPaymentResultRequestInput;
};

/** Query root */
export type QueryBookmarkArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryBookmarkPageArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryBoostersArgs = {
  context?: InputMaybe<RequestContextInput>;
  exclude?: InputMaybe<Array<Scalars['String']>>;
  objectId?: InputMaybe<Scalars['String']>;
  pageType: PageType;
};

/** Query root */
export type QueryCatalogOverviewArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryCheckBookmarkStatusArgs = {
  objectId: Scalars['String'];
  pageType: PageType;
};

/** Query root */
export type QueryComponentsArgs = {
  gss?: InputMaybe<GlobalSearchStateInput>;
  input: ComponentsQueryInput;
  requestContext: RequestContextInput;
};

/** Query root */
export type QueryContactArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryCookieDisclaimerArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryEmailAdvertisingRevocationArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryEmailAdvertisingRevocationConfirmationArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryFlightHomeArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryFlightSrlArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryFlightWaitingScreenArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryGeoArgs = {
  context?: InputMaybe<RequestContextInput>;
  criteria?: InputMaybe<GeoCriteriaInput>;
  currentUri?: InputMaybe<Scalars['String']>;
  encodedCriteria?: InputMaybe<Scalars['String']>;
  gss?: InputMaybe<GlobalSearchStateInput>;
  id: Scalars['String'];
  siblingsPage?: InputMaybe<PageInput>;
};

/** Query root */
export type QueryGeoOverviewArgs = {
  context?: InputMaybe<RequestContextInput>;
  gss?: InputMaybe<GlobalSearchStateInput>;
  id?: InputMaybe<Scalars['String']>;
  searchCriteria?: InputMaybe<GeoCriteriaInput>;
};

/** Query root */
export type QueryHelpOverlayDataArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryHistoryArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryHolidayFinderArgs = {
  context: RequestContextInput;
};

/** Query root */
export type QueryHomeArgs = {
  context?: InputMaybe<RequestContextInput>;
  deviceType: DeviceType;
};

/** Query root */
export type QueryMyAccountWaitingScreenArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryNewsArticlePageArgs = {
  context: RequestContextInput;
  id: Scalars['String'];
};

/** Query root */
export type QueryNewsArticlesOverviewArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryNewsletterConfirmationArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryNewsletterFinalizationArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryNewsletterSubscriptionArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryNewsletterUnsubscriptionArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryNewsletterUnsubscriptionFinalizationArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryNotificationDataArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryNotificationInfoDataArgs = {
  context: RequestContextInput;
};

/** Query root */
export type QueryOrganizationArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryOrlArgs = {
  context?: InputMaybe<RequestContextInput>;
  criteria?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Query root */
export type QueryPageB2BLoginArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryPageFooterArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryPageHeaderArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryPageNotFound404Args = {
  context?: InputMaybe<RequestContextInput>;
  currentUrl?: InputMaybe<Scalars['String']>;
  searchCriteria?: InputMaybe<StaticPageSearchCriteriaInput>;
};

/** Query root */
export type QueryPdpArgs = {
  context?: InputMaybe<RequestContextInput>;
  id?: InputMaybe<Scalars['String']>;
};

/** Query root */
export type QueryPopUpMenuArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryProductWaitingScreenArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryRequestButtonArgs = {
  currentUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  pageType: PageType;
  requestContext: RequestContextInput;
};

/** Query root */
export type QuerySearchOverlayArgs = {
  context: RequestContextInput;
  searchQuery?: InputMaybe<Scalars['String']>;
};

/** Query root */
export type QuerySrlArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryStaticArgs = {
  context?: InputMaybe<RequestContextInput>;
  url: Scalars['String'];
};

/** Query root */
export type QueryThemeArgs = {
  context?: InputMaybe<RequestContextInput>;
  id: Scalars['String'];
};

/** Query root */
export type QueryThemeGeoArgs = {
  context?: InputMaybe<RequestContextInput>;
  geoId: Scalars['String'];
  themeId: Scalars['String'];
};

/** Query root */
export type QueryThemeOverviewArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Query root */
export type QueryWishlistArgs = {
  context: RequestContextInput;
};

/** Query root */
export type QueryWishlistItemArgs = {
  id: Scalars['String'];
};

export type RequestContextInput = {
  agency?: InputMaybe<Scalars['String']>;
  b2bMarkup?: InputMaybe<Scalars['Int']>;
  bd4TravelUserId?: InputMaybe<Scalars['String']>;
  device: DeviceType;
  language: PageLanguage;
  partner?: InputMaybe<Scalars['String']>;
};

/** Enum describing client device type */
export enum DeviceType {
  /** Desktop device */
  Desktop = 'DESKTOP',
  /** Mobile device */
  Mobile = 'MOBILE',
}

/** Page language */
export enum PageLanguage {
  /** German page language */
  De = 'DE',
  /** English page language */
  En = 'EN',
  /** French page language */
  Fr = 'FR',
  /** Unknown page language */
  Unknown = 'UNKNOWN',
}

export type AccessBookingContext = {
  __typename?: 'AccessBookingContext';
  /** Get access booking detail page */
  accessBookingDetailPage: AccessBookingPageResponse;
  /** Get booking by data */
  bookingByData: BookingDetailsResponse;
  /** Get booking cancellation info */
  bookingCancellationInfo: BookingCancellationInfoResponse;
  version: Scalars['String'];
};

export type AccessBookingContextBookingByDataArgs = {
  request: GetBookingByDataRequestInput;
};

export type AccessBookingContextBookingCancellationInfoArgs = {
  bookingId: Scalars['String'];
};

export type AccessBookingPageResponse = {
  __typename?: 'AccessBookingPageResponse';
  activitiesSectionTitle?: Maybe<Scalars['String']>;
  bookingDetailSectionTitle?: Maybe<Scalars['String']>;
  bookingHeaderSectionTitle?: Maybe<Scalars['String']>;
  cancellationSectionButtonText?: Maybe<Scalars['String']>;
  cancellationSectionText?: Maybe<Scalars['String']>;
  cancellationSectionTitle?: Maybe<Scalars['String']>;
  flightInformationSection?: Maybe<AccessBookingFlightInformation>;
  flightInformationSectionTitle?: Maybe<Scalars['String']>;
  mainText: Scalars['String'];
  mainTitle: Scalars['String'];
  meta: PageMetaData;
  paymentInformationSectionTitle?: Maybe<Scalars['String']>;
  subTitle: Scalars['String'];
  transferInformationSection?: Maybe<AccessBookingPageTransferInfo>;
  travelDocumentSection?: Maybe<AccessBookingTravelDocument>;
  travelDocumentSectionTitle?: Maybe<Scalars['String']>;
};

export type AccessBookingFlightInformation = {
  __typename?: 'AccessBookingFlightInformation';
  additionalFlightInformation?: Maybe<Scalars['String']>;
  checkInTitle?: Maybe<Scalars['String']>;
  chickInText?: Maybe<Scalars['String']>;
  mainText?: Maybe<Scalars['String']>;
  mainTitle?: Maybe<Scalars['String']>;
  serviceText?: Maybe<Scalars['String']>;
  serviceTitle?: Maybe<Scalars['String']>;
};

/** Page meta data */
export type PageMetaData = {
  __typename?: 'PageMetaData';
  /** Page description */
  description: Scalars['String'];
  /** Page icon */
  icon?: Maybe<Scalars['String']>;
  /** Is page indexed */
  index: Scalars['Boolean'];
  /** Page keywords */
  keywordsTag: Scalars['String'];
  noFollow: Scalars['Boolean'];
  /** Page OpenGraph properties */
  openGraphProps: Scalars['Map_String_StringScalar'];
  sitemap: Scalars['Boolean'];
  /** Page title */
  title: Scalars['String'];
};

export type AccessBookingPageTransferInfo = {
  __typename?: 'AccessBookingPageTransferInfo';
  accessBookingTransferInformationImage?: Maybe<Image>;
  buttonLink?: Maybe<Link>;
  buttonTitle?: Maybe<Scalars['String']>;
  mainText?: Maybe<Scalars['String']>;
  mainTitle?: Maybe<Scalars['String']>;
};

export type AccessBookingPageTransferInfoAccessBookingTransferInformationImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ImageCriteriaInput = {
  binary?: InputMaybe<Scalars['Boolean']>;
  fit?: InputMaybe<Scalars['Boolean']>;
  height?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  resizingType?: InputMaybe<ImageResizingType>;
  width?: InputMaybe<Scalars['Int']>;
};

export enum ImageResizingType {
  Auto = 'AUTO',
  Fill = 'FILL',
  FillDown = 'FILL_DOWN',
  Fit = 'FIT',
  Force = 'FORCE',
}

export type Image = {
  __typename?: 'Image';
  alt: Scalars['String'];
  copyright?: Maybe<Scalars['String']>;
  /** desaturated image or not, if it's true, then desaturation filter should be skipped */
  desaturated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  resized: Array<ResizedImage>;
};

export type ResizedImage = {
  __typename?: 'ResizedImage';
  id: Scalars['String'];
  url: Scalars['String'];
};

/** Link generic type */
export type Link = ExternalLink | InternalLink;

/** Configuration for links leading to external resource */
export type ExternalLink = {
  __typename?: 'ExternalLink';
  /** Link caption */
  caption?: Maybe<Scalars['String']>;
  /** Describes how this link should be opened */
  openMethod: OpenLinkMethod;
  /** External url */
  url: Scalars['String'];
};

/** Enum describing where link should be opened */
export enum OpenLinkMethod {
  /** Link should be opened in a new tab */
  NewTab = 'NEW_TAB',
  /** Link should be opened in a new window */
  NewWindow = 'NEW_WINDOW',
  /** Link should be opened in an overlay */
  Overlay = 'OVERLAY',
  /** Link should be opened in the current tab */
  ThisTab = 'THIS_TAB',
}

/** Configuration for links leading to internal resource in application */
export type InternalLink = {
  __typename?: 'InternalLink';
  /** Link caption */
  caption?: Maybe<Scalars['String']>;
  /**
   * Requested page object id
   * @deprecated id is always included in uri
   */
  objectId?: Maybe<Scalars['String']>;
  /** Requested page type */
  targetPageType: PageType;
  /** Requested page URI */
  uri: Scalars['String'];
};

export enum PageType {
  AgencyObject = 'AGENCY_OBJECT',
  AgencyOverview = 'AGENCY_OVERVIEW',
  B2BLogin = 'B2B_LOGIN',
  BlogArticle = 'BLOG_ARTICLE',
  BlogOverview = 'BLOG_OVERVIEW',
  Bookmark = 'BOOKMARK',
  CatalogOverview = 'CATALOG_OVERVIEW',
  CombinedThemeGeo = 'COMBINED_THEME_GEO',
  Contact = 'CONTACT',
  EmailAdvertisingRevocation = 'EMAIL_ADVERTISING_REVOCATION',
  EmailAdvertisingRevocationConfirmation = 'EMAIL_ADVERTISING_REVOCATION_CONFIRMATION',
  FlightHome = 'FLIGHT_HOME',
  FlightSrl = 'FLIGHT_SRL',
  GeoChildrenOffers = 'GEO_CHILDREN_OFFERS',
  GeoClimate = 'GEO_CLIMATE',
  GeoFacts = 'GEO_FACTS',
  GeoMap = 'GEO_MAP',
  GeoObject = 'GEO_OBJECT',
  GeoOverview = 'GEO_OVERVIEW',
  GeoSrl = 'GEO_SRL',
  HolidayFinderLanding = 'HOLIDAY_FINDER_LANDING',
  HolidayFinderResults = 'HOLIDAY_FINDER_RESULTS',
  HolidayFinderVoting = 'HOLIDAY_FINDER_VOTING',
  Home = 'HOME',
  MyaccountBookingDetails = 'MYACCOUNT_BOOKING_DETAILS',
  MyaccountConfirmation = 'MYACCOUNT_CONFIRMATION',
  MyaccountLogin = 'MYACCOUNT_LOGIN',
  MyaccountMybookings = 'MYACCOUNT_MYBOOKINGS',
  MyaccountMybookingsPast = 'MYACCOUNT_MYBOOKINGS_PAST',
  MyaccountMybookingsUpcoming = 'MYACCOUNT_MYBOOKINGS_UPCOMING',
  MyaccountMyhistory = 'MYACCOUNT_MYHISTORY',
  MyaccountMyprofile = 'MYACCOUNT_MYPROFILE',
  MyaccountOverview = 'MYACCOUNT_OVERVIEW',
  MyaccountRegistration = 'MYACCOUNT_REGISTRATION',
  MyaccountResetPassword = 'MYACCOUNT_RESET_PASSWORD',
  NewsArticle = 'NEWS_ARTICLE',
  NewsArticleOverview = 'NEWS_ARTICLE_OVERVIEW',
  NewsletterConfirmation = 'NEWSLETTER_CONFIRMATION',
  NewsletterFinalization = 'NEWSLETTER_FINALIZATION',
  NewsletterSubscription = 'NEWSLETTER_SUBSCRIPTION',
  NewsletterUnsubscription = 'NEWSLETTER_UNSUBSCRIPTION',
  NewsletterUnsubscriptionFinalization = 'NEWSLETTER_UNSUBSCRIPTION_FINALIZATION',
  Orl = 'ORL',
  Page_404 = 'PAGE_404',
  Pdp = 'PDP',
  PdpClimate = 'PDP_CLIMATE',
  PdpDescription = 'PDP_DESCRIPTION',
  PdpItinerary = 'PDP_ITINERARY',
  PdpMap = 'PDP_MAP',
  PdpRating = 'PDP_RATING',
  PdpRegion = 'PDP_REGION',
  PdpResort = 'PDP_RESORT',
  PdpReviews = 'PDP_REVIEWS',
  Srl = 'SRL',
  Static = 'STATIC',
  ThemeObject = 'THEME_OBJECT',
  ThemeOverview = 'THEME_OVERVIEW',
  ThemeSrl = 'THEME_SRL',
  Wishlist = 'WISHLIST',
  WishlistCompare = 'WISHLIST_COMPARE',
  WishlistList = 'WISHLIST_LIST',
  WishlistMap = 'WISHLIST_MAP',
  WishlistOfferCompare = 'WISHLIST_OFFER_COMPARE',
}

export type AccessBookingTravelDocument = {
  __typename?: 'AccessBookingTravelDocument';
  buttonTitle?: Maybe<Scalars['String']>;
  mainText?: Maybe<Scalars['String']>;
  mainTitle?: Maybe<Scalars['String']>;
};

export type GetBookingByDataRequestInput = {
  departureDate: Scalars['String'];
  lastName: Scalars['String'];
  orderNumber: Scalars['String'];
};

export type BookingDetailsResponse = {
  __typename?: 'BookingDetailsResponse';
  accessRestrictionStatus?: Maybe<AccessRestrictionStatus>;
  bookingDetails?: Maybe<BookingDetails>;
  hideActivities: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export enum AccessRestrictionStatus {
  Expired = 'EXPIRED',
  UnknownStatus = 'UNKNOWN_STATUS',
  VflyStatus = 'VFLY_STATUS',
}

export type BookingDetails = {
  __typename?: 'BookingDetails';
  allowedPaymentMethods?: Maybe<Array<PaymentMethod>>;
  arrivalDate: Scalars['LocalDate'];
  baseErrorResponse?: Maybe<BookingItem>;
  bookingExtraServices?: Maybe<Array<BookingExtraService>>;
  bookingId: Scalars['String'];
  bookingNumber: Scalars['String'];
  bookingStatus: BookingStatus;
  cancellationDate?: Maybe<Scalars['LocalDate']>;
  cancellationRules?: Maybe<Array<CancellationServiceInfo>>;
  cancelledBookingPayment?: Maybe<PaymentRecord>;
  cancelSearchLink?: Maybe<InternalLink>;
  carRentalDetailList?: Maybe<Array<CarRentalDetails>>;
  contactInfo?: Maybe<Agency>;
  creationDate?: Maybe<Scalars['LocalDate']>;
  customer?: Maybe<AccountPersonalDataResponse>;
  customerErrata?: Maybe<Array<Scalars['String']>>;
  default: Scalars['Boolean'];
  departureDate: Scalars['LocalDate'];
  destination?: Maybe<DestinationInfo>;
  duration: Scalars['String'];
  emergencyPhone?: Maybe<PhoneDetails>;
  flightBookingDetails?: Maybe<Array<FlightBookingDetails>>;
  flightType?: Maybe<Scalars['String']>;
  hotels?: Maybe<Array<HotelBookingDetails>>;
  paymentCondition?: Maybe<PaymentCondition>;
  paymentInformationSection?: Maybe<PaymentInformationSection>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  productStatus?: Maybe<ProductStatus>;
  totalPrice: Price;
  transfers?: Maybe<Array<BookingTransfer>>;
  travellerInfos?: Maybe<Array<TravellerInfo>>;
  travelType: TravelType;
  zurichAirportHotelOffer?: Maybe<BookingDetailsHotelOffer>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  paymentId?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
};

export type BookingItem = {
  __typename?: 'BookingItem';
  arrivalDate: Scalars['LocalDate'];
  boardType?: Maybe<Scalars['String']>;
  /** Booking id */
  bookingId: Scalars['String'];
  bookingItemImage: Image;
  bookingStatus: BookingStatus;
  departureDate: Scalars['LocalDate'];
  duration: Scalars['String'];
  flightDestinationInfo?: Maybe<FlightDestinationInfo>;
  /** Giata id */
  giataId?: Maybe<Scalars['String']>;
  hotelDestinationInfo?: Maybe<HotelDestinationInfo>;
  link?: Maybe<InternalLink>;
  /** Product name */
  name: Scalars['String'];
  productBreadcrumbs?: Maybe<BreadcrumbsComponent>;
  /** Product class */
  productClass?: Maybe<ProductClass>;
  travelType: TravelType;
};

export type BookingItemBookingItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export enum BookingStatus {
  Booked = 'BOOKED',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Travelled = 'TRAVELLED',
  Unknown = 'UNKNOWN',
}

export type FlightDestinationInfo = {
  __typename?: 'FlightDestinationInfo';
  arrivalAirport?: Maybe<Airport>;
  departureAirport?: Maybe<Airport>;
};

export type Airport = {
  __typename?: 'Airport';
  coordinates?: Maybe<GeoCoordinates>;
  iata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GeoCoordinates = {
  __typename?: 'GeoCoordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type HotelDestinationInfo = {
  __typename?: 'HotelDestinationInfo';
  coordinates?: Maybe<GeoCoordinates>;
  country?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type BreadcrumbsComponent = {
  __typename?: 'BreadcrumbsComponent';
  breadcrumbs: Array<Breadcrumb>;
};

export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  caption: Scalars['String'];
  link?: Maybe<InternalLink>;
};

export enum ProductClass {
  Apartment = 'APARTMENT',
  Cruise = 'CRUISE',
  Hotel = 'HOTEL',
  Roundtrip = 'ROUNDTRIP',
}

/** Travel type */
export enum TravelType {
  /** Flight travel type */
  Flight = 'FLIGHT',
  /** Hotel only travel type */
  Hotel = 'HOTEL',
  /** Package travel type */
  Package = 'PACKAGE',
}

export type BookingExtraService = {
  __typename?: 'BookingExtraService';
  detailedDescription?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  travellers?: Maybe<Array<Traveller>>;
};

export type Traveller = {
  __typename?: 'Traveller';
  dateOfBirth: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  ref: Scalars['Int'];
  type: Scalars['String'];
};

export type CancellationServiceInfo = {
  __typename?: 'CancellationServiceInfo';
  descriptions?: Maybe<Array<Scalars['String']>>;
  endDate?: Maybe<Scalars['LocalDate']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['LocalDate']>;
  title?: Maybe<Scalars['String']>;
};

export type PaymentRecord = {
  __typename?: 'PaymentRecord';
  amount?: Maybe<Price>;
  dueDate?: Maybe<Scalars['String']>;
  paymentDone?: Maybe<Price>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentStatusAmount?: Maybe<Price>;
};

export type Price = {
  __typename?: 'Price';
  /** Price amount */
  amount: Scalars['String'];
  /** Price currency */
  currency: Scalars['String'];
  /**
   * Price value
   * @deprecated Use amount field
   */
  total: Scalars['String'];
};

export enum PaymentStatus {
  Open = 'OPEN',
  Paid = 'PAID',
}

export type CarRentalDetails = {
  __typename?: 'CarRentalDetails';
  carDetails?: Maybe<CarDetails>;
  carDriver?: Maybe<CarDriver>;
  carName?: Maybe<Scalars['String']>;
  carRentalImage?: Maybe<Image>;
  companyName?: Maybe<Scalars['String']>;
  dropoffDate?: Maybe<Scalars['LocalDate']>;
  dropoffLocation?: Maybe<Scalars['String']>;
  dropoffTime?: Maybe<Scalars['LocalTime']>;
  pickupDate?: Maybe<Scalars['LocalDate']>;
  pickupLocation?: Maybe<Scalars['String']>;
  pickupTime?: Maybe<Scalars['LocalTime']>;
  rentalConditions?: Maybe<Scalars['String']>;
};

export type CarRentalDetailsCarRentalImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type CarDetails = {
  __typename?: 'CarDetails';
  availAirCondition: Scalars['Boolean'];
  gear?: Maybe<Scalars['String']>;
  numberOfDoors?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
};

export type CarDriver = {
  __typename?: 'CarDriver';
  dateOfBirth: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Agency = {
  __typename?: 'Agency';
  /** @deprecated Deprecated. Please use addressDetails */
  address: Scalars['String'];
  addressDetails: AddressDetails;
  appointmentFormText: Scalars['String'];
  appointmentFormTitle: Scalars['String'];
  appointmentLink: ExternalLink;
  appointmentText: Scalars['String'];
  appointmentTextBgColor?: Maybe<Scalars['String']>;
  appointmentTextBgImage?: Maybe<Image>;
  appointmentTextFgColor?: Maybe<Scalars['String']>;
  appointmentTitle: Scalars['String'];
  /** Shows if the agency office is physically open and is available to offline customers */
  availableOffline: Scalars['Boolean'];
  brand: AgencyBrand;
  breadcrumbs?: Maybe<BreadcrumbsComponent>;
  coordinates?: Maybe<GeoCoordinates>;
  /** @deprecated use root components query */
  dynamicComponents: Array<AgencyDynamicComponent>;
  email: Scalars['String'];
  formTextBgColor?: Maybe<Scalars['String']>;
  formTextBgImage?: Maybe<Image>;
  formTextFgColor?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image: Image;
  images: Array<Image>;
  mainText?: Maybe<Scalars['String']>;
  /** Get meta data */
  meta: PageMetaData;
  opened?: Maybe<Scalars['Boolean']>;
  openingHours: Array<OpeningHours>;
  phone: ExternalLink;
  requestButton: RequestButtonComponent;
  review?: Maybe<Review>;
  specialOpeningHours?: Maybe<Array<SpecialOpeningHours>>;
  tagline?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  title: Scalars['String'];
  url: InternalLink;
  version: Scalars['String'];
};

export type AgencyAppointmentTextBgImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type AgencyFormTextBgImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type AgencyImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type AgencyImagesArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Address details */
export type AddressDetails = {
  __typename?: 'AddressDetails';
  /** City */
  city?: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** Region */
  region?: Maybe<Scalars['String']>;
  /** Street */
  street?: Maybe<Scalars['String']>;
  /** Zip/postal code */
  zip?: Maybe<Scalars['String']>;
};

export enum AgencyBrand {
  GlobusReisen = 'GLOBUS_REISEN',
  Hotelplan = 'HOTELPLAN',
  Sv = 'SV',
  Tpt = 'TPT',
  Travelhouse = 'TRAVELHOUSE',
  Unspecified = 'UNSPECIFIED',
}

/** Generic type for all agency page dynamic components */
export type AgencyDynamicComponent =
  | AgencyRecommendationGroup
  | MediaGallery
  | TextComponent;

export type AgencyRecommendationGroup = {
  __typename?: 'AgencyRecommendationGroup';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<AgencyRecommendationItemDeprecated>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type AgencyRecommendationItemDeprecated =
  | Bd4TravelRecommendation
  | BlogArticleRecommendationItem
  | GeoRecommendationItem
  | MarketingRecommendationItem
  | ProductRecommendationItem
  | ThemeRecommendationItem;

/** BD4Travel recommendation */
export type Bd4TravelRecommendation = {
  __typename?: 'BD4TravelRecommendation';
  /** Offer adults */
  adults: Scalars['Int'];
  /** Offer children */
  children: Scalars['Int'];
  /** Product coordinates */
  coordinates: GeoCoordinates;
  /** Departure date */
  departureDate: Scalars['String'];
  /** Offer disrupter. Optional */
  disrupter?: Maybe<Scalars['String']>;
  /** Disrupter object. Optional */
  disrupterObject?: Maybe<DisrupterObject>;
  /** Distance info. Hotel only */
  distanceInfo?: Maybe<Scalars['String']>;
  /** Duration of stay */
  duration: Scalars['Int'];
  /**
   * Product features
   * @deprecated use featureSummary
   */
  features: Array<ProductFeature>;
  /** Product features */
  featureSummary: Array<ProductFeatureGroup>;
  /** Travel from/to. Roundtrip or ship only */
  fromTo?: Maybe<Scalars['String']>;
  /** Location breadcrumbs */
  geoBreadcrumbs: Array<GeoObject>;
  /** Giata id */
  giata: Scalars['String'];
  /** Hotelplan rating. Optional */
  hpRating?: Maybe<Scalars['Int']>;
  image: Image;
  inWishlist: Scalars['Boolean'];
  /** Link to Product detail page */
  link: InternalLink;
  /** Map zoom */
  mapZoom: Scalars['Int'];
  mealType: Scalars['String'];
  /** Product name */
  name: Scalars['String'];
  offerId: Scalars['String'];
  /** Per person price */
  price: Price;
  /** Product class */
  productClass: ProductClass;
  /** Product code */
  productCode?: Maybe<Scalars['String']>;
  /** Travel provider */
  provider?: Maybe<Scalars['String']>;
  /** Return date */
  returnDate: Scalars['String'];
  /** Rooms info */
  rooms?: Maybe<Array<Maybe<Room>>>;
  /** Tripadvisor rating. Optional */
  taRating?: Maybe<Scalars['Int']>;
  /** Tripadvisor reviews. Optional */
  taReviews?: Maybe<Scalars['Int']>;
  /** Total price */
  totalPrice: Price;
  /** Is transfer included */
  transferIncluded?: Maybe<Scalars['Boolean']>;
  /** Transfer price */
  transferPrice?: Maybe<Price>;
  /** Travel type. Roundtrip or ship only */
  travelType?: Maybe<Scalars['String']>;
  /** Type of product item */
  type: ProductType;
  wishlistItem?: Maybe<WishlistItem>;
};

/** BD4Travel recommendation */
export type Bd4TravelRecommendationImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type DisrupterObject = {
  __typename?: 'DisrupterObject';
  /** DisrupterObject BackgroundColor value */
  backgroundColor?: Maybe<Scalars['String']>;
  /** DisrupterObject FontColor value */
  fontColor?: Maybe<Scalars['String']>;
  /** DisrupterObject icon type value */
  iconType?: Maybe<Scalars['String']>;
  /** DisrupterObject main title value */
  mainTitle?: Maybe<Scalars['String']>;
  /** DisrupterObject sub title value */
  subTitle?: Maybe<Scalars['String']>;
  /** DisrupterObject text value */
  text: Scalars['String'];
};

/** Hotel/product attribute detail */
export type ProductFeature = {
  __typename?: 'ProductFeature';
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Hotel/product attribute */
export type ProductFeatureGroup = {
  __typename?: 'ProductFeatureGroup';
  features: Array<ProductFeature>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type GeoObject = {
  __typename?: 'GeoObject';
  /** Geo object id */
  id: Scalars['String'];
  /** Geo object name */
  name: Scalars['String'];
  /** Geo object type */
  type: GeoType;
};

export enum GeoType {
  Continent = 'CONTINENT',
  Country = 'COUNTRY',
  CountryGroup = 'COUNTRY_GROUP',
  Destination = 'DESTINATION',
  Region = 'REGION',
  Resort = 'RESORT',
}

export type Room = {
  __typename?: 'Room';
  boardType?: Maybe<Scalars['String']>;
  bookingCode?: Maybe<Scalars['String']>;
  roomType?: Maybe<Scalars['String']>;
};

export enum ProductType {
  /** Hotel only */
  Hotel = 'HOTEL',
  /** Package */
  Package = 'PACKAGE',
}

export type WishlistItem = {
  __typename?: 'WishlistItem';
  id: Scalars['String'];
  inWishlist: Scalars['Boolean'];
};

/** Blog article recommendation */
export type BlogArticleRecommendationItem = {
  __typename?: 'BlogArticleRecommendationItem';
  /** Blog article date */
  date: Scalars['String'];
  image: Image;
  /** Recommendation link */
  link: Link;
  /** Blog article tag */
  tag: Scalars['String'];
  /** Recommendation text */
  text: Scalars['String'];
  /** Recommendation title */
  title: Scalars['String'];
};

/** Blog article recommendation */
export type BlogArticleRecommendationItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Geo recommendation */
export type GeoRecommendationItem = {
  __typename?: 'GeoRecommendationItem';
  /** Offer adults */
  adults: Scalars['Int'];
  /** Average temperature. Only for destination */
  averageTemp?: Maybe<Scalars['String']>;
  /** Offer children */
  children: Scalars['Int'];
  /** Geo coordinates */
  coordinates: GeoCoordinates;
  /** Amount of available offers */
  count: Scalars['Int'];
  /** Geo object country. Only for region geo objects and lower */
  country?: Maybe<Scalars['String']>;
  destinationName?: Maybe<Scalars['String']>;
  /** Disrupter. Optional */
  disrupter?: Maybe<Scalars['String']>;
  /** Disrupter object. Optional */
  disrupterObject?: Maybe<DisrupterObject>;
  /** Offer length of stay */
  duration: Scalars['Int'];
  /** Flight duration. Only for package search */
  flightDuration?: Maybe<Scalars['String']>;
  /** Geo object */
  geoObject: GeoObject;
  image: Image;
  leadText?: Maybe<Scalars['String']>;
  /** Link to SRL single */
  link: InternalLink;
  /** Map zoom */
  mapZoom: Scalars['Int'];
  /** Link to geo overview page. Desktop only */
  moreInfo?: Maybe<InternalLink>;
  /** Best offer price */
  perPersonPrice: Price;
  travelType?: Maybe<TravelType>;
};

/** Geo recommendation */
export type GeoRecommendationItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Marketing recommendation */
export type MarketingRecommendationItem = {
  __typename?: 'MarketingRecommendationItem';
  /** Recommendation link */
  link: Link;
  /** Recommendation main text */
  mainText: Scalars['String'];
  /** Recommendation media content */
  preview: Media;
  /** Recommendation subtitle */
  subtitle: Scalars['String'];
  /** Recommendation title */
  title: Scalars['String'];
};

export type Media =
  | ExternalMediaItem
  | ImageMediaItem
  | MusicMediaItem
  | PdfMediaItem
  | VideoMediaItem;

export type ExternalMediaItem = {
  __typename?: 'ExternalMediaItem';
  link?: Maybe<Link>;
  preview: Image;
  resource: Scalars['String'];
  title: Scalars['String'];
};

export type ExternalMediaItemPreviewArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ImageMediaItem = {
  __typename?: 'ImageMediaItem';
  image: Image;
  link?: Maybe<Link>;
  title: Scalars['String'];
};

export type ImageMediaItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type MusicMediaItem = {
  __typename?: 'MusicMediaItem';
  link?: Maybe<Link>;
  preview: Image;
  resource: Scalars['String'];
  title: Scalars['String'];
};

export type MusicMediaItemPreviewArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type PdfMediaItem = {
  __typename?: 'PdfMediaItem';
  link?: Maybe<Link>;
  preview: Image;
  resource: Scalars['String'];
  title: Scalars['String'];
};

export type PdfMediaItemPreviewArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type VideoMediaItem = {
  __typename?: 'VideoMediaItem';
  copyright?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  link?: Maybe<Link>;
  preview: Image;
  ratio?: Maybe<Scalars['Float']>;
  resource: Scalars['String'];
  title: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type VideoMediaItemPreviewArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Product Recommendation item */
export type ProductRecommendationItem = {
  __typename?: 'ProductRecommendationItem';
  /** Offer adults */
  adults: Scalars['Int'];
  /** Offer children */
  children: Scalars['Int'];
  /** Product coordinates */
  coordinates: GeoCoordinates;
  /** Departure date */
  departureDate: Scalars['String'];
  /** Offer disrupter. Optional */
  disrupter?: Maybe<Scalars['String']>;
  /** Disrupter object. Optional */
  disrupterObject?: Maybe<DisrupterObject>;
  /** Distance info. Hotel only */
  distanceInfo?: Maybe<Scalars['String']>;
  /** Duration of stay */
  duration: Scalars['Int'];
  /**
   * Product features
   * @deprecated use featureSummary
   */
  features: Array<ProductFeature>;
  /** Product features */
  featureSummary: Array<ProductFeatureGroup>;
  /** Travel from/to. Roundtrip or ship only */
  fromTo?: Maybe<Scalars['String']>;
  /** Location breadcrumbs */
  geoBreadcrumbs: Array<GeoObject>;
  /** Giata id */
  giata: Scalars['String'];
  /** Hotelplan rating. Optional */
  hpRating?: Maybe<Scalars['Int']>;
  image: Image;
  inWishlist: Scalars['Boolean'];
  /** Link to Product detail page */
  link: InternalLink;
  /** Map zoom */
  mapZoom: Scalars['Int'];
  mealType: Scalars['String'];
  /** Product name */
  name: Scalars['String'];
  offerId: Scalars['String'];
  /** Per person price */
  price: Price;
  /** Product class */
  productClass: ProductClass;
  /** Product code */
  productCode?: Maybe<Scalars['String']>;
  /** Travel provider */
  provider?: Maybe<Scalars['String']>;
  /** Return date */
  returnDate: Scalars['String'];
  /** Rooms info */
  rooms?: Maybe<Array<Maybe<Room>>>;
  /** Tripadvisor rating. Optional */
  taRating?: Maybe<Scalars['Int']>;
  /** Tripadvisor reviews. Optional */
  taReviews?: Maybe<Scalars['Int']>;
  /** Total price */
  totalPrice: Price;
  /** Is transfer included */
  transferIncluded?: Maybe<Scalars['Boolean']>;
  /** Transfer price */
  transferPrice?: Maybe<Price>;
  /** Travel type. Roundtrip or ship only */
  travelType?: Maybe<Scalars['String']>;
  /** Type of product item */
  type: ProductType;
  wishlistItem?: Maybe<WishlistItem>;
};

/** Product Recommendation item */
export type ProductRecommendationItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Theme recommendation */
export type ThemeRecommendationItem = {
  __typename?: 'ThemeRecommendationItem';
  /** Adults count */
  adults: Scalars['Int'];
  /** Children count */
  children: Scalars['Int'];
  /** Recommendation destination name */
  destinationName?: Maybe<Scalars['String']>;
  /** Disrupter object. Optional */
  disrupterObject?: Maybe<DisrupterObject>;
  /** Recommended travel duration */
  duration: Scalars['Int'];
  /** Theme id */
  id: Scalars['String'];
  image: Image;
  /** Recommendation link */
  link: InternalLink;
  /** Theme recommendation caption */
  name: Scalars['String'];
  /** Recommended travel price */
  price: Price;
  /** Recommendation main text */
  text?: Maybe<Scalars['String']>;
  /** Recommendation total product count */
  totalProductCount?: Maybe<Scalars['Int']>;
  /** Recommendation travel type */
  travelType?: Maybe<TravelType>;
};

/** Theme recommendation */
export type ThemeRecommendationItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Generic type for recommendation tracking */
export type RecommendationTracking = Bd4TravelRecommendationTracking;

export type Bd4TravelRecommendationTracking = {
  __typename?: 'BD4TravelRecommendationTracking';
  info?: Maybe<Bd4TravelRecommendationInfo>;
  productCount?: Maybe<Scalars['Int']>;
  productIds?: Maybe<Array<Scalars['String']>>;
  ptoken?: Maybe<Scalars['String']>;
  status: Bd4TravelRecommendationStatus;
};

export type Bd4TravelRecommendationInfo = {
  __typename?: 'BD4TravelRecommendationInfo';
  modelId: Scalars['String'];
  placementId: Scalars['String'];
  strategy: Scalars['String'];
};

export enum Bd4TravelRecommendationStatus {
  ApiTimeout = 'API_TIMEOUT',
  NotAvailable = 'NOT_AVAILABLE',
  Success = 'SUCCESS',
  TooFewResultsFromApi = 'TOO_FEW_RESULTS_FROM_API',
  TooFewResultsFromHub = 'TOO_FEW_RESULTS_FROM_HUB',
}

/** Media gallery component */
export type MediaGallery = {
  __typename?: 'MediaGallery';
  anchor?: Maybe<Scalars['String']>;
  /** Get Media gallery BackgroundColor */
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Image>;
  /** Get Media gallery FontColor */
  fontColor?: Maybe<Scalars['String']>;
  /** Media gallery items */
  mediaItems: Array<Media>;
};

/** Media gallery component */
export type MediaGalleryBackgroundImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Non clickable text component with title and text */
export type TextComponent = {
  __typename?: 'TextComponent';
  anchor?: Maybe<Scalars['String']>;
  /** Text component BackgroundColor value */
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Image>;
  /** Bottom padding. Possible values: 'PADDING_SMALL','PADDING_LARGE', 'PADDING_NONE' */
  bottomPadding?: Maybe<Scalars['String']>;
  /** Text component FontColor value */
  fontColor?: Maybe<Scalars['String']>;
  /** Text component value */
  text: Scalars['String'];
  /** Text component width type. Possible values: 'SMALL','LARGE', 'WIDTH_NOT_SET' */
  textWidth?: Maybe<Scalars['String']>;
  /** Text component title */
  title: Scalars['String'];
  /** Top padding. Possible values: 'PADDING_SMALL',PADDING_'LARGE', 'PADDING_NONE' */
  topPadding?: Maybe<Scalars['String']>;
  /** Text component type. Possible values: 'raw','markdown','geo_lead','geo_long' */
  type: Scalars['String'];
};

/** Non clickable text component with title and text */
export type TextComponentBackgroundImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type OpeningHours = {
  __typename?: 'OpeningHours';
  open: Scalars['Boolean'];
  shifts: Array<Shift>;
};

export type Shift = {
  __typename?: 'Shift';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type RequestButtonComponent = {
  __typename?: 'RequestButtonComponent';
  backgroundColor?: Maybe<Scalars['String']>;
  caption: Scalars['String'];
  fontColor?: Maybe<Scalars['String']>;
  link: ExternalLink;
};

export type Review = {
  __typename?: 'Review';
  averageRating: Scalars['Float'];
  totalReviewCount: Scalars['Int'];
};

export type SpecialOpeningHours = {
  __typename?: 'SpecialOpeningHours';
  closed: Scalars['Boolean'];
  date: Scalars['String'];
  shifts?: Maybe<Array<Shift>>;
};

export type AccountPersonalDataResponse = {
  __typename?: 'AccountPersonalDataResponse';
  addressDetails?: Maybe<AddressDetails>;
  agency?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['LocalDate']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobileDetails?: Maybe<PhoneDetails>;
  nationality?: Maybe<Scalars['String']>;
  phoneDetails?: Maybe<PhoneDetails>;
  salutation?: Maybe<Salutation>;
};

export type PhoneDetails = {
  __typename?: 'PhoneDetails';
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export enum Salutation {
  Mr = 'MR',
  Ms = 'MS',
  SalutationUnknown = 'SALUTATION_UNKNOWN',
}

export type DestinationInfo = {
  __typename?: 'DestinationInfo';
  country?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  destinationCode?: Maybe<Scalars['String']>;
};

export type FlightBookingDetails = {
  __typename?: 'FlightBookingDetails';
  /** Flight details */
  flight: Flight;
  flightDirection?: Maybe<FlightDirection>;
  serviceCheckInUrl?: Maybe<Scalars['String']>;
  serviceExternalReference?: Maybe<Scalars['String']>;
  serviceInformationUrl?: Maybe<Scalars['String']>;
  serviceProvider?: Maybe<Scalars['String']>;
  travellers: Array<Traveller>;
};

export type Flight = {
  __typename?: 'Flight';
  arrival: Airport;
  arrivalDate: Scalars['LocalDate'];
  arrivalTime: Scalars['LocalTime'];
  baggageInfos?: Maybe<Array<FlightBaggageInfo>>;
  departure: Airport;
  departureDate: Scalars['LocalDate'];
  departureTime: Scalars['LocalTime'];
  duration: Scalars['String'];
  overnight: Scalars['Boolean'];
  segments: Array<FlightSegment>;
};

export type FlightBaggageInfo = {
  __typename?: 'FlightBaggageInfo';
  description?: Maybe<Scalars['String']>;
  measureUnit?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type FlightSegment = {
  __typename?: 'FlightSegment';
  airline: Airline;
  airportWaiting?: Maybe<Scalars['String']>;
  arrival: Airport;
  arrivalDate: Scalars['LocalDate'];
  arrivalTime: Scalars['LocalTime'];
  baggageInfos?: Maybe<Array<FlightBaggageInfo>>;
  departure: Airport;
  departureDate: Scalars['LocalDate'];
  departureTime: Scalars['LocalTime'];
  flightClass: FlightClass;
  flightNumber: Scalars['String'];
  operatingAirline?: Maybe<Airline>;
  segmentDuration: Scalars['String'];
};

export type Airline = {
  __typename?: 'Airline';
  airlineLogo: Scalars['String'];
  iata: Scalars['String'];
  name: Scalars['String'];
};

export enum FlightClass {
  Business = 'BUSINESS',
  Economy = 'ECONOMY',
  First = 'FIRST',
  PremiumEconomy = 'PREMIUM_ECONOMY',
}

export enum FlightDirection {
  FlightDirectionInbound = 'FLIGHT_DIRECTION_INBOUND',
  FlightDirectionOutbound = 'FLIGHT_DIRECTION_OUTBOUND',
}

export type HotelBookingDetails = {
  __typename?: 'HotelBookingDetails';
  bookingDetailsImage?: Maybe<Image>;
  checkinDate: Scalars['LocalDate'];
  checkoutDate: Scalars['LocalDate'];
  cruiseInfo?: Maybe<CruiseInfo>;
  duration: Scalars['String'];
  /** Giata id */
  giataId: Scalars['String'];
  hotelDestinationInfo?: Maybe<HotelDestinationInfo>;
  /** Hotelplan rating. Optional */
  hpRating?: Maybe<Scalars['Int']>;
  inGetYourGuideBlacklist: Scalars['Boolean'];
  /** Product name */
  name: Scalars['String'];
  productBreadcrumbs?: Maybe<BreadcrumbsComponent>;
  /** Product class */
  productClass?: Maybe<ProductClass>;
  rooms: Array<BookingHotelRoom>;
  roundtripInfo?: Maybe<RoundtripInfo>;
};

export type HotelBookingDetailsBookingDetailsImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type CruiseInfo = {
  __typename?: 'CruiseInfo';
  duration?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  included?: Maybe<Scalars['String']>;
};

export type BookingHotelRoom = {
  __typename?: 'BookingHotelRoom';
  boardTypes: Array<BookingBoardType>;
  roomType: BookingRoomType;
  travellers: Array<Traveller>;
};

export type BookingBoardType = {
  __typename?: 'BookingBoardType';
  code: Scalars['String'];
  description: Scalars['String'];
};

export type BookingRoomType = {
  __typename?: 'BookingRoomType';
  code: Scalars['String'];
  description: Scalars['String'];
};

export type RoundtripInfo = {
  __typename?: 'RoundtripInfo';
  duration?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  included?: Maybe<Scalars['String']>;
};

export type PaymentCondition = {
  __typename?: 'PaymentCondition';
  downPayment?: Maybe<Price>;
  openAmount: Price;
  restPayments?: Maybe<Array<Maybe<RestPayment>>>;
  total: Price;
};

export type RestPayment = {
  __typename?: 'RestPayment';
  date?: Maybe<Scalars['LocalDate']>;
  payment?: Maybe<Price>;
};

export type PaymentInformationSection = {
  __typename?: 'PaymentInformationSection';
  down: PaymentRecord;
  /** amount of initial payment, if it's possible or null value if not */
  initPaymentAmount?: Maybe<Price>;
  /** null if value is not defined */
  paymentAction?: Maybe<AvailablePaymentAction>;
  payments?: Maybe<Array<PaymentRecord>>;
  rest: PaymentRecord;
  total: PaymentRecord;
};

export enum AvailablePaymentAction {
  PaymentActionChangePayment = 'PAYMENT_ACTION_CHANGE_PAYMENT',
  PaymentActionInitPayment = 'PAYMENT_ACTION_INIT_PAYMENT',
  PaymentActionNotAvailable = 'PAYMENT_ACTION_NOT_AVAILABLE',
  PaymentActionOffline = 'PAYMENT_ACTION_OFFLINE',
}

export enum ProductStatus {
  Requested = 'REQUESTED',
  Unknown = 'UNKNOWN',
}

export type BookingTransfer = {
  __typename?: 'BookingTransfer';
  serviceStatus: ServiceStatus;
  transferDate: TransferDate;
  transferDetailInfo: TransferDetailInfo;
  transferInfo: TransferInfo;
  travellers: Array<Traveller>;
};

export enum ServiceStatus {
  Available = 'AVAILABLE',
  Confirmed = 'CONFIRMED',
  Unknown = 'UNKNOWN',
}

export type TransferDate = {
  __typename?: 'TransferDate';
  endDate?: Maybe<Scalars['LocalDate']>;
  startDate?: Maybe<Scalars['LocalDate']>;
};

export type TransferDetailInfo = {
  __typename?: 'TransferDetailInfo';
  from?: Maybe<TransferPointInfoUnion>;
  to?: Maybe<TransferPointInfoUnion>;
};

export type TransferPointInfoUnion = TransferFlightInfo | TransferHotelInfo;

export type TransferFlightInfo = {
  __typename?: 'TransferFlightInfo';
  airline?: Maybe<Airline>;
  airport?: Maybe<Airport>;
  flightNumber?: Maybe<Scalars['String']>;
};

export type TransferHotelInfo = {
  __typename?: 'TransferHotelInfo';
  giataId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TransferInfo = {
  __typename?: 'TransferInfo';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  transportType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TravellerInfo = {
  __typename?: 'TravellerInfo';
  dateOfBirth: Scalars['String'];
  firstName: Scalars['String'];
  insurance: Scalars['String'];
  lastName: Scalars['String'];
  salutation: Scalars['String'];
};

export type BookingDetailsHotelOffer = {
  __typename?: 'BookingDetailsHotelOffer';
  hotelId?: Maybe<Scalars['String']>;
  offerId?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  provider?: Maybe<Scalars['String']>;
  waitingScreenImage?: Maybe<Scalars['String']>;
};

export enum Status {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export type BookingCancellationInfoResponse = {
  __typename?: 'BookingCancellationInfoResponse';
  cancellable: Scalars['Boolean'];
  fee?: Maybe<Price>;
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type AccountContext = {
  __typename?: 'AccountContext';
  /** Delivers a list of authentication methods of the current user */
  authMethodList: AuthMethodListResponse;
  /** Get booking cancellation info */
  bookingCancellationInfo: BookingCancellationInfoResponse;
  /** Get booking details */
  bookingDetails: BookingDetailsResponse;
  /** Get booking details page */
  bookingDetailsPage: AccountPage;
  /** Check upsell service from a booking basket */
  checkUpsellServiceFromBasket: CheckBasketResponse;
  /** Get confirmation page */
  confirmationPage: AccountPage;
  hero: Image;
  info: TextComponent;
  /** Get login page */
  loginPage: AccountLoginPage;
  /** Get my bookings page */
  myBookingsPage: AccountPage;
  /** Get my history page */
  myHistoryPage: AccountPage;
  /** Get my profile page */
  myProfilePage: AccountMyProfilePage;
  /** Get past bookings */
  pastBookings: BookingResponse;
  /** Get personal data */
  personalData: AccountPersonalDataResponse;
  /** Get registration page */
  registrationPage: AccountPage;
  /** Get reset password page */
  resetPasswordPage: AccountPage;
  /** Get subscription */
  subscription: Array<SubscriptionType>;
  /** Get travel companions */
  travelCompanions: TravelComponentResponse;
  /** Get upcoming bookings */
  upcomingBookings: BookingResponse;
  /** Delivers a list of transfers for a booking */
  upsellTransfers: UpsellTransferResponse;
  /** Get URL */
  url: InternalLink;
  /** Get user params from token */
  userParamsFromToken: UserParamsFromTokenResponse;
  version: Scalars['String'];
};

export type AccountContextBookingCancellationInfoArgs = {
  bookingId: Scalars['String'];
};

export type AccountContextBookingDetailsArgs = {
  bookingId: Scalars['String'];
};

export type AccountContextCheckUpsellServiceFromBasketArgs = {
  request: CheckUpsellServiceFromBasketInput;
};

export type AccountContextHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type AccountContextPastBookingsArgs = {
  page: PageInput;
};

export type AccountContextUpcomingBookingsArgs = {
  page: PageInput;
};

export type AccountContextUpsellTransfersArgs = {
  bookingId: Scalars['String'];
};

export type AccountContextUrlArgs = {
  bookingId?: InputMaybe<Scalars['String']>;
  pageType: PageType;
};

export type AccountContextUserParamsFromTokenArgs = {
  token: Scalars['String'];
};

export type AuthMethodListResponse = {
  __typename?: 'AuthMethodListResponse';
  list: Array<AuthMethod>;
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type AuthMethod = {
  __typename?: 'AuthMethod';
  type: AuthMethodType;
  value: Scalars['String'];
};

export enum AuthMethodType {
  AgentId = 'AGENT_ID',
  EMail = 'E_MAIL',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Phone = 'PHONE',
}

/** My account page data */
export type AccountPage = {
  __typename?: 'AccountPage';
  mainText: Scalars['String'];
  mainTextBgColor?: Maybe<Scalars['String']>;
  mainTextBgImage?: Maybe<Image>;
  mainTextFgColor?: Maybe<Scalars['String']>;
  mainTitle: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  pageMetaData: PageMetaData;
  status: Status;
};

/** My account page data */
export type AccountPageMainTextBgImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type CheckUpsellServiceFromBasketInput = {
  bookingId: Scalars['String'];
};

export type CheckBasketResponse = {
  __typename?: 'CheckBasketResponse';
  allowedPaymentMethods?: Maybe<Array<PaymentMethod>>;
  customerErrata?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  openAmountPrice?: Maybe<Price>;
  status: Status;
  transfers?: Maybe<Array<UpsellTransferService>>;
};

export type UpsellTransferService = {
  __typename?: 'UpsellTransferService';
  description: Scalars['String'];
  direction: Scalars['String'];
  iataCode: Scalars['String'];
  serviceId: Scalars['String'];
  startDate: Scalars['String'];
  totalPrice: Price;
  travellers?: Maybe<Array<Traveller>>;
  type: Scalars['String'];
};

/** My account login page data */
export type AccountLoginPage = {
  __typename?: 'AccountLoginPage';
  loginTitle?: Maybe<Scalars['String']>;
  mainText: Scalars['String'];
  mainTextBgColor?: Maybe<Scalars['String']>;
  mainTextBgImage?: Maybe<Image>;
  mainTextFgColor?: Maybe<Scalars['String']>;
  mainTitle: Scalars['String'];
  pageMetaData: PageMetaData;
  registerIntroText?: Maybe<Scalars['String']>;
  registerTitle?: Maybe<Scalars['String']>;
};

/** My account login page data */
export type AccountLoginPageMainTextBgImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** My account my profile page data */
export type AccountMyProfilePage = {
  __typename?: 'AccountMyProfilePage';
  countryList: Array<Maybe<Scalars['String']>>;
  pageMetaData: PageMetaData;
};

/** Pagination type */
export type PageInput = {
  pageNumber: Scalars['Int'];
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  resultsTotal?: InputMaybe<Scalars['Int']>;
};

export type BookingResponse = {
  __typename?: 'BookingResponse';
  items: Array<BookingItem>;
  message?: Maybe<Scalars['String']>;
  page: Page;
  status: Status;
};

/** Pagination type */
export type Page = {
  __typename?: 'Page';
  /** Page number */
  pageNumber: Scalars['Int'];
  /** Results per page */
  resultsPerPage?: Maybe<Scalars['Int']>;
  /** Results total */
  resultsTotal?: Maybe<Scalars['Int']>;
};

export enum SubscriptionType {
  Dw = 'DW',
  Ns = 'NS',
}

export type TravelComponentResponse = {
  __typename?: 'TravelComponentResponse';
  message?: Maybe<Scalars['String']>;
  page: Page;
  status: Status;
  travelCompanions: Array<TravelCompanion>;
};

export type TravelCompanion = {
  __typename?: 'TravelCompanion';
  dateOfBirth?: Maybe<Scalars['LocalDate']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  salutation?: Maybe<Salutation>;
};

export type UpsellTransferResponse = {
  __typename?: 'UpsellTransferResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
  transfers: Array<UpsellTransfer>;
};

export type UpsellTransfer = {
  __typename?: 'UpsellTransfer';
  offerId: Scalars['String'];
  price: UpsellTransferPrice;
  searchServiceId: Scalars['String'];
  transferInfo: TransferInfo;
};

export type UpsellTransferPrice = {
  __typename?: 'UpsellTransferPrice';
  pricePerPerson: Price;
  totalPrice: Price;
};

export type UserParamsFromTokenResponse = {
  __typename?: 'UserParamsFromTokenResponse';
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['HashMap_String_StringScalar']>;
  status?: Maybe<Status>;
};

export type AgencyContact = {
  __typename?: 'AgencyContact';
  addressDetails: AddressDetails;
  appointmentLink?: Maybe<ExternalLink>;
  emailLink: ExternalLink;
  openingHours: Array<OpeningHours>;
  phone: AgencyContactPhone;
  timezone: Scalars['String'];
  title: Scalars['String'];
  version: Scalars['String'];
};

export type AgencyContactPhone = {
  __typename?: 'AgencyContactPhone';
  link: ExternalLink;
  title?: Maybe<Scalars['String']>;
};

export type AgencyOverviewPageContext = {
  __typename?: 'AgencyOverviewPageContext';
  agencies: Array<Agency>;
  autocomplete: AgencyAutocomplete;
  /** @deprecated use dynamicComponents */
  bd4TravelRecommendations: Array<Bd4TravelRecommendation>;
  blogArticles: Array<BlogArticleRecommendationItem>;
  /** @deprecated use root components query */
  dynamicComponents: Array<AgencyDynamicComponent>;
  /** @deprecated use informationOverview query for HP and TH tenants */
  information: TextComponent;
  informationOverview: AgencyOverviewInfoComponent;
  /** Get meta data */
  meta: PageMetaData;
  /** Delivers agency overview url */
  url: InternalLink;
  version: Scalars['String'];
};

export type AgencyOverviewPageContextAgenciesArgs = {
  userLocation?: InputMaybe<GeoCoordinatesInput>;
};

export type AgencyOverviewPageContextAutocompleteArgs = {
  criteria: AgencyAutocompleteCriteriaInputInput;
};

export type AgencyOverviewPageContextDynamicComponentsArgs = {
  searchCriteria?: InputMaybe<AgencySearchCriteriaInput>;
};

export type GeoCoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type AgencyAutocompleteCriteriaInputInput = {
  input: Scalars['String'];
  page?: InputMaybe<PageInput>;
};

export type AgencyAutocomplete = {
  __typename?: 'AgencyAutocomplete';
  objects: Array<AgencyAutocompleteSuggestion>;
  page: Page;
  status: Status;
};

/** Generic type for agency suggestions */
export type AgencyAutocompleteSuggestion = Agency | MapSuggestion;

export type MapSuggestion = {
  __typename?: 'MapSuggestion';
  center: GeoCoordinates;
  name: Scalars['String'];
  type: MapSuggestionType;
  zoom: Scalars['Int'];
};

export enum MapSuggestionType {
  City = 'CITY',
  Region = 'REGION',
}

/** Agency Page search control criteria input type */
export type AgencySearchCriteriaInput = {
  rooms?: InputMaybe<Scalars['Int']>;
  travellers?: InputMaybe<TravellersInput>;
  travellersDistribution?: InputMaybe<TravellersDistributionInput>;
  travelPeriod?: InputMaybe<TravelPeriodCriteriaInput>;
  travelType?: InputMaybe<TravelType>;
};

export type TravellersInput = {
  adults: Scalars['Int'];
  childrenDobs?: InputMaybe<Array<Scalars['String']>>;
};

/** Travellers to rooms distribution */
export type TravellersDistributionInput = {
  rooms: Array<RoomDistributionInput>;
};

/** Travellers distribution within one room */
export type RoomDistributionInput = {
  adultsAmount: Scalars['Int'];
  childrenIdx?: InputMaybe<Array<Scalars['Int']>>;
};

/** Travel period input for product search */
export type TravelPeriodCriteriaInput = {
  departureDate: Scalars['LocalDate'];
  maxDuration?: InputMaybe<Scalars['Int']>;
  minDuration?: InputMaybe<Scalars['Int']>;
  returnDate: Scalars['LocalDate'];
  searchPeriodType: SearchPeriodType;
};

export enum SearchPeriodType {
  Exact = 'EXACT',
  Flexible = 'FLEXIBLE',
}

export type AgencyOverviewInfoComponent = {
  __typename?: 'AgencyOverviewInfoComponent';
  breadcrumbs?: Maybe<BreadcrumbsComponent>;
  flagline?: Maybe<Scalars['String']>;
  image: Image;
  tagline?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  textBgColor?: Maybe<Scalars['String']>;
  textBgImage?: Maybe<Image>;
  textFgColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AgencyOverviewInfoComponentImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type AgencyOverviewInfoComponentTextBgImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export enum AuthChannelType {
  B2B = 'B2B',
  B2C = 'B2C',
  B2CAnonymous = 'B2C_ANONYMOUS',
}

export type BlogArticle = {
  __typename?: 'BlogArticle';
  author: EmployeeBox;
  breadcrumbs: BreadcrumbsComponent;
  date: Scalars['String'];
  flagline?: Maybe<Scalars['String']>;
  hero?: Maybe<Image>;
  meta: PageMetaData;
  tagline: Scalars['String'];
  tags: Array<Scalars['String']>;
  text: Scalars['String'];
  title: Scalars['String'];
  url: InternalLink;
};

export type BlogArticleHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Employee Component Item */
export type EmployeeBox = {
  __typename?: 'EmployeeBox';
  emailAddress?: Maybe<Scalars['String']>;
  hoverImage?: Maybe<Image>;
  image?: Maybe<Image>;
  imagePlacement?: Maybe<ImagePlacement>;
  jobTitle?: Maybe<Scalars['String']>;
  link?: Maybe<Link>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated use imagePlacement */
  placement?: Maybe<EmployeeImagePlacement>;
  shortText?: Maybe<Scalars['String']>;
  socialProfiles: Array<EmployeeSocialProfile>;
  text?: Maybe<Scalars['String']>;
};

/** Employee Component Item */
export type EmployeeBoxHoverImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Employee Component Item */
export type EmployeeBoxImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export enum ImagePlacement {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum EmployeeImagePlacement {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

/** Employee Social Profile */
export type EmployeeSocialProfile = {
  __typename?: 'EmployeeSocialProfile';
  icon?: Maybe<Image>;
  link?: Maybe<Link>;
};

/** Employee Social Profile */
export type EmployeeSocialProfileIconArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type BlogOverview = {
  __typename?: 'BlogOverview';
  articles: BlogArticles;
  breadcrumbs: BreadcrumbsComponent;
  flagline: Scalars['String'];
  hero: Image;
  meta: PageMetaData;
  tagline: Scalars['String'];
  text: Scalars['String'];
  title: Scalars['String'];
  version: Scalars['String'];
};

export type BlogOverviewArticlesArgs = {
  pageNumber: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type BlogOverviewHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type BlogArticles = {
  __typename?: 'BlogArticles';
  articles: Array<BlogArticleItem>;
  page: Page;
};

export type BlogArticleItem = {
  __typename?: 'BlogArticleItem';
  author: EmployeeBox;
  date?: Maybe<Scalars['LocalDate']>;
  hero: Image;
  id: Scalars['String'];
  leadText: Scalars['String'];
  mainTitle: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  url: InternalLink;
};

export type BlogArticleItemHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type BookingCancellationResultRequestInput = {
  cancellationToken: Scalars['String'];
};

export type BookingCancellationResultResponse = {
  __typename?: 'BookingCancellationResultResponse';
  accessRestrictionStatus?: Maybe<AccessRestrictionStatus>;
  bookingDetails?: Maybe<BookingDetails>;
  hideActivities: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  status: Status;
  tokenExpire: Scalars['Boolean'];
};

export type BookingPaymentResultRequestInput = {
  paymentKey: Scalars['String'];
};

export type BookingPaymentResultResponse = {
  __typename?: 'BookingPaymentResultResponse';
  accessRestrictionStatus?: Maybe<AccessRestrictionStatus>;
  bookingDetails?: Maybe<BookingDetails>;
  hideActivities: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type BookmarkContext = {
  __typename?: 'BookmarkContext';
  /** Get bookmarks quantity */
  bookmarkQuantity: BookmarkQuantityResponse;
  version: Scalars['String'];
};

export type BookmarkQuantityResponse = {
  __typename?: 'BookmarkQuantityResponse';
  message?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  status: Status;
};

export type BookmarkPage = {
  __typename?: 'BookmarkPage';
  /** Get all bookmark groups */
  allBookmarkGroups: AllBookmarkGroupResponse;
  /** Get bookmark items related to the current user */
  bookmarkItems: Array<Maybe<BookmarkItem>>;
  breadcrumbs?: Maybe<BreadcrumbsComponent>;
  buttonLink?: Maybe<Link>;
  buttonText?: Maybe<Scalars['String']>;
  main?: Maybe<Scalars['String']>;
  meta: PageMetaData;
  tagline?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  version: Scalars['String'];
};

export type BookmarkPageAllBookmarkGroupsArgs = {
  page?: InputMaybe<PageInput>;
};

export type BookmarkPageBookmarkItemsArgs = {
  groupId?: InputMaybe<Scalars['String']>;
};

export type AllBookmarkGroupResponse = {
  __typename?: 'AllBookmarkGroupResponse';
  bookmarkGroups?: Maybe<Array<BookmarkGroup>>;
  page: Page;
};

export type BookmarkGroup = {
  __typename?: 'BookmarkGroup';
  bookmarksCount: Scalars['Int'];
  groupId?: Maybe<Scalars['String']>;
  groupImage?: Maybe<Image>;
  name?: Maybe<Scalars['String']>;
  shareUrl?: Maybe<InternalLink>;
};

export type BookmarkGroupGroupImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type BookmarkItem = {
  __typename?: 'BookmarkItem';
  bookmarkType: BookmarkType;
  breadcrumbs?: Maybe<Array<Scalars['String']>>;
  disrupters: Array<DisrupterObject>;
  googleStaticMapImage?: Maybe<Image>;
  image?: Maybe<Image>;
  leadText?: Maybe<Scalars['String']>;
  link: InternalLink;
  name: Scalars['String'];
  productInfoB2B?: Maybe<ProductInfoB2B>;
};

export type BookmarkItemGoogleStaticMapImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type BookmarkItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export enum BookmarkType {
  Adventuretravel = 'ADVENTURETRAVEL',
  Geo = 'GEO',
  Hotel = 'HOTEL',
  Roundtrip = 'ROUNDTRIP',
  Theme = 'THEME',
  Undefined = 'UNDEFINED',
}

export type ProductInfoB2B = {
  __typename?: 'ProductInfoB2B';
  duration: Scalars['String'];
  priceFrom?: Maybe<Scalars['String']>;
  route: Scalars['String'];
  typeFlags?: Maybe<Array<Scalars['String']>>;
};

export type Booster = {
  __typename?: 'Booster';
  delay?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  frequency: BoosterFrequency;
  id: Scalars['String'];
  text: Scalars['String'];
};

export enum BoosterFrequency {
  Infinite = 'INFINITE',
  Once = 'ONCE',
  Twice = 'TWICE',
}

export type CatalogOverviewContext = {
  __typename?: 'CatalogOverviewContext';
  /** Catalog list */
  catalogs: Array<Catalog>;
  /** @deprecated use overviewInformation query for HP and TH tenants */
  hero: Image;
  /** @deprecated use overviewInformation query for HP and TH tenants */
  information: TextComponent;
  meta: PageMetaData;
  overviewInformation: CatalogOverviewInfoComponent;
  url: InternalLink;
  version: Scalars['String'];
};

export type CatalogOverviewContextHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Catalog */
export type Catalog = {
  __typename?: 'Catalog';
  /** Shows if the catalog can be ordered */
  canBeOrderedOnline: Scalars['Boolean'];
  /** Link to pdf */
  downloadLink: ExternalLink;
  /** Valid from */
  from: Scalars['String'];
  /** Unique id of catalog */
  id: Scalars['String'];
  image: Image;
  /** Link to issuu */
  link: ExternalLink;
  /** Catalog title */
  title: Scalars['String'];
  /** Valid to */
  to: Scalars['String'];
  /** Type of catalog */
  type: CatalogType;
};

/** Catalog */
export type CatalogImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Enum describes types of catalogs */
export enum CatalogType {
  /** Catalog */
  Catalogue = 'CATALOGUE',
  /** Flyer */
  Flyer = 'FLYER',
}

export type CatalogOverviewInfoComponent = {
  __typename?: 'CatalogOverviewInfoComponent';
  breadcrumbs?: Maybe<BreadcrumbsComponent>;
  flagline?: Maybe<Scalars['String']>;
  image: Image;
  tagline?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  textBgColor?: Maybe<Scalars['String']>;
  textBgImage?: Maybe<Image>;
  textFgColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CatalogOverviewInfoComponentImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type CatalogOverviewInfoComponentTextBgImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type GlobalSearchStateInput = {
  rooms?: InputMaybe<Scalars['Int']>;
  travellers?: InputMaybe<TravellersInput>;
  travelPeriod?: InputMaybe<TravelPeriodCriteriaInput>;
  travelType?: InputMaybe<TravelType>;
};

export type ComponentsQueryInput = {
  currentUri: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  pageType: PageType;
  searchQuery?: InputMaybe<Scalars['String']>;
};

/** Generic type for all page components */
export type DynamicComponent =
  | AccordionComponent
  | AgencyContactComponent
  | AgencyRecommendation
  | Bd4TravelRecommendationGroup
  | BenefitsComponent
  | BlogArticleRecommendation
  | BrandsComponent
  | ClimateComponent
  | ContactModuleComponent
  | EmployeesComponent
  | FaqComponent
  | FlightWithoutPriceRecommendation
  | FlightWithPriceRecommendation
  | GeoChildrenComponent
  | GeoGalleryComponent
  | GeoRecommendation
  | HeroMediaGallery
  | ImageTextComponent
  | LinkListComponent
  | MarketingRecommendation
  | MarketingTeaserComponent
  | MediaGallery
  | NewsArticlesComponent
  | NewsletterSignupComponent
  | OfferButtonComponent
  | OffersMapPlaceholderComponent
  | ProductGalleryWithMapComponent
  | ProductRecommendation
  | RequestButtonComponent
  | ResortTopHotelsComponent
  | SrlButtonPlaceholderComponent
  | SrlMapPinsComponent
  | StaticBlogRecommendation
  | StaticGeoRecommendation
  | StaticProductRecommendation
  | StaticThemeRecommendation
  | TextComponent
  | ThemeRecommendation
  | UspBoxesComponent;

/** Accordion Component */
export type AccordionComponent = {
  __typename?: 'AccordionComponent';
  anchor?: Maybe<Scalars['String']>;
  bottomBackgroundColor?: Maybe<Scalars['String']>;
  bottomFontColor?: Maybe<Scalars['String']>;
  bottomMainText?: Maybe<Scalars['String']>;
  bottomMainTitle?: Maybe<Scalars['String']>;
  components: Array<DynamicComponent>;
  topBackgroundColor?: Maybe<Scalars['String']>;
  topFontColor?: Maybe<Scalars['String']>;
  topMainText?: Maybe<Scalars['String']>;
  topMainTitle?: Maybe<Scalars['String']>;
};

export type AgencyContactComponent = {
  __typename?: 'AgencyContactComponent';
  address: AddressDetails;
  agencyName: Scalars['String'];
  coordinates: GeoCoordinates;
  image?: Maybe<Image>;
  openingHours: Array<OpeningHours>;
  phone: ExternalLink;
  requestButton: RequestButtonComponent;
  timezone: Scalars['String'];
  title: Scalars['String'];
};

export type AgencyContactComponentImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type AgencyRecommendation = {
  __typename?: 'AgencyRecommendation';
  backgroundColor: Scalars['String'];
  fontColor: Scalars['String'];
  items: Array<AgencyRecommendationItem>;
  title: Scalars['String'];
};

export type AgencyRecommendationItem = {
  __typename?: 'AgencyRecommendationItem';
  city: Scalars['String'];
  image: Image;
  link: InternalLink;
  name: Scalars['String'];
};

export type AgencyRecommendationItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type Bd4TravelRecommendationGroup = {
  __typename?: 'BD4TravelRecommendationGroup';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Image>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<Bd4TravelRecommendation>;
  mainText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tracking?: Maybe<Bd4TravelRecommendationTracking>;
};

export type Bd4TravelRecommendationGroupBackgroundImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Benefits Component */
export type BenefitsComponent = {
  __typename?: 'BenefitsComponent';
  buttonLabel: Scalars['String'];
  buttonLink: Link;
  items: Array<BenefitItem>;
  text: Scalars['String'];
};

/** Benefit item */
export type BenefitItem = {
  __typename?: 'BenefitItem';
  text?: Maybe<Scalars['String']>;
};

export type BlogArticleRecommendation = {
  __typename?: 'BlogArticleRecommendation';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Image>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<BlogArticleRecommendationItem>;
  mainText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type BlogArticleRecommendationBackgroundImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Brands Component */
export type BrandsComponent = {
  __typename?: 'BrandsComponent';
  brands: Array<BrandBox>;
  brandsTitle?: Maybe<Scalars['String']>;
};

/** Brand Component Item */
export type BrandBox = {
  __typename?: 'BrandBox';
  image?: Maybe<Image>;
  link?: Maybe<Link>;
  text?: Maybe<Scalars['String']>;
};

/** Brand Component Item */
export type BrandBoxImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ClimateComponent = {
  __typename?: 'ClimateComponent';
  anchor?: Maybe<Scalars['String']>;
  /** Climate Component BackgroundColor */
  backgroundColor?: Maybe<Scalars['String']>;
  charts: Array<ClimateChart>;
  /** Climate Component MainText */
  mainText?: Maybe<Scalars['String']>;
  sunHoursPerYear: Scalars['String'];
  title: Scalars['String'];
};

export type ClimateChart = {
  __typename?: 'ClimateChart';
  entries: Array<ClimateChartEntry>;
  title: Scalars['String'];
  type: ClimateChartType;
};

export type ClimateChartEntry = {
  __typename?: 'ClimateChartEntry';
  month: Scalars['String'];
  value: Scalars['Float'];
};

export enum ClimateChartType {
  MaxTemperaturePerMonth = 'MAX_TEMPERATURE_PER_MONTH',
  RainDaysPerMonth = 'RAIN_DAYS_PER_MONTH',
  SunHoursPerMonth = 'SUN_HOURS_PER_MONTH',
  WaterTemperaturePerMonth = 'WATER_TEMPERATURE_PER_MONTH',
}

export type ContactModuleComponent = {
  __typename?: 'ContactModuleComponent';
  contactTypeBases: Array<ContactTypeUnion>;
  flagline?: Maybe<Scalars['String']>;
  mainTitle?: Maybe<Scalars['String']>;
};

export type ContactTypeUnion = ContactType | ContactTypePhone;

export type ContactType = {
  __typename?: 'ContactType';
  icon?: Maybe<Image>;
  link?: Maybe<Link>;
  mainTitle?: Maybe<Scalars['String']>;
};

export type ContactTypeIconArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ContactTypePhone = {
  __typename?: 'ContactTypePhone';
  icon?: Maybe<Image>;
  mainTitle?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ContactTypePhoneIconArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Employees Component */
export type EmployeesComponent = {
  __typename?: 'EmployeesComponent';
  anchor?: Maybe<Scalars['String']>;
  employees: Array<EmployeeBox>;
  employeesTitle?: Maybe<Scalars['String']>;
};

export type FaqComponent = {
  __typename?: 'FaqComponent';
  items: Array<TextComponent>;
};

export type FlightWithoutPriceRecommendation = {
  __typename?: 'FlightWithoutPriceRecommendation';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Image>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<FlightRecommendationWithoutPriceItem>;
  mainText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FlightWithoutPriceRecommendationBackgroundImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Flight recommendation with price */
export type FlightRecommendationWithoutPriceItem = {
  __typename?: 'FlightRecommendationWithoutPriceItem';
  /** Recommendation link */
  link: Link;
  /** Media */
  media: Media;
  /** Per person price */
  price: Price;
  /** Recommendation subtitle */
  subtitle: Scalars['String'];
  /** Recommendation title */
  title: Scalars['String'];
};

export type FlightWithPriceRecommendation = {
  __typename?: 'FlightWithPriceRecommendation';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Image>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<FlightRecommendationWithPriceItem>;
  mainText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FlightWithPriceRecommendationBackgroundImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Flight recommendation with price */
export type FlightRecommendationWithPriceItem = {
  __typename?: 'FlightRecommendationWithPriceItem';
  /** Recommendation amount of offers */
  amount: Scalars['String'];
  /** Recommendation link */
  link: InternalLink;
  /** Media */
  media: Media;
  /** Recommendation flight offer */
  offer: FlightOffer;
  /** Recommendation title */
  title: Scalars['String'];
};

export type FlightOffer = {
  __typename?: 'FlightOffer';
  forwardFlight: Flight;
  offerId: Scalars['String'];
  pricePerPerson: Price;
  returnFlight?: Maybe<Flight>;
  totalPrice: Price;
  waitingScreenImage: Scalars['String'];
};

export type GeoChildrenComponent = {
  __typename?: 'GeoChildrenComponent';
  anchor?: Maybe<Scalars['String']>;
  /** Geo Children BackgroundColor */
  backgroundColor?: Maybe<Scalars['String']>;
  children: Array<GeoChildComponent>;
  /** Geo Children MainText */
  mainText?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type GeoChildComponent = {
  __typename?: 'GeoChildComponent';
  image: Image;
  link: InternalLink;
  title: Scalars['String'];
};

export type GeoChildComponentImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Geo gallery component */
export type GeoGalleryComponent = {
  __typename?: 'GeoGalleryComponent';
  anchor?: Maybe<Scalars['String']>;
  /** Get Geo Media gallery BackgroundColor */
  backgroundColor?: Maybe<Scalars['String']>;
  /** Geo Media gallery MainText */
  mainText?: Maybe<Scalars['String']>;
  /** Geo gallery items */
  mediaItems: Array<Media>;
};

export type GeoRecommendation = {
  __typename?: 'GeoRecommendation';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Image>;
  displayType?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<GeoRecommendationItem>;
  mainText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  titleFontColor?: Maybe<Scalars['String']>;
};

export type GeoRecommendationBackgroundImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Hero Media gallery component */
export type HeroMediaGallery = {
  __typename?: 'HeroMediaGallery';
  anchor?: Maybe<Scalars['String']>;
  /** Get Hero Media gallery Flagline */
  flagline?: Maybe<Scalars['String']>;
  /** Get Hero Media gallery FontColor */
  fontColor?: Maybe<Scalars['String']>;
  /** Get Hero Media gallery MainText */
  mainText?: Maybe<Scalars['String']>;
  /** Get Hero Media gallery MainTextFontColor */
  mainTextFontColor?: Maybe<Scalars['String']>;
  /** Get Hero Media gallery MainTitle */
  mainTitle?: Maybe<Scalars['String']>;
  /** Hero Media gallery items */
  mediaItems: Array<HeroMedia>;
};

export type HeroMedia = ImageMediaItem | VideoMediaItem;

/** Image Text Component */
export type ImageTextComponent = {
  __typename?: 'ImageTextComponent';
  image?: Maybe<Image>;
  imagePlacement: ImagePlacement;
  text: Scalars['String'];
};

/** Image Text Component */
export type ImageTextComponentImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Link List Component */
export type LinkListComponent = {
  __typename?: 'LinkListComponent';
  links: Array<LinkListItem>;
  linksTitle?: Maybe<Scalars['String']>;
};

/** Link list item */
export type LinkListItem = {
  __typename?: 'LinkListItem';
  link?: Maybe<Link>;
  title?: Maybe<Scalars['String']>;
};

export type MarketingRecommendation = {
  __typename?: 'MarketingRecommendation';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Image>;
  /** possible values: 'BLOC', 'SWIPE' */
  displayType?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<MarketingRecommendationItem>;
  mainText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  titleFontColor?: Maybe<Scalars['String']>;
};

export type MarketingRecommendationBackgroundImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type MarketingTeaserComponent = {
  __typename?: 'MarketingTeaserComponent';
  anchor?: Maybe<Scalars['String']>;
  /** Background color hexadecimal code */
  backgroundColor?: Maybe<Scalars['String']>;
  /** Background alignment. Possible values: 'FULL', 'TEXT_AREA' */
  backgroundColorAlignment?: Maybe<Scalars['String']>;
  /** Background theme color */
  backgroundTheme?: Maybe<Scalars['String']>;
  /** Bottom padding. Possible values: 'PADDING_SMALL', 'PADDING_LARGE', 'PADDING_NONE' */
  bottomPadding?: Maybe<Scalars['String']>;
  /** CTA button link */
  buttonLink: Link;
  /** CTA button title */
  buttonTitle: Scalars['String'];
  logoImage?: Maybe<Image>;
  /** Marketing teaser rich text */
  mainText?: Maybe<Scalars['String']>;
  teaserImage?: Maybe<Image>;
  /** Marketing teaser title */
  title: Scalars['String'];
  /** Top padding. Possible values: 'PADDING_SMALL', 'PADDING_LARGE', 'PADDING_NONE' */
  topPadding?: Maybe<Scalars['String']>;
};

export type MarketingTeaserComponentLogoImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type MarketingTeaserComponentTeaserImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** News Articles Component */
export type NewsArticlesComponent = {
  __typename?: 'NewsArticlesComponent';
  buttonCaption?: Maybe<Scalars['String']>;
  buttonLink?: Maybe<Link>;
  newsArticles: Array<NewsArticle>;
  newsArticlesTitle?: Maybe<Scalars['String']>;
};

/** News Article Item */
export type NewsArticle = {
  __typename?: 'NewsArticle';
  businessUnit?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  link?: Maybe<Link>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** News Article Item */
export type NewsArticleImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Newsletter Signup Component */
export type NewsletterSignupComponent = {
  __typename?: 'NewsletterSignupComponent';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  buttonText: Scalars['String'];
  disclaimerText: Scalars['String'];
  /** @deprecated not in use anymore */
  errorHandlingText?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  mainText?: Maybe<Scalars['String']>;
  successMessageText: Scalars['String'];
  successMessageTextIcon?: Maybe<Image>;
  successMessageTitle: Scalars['String'];
  titleText: Scalars['String'];
};

/** Newsletter Signup Component */
export type NewsletterSignupComponentImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Newsletter Signup Component */
export type NewsletterSignupComponentSuccessMessageTextIconArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Newsletter Signup Component */
export type OfferButtonComponent = {
  __typename?: 'OfferButtonComponent';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  caption: Scalars['String'];
  link: InternalLink;
};

export type OffersMapPlaceholderComponent = {
  __typename?: 'OffersMapPlaceholderComponent';
  anchor?: Maybe<Scalars['String']>;
  searchQuery: Scalars['String'];
};

export type ProductGalleryWithMapComponent = {
  __typename?: 'ProductGalleryWithMapComponent';
  /** @deprecated use images */
  imageGroups: Array<ProductImageGroup>;
  images: Array<ProductImageItem>;
  relatedRoundtrips?: Maybe<RelatedRoundtrips>;
  routePoints?: Maybe<Array<RoutePoint>>;
  title: Scalars['String'];
};

export type ProductImageGroup = {
  __typename?: 'ProductImageGroup';
  coordinates: GeoCoordinates;
  imageItems: Array<ProductImageItem>;
};

export type ProductImageItem = {
  __typename?: 'ProductImageItem';
  coordinates: GeoCoordinates;
  disrupters?: Maybe<Array<DisrupterObject>>;
  image?: Maybe<Image>;
  productId: Scalars['String'];
  productName: Scalars['String'];
};

export type ProductImageItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type RelatedRoundtrips = {
  __typename?: 'RelatedRoundtrips';
  coordinates?: Maybe<GeoCoordinates>;
  roundtrips?: Maybe<Array<RelatedRoundtripItem>>;
};

export type RelatedRoundtripItem = {
  __typename?: 'RelatedRoundtripItem';
  image?: Maybe<Image>;
  link: InternalLink;
  name: Scalars['String'];
  productInfoB2B?: Maybe<ProductInfoB2B>;
};

export type RelatedRoundtripItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type RoutePoint = {
  __typename?: 'RoutePoint';
  coordinates: GeoCoordinates;
  name: Scalars['String'];
};

export type ProductRecommendation = {
  __typename?: 'ProductRecommendation';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Image>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<ProductRecommendationItem>;
  mainText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ProductRecommendationBackgroundImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ResortTopHotelsComponent = {
  __typename?: 'ResortTopHotelsComponent';
  anchor?: Maybe<Scalars['String']>;
  /** Get Geo Media gallery BackgroundColor */
  backgroundColor?: Maybe<Scalars['String']>;
  /** Geo Media gallery MainText */
  mainText?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  topHotels: Array<ProductRecommendationItem>;
};

export type SrlButtonPlaceholderComponent = {
  __typename?: 'SrlButtonPlaceholderComponent';
  anchor?: Maybe<Scalars['String']>;
  link: InternalLink;
};

export type SrlMapPinsComponent = {
  __typename?: 'SrlMapPinsComponent';
  anchor?: Maybe<Scalars['String']>;
  /** MapPins Component BackgroundColor */
  backgroundColor?: Maybe<Scalars['String']>;
  cheapestGeo?: Maybe<SrlMapGeoPin>;
  cheapestProduct?: Maybe<SrlMapProductPin>;
  geoPins: Array<SrlMapGeoPin>;
  /** MapPins Component MainText */
  mainText?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  nearestAirports?: Maybe<Array<Maybe<Airport>>>;
  productPins: Array<SrlMapProductPin>;
  status: Status;
};

export type SrlMapGeoPin = {
  __typename?: 'SrlMapGeoPin';
  coordinates: GeoCoordinates;
  geo: GeoObject;
  price: Price;
  zoom: Scalars['Int'];
};

export type SrlMapProductPin = {
  __typename?: 'SrlMapProductPin';
  bestseller: Scalars['Boolean'];
  coordinates: GeoCoordinates;
  id: Scalars['String'];
  name: Scalars['String'];
  price: Price;
  top: Scalars['Boolean'];
  zoom: Scalars['Int'];
};

export type StaticBlogRecommendation = {
  __typename?: 'StaticBlogRecommendation';
  articles: Array<BlogArticleItem>;
  title: Scalars['String'];
};

export type StaticGeoRecommendation = {
  __typename?: 'StaticGeoRecommendation';
  backgroundColor: Scalars['String'];
  displayNumber: Scalars['Int'];
  fontColor: Scalars['String'];
  items: Array<StaticGeoRecommendationItem>;
  name?: Maybe<Scalars['String']>;
};

export type StaticGeoRecommendationItem = {
  __typename?: 'StaticGeoRecommendationItem';
  id: Scalars['String'];
  image: Image;
  link: InternalLink;
  title: Scalars['String'];
};

export type StaticGeoRecommendationItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type StaticProductRecommendation = {
  __typename?: 'StaticProductRecommendation';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<StaticProductRecommendationItem>;
  name?: Maybe<Scalars['String']>;
  productFilters?: Maybe<Array<ProductFilter>>;
};

export type StaticProductRecommendationItem = {
  __typename?: 'StaticProductRecommendationItem';
  address?: Maybe<Scalars['String']>;
  bookmarked: Scalars['Boolean'];
  breadcrumbs?: Maybe<Array<Scalars['String']>>;
  disrupters?: Maybe<Array<DisrupterObject>>;
  features: Array<ProductFeature>;
  image: Image;
  link: InternalLink;
  name: Scalars['String'];
  route: Array<Scalars['String']>;
  routePoints?: Maybe<Array<RoutePoint>>;
  teaser: Scalars['String'];
};

export type StaticProductRecommendationItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ProductFilter = {
  __typename?: 'ProductFilter';
  objectId: Scalars['String'];
  objectName: Scalars['String'];
};

export type StaticThemeRecommendation = {
  __typename?: 'StaticThemeRecommendation';
  backgroundColor: Scalars['String'];
  /** possible values: 'BLOC', 'SWIPE' */
  displayType?: Maybe<Scalars['String']>;
  fontColor: Scalars['String'];
  items: Array<StaticThemeRecommendationItem>;
  name?: Maybe<Scalars['String']>;
};

export type StaticThemeRecommendationItem = {
  __typename?: 'StaticThemeRecommendationItem';
  id: Scalars['String'];
  image: Image;
  link: InternalLink;
  name: Scalars['String'];
};

export type StaticThemeRecommendationItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ThemeRecommendation = {
  __typename?: 'ThemeRecommendation';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Image>;
  /** possible values: 'BLOC', 'SWIPE' */
  displayType?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<ThemeRecommendationItem>;
  mainText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ThemeRecommendationBackgroundImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Usp Boxes Component */
export type UspBoxesComponent = {
  __typename?: 'UspBoxesComponent';
  backgroundColor?: Maybe<Scalars['String']>;
  boxes: Array<UspBox>;
  fontColor?: Maybe<Scalars['String']>;
  uspTitle?: Maybe<Scalars['String']>;
};

/** Usp box */
export type UspBox = {
  __typename?: 'UspBox';
  icon?: Maybe<Image>;
  link?: Maybe<Link>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Usp box */
export type UspBoxIconArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ContactPageContext = {
  __typename?: 'ContactPageContext';
  /** @deprecated use root components query */
  dynamicComponents: Array<ContactDynamicComponent>;
  forms: Array<ContactForm>;
  /** Delivers contact page hero picture */
  hero: Image;
  information: TextComponent;
  /** Gets meta data */
  meta: PageMetaData;
  /** Delivers contact page url */
  url: InternalLink;
  version: Scalars['String'];
};

export type ContactPageContextHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Generic type for all contact page dynamic components */
export type ContactDynamicComponent =
  | ContactRecommendationGroup
  | MediaGallery
  | TextComponent;

export type ContactRecommendationGroup = {
  __typename?: 'ContactRecommendationGroup';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<ContactRecommendationItem>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type ContactRecommendationItem = MarketingRecommendationItem;

/** Contact form */
export type ContactForm = {
  __typename?: 'ContactForm';
  /** Form placeholder */
  placeholder?: Maybe<Scalars['String']>;
  /** Show form */
  showForm: Scalars['Boolean'];
  /** Text */
  text?: Maybe<Scalars['String']>;
  /** Title */
  title: Scalars['String'];
  /** Form type */
  type: Scalars['String'];
};

export type CookieDisclaimer = {
  __typename?: 'CookieDisclaimer';
  text?: Maybe<Scalars['String']>;
};

export type EmailAdvertisingRevocationPageContext = {
  __typename?: 'EmailAdvertisingRevocationPageContext';
  /** @deprecated use contentInfo query */
  content: EmailAdvertisingStaticContent;
  /** Delivers email revocation advertising confirmation content */
  contentInfo: EmailAdvertisingStaticContentInfo;
  /** Gets email from revoking email advertising token */
  emailFromToken: EmailAdvertisingGetEmailFromTokenResponse;
  /** Gets meta data */
  meta: PageMetaData;
  /** Converts email to revoking email advertising token */
  tokenFromEmail: EmailAdvertisingGetTokenFromEmailResponse;
  /** Delivers email advertising revocation url */
  url: InternalLink;
  version: Scalars['String'];
};

export type EmailAdvertisingRevocationPageContextEmailFromTokenArgs = {
  token: Scalars['String'];
};

export type EmailAdvertisingRevocationPageContextTokenFromEmailArgs = {
  email: Scalars['String'];
};

export type EmailAdvertisingStaticContent = {
  __typename?: 'EmailAdvertisingStaticContent';
  /** @deprecated use emailAdvertisingImagesResolver */
  hero: Image;
  info: TextComponent;
};

export type EmailAdvertisingStaticContentHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type EmailAdvertisingStaticContentInfo = {
  __typename?: 'EmailAdvertisingStaticContentInfo';
  breadcrumbs?: Maybe<BreadcrumbsComponent>;
  flagline?: Maybe<Scalars['String']>;
  image: Image;
  tagline?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  textBgColor?: Maybe<Scalars['String']>;
  textBgImage?: Maybe<Image>;
  textFgColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type EmailAdvertisingStaticContentInfoImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type EmailAdvertisingStaticContentInfoTextBgImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type EmailAdvertisingGetEmailFromTokenResponse = {
  __typename?: 'EmailAdvertisingGetEmailFromTokenResponse';
  email: Scalars['String'];
  status: Status;
};

export type EmailAdvertisingGetTokenFromEmailResponse = {
  __typename?: 'EmailAdvertisingGetTokenFromEmailResponse';
  status: Status;
  token: Scalars['String'];
};

export type EmailAdvertisingRevocationConfirmationPageContext = {
  __typename?: 'EmailAdvertisingRevocationConfirmationPageContext';
  /** @deprecated use contentInfo query */
  content: EmailAdvertisingStaticContent;
  /** Delivers email revocation advertising confirmation content */
  contentInfo: EmailAdvertisingStaticContentInfo;
  /** @deprecated use root components query */
  dynamicComponents: Array<EmailAdvertisingDynamicComponent>;
  /** Gets meta data */
  meta: PageMetaData;
  /** Delivers email advertising revocation confirmation url */
  url: InternalLink;
  version: Scalars['String'];
};

/** Generic type for all email advertising page dynamic components */
export type EmailAdvertisingDynamicComponent = EmailAdvertisingRecommendationGroup;

export type EmailAdvertisingRecommendationGroup = {
  __typename?: 'EmailAdvertisingRecommendationGroup';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<EmailAdvertisingRecommendationItem>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type EmailAdvertisingRecommendationItem =
  | GeoRecommendationItem
  | ThemeRecommendationItem;

/** flight home page root type */
export type FlightHomeContext = {
  __typename?: 'FlightHomeContext';
  /** Autocomplete Airport */
  autocomplete: AutocompleteComponent;
  /**
   * Load flight home page flexible components
   * @deprecated use root query
   */
  dynamicComponents: Array<FlightHomeDynamicComponent>;
  hero: Image;
  info: TextComponent;
  /** Get meta data */
  meta: PageMetaData;
  /** Get search control */
  searchControl: SearchControlUnion;
  /** Get URL */
  url: InternalLink;
  version: Scalars['String'];
};

/** flight home page root type */
export type FlightHomeContextAutocompleteArgs = {
  criteria: AutocompleteCriteriaInput;
};

/** flight home page root type */
export type FlightHomeContextDynamicComponentsArgs = {
  criteria?: InputMaybe<FlightHomeSearchCriteriaInput>;
};

/** flight home page root type */
export type FlightHomeContextHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** flight home page root type */
export type FlightHomeContextSearchControlArgs = {
  travelType?: InputMaybe<TravelType>;
};

/** flight home page root type */
export type FlightHomeContextUrlArgs = {
  criteria: FlightHomeSearchCriteriaInput;
  targetPageType: PageType;
};

export type AutocompleteCriteriaInput = {
  input: Scalars['String'];
  page: PageInput;
  selected?: InputMaybe<Array<Scalars['String']>>;
};

/** Autocomplete component */
export type AutocompleteComponent = {
  __typename?: 'AutocompleteComponent';
  /** Found travel destinations */
  destinations: Array<TravelDestination>;
  /** Page information */
  page: Page;
};

export type TravelDestination = {
  __typename?: 'TravelDestination';
  /** Travel destination id */
  documentId?: Maybe<Scalars['String']>;
  /** Travel destination id */
  id: Scalars['String'];
  /** Travel destination location info */
  location?: Maybe<GeoLocation>;
  mythosCode?: Maybe<Scalars['String']>;
  /** Travel destination name */
  name: Scalars['String'];
  /** Theme destination information */
  themeInformation?: Maybe<ThemeDestinationInformation>;
  /** Travel destination type */
  type: TravelDestinationType;
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  /** Airport */
  airport?: Maybe<Airport>;
  /** Continent */
  continent?: Maybe<GeoObject>;
  /** Geo coordinates */
  coordinates?: Maybe<GeoCoordinates>;
  /** Country */
  country?: Maybe<GeoObject>;
  /** Country group */
  countryGroup?: Maybe<GeoObject>;
  /** Destination */
  destination?: Maybe<GeoObject>;
  /** Region */
  region?: Maybe<GeoObject>;
  /** Destination */
  resort?: Maybe<GeoObject>;
};

export type ThemeDestinationInformation = {
  __typename?: 'ThemeDestinationInformation';
  /** Theme information plural */
  plural?: Maybe<Scalars['String']>;
  /** Theme information singular */
  singular?: Maybe<Scalars['String']>;
};

export enum TravelDestinationType {
  Airport = 'AIRPORT',
  AirportGroup = 'AIRPORT_GROUP',
  Continent = 'CONTINENT',
  Country = 'COUNTRY',
  CountryGroup = 'COUNTRY_GROUP',
  Cruise = 'CRUISE',
  Destination = 'DESTINATION',
  Geo = 'GEO',
  GiataCode = 'GIATA_CODE',
  Hotel = 'HOTEL',
  MythosCode = 'MYTHOS_CODE',
  Region = 'REGION',
  Resort = 'RESORT',
  Roundtrip = 'ROUNDTRIP',
  Theme = 'THEME',
}

/** Flight Home Page search criteria input type */
export type FlightHomeSearchCriteriaInput = {
  searchControl: FlightSearchControlComponentInput;
};

export type FlightSearchControlComponentInput = {
  flightPartitions?: InputMaybe<Array<FlightPartitionInput>>;
  flightType: FlightType;
  travellers: TravellersInput;
  travelType: TravelType;
};

export type FlightPartitionInput = {
  arrivalAirport?: InputMaybe<AirportInput>;
  departureAirport?: InputMaybe<AirportInput>;
  flightClass: FlightClass;
  travelPeriod: ExactTravelPeriodInput;
};

export type AirportInput = {
  coordinates?: InputMaybe<GeoCoordinatesInput>;
  iata?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ExactTravelPeriodInput = {
  departureDate: Scalars['String'];
  returnDate: Scalars['String'];
};

export enum FlightType {
  OneWay = 'ONE_WAY',
  OpenJaw = 'OPEN_JAW',
  Return = 'RETURN',
}

/** Generic type for all Flight Home page flexible components */
export type FlightHomeDynamicComponent =
  | FlightHomeRecommendationGroup
  | MediaGallery
  | TextComponent;

export type FlightHomeRecommendationGroup = {
  __typename?: 'FlightHomeRecommendationGroup';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<FlightHomeRecommendationItem>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type FlightHomeRecommendationItem =
  | Bd4TravelRecommendation
  | FlightRecommendationWithoutPriceItem
  | FlightRecommendationWithPriceItem
  | GeoRecommendationItem
  | ThemeRecommendationItem;

export type SearchControlUnion =
  | FlightSearchControlComponent
  | SearchControlComponent;

export type FlightSearchControlComponent = {
  __typename?: 'FlightSearchControlComponent';
  flightPartitions?: Maybe<Array<FlightPartition>>;
  flightType: FlightType;
  travellers: Travellers;
  travelType: TravelType;
};

export type FlightPartition = {
  __typename?: 'FlightPartition';
  arrivalAirport?: Maybe<Airport>;
  departureAirport?: Maybe<Airport>;
  flightClass: FlightClass;
  travelPeriod: ExactTravelPeriod;
};

export type ExactTravelPeriod = {
  __typename?: 'ExactTravelPeriod';
  departureDate: Scalars['String'];
  returnDate: Scalars['String'];
};

export type Travellers = {
  __typename?: 'Travellers';
  adults: Scalars['Int'];
  childrenDobs?: Maybe<Array<Scalars['String']>>;
};

export type SearchControlComponent = {
  __typename?: 'SearchControlComponent';
  destinations?: Maybe<Array<TravelDestination>>;
  period: TravelPeriodComponent;
  rooms: Scalars['Int'];
  travellers: Travellers;
  travellersDistribution?: Maybe<TravellersDistribution>;
  /** @deprecated use period */
  travelPeriod: TravelPeriodUnion;
  travelType: TravelType;
};

export type TravelPeriodComponent = {
  __typename?: 'TravelPeriodComponent';
  exactPeriod: ExactTravelPeriod;
  flexiblePeriod: FlexibleTravelPeriod;
  type: SearchPeriodType;
};

export type FlexibleTravelPeriod = {
  __typename?: 'FlexibleTravelPeriod';
  departureDate: Scalars['String'];
  maxDuration: Scalars['Int'];
  minDuration: Scalars['Int'];
  returnDate: Scalars['String'];
};

/** Travellers to rooms distribution */
export type TravellersDistribution = {
  __typename?: 'TravellersDistribution';
  rooms: Array<RoomDistribution>;
};

/** Travellers distribution within one room */
export type RoomDistribution = {
  __typename?: 'RoomDistribution';
  adultsAmount: Scalars['Int'];
  childrenIdx?: Maybe<Array<Scalars['Int']>>;
};

export type TravelPeriodUnion = ExactTravelPeriod | FlexibleTravelPeriod;

/** flight srl page root type */
export type FlightSrlContext = {
  __typename?: 'FlightSrlContext';
  /** Autocomplete Airport */
  autocomplete: AutocompleteComponent;
  /** Checkout offer */
  checkout: FlightCheckoutComponent;
  content: FlightSrlContainer;
  /**
   * Get dynamic components
   * @deprecated use root query
   */
  dynamicComponents: Array<FlightSrlDynamicComponent>;
  /** Flight Srl filters */
  filters: FilterComponent;
  /** Get meta data */
  meta: PageMetaData;
  /** Get form component */
  searchControl: FlightSearchControlComponent;
  /** Get URL */
  url: InternalLink;
  version: Scalars['String'];
};

/** flight srl page root type */
export type FlightSrlContextAutocompleteArgs = {
  criteria: AutocompleteCriteriaInput;
};

/** flight srl page root type */
export type FlightSrlContextCheckoutArgs = {
  backUrl: Scalars['String'];
  offerId: Scalars['String'];
};

/** flight srl page root type */
export type FlightSrlContextDynamicComponentsArgs = {
  searchCriteria: FlightSrlSearchCriteriaInput;
};

/** flight srl page root type */
export type FlightSrlContextFiltersArgs = {
  encodedCriteria?: InputMaybe<Scalars['String']>;
  searchCriteria?: InputMaybe<FlightSrlSearchCriteriaInput>;
};

/** flight srl page root type */
export type FlightSrlContextSearchControlArgs = {
  encodedCriteria?: InputMaybe<Scalars['String']>;
};

/** flight srl page root type */
export type FlightSrlContextUrlArgs = {
  criteria: FlightSrlSearchCriteriaInput;
};

export type FlightCheckoutComponent = {
  __typename?: 'FlightCheckoutComponent';
  changedOffer?: Maybe<FlightOffer>;
  checkoutStatus: FlightCheckoutStatus;
  flightChanged: Scalars['Boolean'];
  link?: Maybe<ExternalLink>;
  message?: Maybe<Scalars['String']>;
  priceChanged: Scalars['Boolean'];
};

export enum FlightCheckoutStatus {
  FlightCheckoutOfferChanged = 'FLIGHT_CHECKOUT_OFFER_CHANGED',
  FlightCheckoutSuccess = 'FLIGHT_CHECKOUT_SUCCESS',
  FlightCheckoutUnavailable = 'FLIGHT_CHECKOUT_UNAVAILABLE',
}

export type FlightSrlContainer = {
  __typename?: 'FlightSrlContainer';
  /** Search flight offers */
  flights: FlightSrlComponent;
};

export type FlightSrlContainerFlightsArgs = {
  flightSrlSearchCriteria: FlightSrlSearchCriteriaInput;
};

/** Flight SRL Page search criteria input type */
export type FlightSrlSearchCriteriaInput = {
  /** Search filters criteria input */
  filter?: InputMaybe<FlightSrlFilterCriteriaInput>;
  /** Requested page */
  page: PageInput;
  /** Search control */
  searchControl: FlightSearchControlComponentInput;
  /** Search offers only in cache */
  searchInCacheOnly?: InputMaybe<Scalars['Boolean']>;
  /** Flight sorting */
  sort?: InputMaybe<FlightSortInput>;
};

/** Input type for flight srl filter */
export type FlightSrlFilterCriteriaInput = {
  /** Direct flight arrival time id */
  directFlightArrivalTime?: InputMaybe<Scalars['String']>;
  /** Direct flight departure time id */
  directFlightDepartureTime?: InputMaybe<Scalars['String']>;
  /** Selected airlines platform ids */
  flightAirlines?: InputMaybe<Array<Scalars['String']>>;
  /** Selected stop overs id */
  flightStopOver?: InputMaybe<Scalars['String']>;
  /** Selected max duration ids */
  maxFlightStopOverDuration?: InputMaybe<Scalars['String']>;
  /** Selected max price per person */
  maxPricePerPerson?: InputMaybe<Scalars['String']>;
  /** Selected min duration ids */
  minFlightStopOverDuration?: InputMaybe<Scalars['String']>;
  /** Return flight arrival time id */
  returnFlightArrivalTime?: InputMaybe<Scalars['String']>;
  /** Return flight departure time id */
  returnFlightDepartureTime?: InputMaybe<Scalars['String']>;
};

export type FlightSortInput = {
  direction?: InputMaybe<FlightSortDirection>;
  field: FlightSortField;
};

export enum FlightSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum FlightSortField {
  DepartureTime = 'DEPARTURE_TIME',
  Duration = 'DURATION',
  Price = 'PRICE',
}

export type FlightSrlComponent = {
  __typename?: 'FlightSrlComponent';
  cheapestPrice: Price;
  count?: Maybe<Scalars['Int']>;
  isCacheEmpty?: Maybe<Scalars['Boolean']>;
  offers: Array<FlightOffer>;
  page: Page;
  stopOverDuration: FlightStopOverDuration;
  version?: Maybe<Scalars['String']>;
};

export type FlightStopOverDuration = {
  __typename?: 'FlightStopOverDuration';
  maxDuration: Scalars['String'];
  minDuration: Scalars['String'];
};

export type FlightSrlDynamicComponent = FlightSrlRecommendationGroup;

export type FlightSrlRecommendationGroup = {
  __typename?: 'FlightSrlRecommendationGroup';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<FlightSrlRecommendationItem>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type FlightSrlRecommendationItem = Bd4TravelRecommendation;

export type FilterComponent = {
  __typename?: 'FilterComponent';
  accommodationSize?: Maybe<CheckboxFilterComponent>;
  adultsFeatures: CheckboxFilterComponent;
  arrivalAirports: CheckboxFilterComponent;
  arrivalWeekdays: CheckboxFilterComponent;
  beachFeatures: CheckboxFilterComponent;
  boardTypes: CheckboxFilterComponent;
  childrenFeatures: CheckboxFilterComponent;
  cityFeatures: CheckboxFilterComponent;
  clubHotelsFeatures: CheckboxFilterComponent;
  cultureFeatures: CheckboxFilterComponent;
  customerSegmentFeatures: CheckboxFilterComponent;
  departureAirports: CheckboxFilterComponent;
  departureFlightTime: RadiobuttonFilterComponent;
  departureWeekdays: CheckboxFilterComponent;
  directFlightArrivalTime: RadiobuttonFilterComponent;
  directFlightDepartureTime: RadiobuttonFilterComponent;
  distanceFeatures: CheckboxFilterComponent;
  ECarChargingFeatures: CheckboxFilterComponent;
  environmentFeatures: CheckboxFilterComponent;
  flightAirlines: CheckboxFilterComponent;
  flightDuration: SliderFilterComponent;
  flightProvider?: Maybe<SingleValueFilterComponent>;
  flightStopOverDuration: DoubleRangeSliderFilterComponent;
  hotelEntertainmentFeatures: CheckboxFilterComponent;
  hotelFeatures: CheckboxFilterComponent;
  hpRating: RadiobuttonFilterComponent;
  luxuryFeatures: CheckboxFilterComponent;
  mainFeatures: CheckboxFilterComponent;
  maxPrice: SliderFilterComponent;
  minPrice: SliderFilterComponent;
  nationalParkFeatures: CheckboxFilterComponent;
  nightlifeFeatures: CheckboxFilterComponent;
  petsFeatures: CheckboxFilterComponent;
  popularFilters?: Maybe<CheckboxFilterComponent>;
  productCode?: Maybe<SingleValueFilterComponent>;
  provider?: Maybe<SingleValueFilterComponent>;
  radius?: Maybe<RadiusFilterComponent>;
  refundableFeatures: CheckboxFilterComponent;
  resortFlairFeatures: CheckboxFilterComponent;
  resortNatureFeatures: CheckboxFilterComponent;
  resortShoppingFeatures: CheckboxFilterComponent;
  restaurantFeatures: CheckboxFilterComponent;
  returnFlightArrivalTime: RadiobuttonFilterComponent;
  returnFlightDepartureTime: RadiobuttonFilterComponent;
  returnFlightTime: RadiobuttonFilterComponent;
  roomTypes: CheckboxFilterComponent;
  roundtripFeatures?: Maybe<CheckboxFilterComponent>;
  shipFeatures?: Maybe<CheckboxFilterComponent>;
  sportFeatures: CheckboxFilterComponent;
  stopOvers: RadiobuttonFilterComponent;
  summerSportFeatures: CheckboxFilterComponent;
  sustainableFeatures: CheckboxFilterComponent;
  taRating: RadiobuttonFilterComponent;
  waterSportFeatures: CheckboxFilterComponent;
  wellnessFeatures: CheckboxFilterComponent;
  winterSportFeatures: CheckboxFilterComponent;
  wlanFeatures: CheckboxFilterComponent;
};

export type CheckboxFilterComponent = {
  __typename?: 'CheckboxFilterComponent';
  caption: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  options: Array<FilterItem>;
  selected: Array<Scalars['String']>;
};

export type FilterItem = {
  __typename?: 'FilterItem';
  caption: Scalars['String'];
  id: Scalars['String'];
  productCount: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
};

export type RadiobuttonFilterComponent = {
  __typename?: 'RadiobuttonFilterComponent';
  caption: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  options: Array<FilterItem>;
  selected: Scalars['String'];
};

export type SliderFilterComponent = {
  __typename?: 'SliderFilterComponent';
  caption: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  selected: Scalars['String'];
};

export type SingleValueFilterComponent = {
  __typename?: 'SingleValueFilterComponent';
  caption: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type DoubleRangeSliderFilterComponent = {
  __typename?: 'DoubleRangeSliderFilterComponent';
  caption: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maxSelected: Scalars['String'];
  minSelected: Scalars['String'];
};

export type RadiusFilterComponent = {
  __typename?: 'RadiusFilterComponent';
  center: GeoCoordinates;
  label?: Maybe<Scalars['String']>;
  radius: Scalars['Int'];
};

export type WaitingScreen = {
  __typename?: 'WaitingScreen';
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type GeoCriteriaInput = {
  /** Search filters criteria input */
  filters?: InputMaybe<SrlFilterCriteriaInput>;
  /** Force single view. Relevant for SRL group page */
  forceSingleView?: InputMaybe<Scalars['Boolean']>;
  /** Group sorting criteria input */
  groupSorting?: InputMaybe<SrlGroupSorting>;
  /** Product sorting criteria input */
  productSorting?: InputMaybe<SrlProductSorting>;
  rooms: Scalars['Int'];
  /** Sub geo filter criteria input */
  subGeoFilter?: InputMaybe<Array<Scalars['String']>>;
  travelDestinations?: InputMaybe<Array<TravelDestinationInput>>;
  travellers: TravellersInput;
  travellersDistribution?: InputMaybe<TravellersDistributionInput>;
  travelPeriod: TravelPeriodCriteriaInput;
  travelType: TravelType;
};

export type SrlFilterCriteriaInput = {
  accommodationSize?: InputMaybe<Array<Scalars['String']>>;
  airlines?: InputMaybe<Array<Scalars['String']>>;
  arrivalAirports?: InputMaybe<Array<Scalars['String']>>;
  boardTypes?: InputMaybe<Array<Scalars['String']>>;
  departureAirports?: InputMaybe<Array<Scalars['String']>>;
  departureFlightArrivalTime?: InputMaybe<Scalars['String']>;
  departureFlightDepartureTime?: InputMaybe<Scalars['String']>;
  departureWeekday?: InputMaybe<Array<Scalars['String']>>;
  flightProvider?: InputMaybe<Scalars['String']>;
  flightStopOver?: InputMaybe<Scalars['String']>;
  hotelplanRating?: InputMaybe<Scalars['String']>;
  maxFlightDuration?: InputMaybe<Scalars['Int']>;
  maxPricePerPerson?: InputMaybe<Scalars['String']>;
  minPricePerPerson?: InputMaybe<Scalars['String']>;
  productCode?: InputMaybe<Scalars['String']>;
  productFeatures?: InputMaybe<Array<Scalars['String']>>;
  provider?: InputMaybe<Scalars['String']>;
  radius?: InputMaybe<SrlRadiusCriteriaInput>;
  returnFlightArrivalTime?: InputMaybe<Scalars['String']>;
  returnFlightDepartureTime?: InputMaybe<Scalars['String']>;
  returnWeekday?: InputMaybe<Array<Scalars['String']>>;
  roomTypes?: InputMaybe<Array<Scalars['String']>>;
  tripAdvisorRating?: InputMaybe<Scalars['String']>;
};

export type SrlRadiusCriteriaInput = {
  center: GeoCoordinatesInput;
  label?: InputMaybe<Scalars['String']>;
  radius: Scalars['Int'];
};

export enum SrlGroupSorting {
  GroupAlphabetic = 'GROUP_ALPHABETIC',
  GroupPrice = 'GROUP_PRICE',
  GroupTopDestination = 'GROUP_TOP_DESTINATION',
}

export enum SrlProductSorting {
  ProductBd4TravelRecommendation = 'PRODUCT_BD4TRAVEL_RECOMMENDATION',
  ProductBestseller = 'PRODUCT_BESTSELLER',
  ProductDistanceToPoi = 'PRODUCT_DISTANCE_TO_POI',
  ProductPopularity = 'PRODUCT_POPULARITY',
  ProductPrice = 'PRODUCT_PRICE',
  ProductRating = 'PRODUCT_RATING',
  ProductTaRating = 'PRODUCT_TA_RATING',
}

export type TravelDestinationInput = {
  /** Travel destination id */
  documentId?: InputMaybe<Scalars['String']>;
  /** Travel destination id */
  id: Scalars['String'];
  /** Travel destination location info */
  location?: InputMaybe<GeoLocationInput>;
  mythosCode?: InputMaybe<Scalars['String']>;
  /** Travel destination name */
  name: Scalars['String'];
  /** Theme destination information */
  themeInformation?: InputMaybe<ThemeDestinationInformationInput>;
  /** Travel destination type */
  type: TravelDestinationType;
};

export type GeoLocationInput = {
  airport?: InputMaybe<AirportInput>;
  continent?: InputMaybe<GeoObjectInput>;
  coordinates?: InputMaybe<GeoCoordinatesInput>;
  country?: InputMaybe<GeoObjectInput>;
  countryGroup?: InputMaybe<GeoObjectInput>;
  destination?: InputMaybe<GeoObjectInput>;
  region?: InputMaybe<GeoObjectInput>;
  resort?: InputMaybe<GeoObjectInput>;
};

export type GeoObjectInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  type: GeoType;
};

export type ThemeDestinationInformationInput = {
  /** Theme information plural */
  plural?: InputMaybe<Scalars['String']>;
  /** Theme information singular */
  singular?: InputMaybe<Scalars['String']>;
};

/** Geo page root type */
export type GeoContext = {
  __typename?: 'GeoContext';
  autocomplete: AutocompleteComponent;
  blogArticles: Array<BlogArticleItem>;
  bookmarked: Scalars['Boolean'];
  children: GeoChildrenComponentInterface;
  childrenOffers?: Maybe<GeoChildrenOffersComponent>;
  climateData?: Maybe<ClimateComponent>;
  componentsBasedStructure: Scalars['Boolean'];
  defaultRecommendations?: Maybe<GeoDefaultGeoRecommendationsComponent>;
  /** @deprecated use root components query */
  dynamicComponents: Array<GeoObjectPageComponent>;
  extraHotels: Array<GeoProduct>;
  /** Geo filters */
  filters: MainFilterComponent;
  /** @deprecated use productGallery */
  galleryWithMap?: Maybe<ProductGalleryWithMapComponent>;
  geoProductFilters?: Maybe<Array<ProductFilter>>;
  geoStaticComponents?: Maybe<Array<Maybe<DynamicComponent>>>;
  hero: Image;
  information: GeoInfoComponent;
  inpageNavigation?: Maybe<Array<InpageNavigationItem>>;
  mapAccess: Scalars['Boolean'];
  mediaGallery: MediaGallery;
  /** Get meta data */
  meta: PageMetaData;
  productGallery?: Maybe<ProductImageGallery>;
  products: Array<GeoProduct>;
  regularHotels: Array<GeoProduct>;
  relatedRoundtrips?: Maybe<Array<RelatedRoundtrips>>;
  requestButton?: Maybe<RequestButtonComponent>;
  searchControl: SearchControlUnion;
  seoChildren?: Maybe<SeoChildrenComponent>;
  siblings?: Maybe<GeoSiblingsComponent>;
  tagline?: Maybe<Scalars['String']>;
  themes: Array<GeoTheme>;
  /** Get URL */
  url: InternalLink;
  version: Scalars['String'];
};

/** Geo page root type */
export type GeoContextAutocompleteArgs = {
  criteria?: InputMaybe<AutocompleteCriteriaInput>;
};

/** Geo page root type */
export type GeoContextChildrenOffersArgs = {
  criteria?: InputMaybe<GeoCriteriaInput>;
  currentUri?: InputMaybe<Scalars['String']>;
  gss?: InputMaybe<GlobalSearchStateInput>;
  page?: InputMaybe<PageInput>;
};

/** Geo page root type */
export type GeoContextDefaultRecommendationsArgs = {
  criteria?: InputMaybe<GeoCriteriaInput>;
  currentUri?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<PageInput>;
};

/** Geo page root type */
export type GeoContextDynamicComponentsArgs = {
  criteria?: InputMaybe<GeoCriteriaInput>;
};

/** Geo page root type */
export type GeoContextFiltersArgs = {
  encodedCriteria?: InputMaybe<Scalars['String']>;
};

/** Geo page root type */
export type GeoContextHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Geo page root type */
export type GeoContextSearchControlArgs = {
  encodedCriteria?: InputMaybe<Scalars['String']>;
  travelType?: InputMaybe<TravelType>;
};

/** Geo page root type */
export type GeoContextSeoChildrenArgs = {
  currentUri?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<PageInput>;
};

/** Geo page root type */
export type GeoContextUrlArgs = {
  criteria?: InputMaybe<GeoCriteriaInput>;
  targetPageType?: InputMaybe<PageType>;
};

export type GeoChildrenComponentInterface =
  | GeoChildrenComponent
  | ResortTopHotelsComponent;

export type GeoChildrenOffersComponent = {
  __typename?: 'GeoChildrenOffersComponent';
  childrenOffers?: Maybe<Array<Maybe<GeoChildrenOffer>>>;
  page?: Maybe<Page>;
  title?: Maybe<Scalars['String']>;
};

export type GeoChildrenOffer = {
  __typename?: 'GeoChildrenOffer';
  image: Image;
  leadText?: Maybe<Scalars['String']>;
  link?: Maybe<InternalLink>;
  name?: Maybe<Scalars['String']>;
  offerInfo?: Maybe<GeoOfferInfo>;
  productsTotal: Scalars['Int'];
};

export type GeoChildrenOfferImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type GeoOfferInfo = {
  __typename?: 'GeoOfferInfo';
  cheapestPrice?: Maybe<Price>;
  numberOfNightsInfo?: Maybe<Scalars['String']>;
  productsTotal: Scalars['Int'];
  travelTypeInfo?: Maybe<Scalars['String']>;
};

export type GeoDefaultGeoRecommendationsComponent = {
  __typename?: 'GeoDefaultGeoRecommendationsComponent';
  items: Array<GeoRecommendationItem>;
  page: Page;
  title: Scalars['String'];
};

export type GeoObjectPageComponent = GeoObjectRecommendationGroupComponent;

export type GeoObjectRecommendationGroupComponent = {
  __typename?: 'GeoObjectRecommendationGroupComponent';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<GeoObjectRecommendationItem>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type GeoObjectRecommendationItem =
  | Bd4TravelRecommendation
  | BlogArticleRecommendationItem
  | GeoRecommendationItem
  | MarketingRecommendationItem
  | ProductRecommendationItem
  | ThemeRecommendationItem;

export type GeoProduct = {
  __typename?: 'GeoProduct';
  address?: Maybe<Scalars['String']>;
  breadcrumbs?: Maybe<Array<Scalars['String']>>;
  disrupters?: Maybe<Array<DisrupterObject>>;
  features: Array<ProductFeature>;
  googleStaticMapImage?: Maybe<Image>;
  image: Image;
  link: InternalLink;
  name: Scalars['String'];
  productInfoB2B?: Maybe<ProductInfoB2B>;
  route: Array<Scalars['String']>;
  routePoints?: Maybe<Array<RoutePoint>>;
  teaser: Scalars['String'];
};

export type GeoProductGoogleStaticMapImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type GeoProductImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type MainFilterComponent = {
  __typename?: 'MainFilterComponent';
  mainFilter: FilterComponent;
  subGeoFilter: SubGeoFilterComponent;
};

export type SubGeoFilterComponent = {
  __typename?: 'SubGeoFilterComponent';
  items?: Maybe<Array<SubGeoItem>>;
};

export type SubGeoItem = {
  __typename?: 'SubGeoItem';
  caption: Scalars['String'];
  FILTER_ATTRIBUTE_CAPTION?: Maybe<Scalars['String']>;
  FILTER_ATTRIBUTE_ID?: Maybe<Scalars['String']>;
  FILTER_ATTRIBUTE_PRODUCT_COUNT?: Maybe<Scalars['String']>;
  FILTER_ATTRIBUTE_SELECTED?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image: Image;
  productCount: Scalars['Int'];
  selected: Scalars['Boolean'];
};

export type SubGeoItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type GeoInfoComponent = {
  __typename?: 'GeoInfoComponent';
  attributes?: Maybe<Array<GeoFeatureGroup>>;
  bestTravelPeriodInformation?: Maybe<Scalars['String']>;
  bestTravelPeriodInformationAccordion?: Maybe<Array<Maybe<AccordionItem>>>;
  bestTravelPeriodText?: Maybe<Scalars['String']>;
  bestTravelPeriodTitle?: Maybe<Scalars['String']>;
  breadcrumbs?: Maybe<BreadcrumbsComponent>;
  coordinates?: Maybe<GeoCoordinates>;
  facts?: Maybe<Scalars['String']>;
  flightDuration?: Maybe<Scalars['String']>;
  highlightsText?: Maybe<Scalars['String']>;
  highlightsTitle?: Maybe<Scalars['String']>;
  leadText?: Maybe<Scalars['String']>;
  leadTitle?: Maybe<Scalars['String']>;
  link?: Maybe<InternalLink>;
  mostPopularTravelPeriod?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameH1?: Maybe<Scalars['String']>;
  offerInfo?: Maybe<GeoOfferInfo>;
  seoText?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

/** Resort attribute */
export type GeoFeatureGroup = {
  __typename?: 'GeoFeatureGroup';
  details: Array<GeoFeature>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
};

/** Resort attribute detail */
export type GeoFeature = {
  __typename?: 'GeoFeature';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
};

export type AccordionItem = {
  __typename?: 'AccordionItem';
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type InpageNavigationItem = {
  __typename?: 'InpageNavigationItem';
  anchor: Scalars['String'];
  label: Scalars['String'];
};

export type ProductImageGallery = {
  __typename?: 'ProductImageGallery';
  images: Array<ProductImage>;
  productsImages: Array<ProductImage>;
  title: Scalars['String'];
};

export type ProductImage = {
  __typename?: 'ProductImage';
  image: ImageMediaItem;
  images?: Maybe<Array<Maybe<ImageMediaItem>>>;
  link: InternalLink;
  productInfoB2B?: Maybe<ProductInfoB2B>;
  productName: Scalars['String'];
};

export type SeoChildrenComponent = {
  __typename?: 'SeoChildrenComponent';
  childrenOffers?: Maybe<Array<Maybe<SeoGeoChildren>>>;
  title?: Maybe<Scalars['String']>;
};

export type SeoGeoChildren = {
  __typename?: 'SeoGeoChildren';
  link?: Maybe<InternalLink>;
  name?: Maybe<Scalars['String']>;
};

export type GeoSiblingsComponent = {
  __typename?: 'GeoSiblingsComponent';
  page?: Maybe<Page>;
  siblings: Array<GeoChildComponent>;
  title: Scalars['String'];
};

export type GeoTheme = {
  __typename?: 'GeoTheme';
  image: Image;
  link: InternalLink;
  name: Scalars['String'];
};

export type GeoThemeImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type GeoOverviewContext = {
  __typename?: 'GeoOverviewContext';
  blogArticles: Array<BlogArticleRecommendationItem>;
  blogArticlesTitle: Scalars['String'];
  breadcrumbs: Array<GeoObject>;
  children: Array<GeoOverviewChildComponent>;
  /** @deprecated use root components query */
  dynamicComponents: Array<GeoOverviewPageComponent>;
  geoHierarchy: GeoHierarchyComponent;
  hero: Image;
  information: TextComponent;
  mainTitle?: Maybe<Scalars['String']>;
  meta: PageMetaData;
  pageBreadcrumbs: BreadcrumbsComponent;
  sharingOverlayDefaultImage?: Maybe<Image>;
  tagline?: Maybe<Scalars['String']>;
  /** Get geo overview URL */
  url: InternalLink;
};

export type GeoOverviewContextHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type GeoOverviewContextSharingOverlayDefaultImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type GeoOverviewChildComponent = {
  __typename?: 'GeoOverviewChildComponent';
  id: Scalars['String'];
  image: Image;
  lastChild: Scalars['Boolean'];
  offerInfo?: Maybe<GeoOverviewChildOfferInfo>;
  srlLink: InternalLink;
  title: Scalars['String'];
};

export type GeoOverviewChildComponentImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type GeoOverviewChildOfferInfo = {
  __typename?: 'GeoOverviewChildOfferInfo';
  cheapestPrice?: Maybe<Price>;
  destinationName?: Maybe<Scalars['String']>;
  example?: Maybe<Scalars['String']>;
  numberOfNightsInfo?: Maybe<Scalars['String']>;
  travelTypeInfo?: Maybe<Scalars['String']>;
};

export type GeoOverviewPageComponent = GeoOverviewRecommendationGroupComponent;

export type GeoOverviewRecommendationGroupComponent = {
  __typename?: 'GeoOverviewRecommendationGroupComponent';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<GeoOverviewRecommendationItem>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type GeoOverviewRecommendationItem =
  | GeoRecommendationItem
  | ThemeRecommendationItem;

export type GeoHierarchyComponent = {
  __typename?: 'GeoHierarchyComponent';
  continents: Array<GeoHierarchyContinent>;
};

export type GeoHierarchyContinent = {
  __typename?: 'GeoHierarchyContinent';
  countries: Array<GeoHierarchyCountry>;
  image: Image;
  name: Scalars['String'];
};

export type GeoHierarchyContinentImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type GeoHierarchyCountry = {
  __typename?: 'GeoHierarchyCountry';
  link: InternalLink;
  name: Scalars['String'];
};

/** Container for help overlay boxes */
export type HelpOverlayData = {
  __typename?: 'HelpOverlayData';
  helpOverlayBoxes?: Maybe<Array<HelpOverlayBoxBase>>;
};

/** Generic type for all help overlay boxes */
export type HelpOverlayBoxBase =
  | HelpOverlayBox
  | HelpOverlayBoxChat
  | HelpOverlayBoxContact;

export type HelpOverlayBox = {
  __typename?: 'HelpOverlayBox';
  icon: Image;
  mainText?: Maybe<Scalars['String']>;
  mainTitle?: Maybe<Scalars['String']>;
  targetUrl?: Maybe<Link>;
};

export type HelpOverlayBoxIconArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type HelpOverlayBoxChat = {
  __typename?: 'HelpOverlayBoxChat';
  icon: Image;
  mainText?: Maybe<Scalars['String']>;
  mainTitle?: Maybe<Scalars['String']>;
  targetUrl?: Maybe<Link>;
};

export type HelpOverlayBoxChatIconArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type HelpOverlayBoxContact = {
  __typename?: 'HelpOverlayBoxContact';
  additionalInformation?: Maybe<Scalars['String']>;
  icon: Image;
  mainText?: Maybe<Scalars['String']>;
  mainTitle?: Maybe<Scalars['String']>;
  targetUrl?: Maybe<Link>;
};

export type HelpOverlayBoxContactIconArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type HistoryContext = {
  __typename?: 'HistoryContext';
  /** Get last seen hotels for current user */
  lastSeenHotels: LastSeenResponse;
  /** Get resent flight searches from user's history */
  recentFlightSearches: Array<HistoryFlightRecord>;
  /** Get resent flight searches from user's history */
  recentFlightSearchesWithPage: HistoryFlightResponse;
  /** Get resent full text searches from user's history */
  recentFullTextSearches: Array<HistoryUserRecord>;
  /** Get resent full text searches from user's history */
  recentFullTextSearchesWithPage: HistoryUserResponse;
  /** Get resent products from user's history */
  recentProducts: Array<HistoryProductRecord>;
  /** Get resent products from user's history */
  recentProductsWithPage: HistoryProductResponse;
  /** Get resent searches from user's history */
  recentSearches: Array<HistoryRecordUnion>;
  /** Get resent searches by travel type from user's history */
  recentSearchesByTravelType: Array<HistoryRecordUnion>;
  /** Get resent searches by travel type from user's history */
  recentSearchesByTravelTypeWithPage: HistoryUnionResponse;
  /** Get resent searches from user's history */
  recentSearchesWithPage: HistoryUnionResponse;
  /** Get resent srl searches from user's history */
  recentSrlSearches: Array<HistorySrlRecord>;
  /** Get resent srl searches from user's history */
  recentSrlSearchesWithPage: HistorySrlResponse;
  /** Get resent viewed pages from user's history */
  recentViewed: Array<HistoryUserRecord>;
  /** Get resent viewed pages from user's history */
  recentViewedWithPage: HistoryUserResponse;
};

export type HistoryContextLastSeenHotelsArgs = {
  request?: InputMaybe<LastSeenRequestInput>;
};

export type HistoryContextRecentFlightSearchesArgs = {
  page?: InputMaybe<PageInput>;
};

export type HistoryContextRecentFlightSearchesWithPageArgs = {
  page: PageInput;
};

export type HistoryContextRecentFullTextSearchesArgs = {
  page?: InputMaybe<PageInput>;
};

export type HistoryContextRecentFullTextSearchesWithPageArgs = {
  page: PageInput;
};

export type HistoryContextRecentProductsArgs = {
  page?: InputMaybe<PageInput>;
};

export type HistoryContextRecentProductsWithPageArgs = {
  page: PageInput;
};

export type HistoryContextRecentSearchesArgs = {
  page?: InputMaybe<PageInput>;
};

export type HistoryContextRecentSearchesByTravelTypeArgs = {
  page?: InputMaybe<PageInput>;
  travelType: TravelType;
};

export type HistoryContextRecentSearchesByTravelTypeWithPageArgs = {
  page: PageInput;
  travelType: TravelType;
};

export type HistoryContextRecentSearchesWithPageArgs = {
  page: PageInput;
};

export type HistoryContextRecentSrlSearchesArgs = {
  page?: InputMaybe<PageInput>;
};

export type HistoryContextRecentSrlSearchesWithPageArgs = {
  page: PageInput;
};

export type HistoryContextRecentViewedArgs = {
  page?: InputMaybe<PageInput>;
};

export type HistoryContextRecentViewedWithPageArgs = {
  page: PageInput;
};

export type LastSeenRequestInput = {
  page?: InputMaybe<PageInput>;
};

export type LastSeenResponse = {
  __typename?: 'LastSeenResponse';
  lastSeenItems?: Maybe<Array<LastSeenItem>>;
  message?: Maybe<Scalars['String']>;
  page: Page;
  status: Status;
};

export type LastSeenItem = {
  __typename?: 'LastSeenItem';
  hero?: Maybe<Image>;
  /** Hotelplan rating. */
  hpRating?: Maybe<Scalars['Int']>;
  /** Link to the PDP page. */
  link: InternalLink;
  locations?: Maybe<Array<GeoObject>>;
  productName: Scalars['String'];
};

export type LastSeenItemHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type HistoryFlightRecord = {
  __typename?: 'HistoryFlightRecord';
  flightPartitions?: Maybe<Array<FlightPartition>>;
  flightType: FlightType;
  link: InternalLink;
  travellers: Travellers;
  travelType: TravelType;
};

export type HistoryFlightResponse = {
  __typename?: 'HistoryFlightResponse';
  flightRecords?: Maybe<Array<HistoryFlightRecord>>;
  page: Page;
};

export type HistoryUserRecord = {
  __typename?: 'HistoryUserRecord';
  link: InternalLink;
  title: Scalars['String'];
};

export type HistoryUserResponse = {
  __typename?: 'HistoryUserResponse';
  page: Page;
  userRecords?: Maybe<Array<HistoryUserRecord>>;
};

export type HistoryProductRecord = {
  __typename?: 'HistoryProductRecord';
  destinations?: Maybe<Array<TravelDestination>>;
  link: InternalLink;
  productCase: Scalars['String'];
  travelType?: Maybe<TravelType>;
};

export type HistoryProductResponse = {
  __typename?: 'HistoryProductResponse';
  page: Page;
  productRecords?: Maybe<Array<HistoryProductRecord>>;
};

export type HistoryRecordUnion = HistoryFlightRecord | HistorySrlRecord;

export type HistorySrlRecord = {
  __typename?: 'HistorySrlRecord';
  destinations?: Maybe<Array<TravelDestination>>;
  link: InternalLink;
  period: TravelPeriodComponent;
  rooms: Scalars['Int'];
  travellers: Travellers;
  travelType: TravelType;
};

export type HistoryUnionResponse = {
  __typename?: 'HistoryUnionResponse';
  page: Page;
  recordUnions?: Maybe<Array<HistoryRecordUnion>>;
};

export type HistorySrlResponse = {
  __typename?: 'HistorySrlResponse';
  page: Page;
  srlRecords?: Maybe<Array<HistorySrlRecord>>;
};

export type HolidayFinderPageContext = {
  __typename?: 'HolidayFinderPageContext';
  /** Gets initial offers for the current user to start voting */
  initialOffers: HolidayFinderVotingResponse;
  /** Gets landing page data */
  landingPage: HolidayFinderLandingPage;
  pageMetaData: PageMetaData;
  /** Gets result offers for the current user */
  resultOffers: HolidayFinderVotingResponse;
  /** Gets result page data */
  resultPage: HolidayFinderPage;
  /** Get result offers by offline agency request */
  searchResult: HolidayFinderSearchResponse;
  version: Scalars['String'];
  /** Gets voting page data */
  votingPage: HolidayFinderPage;
};

export type HolidayFinderPageContextInitialOffersArgs = {
  request: HolidayFinderStartVotingRequestInput;
};

export type HolidayFinderPageContextResultOffersArgs = {
  request: HolidayFinderFinishVotingRequestInput;
};

export type HolidayFinderPageContextSearchResultArgs = {
  request: HolidayFinderSearchRequestInput;
};

export type HolidayFinderStartVotingRequestInput = {
  children: Scalars['Boolean'];
  limit: Scalars['Int'];
  placementId: Scalars['String'];
  productFeatures?: InputMaybe<Array<Scalars['String']>>;
  travelPeriod: TravelPeriodCriteriaInput;
};

export type HolidayFinderVotingResponse = {
  __typename?: 'HolidayFinderVotingResponse';
  info: HolidayFinderInfo;
  lowestPrice: Price;
  message?: Maybe<Scalars['String']>;
  productCount: Scalars['Int'];
  products: Array<HolidayFinderProduct>;
  ptoken: Scalars['String'];
  status: Status;
  tracking: HolidayFinderTracking;
};

export type HolidayFinderInfo = {
  __typename?: 'HolidayFinderInfo';
  continueVoting: Scalars['Boolean'];
  elementSetId: Scalars['String'];
};

export type HolidayFinderProduct = {
  __typename?: 'HolidayFinderProduct';
  breadcrumbs: Array<GeoObject>;
  coordinates: GeoCoordinates;
  featureSummary: Array<ProductFeatureGroup>;
  giataId: Scalars['String'];
  hpRating?: Maybe<Scalars['Int']>;
  image: Image;
  name: Scalars['String'];
  offers: Array<HolidayFinderOffer>;
  productClass: ProductClass;
};

export type HolidayFinderProductImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type HolidayFinderOffer = {
  __typename?: 'HolidayFinderOffer';
  departureDate: Scalars['String'];
  duration: Scalars['Int'];
  inWishlist: Scalars['Boolean'];
  link: InternalLink;
  offerId: Scalars['String'];
  pricePerPerson: Price;
  returnDate: Scalars['String'];
  type: ProductType;
  wishlistItem?: Maybe<WishlistItem>;
};

export type HolidayFinderTracking = {
  __typename?: 'HolidayFinderTracking';
  elementSetId: Scalars['String'];
  votesCollected: Scalars['Int'];
};

export type HolidayFinderLandingPage = {
  __typename?: 'HolidayFinderLandingPage';
  children: Scalars['Boolean'];
  hero: Image;
  info: TextComponent;
  message?: Maybe<Scalars['String']>;
  status: Status;
  travelPeriod: TravelPeriodComponent;
};

export type HolidayFinderLandingPageHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type HolidayFinderFinishVotingRequestInput = {
  children: Scalars['Boolean'];
  limit: Scalars['Int'];
  placementId: Scalars['String'];
  productFeatures?: InputMaybe<Array<Scalars['String']>>;
  sessionId: Scalars['String'];
  travelPeriod: TravelPeriodCriteriaInput;
};

export type HolidayFinderPage = {
  __typename?: 'HolidayFinderPage';
  info: TextComponent;
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type HolidayFinderSearchRequestInput = {
  giataIds: Array<InputMaybe<Scalars['String']>>;
  travelPeriod?: InputMaybe<TravelPeriodCriteriaInput>;
};

export type HolidayFinderSearchResponse = {
  __typename?: 'HolidayFinderSearchResponse';
  lowestPrice: Price;
  message?: Maybe<Scalars['String']>;
  productCount: Scalars['Int'];
  products: Array<HolidayFinderProduct>;
  status: Status;
};

export type HomeContext = {
  __typename?: 'HomeContext';
  /** Autocomplete Travel Destinations */
  autocomplete: AutocompleteComponent;
  /**
   * Load home page flexible components
   * @deprecated use root query
   */
  dynamicComponents: Array<HomeDynamicComponent>;
  /** Get link for target page */
  link: InternalLink;
  /** Get meta data */
  meta: PageMetaData;
  moreVisibleComponent: MoreVisibilityStaticComponent;
  searchControl: SearchControlUnion;
  staticContent: HomeStaticContent;
  /** Get total product count */
  totalProductCount: HomeTotalProductCount;
  version: Scalars['String'];
};

export type HomeContextAutocompleteArgs = {
  criteria: AutocompleteCriteriaInput;
};

export type HomeContextDynamicComponentsArgs = {
  criteria?: InputMaybe<HomeSearchCriteriaInput>;
};

export type HomeContextLinkArgs = {
  searchCriteria: HomeSearchCriteriaInput;
  targetPageType: PageType;
};

export type HomeContextMoreVisibleComponentArgs = {
  gss?: InputMaybe<GlobalSearchStateInput>;
};

export type HomeContextSearchControlArgs = {
  travelType?: InputMaybe<TravelType>;
};

export type HomeContextTotalProductCountArgs = {
  criteria?: InputMaybe<HomeSearchCriteriaInput>;
};

/** Home Page search criteria input type */
export type HomeSearchCriteriaInput = {
  /** Search filters criteria input */
  filters?: InputMaybe<SrlFilterCriteriaInput>;
  searchControl: HomeSearchControlCriteriaInput;
};

/** Home Page search control criteria input type */
export type HomeSearchControlCriteriaInput = {
  rooms: Scalars['Int'];
  travelDestinations?: InputMaybe<Array<TravelDestinationInput>>;
  travellers: TravellersInput;
  travellersDistribution?: InputMaybe<TravellersDistributionInput>;
  travelPeriod: TravelPeriodCriteriaInput;
  travelType: TravelType;
};

/** Generic type for all Home page flexible components */
export type HomeDynamicComponent =
  | BrandsComponent
  | EmployeesComponent
  | FaqComponent
  | HeroMediaGallery
  | HomeRecommendationGroup
  | LinkListComponent
  | MediaGallery
  | NewsArticlesComponent
  | TextComponent
  | UspBoxesComponent;

export type HomeRecommendationGroup = {
  __typename?: 'HomeRecommendationGroup';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<HomeRecommendationItem>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type HomeRecommendationItem =
  | Bd4TravelRecommendation
  | GeoRecommendationItem
  | MarketingRecommendationItem
  | ProductRecommendationItem
  | ThemeRecommendationItem;

export type MoreVisibilityStaticComponent = {
  __typename?: 'MoreVisibilityStaticComponent';
  bd4TravelRecommendation: Bd4TravelRecommendationGroup;
  moreVisibleItems?: Maybe<Array<MoreVisibleItem>>;
};

export type MoreVisibleItem = {
  __typename?: 'MoreVisibleItem';
  actionLink: InternalLink;
  itemType: MoreVisibleItemType;
  locations?: Maybe<Array<GeoObject>>;
  moreVisibleItemImage?: Maybe<Image>;
  rooms: Scalars['Int'];
  travellers: Travellers;
  travelPeriod: TravelPeriodUnion;
  travelType: TravelType;
};

export type MoreVisibleItemMoreVisibleItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export enum MoreVisibleItemType {
  MoreVisibleFromOffers = 'MORE_VISIBLE_FROM_OFFERS',
  MoreVisibleFromSearches = 'MORE_VISIBLE_FROM_SEARCHES',
  MoreVisibleFromWishlist = 'MORE_VISIBLE_FROM_WISHLIST',
}

export type HomeStaticContent = {
  __typename?: 'HomeStaticContent';
  barColor?: Maybe<Scalars['String']>;
  hero: Image;
  info: TextComponent;
  macroPicture: Image;
  sharingOverlayDefaultImage?: Maybe<Image>;
  /** @deprecated use info */
  title: HomeTitle;
};

export type HomeStaticContentHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type HomeStaticContentMacroPictureArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type HomeStaticContentSharingOverlayDefaultImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type HomeTitle = {
  __typename?: 'HomeTitle';
  value: Scalars['String'];
};

export type HomeTotalProductCount = {
  __typename?: 'HomeTotalProductCount';
  message?: Maybe<Scalars['String']>;
  status: Status;
  totalCount: Scalars['Int'];
};

export type NewsArticlePage = {
  __typename?: 'NewsArticlePage';
  businessUnit: BusinessUnit;
  /** @deprecated use root components query */
  components: Array<StaticPageComponent>;
  date: Scalars['String'];
  image?: Maybe<Image>;
  meta: PageMetaData;
  shortText: Scalars['String'];
  slug: Scalars['String'];
  status: StaticPageStatus;
  text: Scalars['String'];
  title: Scalars['String'];
  url: InternalLink;
};

export type NewsArticlePageImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type BusinessUnit = {
  __typename?: 'BusinessUnit';
  contact?: Maybe<EmployeeBox>;
  id: Scalars['String'];
  logo?: Maybe<Image>;
  name?: Maybe<Scalars['String']>;
};

export type BusinessUnitLogoArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Generic type for all static page flexible components */
export type StaticPageComponent =
  | BrandsComponent
  | EmployeesComponent
  | FaqComponent
  | HeroMediaGallery
  | LinkListComponent
  | MediaGallery
  | NewsArticlesComponent
  | StaticRecommendationGroup
  | TextComponent
  | UspBoxesComponent;

export type StaticRecommendationGroup = {
  __typename?: 'StaticRecommendationGroup';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<StaticRecommendationItem>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type StaticRecommendationItem =
  | Bd4TravelRecommendation
  | MarketingRecommendationItem;

export enum StaticPageStatus {
  Error = 'ERROR',
  PageDoesNotExist = 'PAGE_DOES_NOT_EXIST',
  Success = 'SUCCESS',
  UnacceptedLanguage = 'UNACCEPTED_LANGUAGE',
}

export type NewsArticlesOverviewPage = {
  __typename?: 'NewsArticlesOverviewPage';
  /** @deprecated use root components query */
  components: Array<StaticPageComponent>;
  filter: NewsArticlesFilter;
  meta: PageMetaData;
  overview: NewsArticlesOverview;
  text: Scalars['String'];
  title: Scalars['String'];
};

export type NewsArticlesOverviewPageOverviewArgs = {
  criteria: NewsArticlesSearchCriteriaInput;
};

export type NewsArticlesFilter = {
  __typename?: 'NewsArticlesFilter';
  businessUnits: Array<SingleValueFilterComponent>;
  selectedBusinessUnit: Scalars['String'];
  selectedYear: Scalars['String'];
  years: Array<SingleValueFilterComponent>;
};

export type NewsArticlesSearchCriteriaInput = {
  businessUnit: Scalars['String'];
  keyword?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  resultsPerPage: Scalars['Int'];
  year: Scalars['String'];
};

export type NewsArticlesOverview = {
  __typename?: 'NewsArticlesOverview';
  newsArticles: Array<NewsArticle>;
  page: Page;
};

export type NewsletterConfirmationContext = {
  __typename?: 'NewsletterConfirmationContext';
  /** @deprecated use contentInfo query for HP and TH tenants */
  content: TextComponent;
  contentInfo: NewsletterInfoComponent;
  /**
   * Load newsletter confirmation page dynamic components
   * @deprecated use root query
   */
  dynamicContent: Array<NewsletterDynamicComponent>;
  /** @deprecated use contentInfo query for HP and TH tenants */
  hero: Image;
  /** Get meta data */
  meta: PageMetaData;
  /** Delivers newsletter confirmation url */
  url: InternalLink;
  version: Scalars['String'];
};

export type NewsletterConfirmationContextHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type NewsletterInfoComponent = {
  __typename?: 'NewsletterInfoComponent';
  breadcrumbs?: Maybe<BreadcrumbsComponent>;
  flagline?: Maybe<Scalars['String']>;
  image: Image;
  tagline?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  textBgColor?: Maybe<Scalars['String']>;
  textBgImage?: Maybe<Image>;
  textFgColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type NewsletterInfoComponentImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type NewsletterInfoComponentTextBgImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Generic type for all Newsletter page dynamic components */
export type NewsletterDynamicComponent = NewsletterRecommendationGroup;

export type NewsletterRecommendationGroup = {
  __typename?: 'NewsletterRecommendationGroup';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<NewsletterRecommendationItem>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type NewsletterRecommendationItem =
  | GeoRecommendationItem
  | ThemeRecommendationItem;

export type NewsletterFinalizationContext = {
  __typename?: 'NewsletterFinalizationContext';
  /** @deprecated use contentInfo query for HP and TH tenants */
  content: TextComponent;
  contentInfo: NewsletterInfoComponent;
  /**
   * Load newsletter finalization page dynamic components
   * @deprecated use root query
   */
  dynamicContent: Array<NewsletterDynamicComponent>;
  /** @deprecated use contentInfo query for HP and TH tenants */
  hero: Image;
  /** Get meta data */
  meta: PageMetaData;
  /** Delivers newsletter finalization url */
  url: InternalLink;
  version: Scalars['String'];
};

export type NewsletterFinalizationContextHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type NewsletterSubscriptionContext = {
  __typename?: 'NewsletterSubscriptionContext';
  /** @deprecated use contentInfo query for HP and TH tenants */
  content: TextComponent;
  contentInfo: NewsletterInfoComponent;
  /** @deprecated use contentInfo query for HP and TH tenants */
  hero: Image;
  /** Get meta data */
  meta: PageMetaData;
  subscriptionTokenFromUserData: NewsletterSubscriptionTokenResponse;
  /** Delivers newsletter subscription url */
  url: InternalLink;
  version: Scalars['String'];
};

export type NewsletterSubscriptionContextHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type NewsletterSubscriptionContextSubscriptionTokenFromUserDataArgs = {
  userData: NewsletterSubscriptionTokenInputInput;
};

export type NewsletterSubscriptionTokenInputInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  salutation: Salutation;
  secret: Scalars['String'];
};

export type NewsletterSubscriptionTokenResponse = {
  __typename?: 'NewsletterSubscriptionTokenResponse';
  confirmationUrl?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status: Status;
  token?: Maybe<Scalars['String']>;
};

export type NewsletterUnsubscriptionContext = {
  __typename?: 'NewsletterUnsubscriptionContext';
  /** @deprecated use contentInfo query for HP and TH tenants */
  content: TextComponent;
  contentInfo: NewsletterInfoComponent;
  /** Gets email from unsubscription token */
  emailFromToken: NewsletterGetEmailFromTokenResponse;
  /** @deprecated use contentInfo query for HP and TH tenants */
  hero: Image;
  /** Get meta data */
  meta: PageMetaData;
  /** Converts email to unsubscription token */
  tokenFromEmail: NewsletterGetTokenFromEmailResponse;
  /** Delivers newsletter unsubscription url */
  url: InternalLink;
  version: Scalars['String'];
};

export type NewsletterUnsubscriptionContextEmailFromTokenArgs = {
  token: Scalars['String'];
};

export type NewsletterUnsubscriptionContextHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type NewsletterUnsubscriptionContextTokenFromEmailArgs = {
  email: Scalars['String'];
};

export type NewsletterGetEmailFromTokenResponse = {
  __typename?: 'NewsletterGetEmailFromTokenResponse';
  email: Scalars['String'];
  status: Status;
};

export type NewsletterGetTokenFromEmailResponse = {
  __typename?: 'NewsletterGetTokenFromEmailResponse';
  status: Status;
  token: Scalars['String'];
};

export type NewsletterUnsubscriptionFinalizationContext = {
  __typename?: 'NewsletterUnsubscriptionFinalizationContext';
  /** @deprecated use contentInfo query for HP and TH tenants */
  content: TextComponent;
  contentInfo: NewsletterInfoComponent;
  /**
   * Load newsletter unsubscription finalization page dynamic components
   * @deprecated use root query
   */
  dynamicContent: Array<NewsletterDynamicComponent>;
  /** @deprecated use contentInfo query for HP and TH tenants */
  hero: Image;
  /** Get meta data */
  meta: PageMetaData;
  /** Delivers newsletter unsubscription finalization url */
  url: InternalLink;
  version: Scalars['String'];
};

export type NewsletterUnsubscriptionFinalizationContextHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Notification data */
export type Notification = {
  __typename?: 'Notification';
  backgroundColor?: Maybe<Scalars['String']>;
  closable: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
  mainText?: Maybe<Scalars['String']>;
  mainTitle?: Maybe<Scalars['String']>;
  notificationType?: Maybe<MaintenanceNotificationType>;
  showIfClosed?: Maybe<MaintenanceNotificationShowMode>;
};

export enum MaintenanceNotificationType {
  Emergency = 'EMERGENCY',
  Maintenance = 'MAINTENANCE',
}

export enum MaintenanceNotificationShowMode {
  Once = 'ONCE',
  OnceEvery_2Hours = 'ONCE_EVERY_2_HOURS',
  OnceEvery_24Hours = 'ONCE_EVERY_24_HOURS',
}

/** Notification info data */
export type NotificationInfo = {
  __typename?: 'NotificationInfo';
  icon: Image;
  mainText: Scalars['String'];
};

/** Notification info data */
export type NotificationInfoIconArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type Organization = {
  __typename?: 'Organization';
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Offer result list page root type */
export type OrlContext = {
  __typename?: 'OrlContext';
  /** Checkout offer */
  checkout: OrlCheckoutComponent;
  /** Orl filters */
  filters: FilterComponent;
  /** Get included in price component */
  includedInPrice: OrlIncludedInPriceComponent;
  /** Get price/date overview component */
  priceDateOverview?: Maybe<PdpPriceDateOverviewComponent>;
  /** Get Price explanation component */
  priceExplanation: OrlPriceExplanationComponent;
  /** Get quick hotel info */
  quickHotelInfo: QuickHotelInfoComponent;
  /** Orl search control */
  searchControl: SearchControlComponent;
  /** Container for search queries */
  searchResult: OrlSearchContainer;
  /** Get URL */
  url: InternalLink;
  version: Scalars['String'];
};

/** Offer result list page root type */
export type OrlContextCheckoutArgs = {
  backUrl: Scalars['String'];
  bookingId?: InputMaybe<Scalars['String']>;
  deviceType: DeviceType;
  metaSearch?: InputMaybe<Scalars['Boolean']>;
  offerId: Scalars['String'];
};

/** Offer result list page root type */
export type OrlContextFiltersArgs = {
  searchCriteria?: InputMaybe<PdpSearchCriteriaInput>;
  travelType?: InputMaybe<TravelType>;
};

/** Offer result list page root type */
export type OrlContextPriceDateOverviewArgs = {
  criteria?: InputMaybe<PdpSearchCriteriaInput>;
};

/** Offer result list page root type */
export type OrlContextPriceExplanationArgs = {
  criteria: PdpSearchCriteriaInput;
};

/** Offer result list page root type */
export type OrlContextQuickHotelInfoArgs = {
  criteria?: InputMaybe<PdpSearchCriteriaInput>;
};

/** Offer result list page root type */
export type OrlContextSearchControlArgs = {
  travelType?: InputMaybe<TravelType>;
};

/** Offer result list page root type */
export type OrlContextSearchResultArgs = {
  criteria: PdpSearchCriteriaInput;
};

/** Offer result list page root type */
export type OrlContextUrlArgs = {
  criteria?: InputMaybe<PdpSearchCriteriaInput>;
};

export type OrlCheckoutComponent = {
  __typename?: 'OrlCheckoutComponent';
  changedOffer?: Maybe<SingleOrlItem>;
  checkoutStatus: OrlCheckoutStatus;
  flightChanged: Scalars['Boolean'];
  link?: Maybe<ExternalLink>;
  message?: Maybe<Scalars['String']>;
  priceChanged: Scalars['Boolean'];
};

export type SingleOrlItem = {
  __typename?: 'SingleOrlItem';
  board: Scalars['String'];
  departureDate: Scalars['String'];
  duration: Scalars['Int'];
  flightAlternativesAvailable: Scalars['Boolean'];
  giataId?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  inboundFlight?: Maybe<Flight>;
  inWishlist: Scalars['Boolean'];
  lastMinute: Scalars['Boolean'];
  link: InternalLink;
  outboundFlight?: Maybe<Flight>;
  pricePerPerson: Price;
  productClass: ProductClass;
  productCode?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  returnDate: Scalars['String'];
  rooms: Array<OrlRoom>;
  /** @deprecated use departure date */
  startDate: Scalars['LocalDate'];
  totalPrice: Price;
  transferIncluded: Scalars['Boolean'];
  transferPrice?: Maybe<Price>;
  travelType: TravelType;
  waitingScreenImage: Scalars['String'];
  wishlistItem?: Maybe<WishlistItem>;
};

export type OrlRoom = {
  __typename?: 'OrlRoom';
  adults: Scalars['Int'];
  alternativeRoomAvailability?: Maybe<AlternativeRoom>;
  boardType: BoardType;
  children: Scalars['Int'];
  roomType: RoomType;
};

export enum AlternativeRoom {
  RoomAlternativeBoard = 'ROOM_ALTERNATIVE_BOARD',
  RoomAlternativeRoom = 'ROOM_ALTERNATIVE_ROOM',
  RoomAlternativeRoomAndBoard = 'ROOM_ALTERNATIVE_ROOM_AND_BOARD',
}

export type BoardType = {
  __typename?: 'BoardType';
  code: Scalars['String'];
  description: Scalars['String'];
};

export type RoomType = {
  __typename?: 'RoomType';
  code: Scalars['String'];
  description: Scalars['String'];
};

export enum OrlCheckoutStatus {
  CheckoutOfferChanged = 'CHECKOUT_OFFER_CHANGED',
  CheckoutStatusNotSet = 'CHECKOUT_STATUS_NOT_SET',
  CheckoutSuccess = 'CHECKOUT_SUCCESS',
  CheckoutUnavailable = 'CHECKOUT_UNAVAILABLE',
}

/** Input type for orl search */
export type PdpSearchCriteriaInput = {
  /** Offers sorting */
  filter?: InputMaybe<PdpFilterCriteriaInput>;
  /** Offers pagination */
  page?: InputMaybe<GroupPageInput>;
  /** Search control input */
  searchControl: PdpSearchControlCriteriaInput;
  /** Offers sorting */
  sort?: InputMaybe<OrlSortInput>;
};

/** Input type for orl filter */
export type PdpFilterCriteriaInput = {
  /** Airlines */
  airlines?: InputMaybe<Array<Scalars['String']>>;
  /** Arrival airports */
  arrivalAirports?: InputMaybe<Array<Scalars['String']>>;
  /** Selected board type ids */
  boardTypes?: InputMaybe<Array<Scalars['String']>>;
  /** Selected departure airport platform ids */
  departureAirports?: InputMaybe<Array<Scalars['String']>>;
  /** Departure flight arrival time */
  departureFlightArrivalTime?: InputMaybe<Scalars['String']>;
  /** Departure flight departure time id */
  departureTime?: InputMaybe<Scalars['String']>;
  /** Departure weekday */
  departureWeekday?: InputMaybe<Array<Scalars['String']>>;
  /** Flight duration */
  flightDuration?: InputMaybe<Scalars['Int']>;
  /** Flight Provider */
  flightProvider?: InputMaybe<Scalars['String']>;
  /** Selected stop overs id */
  flightStopOver?: InputMaybe<Scalars['String']>;
  /** Selected max price per person */
  maxPricePerPerson?: InputMaybe<Scalars['String']>;
  /** Min price per person */
  minPricePerPerson?: InputMaybe<Scalars['String']>;
  /** Product code */
  productCode?: InputMaybe<Scalars['String']>;
  /** Product features */
  productFeatures?: InputMaybe<Array<Scalars['String']>>;
  /** Provider */
  provider?: InputMaybe<Scalars['String']>;
  /** Return flight arrival time */
  returnFlightArrivalTime?: InputMaybe<Scalars['String']>;
  /** Return flight departure time id */
  returnTime?: InputMaybe<Scalars['String']>;
  /** Return weekday */
  returnWeekday?: InputMaybe<Array<Scalars['String']>>;
  /** Selected room type ids */
  roomTypes?: InputMaybe<Array<Scalars['String']>>;
};

export type GroupPageInput = {
  groupId?: InputMaybe<Scalars['String']>;
  pageNumber: Scalars['Int'];
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  resultsTotal?: InputMaybe<Scalars['Int']>;
};

/** Input type for orl search control */
export type PdpSearchControlCriteriaInput = {
  /** Rooms count */
  rooms: Scalars['Int'];
  travelDestinations?: InputMaybe<Array<TravelDestinationInput>>;
  /** Travellers */
  travellersCriteria: TravellersInput;
  travellersDistribution?: InputMaybe<TravellersDistributionInput>;
  /** Travel period criteria */
  travelPeriodCriteria: TravelPeriodCriteriaInput;
  travelType: TravelType;
};

export type OrlSortInput = {
  field: OrlSortField;
};

export enum OrlSortField {
  DepartureAirport = 'DEPARTURE_AIRPORT',
  DepartureDate = 'DEPARTURE_DATE',
  Duration = 'DURATION',
  Price = 'PRICE',
}

export type OrlIncludedInPriceComponent = {
  __typename?: 'OrlIncludedInPriceComponent';
  message?: Maybe<Scalars['String']>;
  status: Status;
  text?: Maybe<TextComponent>;
};

export type PdpPriceDateOverviewComponent = {
  __typename?: 'PdpPriceDateOverviewComponent';
  /** @deprecated use orlItems, it contains full information */
  items: Array<PdoItem>;
  orlItems?: Maybe<Array<SingleOrlItem>>;
};

export type PdoItem = {
  __typename?: 'PDOItem';
  adults: Scalars['Int'];
  board: Scalars['String'];
  children: Scalars['Int'];
  departureDate?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  link: InternalLink;
  /** @deprecated use adults and children */
  pax: Scalars['Int'];
  pricePerPerson: Price;
  productClass: ProductClass;
  returnDate?: Maybe<Scalars['String']>;
  type: TravelType;
};

export type OrlPriceExplanationComponent = {
  __typename?: 'OrlPriceExplanationComponent';
  explanation?: Maybe<OrlPriceExplanation>;
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type OrlPriceExplanation = {
  __typename?: 'OrlPriceExplanation';
  duration: Scalars['Int'];
  hpLogo: Image;
  hpPrice: Price;
  meal: Scalars['String'];
  partnerLogo: Image;
  partnerName: Scalars['String'];
  partnerPrice: Price;
  partnerPricing: OrlPartnerPricing;
  room: Scalars['String'];
  travelType: TravelType;
};

export type OrlPriceExplanationHpLogoArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type OrlPriceExplanationPartnerLogoArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export enum OrlPartnerPricing {
  PricePerRoomPerNight = 'PRICE_PER_ROOM_PER_NIGHT',
}

export type QuickHotelInfoComponent = {
  __typename?: 'QuickHotelInfoComponent';
  points?: Maybe<Array<QuickHotelInfoPoint>>;
};

export type QuickHotelInfoPoint = {
  __typename?: 'QuickHotelInfoPoint';
  pointId: Scalars['String'];
  pointText: Scalars['String'];
};

export type OrlSearchContainer = {
  __typename?: 'OrlSearchContainer';
  /** Search offer flight alternatives */
  flightAlternatives: Array<OrlFlightAlternative>;
  /** Search offer groups */
  group: OrlGroupListComponent;
  /** Search offers */
  single: OrlSingleListComponent;
};

export type OrlSearchContainerFlightAlternativesArgs = {
  offerId: Scalars['String'];
};

export type OrlSearchContainerSingleArgs = {
  groupId?: InputMaybe<Scalars['String']>;
};

export type OrlFlightAlternative = {
  __typename?: 'OrlFlightAlternative';
  inboundFlight?: Maybe<Flight>;
  lastMinute: Scalars['Boolean'];
  offerId: Scalars['String'];
  outboundFlight: Flight;
  pricePerPerson: Price;
  totalPrice?: Maybe<Price>;
  waitingScreenImage: Scalars['String'];
};

export type OrlGroupListComponent = {
  __typename?: 'OrlGroupListComponent';
  alternativeSearchUrl?: Maybe<InternalLink>;
  altTravelType: TravelType;
  groups: Array<GroupOrlItem>;
  message: Scalars['String'];
  status: Status;
};

export type GroupOrlItem = {
  __typename?: 'GroupOrlItem';
  groupId: Scalars['String'];
  heading: Scalars['String'];
  headingType: GroupOrlItemHeadingType;
  /** @deprecated use searchResult.single query to get offers */
  offers: Array<SingleOrlItem>;
  page: Page;
};

export enum GroupOrlItemHeadingType {
  Date = 'DATE',
  Number = 'NUMBER',
  Text = 'TEXT',
}

export type OrlSingleListComponent = {
  __typename?: 'OrlSingleListComponent';
  alternativeSearchUrl?: Maybe<InternalLink>;
  altTravelType: TravelType;
  cheapestPrice: Price;
  message: Scalars['String'];
  offers: Array<SingleOrlItem>;
  /** current page */
  page: Page;
  status: Status;
};

/** B2B login page data */
export type PageB2BLoginData = {
  __typename?: 'PageB2BLoginData';
  mainText: Scalars['String'];
  mainTitle: Scalars['String'];
  pageMetaData: PageMetaData;
};

/** Page footer data */
export type PageFooterData = {
  __typename?: 'PageFooterData';
  /** Brand logo links */
  brandLogos?: Maybe<Array<IconMenuItem>>;
  /**
   * Contact information
   * @deprecated use agency api
   */
  contactInformation?: Maybe<ContactInformation>;
  /** Language chooser */
  language: IconMenuItem;
  logo?: Maybe<Image>;
  /** Footer navigation menu */
  navigationMenu: Array<Navigable>;
  /** Payment methods links */
  paymentMethods: Array<IconMenuItem>;
  /** Reasons of confidence */
  reasonsOfConfidence?: Maybe<ReasonsOfConfidence>;
  /** Social media links */
  socialMedia: Array<IconMenuItem>;
};

/** Page footer data */
export type PageFooterDataLogoArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Icon Menu item */
export type IconMenuItem = {
  __typename?: 'IconMenuItem';
  caption: Scalars['String'];
  icon: Image;
  iconId: Scalars['String'];
  link?: Maybe<Link>;
  title?: Maybe<Scalars['String']>;
};

/** Icon Menu item */
export type IconMenuItemIconArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Contact information */
export type ContactInformation = {
  __typename?: 'ContactInformation';
  /** email address */
  emailAddress?: Maybe<Scalars['String']>;
  /** email title */
  emailTitle?: Maybe<Scalars['String']>;
  /** main title */
  mainTitle?: Maybe<Scalars['String']>;
  /** opening hours */
  openingHours?: Maybe<Scalars['String']>;
  /** phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  /** phone title */
  phoneTitle?: Maybe<Scalars['String']>;
};

/** Generic type for all navigable elements */
export type Navigable = Menu | TextMenuItem;

/** Menu with nested elements */
export type Menu = {
  __typename?: 'Menu';
  /** Menu item caption */
  caption: Scalars['String'];
  /** Menu entries */
  entries: Array<TextMenuItem>;
  /** Menu item fontColor value */
  fontColor?: Maybe<Scalars['String']>;
  /** Menu link */
  target?: Maybe<Link>;
};

/** Text Menu item */
export type TextMenuItem = {
  __typename?: 'TextMenuItem';
  caption: Scalars['String'];
  fontColor?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  link: Link;
};

export enum IconType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
}

/** Contact information */
export type ReasonsOfConfidence = {
  __typename?: 'ReasonsOfConfidence';
  /** Link */
  link?: Maybe<Link>;
  /** main text */
  mainText?: Maybe<Scalars['String']>;
  /** main title */
  mainTitle?: Maybe<Scalars['String']>;
};

/** Page header data */
export type PageHeaderData = {
  __typename?: 'PageHeaderData';
  /** Contact information */
  contactInformation?: Maybe<ContactInformation>;
  logo: Image;
  /** Header navigation menu */
  navigationMenu: Array<Navigable>;
  /** Selected agency phone */
  selectedPhone?: Maybe<Scalars['String']>;
};

/** Page header data */
export type PageHeaderDataLogoArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Static Page search criteria input type */
export type StaticPageSearchCriteriaInput = {
  rooms?: InputMaybe<Scalars['Int']>;
  travellers?: InputMaybe<TravellersInput>;
  travellersDistribution?: InputMaybe<TravellersDistributionInput>;
  travelPeriod?: InputMaybe<TravelPeriodCriteriaInput>;
  travelType?: InputMaybe<TravelType>;
};

/** 404 (Page not found) page data */
export type PageNotFound404Data = {
  __typename?: 'PageNotFound404Data';
  components: Array<StaticPageComponent>;
  mainText: Scalars['String'];
  mainTextBgColor?: Maybe<Scalars['String']>;
  mainTextBgImage?: Maybe<Image>;
  mainTextFgColor?: Maybe<Scalars['String']>;
  mainTitle: Scalars['String'];
  pageMetaData: PageMetaData;
  /** @deprecated use pageMetaData.sitemap */
  sitemap: Scalars['Boolean'];
};

/** 404 (Page not found) page data */
export type PageNotFound404DataMainTextBgImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Product detail page root type */
export type PdpContext = {
  __typename?: 'PdpContext';
  autocomplete?: Maybe<AutocompleteComponent>;
  blogArticles: Array<BlogArticleItem>;
  bookmarked: Scalars['Boolean'];
  /** Get product detail page cms content */
  content: PdpContainer;
  /** Get destination hotels */
  destinationHotels: PdpHotelDestinationInfoComponent;
  /**
   * Get dynamic components
   * @deprecated use root components query
   */
  dynamicComponents: Array<PdpPageComponent>;
  /** Get product detail page map properties */
  map: PdpMapComponent;
  /** Get meta data */
  meta: PageMetaData;
  /** Get more offers link */
  moreOffersLink: PdpMoreOffersButton;
  /** Get product overview */
  priceDateOverview: PdpPriceDateOverviewComponent;
  /** Get quick hotel info */
  quickHotelInfo: QuickHotelInfoComponent;
  /** Get product detail page cms content */
  searchControl: SearchControlComponent;
  /** Get URL */
  url?: Maybe<InternalLink>;
  version: Scalars['String'];
};

/** Product detail page root type */
export type PdpContextAutocompleteArgs = {
  criteria: AutocompleteCriteriaInput;
};

/** Product detail page root type */
export type PdpContextContentArgs = {
  currentUri?: InputMaybe<Scalars['String']>;
  encodedCriteria?: InputMaybe<Scalars['String']>;
  gss?: InputMaybe<GlobalSearchStateInput>;
};

/** Product detail page root type */
export type PdpContextDynamicComponentsArgs = {
  criteria?: InputMaybe<PdpSearchCriteriaInput>;
};

/** Product detail page root type */
export type PdpContextMoreOffersLinkArgs = {
  criteria?: InputMaybe<PdpSearchCriteriaInput>;
};

/** Product detail page root type */
export type PdpContextSearchControlArgs = {
  travelType?: InputMaybe<TravelType>;
};

/** Product detail page root type */
export type PdpContextUrlArgs = {
  searchCriteria?: InputMaybe<PdpSearchCriteriaInput>;
  targetPage?: InputMaybe<PageType>;
};

export type PdpContainer = {
  __typename?: 'PdpContainer';
  description: PdpDescriptionComponent;
  destinationInfo: PdpDestinationInfoComponent;
  overview: PdpOverviewComponent;
  tripAdvisor?: Maybe<PdpTripAdvisorComponent>;
};

export type PdpDescriptionComponent = {
  __typename?: 'PdpDescriptionComponent';
  arrivalDeparture?: Maybe<Scalars['String']>;
  b2bInformationGroups: Array<PdpInformationGroup>;
  co2Footprint?: Maybe<Co2Footprint>;
  countryProducts: StaticProductRecommendation;
  countryThemes: StaticThemeRecommendation;
  extraOrdinary: Scalars['Boolean'];
  featureRatings: Array<PdpFeatureRating>;
  galleryWithMap: ProductGalleryWithMapComponent;
  googleStaticMapImage?: Maybe<Image>;
  hotelCoordinates?: Maybe<GeoCoordinates>;
  includedInPrice?: Maybe<TextComponent>;
  leadText: Scalars['String'];
  nearestAirport?: Maybe<Airport>;
  overnightStayAccommodation?: Maybe<Scalars['String']>;
  productFeatures: Array<ProductFeatureGroup>;
  productStaticComponents?: Maybe<Array<Maybe<DynamicComponent>>>;
  programm?: Maybe<TextComponent>;
  requestButton?: Maybe<RequestButtonComponent>;
  route: Array<Scalars['String']>;
  sellingPropositions: Scalars['String'];
  specialities?: Maybe<Scalars['String']>;
  staticMaps: Array<Image>;
  tagline?: Maybe<Scalars['String']>;
  textBlocks: Array<TextComponent>;
  transportInfo?: Maybe<Scalars['String']>;
};

export type PdpDescriptionComponentGoogleStaticMapImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type PdpDescriptionComponentStaticMapsArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type PdpInformationGroup = {
  __typename?: 'PdpInformationGroup';
  id?: Maybe<Scalars['String']>;
  /** @deprecated use informationSection */
  information?: Maybe<Array<TextComponent>>;
  informationSection?: Maybe<Array<Maybe<PdpInformation>>>;
  name?: Maybe<Scalars['String']>;
  pdfLinks?: Maybe<Array<Maybe<InternalLink>>>;
};

/** Non clickable pdp information */
export type PdpInformation = {
  __typename?: 'PdpInformation';
  /** Information flags */
  flags?: Maybe<Array<Scalars['String']>>;
  /** Information value */
  text?: Maybe<Scalars['String']>;
  /** Information title */
  title: Scalars['String'];
};

export type Co2Footprint = {
  __typename?: 'Co2Footprint';
  accommodationEmissionPercentage: Scalars['Int'];
  flightEmissionPercentage: Scalars['Int'];
  specialitiesEmissionPercentage: Scalars['Int'];
  totalEmission: Scalars['Float'];
  transportEmissionPercentage: Scalars['Int'];
};

export type PdpFeatureRating = {
  __typename?: 'PdpFeatureRating';
  id: Scalars['String'];
  name: Scalars['String'];
  rating: Scalars['Int'];
};

export type PdpDestinationInfoComponent = {
  __typename?: 'PdpDestinationInfoComponent';
  attributes: Array<GeoFeatureGroup>;
  climate?: Maybe<ClimateComponent>;
  destinationAttributes?: Maybe<Array<DestinationFeatureGroup>>;
  destinationFacts?: Maybe<Scalars['String']>;
  destinationImage?: Maybe<Image>;
  destinationLeadText: Scalars['String'];
  destinationName: Scalars['String'];
  destinationText: Scalars['String'];
  flightDuration: Scalars['String'];
  mediaGallery: MediaGallery;
  mostPopularTravelPeriod: Scalars['String'];
  resortFacts?: Maybe<Scalars['String']>;
  resortImage?: Maybe<Image>;
  resortLeadText: Scalars['String'];
  resortName: Scalars['String'];
  resortText: Scalars['String'];
};

export type PdpDestinationInfoComponentDestinationImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type PdpDestinationInfoComponentResortImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Destination attribute */
export type DestinationFeatureGroup = {
  __typename?: 'DestinationFeatureGroup';
  details: Array<DestinationFeature>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
};

/** Destination attribute detail */
export type DestinationFeature = {
  __typename?: 'DestinationFeature';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
};

export type PdpOverviewComponent = {
  __typename?: 'PdpOverviewComponent';
  /** @deprecated use featuresSummary */
  attributeSummary: Array<ProductFeature>;
  breadcrumbsExtended: BreadcrumbsComponent;
  disrupters: Array<DisrupterObject>;
  featuresSummary: Array<ProductFeatureGroup>;
  headerImage: Image;
  hpRating: Scalars['Int'];
  location: GeoLocation;
  media: MediaGallery;
  moreOffers?: Maybe<InternalLink>;
  productBreadcrumbs: BreadcrumbsComponent;
  productClass: ProductClass;
  productName: Scalars['String'];
  subtitle: Scalars['String'];
  tagline?: Maybe<Scalars['String']>;
  taRating?: Maybe<TripAdvisorRating>;
};

export type PdpOverviewComponentHeaderImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type TripAdvisorRating = {
  __typename?: 'TripAdvisorRating';
  /** TripAdvisor reviews count */
  count?: Maybe<Scalars['Int']>;
  /** TripAdvisor overall hotel rating */
  rating?: Maybe<Scalars['Int']>;
};

export type PdpTripAdvisorComponent = {
  __typename?: 'PdpTripAdvisorComponent';
  reviews: Array<TripAdvisorReview>;
  subratings: Array<TripAdvisorSubrating>;
  taRating: TripAdvisorRating;
};

export type TripAdvisorReview = {
  __typename?: 'TripAdvisorReview';
  author?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  message?: Maybe<TextComponent>;
  rating?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  subratings?: Maybe<Array<TripAdvisorSubrating>>;
};

export type TripAdvisorSubrating = {
  __typename?: 'TripAdvisorSubrating';
  name: Scalars['String'];
  rating: Scalars['Int'];
};

export type PdpHotelDestinationInfoComponent = {
  __typename?: 'PdpHotelDestinationInfoComponent';
  hotelDestinationInfos: Array<HotelDestinationMapInfo>;
};

export type HotelDestinationMapInfo = {
  __typename?: 'HotelDestinationMapInfo';
  coordinates: GeoCoordinates;
  image: Image;
  link: InternalLink;
  title: Scalars['String'];
};

export type HotelDestinationMapInfoImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Generic type for all pdp page dynamic components */
export type PdpPageComponent = PdpRecommendationGroup;

export type PdpRecommendationGroup = {
  __typename?: 'PdpRecommendationGroup';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<PdpRecommendationItem>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type PdpRecommendationItem =
  | Bd4TravelRecommendation
  | ProductRecommendationItem;

export type PdpMapComponent = {
  __typename?: 'PdpMapComponent';
  hotel: PdpMapHotel;
};

export type PdpMapHotel = {
  __typename?: 'PdpMapHotel';
  coordinates: GeoCoordinates;
};

export type PdpMoreOffersButton = {
  __typename?: 'PdpMoreOffersButton';
  geoName?: Maybe<Scalars['String']>;
  link?: Maybe<InternalLink>;
  message?: Maybe<Scalars['String']>;
  status: Status;
};

/** Pop-up menu data */
export type PopUpMenuData = {
  __typename?: 'PopUpMenuData';
  /** Pop-up menu items */
  items: Array<IconMenuItem>;
};

export type SearchOverlay = {
  __typename?: 'SearchOverlay';
  keywords: Array<Scalars['String']>;
  mostSearched: Array<Scalars['String']>;
  searchTrends: Array<Scalars['String']>;
};

export type SrlContext = {
  __typename?: 'SrlContext';
  /** Autocomplete Travel Destinations */
  autocomplete?: Maybe<AutocompleteComponent>;
  /** Srl page bd4travel recommendations */
  bD4TravelRecommendationsComponent: SrlBd4TravelRecommendationsComponent;
  /** Srl page breadcrumbs */
  breadcrumbs: BreadcrumbsComponent;
  /** use root query */
  dynamicComponents: Array<SrlPageComponent>;
  /** Full text search */
  fullTextSearch: SrlFullTextSearchResultComponent;
  /** Get url ghost offers */
  ghostOffers: SrlGhostOffersComponent;
  /** Get url parameter for target page */
  link: InternalLink;
  /** Get meta data */
  meta: PageMetaData;
  /** Get search result */
  search: SrlResultContext;
  /** Srl page search controls components */
  searchControls: SrlSearchControlsContext;
  /** Get get sharing overlay default image */
  sharingOverlayDefaultImage: Image;
  staticContent: SrlStaticContent;
  version: Scalars['String'];
};

export type SrlContextAutocompleteArgs = {
  criteria: AutocompleteCriteriaInput;
};

export type SrlContextBd4TravelRecommendationsComponentArgs = {
  currentUri: Scalars['String'];
  encodedCriteria: Scalars['String'];
  gss?: InputMaybe<GlobalSearchStateInput>;
};

export type SrlContextDynamicComponentsArgs = {
  searchCriteria: SrlSearchCriteriaInput;
};

export type SrlContextFullTextSearchArgs = {
  fullMatch?: InputMaybe<Scalars['Boolean']>;
  keywords: Array<Scalars['String']>;
};

export type SrlContextGhostOffersArgs = {
  searchCriteria: SrlSearchCriteriaInput;
};

export type SrlContextLinkArgs = {
  searchCriteria: SrlSearchCriteriaInput;
};

export type SrlContextSearchArgs = {
  searchCriteria: SrlSearchCriteriaInput;
};

export type SrlContextSearchControlsArgs = {
  encodedCriteria?: InputMaybe<Scalars['String']>;
  searchCriteria?: InputMaybe<SrlSearchCriteriaInput>;
  travelType?: InputMaybe<TravelType>;
};

export type SrlContextSharingOverlayDefaultImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type SrlBd4TravelRecommendationsComponent = {
  __typename?: 'SrlBD4TravelRecommendationsComponent';
  BD4TravelRecommendationAccordion?: Maybe<Bd4TravelRecommendationGroup>;
  BD4TravelRecommendationBasic?: Maybe<Bd4TravelRecommendationGroup>;
};

/** SRL Page search criteria input */
export type SrlSearchCriteriaInput = {
  filters?: InputMaybe<SrlFilterCriteriaInput>;
  forceSingleView?: InputMaybe<Scalars['Boolean']>;
  groupSorting?: InputMaybe<SrlGroupSorting>;
  page: PageInput;
  productSorting?: InputMaybe<SrlProductSorting>;
  searchControl: SrlSearchControlCriteriaInput;
  subGeoFilter?: InputMaybe<Array<Scalars['String']>>;
};

/** SRL Page search control criteria input type */
export type SrlSearchControlCriteriaInput = {
  rooms: Scalars['Int'];
  travelDestinations?: InputMaybe<Array<TravelDestinationInput>>;
  travellers: TravellersInput;
  travellersDistribution?: InputMaybe<TravellersDistributionInput>;
  travelPeriod: TravelPeriodCriteriaInput;
  travelType: TravelType;
};

/** Generic type for all SRL page dynamic components */
export type SrlPageComponent = SrlRecommendationGroupComponent;

export type SrlRecommendationGroupComponent = {
  __typename?: 'SrlRecommendationGroupComponent';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<SrlRecommendationItem>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type SrlRecommendationItem =
  | Bd4TravelRecommendation
  | GeoRecommendationItem
  | MarketingRecommendationItem
  | ProductRecommendationItem
  | ThemeRecommendationItem;

export type SrlFullTextSearchResultComponent = {
  __typename?: 'SrlFullTextSearchResultComponent';
  /** Full text search adventuretravel products */
  adventuretravel: SrlFullTextSearchResult;
  /** Full text search agencies with address and open flag */
  agenciesWithDetails: AgencySrlFullTextSearchResult;
  /** Auto suggest */
  autoSuggest: SrlFullTextSearchResult;
  /** Full text search blog articles */
  blogArticles: SrlFullTextSearchResult;
  /** Full text search extra hotels */
  extraHotels: SrlFullTextSearchResult;
  /** Full text search geo */
  geo: SrlFullTextSearchResult;
  /** Get full text search url */
  link: InternalLink;
  /** Full text search other */
  other: SrlFullTextSearchResult;
  /** Full text search regular hotels B2B */
  regularHotels: SrlFullTextSearchResult;
  /** Full text search roundtrip products */
  roundtrip: SrlFullTextSearchResult;
  /** Full text search themes */
  themes: SrlFullTextSearchResult;
};

export type SrlFullTextSearchResultComponentAdventuretravelArgs = {
  page: PageInput;
};

export type SrlFullTextSearchResultComponentAgenciesWithDetailsArgs = {
  page: PageInput;
};

export type SrlFullTextSearchResultComponentAutoSuggestArgs = {
  page: PageInput;
  types: Array<InputMaybe<SearchTypeEnum>>;
};

export type SrlFullTextSearchResultComponentBlogArticlesArgs = {
  page: PageInput;
};

export type SrlFullTextSearchResultComponentExtraHotelsArgs = {
  page: PageInput;
};

export type SrlFullTextSearchResultComponentGeoArgs = {
  page: PageInput;
};

export type SrlFullTextSearchResultComponentOtherArgs = {
  page: PageInput;
};

export type SrlFullTextSearchResultComponentRegularHotelsArgs = {
  page: PageInput;
};

export type SrlFullTextSearchResultComponentRoundtripArgs = {
  page: PageInput;
};

export type SrlFullTextSearchResultComponentThemesArgs = {
  page: PageInput;
};

export type SrlFullTextSearchResult = {
  __typename?: 'SrlFullTextSearchResult';
  items: Array<FullTextSearchItem>;
  page: Page;
  title: Scalars['String'];
};

export type FullTextSearchItem = {
  __typename?: 'FullTextSearchItem';
  bookmarked: Scalars['Boolean'];
  breadcrumbs?: Maybe<Array<Scalars['String']>>;
  debugInfo?: Maybe<Scalars['String']>;
  disrupters?: Maybe<Array<DisrupterObject>>;
  googleStaticMapImage?: Maybe<Image>;
  highlightedText?: Maybe<Scalars['String']>;
  image: Image;
  link: InternalLink;
  mainText: Scalars['String'];
  name: Scalars['String'];
  productInfoB2B?: Maybe<SrlProductInfoB2B>;
  productInfoB2C?: Maybe<SrlProductInfoB2C>;
  routePoints?: Maybe<Array<RoutePoint>>;
};

export type FullTextSearchItemGoogleStaticMapImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type FullTextSearchItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type SrlProductInfoB2B = {
  __typename?: 'SrlProductInfoB2B';
  duration: Scalars['String'];
  lead: Scalars['String'];
  priceFrom: Scalars['String'];
  route: Scalars['String'];
  typeFlags?: Maybe<Array<Scalars['String']>>;
};

export type SrlProductInfoB2C = {
  __typename?: 'SrlProductInfoB2C';
  lead: Scalars['String'];
  typeFlags?: Maybe<Array<Scalars['String']>>;
};

export type AgencySrlFullTextSearchResult = {
  __typename?: 'AgencySrlFullTextSearchResult';
  items: Array<AgencyFullTextSearchItem>;
  page: Page;
  title: Scalars['String'];
};

export type AgencyFullTextSearchItem = {
  __typename?: 'AgencyFullTextSearchItem';
  addressDetails?: Maybe<AddressDetails>;
  bookmarked: Scalars['Boolean'];
  breadcrumbs?: Maybe<Array<Scalars['String']>>;
  debugInfo?: Maybe<Scalars['String']>;
  disrupters?: Maybe<Array<DisrupterObject>>;
  googleStaticMapImage?: Maybe<Image>;
  highlightedText?: Maybe<Scalars['String']>;
  image: Image;
  link: InternalLink;
  mainText: Scalars['String'];
  name: Scalars['String'];
  opened?: Maybe<Scalars['Boolean']>;
  productInfoB2B?: Maybe<SrlProductInfoB2B>;
  productInfoB2C?: Maybe<SrlProductInfoB2C>;
  routePoints?: Maybe<Array<RoutePoint>>;
};

export type AgencyFullTextSearchItemGoogleStaticMapImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type AgencyFullTextSearchItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export enum SearchTypeEnum {
  Adventuretravel = 'ADVENTURETRAVEL',
  AutosuggestNonProducts = 'AUTOSUGGEST_NON_PRODUCTS',
  AutosuggestProducts = 'AUTOSUGGEST_PRODUCTS',
  BlogArticle = 'BLOG_ARTICLE',
  ExtraHotel = 'EXTRA_HOTEL',
  Geo = 'GEO',
  Hotel = 'HOTEL',
  Roundtrip = 'ROUNDTRIP',
  StaticPage = 'STATIC_PAGE',
  Theme = 'THEME',
}

export type SrlGhostOffersComponent = {
  __typename?: 'SrlGhostOffersComponent';
  items: Array<SrlGhostOfferItem>;
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type SrlGhostOfferItem = {
  __typename?: 'SrlGhostOfferItem';
  /** Offer disrupter. Optional */
  disrupter?: Maybe<Scalars['String']>;
  /** Disrupter object. Optional */
  disrupterObject?: Maybe<DisrupterObject>;
  /** Travel from/to. Roundtrip or ship only */
  fromTo?: Maybe<Scalars['String']>;
  /** Location breadcrumbs */
  geoBreadcrumbs: Array<GeoObject>;
  /** Giata id */
  giata: Scalars['String'];
  /** Hotelplan rating. Optional */
  hpRating?: Maybe<Scalars['Int']>;
  image: Image;
  /** Link to Product detail page */
  link: InternalLink;
  /** Product name */
  name: Scalars['String'];
  /** Product class */
  productClass: ProductClass;
  /** Travel type. Roundtrip or ship only */
  travelType?: Maybe<Scalars['String']>;
  /** Type of product item */
  type: ProductType;
};

export type SrlGhostOfferItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type SrlResultContext = {
  __typename?: 'SrlResultContext';
  /** Get any search results */
  any: SrlAnyResult;
  /** Get destination groups */
  destinationGroup: SrlDestinationGroupsResult;
  /** Get geo group page */
  geoGroup: SrlGeoGroupItem;
  /** Get geo groups search results */
  group: SrlGroupOrEmptyResult;
  /** Get map pins */
  mapPins: SrlMapPinsComponent;
  /** Get map product cards */
  mapProductCards: SrlSingleOrEmptyResult;
  /** Get products search results */
  single: SrlSingleOrEmptyResult;
};

export type SrlResultContextAnyArgs = {
  pageType?: InputMaybe<PageType>;
};

export type SrlResultContextGeoGroupArgs = {
  groupId: Scalars['String'];
  page: PageInput;
};

export type SrlResultContextGroupArgs = {
  pageType?: InputMaybe<PageType>;
};

export type SrlResultContextMapProductCardsArgs = {
  productIds: Array<Scalars['String']>;
};

export type SrlResultContextSingleArgs = {
  pageType?: InputMaybe<PageType>;
};

/** Srl Result Union */
export type SrlAnyResult = SrlEmptyResult | SrlGeoGroupResult | SrlSingleResult;

/** Empty Search Result List */
export type SrlEmptyResult = {
  __typename?: 'SrlEmptyResult';
  alternativeSearchUrl?: Maybe<InternalLink>;
  altTravelType: TravelType;
  /** SRL message */
  message?: Maybe<Scalars['String']>;
  /** SRL status */
  status: Status;
};

/** Search Result List Group */
export type SrlGeoGroupResult = {
  __typename?: 'SrlGeoGroupResult';
  /** Cheapest group */
  cheapestGroup: SrlGeoItem;
  /**
   * Cheapest product price
   * @deprecated use cheapest group
   */
  cheapestPrice: Price;
  /** SRL Group items */
  groups: Array<SrlGeoGroupItem>;
  /** SRL message */
  message?: Maybe<Scalars['String']>;
  /** Overall amount of products in resutl */
  productsTotal: Scalars['Int'];
  /** Show all products for current parameters */
  showAllProducts: InternalLink;
  /** Geo object */
  sourceGeoObject?: Maybe<GeoObject>;
  /** SRL status */
  status: Status;
};

export type SrlGeoItem = {
  __typename?: 'SrlGeoItem';
  /** Offer adults */
  adults: Scalars['Int'];
  /** Average temperature. Only for destination */
  averageTemp?: Maybe<Scalars['String']>;
  /** Offer children */
  children: Scalars['Int'];
  /** Geo coordinates */
  coordinates: GeoCoordinates;
  /** Amount of available offers */
  count: Scalars['Int'];
  /** Geo object country. Only for region geo objects and lower */
  country?: Maybe<Scalars['String']>;
  destinationName?: Maybe<Scalars['String']>;
  /** Disrupter. Optional */
  disrupter?: Maybe<Scalars['String']>;
  /** Disrupter object. Optional */
  disrupterObject?: Maybe<DisrupterObject>;
  /** Offer length of stay */
  duration: Scalars['Int'];
  /** Flight duration. Only for package search */
  flightDuration?: Maybe<Scalars['String']>;
  /** Geo object */
  geoObject: GeoObject;
  /** Geo search objects */
  geoSearchObjects?: Maybe<Array<Maybe<GeoObject>>>;
  image: Image;
  leadText?: Maybe<Scalars['String']>;
  /** Link to SRL single */
  link: InternalLink;
  /** Map zoom */
  mapZoom: Scalars['Int'];
  /** Link to geo overview page. Desktop only */
  moreInfo?: Maybe<InternalLink>;
  /** Srl page */
  page?: Maybe<Page>;
  /** Best offer price */
  perPersonPrice: Price;
  /** SRL offers preview */
  previewItems?: Maybe<Array<SrlSingleItem>>;
  travelType?: Maybe<TravelType>;
};

export type SrlGeoItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Srl Group Item Union */
export type SrlSingleItem = NudgeItem | SrlProductItem;

/** Search Result List nudge item */
export type NudgeItem = {
  __typename?: 'NudgeItem';
  /** Nudge text */
  text?: Maybe<Scalars['String']>;
};

export type SrlProductItem = {
  __typename?: 'SrlProductItem';
  /** Offer adults */
  adults: Scalars['Int'];
  /** Offer children */
  children: Scalars['Int'];
  /** Product coordinates */
  coordinates: GeoCoordinates;
  /** Departure date */
  departureDate: Scalars['String'];
  /** Offer disrupter. Optional */
  disrupter?: Maybe<Scalars['String']>;
  /** Disrupter object. Optional */
  disrupterObject?: Maybe<DisrupterObject>;
  /** Distance info. Hotel only */
  distanceInfo?: Maybe<Scalars['String']>;
  /** Duration of stay */
  duration: Scalars['Int'];
  /**
   * Product features
   * @deprecated use featureSummary
   */
  features: Array<ProductFeature>;
  /** Product features */
  featureSummary: Array<ProductFeatureGroup>;
  /** Travel from/to. Roundtrip or ship only */
  fromTo?: Maybe<Scalars['String']>;
  /** Location breadcrumbs */
  geoBreadcrumbs: Array<GeoObject>;
  /** Giata id */
  giata: Scalars['String'];
  /** Hotelplan rating. Optional */
  hpRating?: Maybe<Scalars['Int']>;
  image: Image;
  inWishlist: Scalars['Boolean'];
  /** Link to Product detail page */
  link: InternalLink;
  /** Map zoom */
  mapZoom: Scalars['Int'];
  mealType: Scalars['String'];
  /** Product name */
  name: Scalars['String'];
  offerId: Scalars['String'];
  /** Per person price */
  price: Price;
  /** Product class */
  productClass: ProductClass;
  /** Product code */
  productCode?: Maybe<Scalars['String']>;
  /** Travel provider */
  provider?: Maybe<Scalars['String']>;
  /** Return date */
  returnDate: Scalars['String'];
  /** Rooms info */
  rooms?: Maybe<Array<Maybe<Room>>>;
  /** Tripadvisor rating. Optional */
  taRating?: Maybe<Scalars['Int']>;
  /** Tripadvisor reviews. Optional */
  taReviews?: Maybe<Scalars['Int']>;
  /** Total price */
  totalPrice: Price;
  /** Is transfer included */
  transferIncluded?: Maybe<Scalars['Boolean']>;
  /** Transfer price */
  transferPrice?: Maybe<Price>;
  /** Travel type. Roundtrip or ship only */
  travelType?: Maybe<Scalars['String']>;
  /** Type of product item */
  type: ProductType;
  wishlistItem?: Maybe<WishlistItem>;
};

export type SrlProductItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type SrlGeoGroupItem = {
  __typename?: 'SrlGeoGroupItem';
  /** Group geo object */
  geoObject: GeoObject;
  /** Geo items/nudges */
  items: Array<SrlGroupItem>;
  /** SRL single link for this group */
  link: InternalLink;
  /** Nudges */
  nudges: Array<NudgeItem>;
  /** Pagination info */
  page: Page;
};

/** Srl Group Item Union */
export type SrlGroupItem = NudgeItem | SrlGeoItem;

/** Search Result List Single */
export type SrlSingleResult = {
  __typename?: 'SrlSingleResult';
  /** Cheapest product */
  cheapestProduct: SrlProductItem;
  /** SRL Single items */
  items: Array<SrlSingleItem>;
  /** SRL message */
  message?: Maybe<Scalars['String']>;
  /** Pagination info */
  page: Page;
  /** Overall amount of products in SRL */
  productsTotal: Scalars['Int'];
  /** SRL status */
  status: Status;
};

export type SrlDestinationGroupsResult = {
  __typename?: 'SrlDestinationGroupsResult';
  /** Continent group items */
  continentGroupItems: Array<ContinentGroupItem>;
  /** SRL message */
  message?: Maybe<Scalars['String']>;
  /** SRL status */
  status: Status;
};

export type ContinentGroupItem = {
  __typename?: 'ContinentGroupItem';
  countryGroupItems?: Maybe<Array<Maybe<CountryGroupItem>>>;
  /** Continent id */
  id: Scalars['String'];
  /** Continent name */
  name: Scalars['String'];
};

export type CountryGroupItem = {
  __typename?: 'CountryGroupItem';
  destinations: Array<Maybe<DestinationGroupItem>>;
  /** Country id */
  id: Scalars['String'];
  /** Country name */
  name: Scalars['String'];
  productCount: Scalars['Int'];
};

export type DestinationGroupItem = {
  __typename?: 'DestinationGroupItem';
  /** Destination id */
  id: Scalars['String'];
  /** Destination name */
  name: Scalars['String'];
  productCount: Scalars['Int'];
};

/** Srl Single Or Empty Result Union */
export type SrlGroupOrEmptyResult = SrlEmptyResult | SrlGeoGroupResult;

/** Srl Single Or Empty Result Union */
export type SrlSingleOrEmptyResult = SrlEmptyResult | SrlSingleResult;

export type SrlSearchControlsContext = {
  __typename?: 'SrlSearchControlsContext';
  forceSingleView: Scalars['Boolean'];
  mainFilter: FilterComponent;
  searchControl: SearchControlComponent;
  sort: SrlSortComponent;
  subGeoFilter: SrlSubGeoFilterComponent;
};

export type SrlSortComponent = {
  __typename?: 'SrlSortComponent';
  groupSorting: SrlGroupSorting;
  primarySort: SrlPrimarySort;
  productSorting: SrlProductSorting;
};

export enum SrlPrimarySort {
  Group = 'GROUP',
  None = 'NONE',
  Single = 'SINGLE',
}

export type SrlSubGeoFilterComponent = {
  __typename?: 'SrlSubGeoFilterComponent';
  items?: Maybe<Array<SrlSubGeoItem>>;
};

export type SrlSubGeoItem = {
  __typename?: 'SrlSubGeoItem';
  caption: Scalars['String'];
  id: Scalars['String'];
  image: Image;
  productCount: Scalars['Int'];
  selected: Scalars['Boolean'];
};

export type SrlSubGeoItemImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type SrlStaticContent = {
  __typename?: 'SrlStaticContent';
  /** @deprecated SRL 0 Result text */
  zeroResultText: Scalars['String'];
};

export type StaticContext = {
  __typename?: 'StaticContext';
  /** @deprecated use root components query */
  components: Array<StaticPageComponent>;
  disrupterObject?: Maybe<DisrupterObject>;
  hero: Image;
  lang: PageLanguage;
  meta: PageMetaData;
  pageBreadcrumbs?: Maybe<BreadcrumbsComponent>;
  removeHeaderFooter?: Maybe<Scalars['Boolean']>;
  sharingOverlayDefaultImage?: Maybe<Image>;
  slug: Scalars['String'];
  status: StaticPageStatus;
  tagline?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  textBgColor?: Maybe<Scalars['String']>;
  textBgImage?: Maybe<Image>;
  textFgColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  version: Scalars['String'];
};

export type StaticContextHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type StaticContextSharingOverlayDefaultImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type StaticContextTextBgImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ThemeContext = {
  __typename?: 'ThemeContext';
  /** Autocomplete Travel Destinations */
  autocomplete: AutocompleteComponent;
  bookmarked: Scalars['Boolean'];
  /**
   * Load theme page flexible content
   * @deprecated use root query
   */
  dynamicContent: Array<ThemePageDynamicComponent>;
  /** Theme filters */
  filters: MainFilterComponent;
  /** Get url parameter for target page */
  link: InternalLink;
  /** Get meta data */
  meta: PageMetaData;
  /** Load theme page search control */
  searchControl: SearchControlUnion;
  /** Load theme page static content */
  staticContent: ThemeStaticContent;
  version: Scalars['String'];
};

export type ThemeContextAutocompleteArgs = {
  criteria: AutocompleteCriteriaInput;
};

export type ThemeContextDynamicContentArgs = {
  searchCriteria: ThemeSearchCriteriaInput;
};

export type ThemeContextFiltersArgs = {
  encodedCriteria?: InputMaybe<Scalars['String']>;
};

export type ThemeContextLinkArgs = {
  searchCriteria?: InputMaybe<ThemeSearchCriteriaInput>;
  targetPageType: PageType;
};

export type ThemeContextSearchControlArgs = {
  encodedCriteria?: InputMaybe<Scalars['String']>;
  travelType?: InputMaybe<TravelType>;
};

export type ThemeContextStaticContentArgs = {
  currentUri?: InputMaybe<Scalars['String']>;
  encodedCriteria?: InputMaybe<Scalars['String']>;
  gss?: InputMaybe<GlobalSearchStateInput>;
};

/** Them object page search criteria input type */
export type ThemeSearchCriteriaInput = {
  filters?: InputMaybe<SrlFilterCriteriaInput>;
  forceSingleView?: InputMaybe<Scalars['Boolean']>;
  groupSorting?: InputMaybe<SrlGroupSorting>;
  productSorting?: InputMaybe<SrlProductSorting>;
  rooms: Scalars['Int'];
  subGeoFilter?: InputMaybe<Array<Scalars['String']>>;
  travelDestinations?: InputMaybe<Array<TravelDestinationInput>>;
  travellers: TravellersInput;
  travellersDistribution?: InputMaybe<TravellersDistributionInput>;
  travelPeriod: TravelPeriodCriteriaInput;
  travelType: TravelType;
};

/** Generic type for all Home page flexible components */
export type ThemePageDynamicComponent =
  | MediaGallery
  | TextComponent
  | ThemePageRecommendationGroup;

export type ThemePageRecommendationGroup = {
  __typename?: 'ThemePageRecommendationGroup';
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  items: Array<ThemePageRecommendationItem>;
  name: Scalars['String'];
  tracking?: Maybe<RecommendationTracking>;
};

export type ThemePageRecommendationItem =
  | Bd4TravelRecommendation
  | BlogArticleRecommendationItem
  | GeoRecommendationItem
  | MarketingRecommendationItem
  | ProductRecommendationItem
  | ThemeRecommendationItem;

export type ThemeStaticContent = {
  __typename?: 'ThemeStaticContent';
  disrupterObject?: Maybe<DisrupterObject>;
  hero: Image;
  information: TextComponent;
  mapAccess: Scalars['Boolean'];
  pageBreadcrumbs?: Maybe<BreadcrumbsComponent>;
  requestButton?: Maybe<RequestButtonComponent>;
  seoText?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  themeStaticComponents?: Maybe<Array<Maybe<DynamicComponent>>>;
};

export type ThemeStaticContentHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ThemeGeoContext = {
  __typename?: 'ThemeGeoContext';
  /** Get theme geo link */
  link: InternalLink;
  /** Get theme geo page */
  page: ThemeGeoPage;
  version: Scalars['String'];
};

export type ThemeGeoPage = {
  __typename?: 'ThemeGeoPage';
  hero: Image;
  meta: PageMetaData;
  pageBreadcrumbs: BreadcrumbsComponent;
  productRecommendations: Array<ThemeGeoProductRecommendation>;
  recommendationTitle: Scalars['String'];
  requestButton: RequestButtonComponent;
  tagline: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  themeRecommendations: Array<ThemeGeoThemeRecommendation>;
  title: Scalars['String'];
};

export type ThemeGeoPageHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ThemeGeoProductRecommendation = {
  __typename?: 'ThemeGeoProductRecommendation';
  breadcrumbs: Array<Scalars['String']>;
  disrupters: Array<DisrupterObject>;
  features: Array<ProductFeature>;
  image: Image;
  link: InternalLink;
  name: Scalars['String'];
  routePoints: Array<RoutePoint>;
  teaser: Scalars['String'];
  type: Scalars['String'];
};

export type ThemeGeoProductRecommendationImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ThemeGeoThemeRecommendation = {
  __typename?: 'ThemeGeoThemeRecommendation';
  image: Image;
  link: InternalLink;
  name: Scalars['String'];
};

export type ThemeGeoThemeRecommendationImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ThemeOverviewContext = {
  __typename?: 'ThemeOverviewContext';
  autocompleteDestinations: AutocompleteComponent;
  /** Get url parameter for target page */
  link: InternalLink;
  /** Get meta data */
  meta: PageMetaData;
  page: ThemeOverviewPage;
  searchControl: SearchControlUnion;
};

export type ThemeOverviewContextAutocompleteDestinationsArgs = {
  criteria: AutocompleteCriteriaInput;
};

export type ThemeOverviewContextLinkArgs = {
  searchCriteria?: InputMaybe<ThemeSearchCriteriaInput>;
  targetPageType: PageType;
};

export type ThemeOverviewContextSearchControlArgs = {
  travelType?: InputMaybe<TravelType>;
};

export type ThemeOverviewPage = {
  __typename?: 'ThemeOverviewPage';
  blogArticle?: Maybe<ThemePageRecommendationGroup>;
  blogArticleComponent?: Maybe<BlogArticleRecommendation>;
  hero: Image;
  information?: Maybe<TextComponent>;
  pageBreadcrumbs?: Maybe<BreadcrumbsComponent>;
  previews?: Maybe<Array<ThemePreviewComponent>>;
  sharingOverlayDefaultImage?: Maybe<Image>;
  subtitle?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
};

export type ThemeOverviewPageHeroArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ThemeOverviewPageSharingOverlayDefaultImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type ThemePreviewComponent = {
  __typename?: 'ThemePreviewComponent';
  image: Image;
  searchLink: InternalLink;
  text: Scalars['String'];
  themeLink: InternalLink;
  title: Scalars['String'];
};

export type ThemePreviewComponentImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type WishlistContext = {
  __typename?: 'WishlistContext';
  active: WishlistActiveComponent;
  activeOffer: WishlistActiveComponent;
  filters: FilterComponent;
  /** Get meta data */
  meta: PageMetaData;
  overview: WishlistOverviewComponent;
  productsAmount: Scalars['Int'];
  searchControl: SearchControlComponent;
  shareUrl: InternalLink;
  /** Get get sharing overlay default image */
  sharingOverlayDefaultImage: Image;
  url: InternalLink;
  version: Scalars['String'];
};

export type WishlistContextActiveArgs = {
  searchCriteria?: InputMaybe<WishlistSearchCriteriaInput>;
};

export type WishlistContextActiveOfferArgs = {
  productId: Scalars['String'];
  searchCriteria?: InputMaybe<WishlistSearchCriteriaInput>;
};

export type WishlistContextFiltersArgs = {
  searchCriteria?: InputMaybe<WishlistSearchCriteriaInput>;
  searchQuery?: InputMaybe<Scalars['String']>;
  travelType?: InputMaybe<TravelType>;
};

export type WishlistContextSearchControlArgs = {
  searchQuery?: InputMaybe<Scalars['String']>;
  travelType?: InputMaybe<TravelType>;
};

export type WishlistContextShareUrlArgs = {
  wishlistId?: InputMaybe<Scalars['String']>;
};

export type WishlistContextSharingOverlayDefaultImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export type WishlistContextUrlArgs = {
  pageType?: InputMaybe<PageType>;
  searchCriteria?: InputMaybe<WishlistSearchCriteriaInput>;
};

export type WishlistSearchCriteriaInput = {
  filters?: InputMaybe<WishlistFiltersCriteriaInput>;
  searchControl: WishlistSearchControlCriteriaInput;
};

export type WishlistFiltersCriteriaInput = {
  accommodationSize?: InputMaybe<Array<Scalars['String']>>;
  boardTypes?: InputMaybe<Array<Scalars['String']>>;
  departureAirports?: InputMaybe<Array<Scalars['String']>>;
  departureFlightDepartureTime?: InputMaybe<Scalars['String']>;
  flightStopOver?: InputMaybe<Scalars['String']>;
  hotelplanRating?: InputMaybe<Scalars['String']>;
  maxFlightDuration?: InputMaybe<Scalars['Int']>;
  maxPricePerPerson?: InputMaybe<Scalars['String']>;
  productFeatures?: InputMaybe<Array<Scalars['String']>>;
  returnFlightDepartureTime?: InputMaybe<Scalars['String']>;
  roomTypes?: InputMaybe<Array<Scalars['String']>>;
  tripAdvisorRating?: InputMaybe<Scalars['String']>;
};

export type WishlistSearchControlCriteriaInput = {
  rooms: Scalars['Int'];
  travellers: TravellersInput;
  travellersDistribution?: InputMaybe<TravellersDistributionInput>;
  travelPeriod: TravelPeriodCriteriaInput;
  travelType: TravelType;
};

export type WishlistActiveComponent = {
  __typename?: 'WishlistActiveComponent';
  items: Array<WishlistProductItem>;
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type WishlistProductItem = {
  __typename?: 'WishlistProductItem';
  changedOffer?: Maybe<WishlistOffer>;
  link: InternalLink;
  offer: WishlistOffer;
  product: WishlistProduct;
  source?: Maybe<Scalars['String']>;
  type: WishlistProductItemType;
};

export type WishlistOffer = {
  __typename?: 'WishlistOffer';
  departureDate: Scalars['String'];
  duration: Scalars['Int'];
  flightAlternativesAvailable?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  inboundFlight?: Maybe<Flight>;
  outboundFlight?: Maybe<Flight>;
  pricePerPerson: Price;
  productCode?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  returnDate: Scalars['String'];
  rooms: Array<WishlistOfferRoom>;
  totalPrice: Price;
  transferIncluded: Scalars['Boolean'];
  transferPrice?: Maybe<Price>;
  travelType: TravelType;
  waitingScreenImage?: Maybe<Scalars['String']>;
  wishlistItem?: Maybe<WishlistItem>;
};

export type WishlistOfferRoom = {
  __typename?: 'WishlistOfferRoom';
  adults: Scalars['Int'];
  boardCode: Scalars['String'];
  boardDescription: Scalars['String'];
  children: Scalars['Int'];
  roomCode: Scalars['String'];
  roomDescription: Scalars['String'];
};

export type WishlistProduct = {
  __typename?: 'WishlistProduct';
  breadcrumbs: Array<GeoObject>;
  coordinates: GeoCoordinates;
  featureGroups: Array<ProductFeatureGroup>;
  featureSummary: Array<ProductFeatureGroup>;
  /** roundrtip/ship only */
  fromTo?: Maybe<Scalars['String']>;
  giataId: Scalars['Int'];
  hpRating: Scalars['Int'];
  id: Scalars['String'];
  image: Image;
  /** roundrtip/ship only */
  includedInPrice?: Maybe<TextComponent>;
  name: Scalars['String'];
  productClass: ProductClass;
  sellingPropositions?: Maybe<Scalars['String']>;
  taRating?: Maybe<Scalars['Int']>;
  taReviews?: Maybe<Scalars['Int']>;
  travelType?: Maybe<Scalars['String']>;
  zoom: Scalars['Int'];
};

export type WishlistProductImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

export enum WishlistProductItemType {
  Available = 'AVAILABLE',
  FlightAndPriceChanged = 'FLIGHT_AND_PRICE_CHANGED',
  FlightChanged = 'FLIGHT_CHANGED',
  NotAvailable = 'NOT_AVAILABLE',
  PriceChanged = 'PRICE_CHANGED',
}

export type WishlistOverviewComponent = {
  __typename?: 'WishlistOverviewComponent';
  message?: Maybe<Scalars['String']>;
  status: Status;
  wishlists: Array<Wishlist>;
};

export type Wishlist = {
  __typename?: 'Wishlist';
  active: Scalars['Boolean'];
  id: Scalars['String'];
  image: Image;
  name: Scalars['String'];
  productsAmount: Scalars['Int'];
};

export type WishlistImageArgs = {
  criteria: Array<ImageCriteriaInput>;
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  /** Add booking */
  addBooking: AddBookingResponse;
  /** Add bookmark, The quantity from the response is amount of bookmarks in a single group */
  addBookmark: BookmarkQuantityResponse;
  /** Add bookmarks */
  addBookmarks: BookmarkBulkResponse;
  addFlightSrlHistory: FlightHistoryResponse;
  addFullTextSearchHistory: SrlHistoryResponse;
  /** @deprecated addMultipleToActiveWishlistWithSource */
  addMultipleToActiveWishlist: WishlistAddMultipleToWishlistResponse;
  addMultipleToActiveWishlistWithSource: WishlistAddMultipleToWishlistResponse;
  addOrlHistory: OrlHistoryResponse;
  /** Add Shared Bookmark Group */
  addSharedBookmarkGroup: SharedBookmarkGroupResponse;
  addSharedWishlist: WishlistMutationResponse;
  addSrlHistory: SrlHistoryResponse;
  /** @deprecated use addToActiveWishlistWithSource */
  addToActiveWishlist: WishlistAddToWishlistResponse;
  addToActiveWishlistWithSource: WishlistAddToWishlistResponse;
  /** Add travel companion */
  addTravelCompanion: AddTravelCompanionResponse;
  /** Add transfer service to a booking basket */
  addUpsellTransferToBasket: AddUpsellTransferToBasketResponse;
  addViewedHistory: AddViewedHistoryResponse;
  /** Process anonymous logged in */
  anonymousLoggedIn: AccountMutationResponse;
  /** Cancel a booking */
  cancelBooking: BookingCancellationResponse;
  /** Change payment method */
  changePaymentMethod: ChangePaymentMethodResponse;
  /** Copy bookmark */
  copyBookmark: BookmarkResponse;
  /** Create bookmark group */
  createBookmarkGroup: BookmarkGroupCreateResponse;
  createCatalogOrder: CatalogOrderResponse;
  createNewsletterSubscription: NewsletterSubscriptionResponse;
  createWishlist: WishlistMutationResponse;
  /** Delete bookmark. The quantity from the response is amount of bookmarks in a single group */
  deleteBookmark: BookmarkQuantityResponse;
  /** Delete bookmark group */
  deleteBookmarkGroup: BookmarkGroupDeleteResponse;
  /** Delete bookmarks */
  deleteBookmarks: BookmarkBulkResponse;
  deleteHistory: DeleteHistoryResponse;
  /** Delete travel companion */
  deleteTravelCompanion: AccountMutationResponse;
  /** Delete upsell service from a booking basket */
  deleteUpsellServiceFromBasket: AccountMutationResponse;
  deleteWishlist: WishlistMutationResponse;
  /** Finalize booking cancellation with cancellation token from user email generated by initBookingCancellation */
  finalizeAccessBookingCancellation: AccessBookingCancellationFinalizationResponse;
  /** Finalize booking cancellation. Deprecated, always returns Error */
  finalizeBookingCancellation: BookingCancellationFinalizationResponse;
  /** Finalize subscription by token */
  finalizeSubscriptionByToken: NewsletterSubscriptionResponse;
  /** Get user email from token */
  finalizeUser: UserFinalizationResponse;
  holidayFinderVote: HolidayFinderVotingResponse;
  /** Init booking cancellation */
  initBookingCancellation: AccessBookingCancellationResponse;
  /** Init payment */
  initPaymentMethod: InitPaymentResponse;
  /** Init payment for upsell transfer */
  initUpsellPaymentMethod: InitPaymentResponse;
  /** Move bookmark */
  moveBookmark: BookmarkResponse;
  moveToWishlist: WishlistMutationResponse;
  /** Upload pdfs to cloud */
  pdfUpload: BookmarkPdfUploadResponse;
  /** Register user */
  registerUser: UserRegistrationResponse;
  removeFromActiveWishlist: WishlistMutationResponse;
  removeNewsletterSubscription: NewsletterUnsubscriptionResponse;
  /** Rename bookmark group */
  renameBookmarkGroup: BookmarkGroupRenameResponse;
  renameWishlist: WishlistMutationResponse;
  /** Resend travel document */
  resendTravelDocument: ResendTravelDocumentResponse;
  revokeEmailAdvertising: EmailAdvertisingRevocationResponse;
  /** @deprecated use jotform link from agency query */
  sendAgencyAppointmentRequest: AgencyAppointmentResponse;
  sendContactFormRequest: ContactFormResponse;
  setActiveWishlist: WishlistMutationResponse;
  /** Update emergency phone */
  updateEmergencyPhone: UpdateEmergencyPhoneResponse;
  /** Update personal data */
  updatePersonalData: AccountMutationResponse;
  /** Update subscription */
  updateSubscription: AccountMutationResponse;
  /** Update travel companion */
  updateTravelCompanion: AccountMutationResponse;
  viewedProductAddedToWishlist: ViewedProductHistoryResponse;
  viewedProductCheckout: ViewedProductHistoryResponse;
  viewedProductVisited: ViewedProductHistoryResponse;
};

/** Mutation root */
export type MutationAddBookingArgs = {
  addBookingRequest: AddBookingRequestInput;
  context: RequestContextInput;
};

/** Mutation root */
export type MutationAddBookmarkArgs = {
  groupId?: InputMaybe<Scalars['String']>;
  objectId: Scalars['String'];
  pageType: PageType;
};

/** Mutation root */
export type MutationAddBookmarksArgs = {
  bookmarks: Array<BookmarkRequestItemInput>;
};

/** Mutation root */
export type MutationAddFlightSrlHistoryArgs = {
  context?: InputMaybe<RequestContextInput>;
  flightSrlSearchCriteria: FlightSrlSearchCriteriaInput;
};

/** Mutation root */
export type MutationAddFullTextSearchHistoryArgs = {
  context?: InputMaybe<RequestContextInput>;
  keywords: Array<InputMaybe<Scalars['String']>>;
};

/** Mutation root */
export type MutationAddMultipleToActiveWishlistArgs = {
  context?: InputMaybe<RequestContextInput>;
  offerIds: Array<Scalars['String']>;
};

/** Mutation root */
export type MutationAddMultipleToActiveWishlistWithSourceArgs = {
  context?: InputMaybe<RequestContextInput>;
  offerIds: Array<WishlistOfferIdInput>;
};

/** Mutation root */
export type MutationAddOrlHistoryArgs = {
  context?: InputMaybe<RequestContextInput>;
  criteria: PdpSearchCriteriaInput;
  productId: Scalars['String'];
};

/** Mutation root */
export type MutationAddSharedBookmarkGroupArgs = {
  bookmarkGroup: Scalars['String'];
};

/** Mutation root */
export type MutationAddSharedWishlistArgs = {
  wishlistId: Scalars['String'];
};

/** Mutation root */
export type MutationAddSrlHistoryArgs = {
  context?: InputMaybe<RequestContextInput>;
  searchCriteria: SrlSearchCriteriaInput;
};

/** Mutation root */
export type MutationAddToActiveWishlistArgs = {
  context?: InputMaybe<RequestContextInput>;
  offerId: Scalars['String'];
};

/** Mutation root */
export type MutationAddToActiveWishlistWithSourceArgs = {
  context?: InputMaybe<RequestContextInput>;
  offerId: WishlistOfferIdInput;
};

/** Mutation root */
export type MutationAddTravelCompanionArgs = {
  travelCompanion: TravelCompanionInput;
};

/** Mutation root */
export type MutationAddUpsellTransferToBasketArgs = {
  context: RequestContextInput;
  request: AddUpsellTransferToBasketRequestInput;
};

/** Mutation root */
export type MutationAddViewedHistoryArgs = {
  context?: InputMaybe<RequestContextInput>;
  objectId: Scalars['String'];
  pageType: PageType;
};

/** Mutation root */
export type MutationAnonymousLoggedInArgs = {
  anonymousToken: Scalars['String'];
  context: RequestContextInput;
};

/** Mutation root */
export type MutationCancelBookingArgs = {
  bookingId: Scalars['String'];
  context: RequestContextInput;
};

/** Mutation root */
export type MutationChangePaymentMethodArgs = {
  request: ChangePaymentMethodRequestInput;
};

/** Mutation root */
export type MutationCopyBookmarkArgs = {
  groupIdFrom: Scalars['String'];
  groupIdTo: Scalars['String'];
  objectId: Scalars['String'];
  pageType: PageType;
};

/** Mutation root */
export type MutationCreateBookmarkGroupArgs = {
  name: Scalars['String'];
};

/** Mutation root */
export type MutationCreateCatalogOrderArgs = {
  context?: InputMaybe<RequestContextInput>;
  order: CatalogOrderInput;
};

/** Mutation root */
export type MutationCreateNewsletterSubscriptionArgs = {
  context?: InputMaybe<RequestContextInput>;
  newsletterSubscription: NewsletterSubscriptionInputInput;
};

/** Mutation root */
export type MutationCreateWishlistArgs = {
  name?: InputMaybe<Scalars['String']>;
};

/** Mutation root */
export type MutationDeleteBookmarkArgs = {
  groupId?: InputMaybe<Scalars['String']>;
  objectId: Scalars['String'];
  pageType: PageType;
};

/** Mutation root */
export type MutationDeleteBookmarkGroupArgs = {
  groupId: Scalars['String'];
};

/** Mutation root */
export type MutationDeleteBookmarksArgs = {
  bookmarks: Array<BookmarkRequestItemInput>;
};

/** Mutation root */
export type MutationDeleteHistoryArgs = {
  context?: InputMaybe<RequestContextInput>;
};

/** Mutation root */
export type MutationDeleteTravelCompanionArgs = {
  id: Scalars['String'];
};

/** Mutation root */
export type MutationDeleteUpsellServiceFromBasketArgs = {
  context: RequestContextInput;
  request: DeleteUpsellServiceFromBasketInput;
};

/** Mutation root */
export type MutationDeleteWishlistArgs = {
  wishlistId: Scalars['String'];
};

/** Mutation root */
export type MutationFinalizeAccessBookingCancellationArgs = {
  cancellationToken: Scalars['String'];
};

/** Mutation root */
export type MutationFinalizeBookingCancellationArgs = {
  context: RequestContextInput;
  token: Scalars['String'];
};

/** Mutation root */
export type MutationFinalizeSubscriptionByTokenArgs = {
  context?: InputMaybe<RequestContextInput>;
  token: Scalars['String'];
};

/** Mutation root */
export type MutationFinalizeUserArgs = {
  context: RequestContextInput;
  request: UserFinalizationRequestInput;
};

/** Mutation root */
export type MutationHolidayFinderVoteArgs = {
  context?: InputMaybe<RequestContextInput>;
  request: HolidayFinderVoteRequestInput;
};

/** Mutation root */
export type MutationInitBookingCancellationArgs = {
  bookingId: Scalars['String'];
};

/** Mutation root */
export type MutationInitPaymentMethodArgs = {
  initPaymentRequest: InitPaymentRequestInput;
};

/** Mutation root */
export type MutationInitUpsellPaymentMethodArgs = {
  context: RequestContextInput;
  initPaymentRequest: InitPaymentRequestInput;
};

/** Mutation root */
export type MutationMoveBookmarkArgs = {
  groupIdFrom: Scalars['String'];
  groupIdTo: Scalars['String'];
  objectId: Scalars['String'];
  pageType: PageType;
};

/** Mutation root */
export type MutationMoveToWishlistArgs = {
  offerId: Scalars['String'];
  wishlistId: Scalars['String'];
};

/** Mutation root */
export type MutationPdfUploadArgs = {
  request: BookmarkPdfUploadRequestInput;
  requestContext?: InputMaybe<RequestContextInput>;
};

/** Mutation root */
export type MutationRegisterUserArgs = {
  context: RequestContextInput;
  request: UserRegistrationRequestInput;
};

/** Mutation root */
export type MutationRemoveFromActiveWishlistArgs = {
  offerId: Scalars['String'];
};

/** Mutation root */
export type MutationRemoveNewsletterSubscriptionArgs = {
  context?: InputMaybe<RequestContextInput>;
  newsletterSubscription: NewsletterUnsubscriptionInputInput;
};

/** Mutation root */
export type MutationRenameBookmarkGroupArgs = {
  groupId: Scalars['String'];
  groupName: Scalars['String'];
};

/** Mutation root */
export type MutationRenameWishlistArgs = {
  newName: Scalars['String'];
  wishlistId: Scalars['String'];
};

/** Mutation root */
export type MutationResendTravelDocumentArgs = {
  request: ResendTravelDocumentRequestInput;
};

/** Mutation root */
export type MutationRevokeEmailAdvertisingArgs = {
  token: Scalars['String'];
};

/** Mutation root */
export type MutationSendAgencyAppointmentRequestArgs = {
  input?: InputMaybe<AgencyAppointmentInputInput>;
};

/** Mutation root */
export type MutationSendContactFormRequestArgs = {
  context?: InputMaybe<RequestContextInput>;
  request: ContactFormRequestInput;
};

/** Mutation root */
export type MutationSetActiveWishlistArgs = {
  wishlistId: Scalars['String'];
};

/** Mutation root */
export type MutationUpdateEmergencyPhoneArgs = {
  request: UpdateEmergencyPhoneRequestInput;
};

/** Mutation root */
export type MutationUpdatePersonalDataArgs = {
  personalDataInput: AccountPersonalDataInput;
};

/** Mutation root */
export type MutationUpdateSubscriptionArgs = {
  subscriptionTypes: Array<SubscriptionType>;
};

/** Mutation root */
export type MutationUpdateTravelCompanionArgs = {
  travelCompanion: TravelCompanionInput;
};

/** Mutation root */
export type MutationViewedProductAddedToWishlistArgs = {
  context?: InputMaybe<RequestContextInput>;
  productId: Scalars['String'];
};

/** Mutation root */
export type MutationViewedProductCheckoutArgs = {
  context?: InputMaybe<RequestContextInput>;
  productId: Scalars['String'];
};

/** Mutation root */
export type MutationViewedProductVisitedArgs = {
  context?: InputMaybe<RequestContextInput>;
  productId: Scalars['String'];
};

export type AddBookingRequestInput = {
  departureDate?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  orderNumber: Scalars['String'];
};

export type AddBookingResponse = {
  __typename?: 'AddBookingResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type BookmarkRequestItemInput = {
  groupId: Scalars['String'];
  objectId: Scalars['String'];
  pageType: PageType;
};

export type BookmarkBulkResponse = {
  __typename?: 'BookmarkBulkResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type FlightHistoryResponse = {
  __typename?: 'FlightHistoryResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type SrlHistoryResponse = {
  __typename?: 'SrlHistoryResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type WishlistAddMultipleToWishlistResponse = {
  __typename?: 'WishlistAddMultipleToWishlistResponse';
  message?: Maybe<Scalars['String']>;
  offers: Array<WishlistOffer>;
  status: Status;
};

export type WishlistOfferIdInput = {
  offerId: Scalars['String'];
  source?: InputMaybe<Scalars['String']>;
};

export type OrlHistoryResponse = {
  __typename?: 'OrlHistoryResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type SharedBookmarkGroupResponse = {
  __typename?: 'SharedBookmarkGroupResponse';
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type WishlistMutationResponse = {
  __typename?: 'WishlistMutationResponse';
  activeChanged: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  sharedAvailable?: Maybe<Scalars['Boolean']>;
  status: Status;
};

export type WishlistAddToWishlistResponse = {
  __typename?: 'WishlistAddToWishlistResponse';
  message?: Maybe<Scalars['String']>;
  offer?: Maybe<WishlistOffer>;
  status: Status;
};

export type TravelCompanionInput = {
  dateOfBirth?: InputMaybe<Scalars['LocalDate']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Salutation>;
};

export type AddTravelCompanionResponse = {
  __typename?: 'AddTravelCompanionResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
  travelCompanion?: Maybe<TravelCompanion>;
};

export type AddUpsellTransferToBasketRequestInput = {
  bookingId: Scalars['String'];
  direction: Scalars['String'];
  offerId: Scalars['String'];
  searchServiceId: Scalars['String'];
};

export type AddUpsellTransferToBasketResponse = {
  __typename?: 'AddUpsellTransferToBasketResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type AddViewedHistoryResponse = {
  __typename?: 'AddViewedHistoryResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type AccountMutationResponse = {
  __typename?: 'AccountMutationResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type BookingCancellationResponse = {
  __typename?: 'BookingCancellationResponse';
  code: BookingCancellationCode;
  message?: Maybe<Scalars['String']>;
  payload?: Maybe<BookingCancellationPayloadUnion>;
  status: Status;
};

export enum BookingCancellationCode {
  BookingCancellationCodeError = 'BOOKING_CANCELLATION_CODE_ERROR',
  BookingCancellationCodeErrorNotAuthorized = 'BOOKING_CANCELLATION_CODE_ERROR_NOT_AUTHORIZED',
  BookingCancellationCodeOk = 'BOOKING_CANCELLATION_CODE_OK',
  BookingCancellationCodeWaitingForConfirmation = 'BOOKING_CANCELLATION_CODE_WAITING_FOR_CONFIRMATION',
}

export type BookingCancellationPayloadUnion = BookingCancellationConfirmationPayload;

export type BookingCancellationConfirmationPayload = {
  __typename?: 'BookingCancellationConfirmationPayload';
  email: Scalars['String'];
};

export type ChangePaymentMethodRequestInput = {
  bookingId: Scalars['String'];
  iban?: InputMaybe<Scalars['String']>;
  ownerName?: InputMaybe<Scalars['String']>;
  paymentId: Scalars['String'];
};

export type ChangePaymentMethodResponse = {
  __typename?: 'ChangePaymentMethodResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type BookmarkResponse = {
  __typename?: 'BookmarkResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type BookmarkGroupCreateResponse = {
  __typename?: 'BookmarkGroupCreateResponse';
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};

export type CatalogOrderInput = {
  catalogKeys: Array<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  recaptchaToken?: InputMaybe<Scalars['String']>;
  road: Scalars['String'];
  salutation: Salutation;
  subscribe?: InputMaybe<Scalars['Boolean']>;
  zip: Scalars['String'];
};

/** Catalog response */
export type CatalogOrderResponse = {
  __typename?: 'CatalogOrderResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type NewsletterSubscriptionInputInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  salutation: Salutation;
};

export type NewsletterSubscriptionResponse = {
  __typename?: 'NewsletterSubscriptionResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
  subscription: NewsletterSubscription;
};

export type NewsletterSubscription = {
  __typename?: 'NewsletterSubscription';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  salutation: Salutation;
  state: SubscriptionState;
};

export enum SubscriptionState {
  Finalized = 'FINALIZED',
  Pending = 'PENDING',
}

export type BookmarkGroupDeleteResponse = {
  __typename?: 'BookmarkGroupDeleteResponse';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};

export type DeleteHistoryResponse = {
  __typename?: 'DeleteHistoryResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type DeleteUpsellServiceFromBasketInput = {
  bookingId: Scalars['String'];
  serviceId: Scalars['String'];
};

export type AccessBookingCancellationFinalizationResponse = {
  __typename?: 'AccessBookingCancellationFinalizationResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
  tokenExpire: Scalars['Boolean'];
};

export type BookingCancellationFinalizationResponse = {
  __typename?: 'BookingCancellationFinalizationResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type UserFinalizationRequestInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type UserFinalizationResponse = {
  __typename?: 'UserFinalizationResponse';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};

export type HolidayFinderVoteRequestInput = {
  children: Scalars['Boolean'];
  limit: Scalars['Int'];
  placementId: Scalars['String'];
  productFeatures?: InputMaybe<Array<Scalars['String']>>;
  ratings: Array<HolidayFinderRatingInput>;
  sessionId: Scalars['String'];
  travelPeriod: TravelPeriodCriteriaInput;
};

export type HolidayFinderRatingInput = {
  giataId: Scalars['String'];
  value: Scalars['Int'];
};

export type AccessBookingCancellationResponse = {
  __typename?: 'AccessBookingCancellationResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type InitPaymentRequestInput = {
  bookingId: Scalars['String'];
  paymentFraud?: InputMaybe<PaymentFraudInput>;
  paymentId: Scalars['String'];
};

export type PaymentFraudInput = {
  device?: InputMaybe<DeviceInput>;
};

export type DeviceInput = {
  browserData?: InputMaybe<BrowserInput>;
  locale?: InputMaybe<Scalars['String']>;
  timezoneOffsetUtcMinutes?: InputMaybe<Scalars['Int']>;
};

export type BrowserInput = {
  colorDepth?: InputMaybe<Scalars['Int']>;
  javaEnabled?: InputMaybe<Scalars['Boolean']>;
  javaScriptEnabled?: InputMaybe<Scalars['Boolean']>;
  screenHeight?: InputMaybe<Scalars['Int']>;
  screenWidth?: InputMaybe<Scalars['Int']>;
};

export type InitPaymentResponse = {
  __typename?: 'InitPaymentResponse';
  httpMethod?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  redirectFormData?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  status: Status;
};

export type BookmarkPdfUploadRequestInput = {
  productIds: Array<Scalars['String']>;
};

export type BookmarkPdfUploadResponse = {
  __typename?: 'BookmarkPdfUploadResponse';
  message?: Maybe<Scalars['String']>;
  uploadInfo?: Maybe<Array<BookmarkPdfUploadInfo>>;
};

export type BookmarkPdfUploadInfo = {
  __typename?: 'BookmarkPdfUploadInfo';
  link?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productText?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UserRegistrationRequestInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  params?: InputMaybe<Scalars['HashMap_String_StringScalar']>;
  salutation: Salutation;
  subscribe?: InputMaybe<Scalars['Boolean']>;
};

export type UserRegistrationResponse = {
  __typename?: 'UserRegistrationResponse';
  code?: Maybe<UserRegistrationCode>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  token?: Maybe<Scalars['String']>;
};

export enum UserRegistrationCode {
  UserRegistrationCodeConflict = 'USER_REGISTRATION_CODE_CONFLICT',
  UserRegistrationCodeError = 'USER_REGISTRATION_CODE_ERROR',
  UserRegistrationCodeOk = 'USER_REGISTRATION_CODE_OK',
}

export type NewsletterUnsubscriptionInputInput = {
  newsletterTypes: Array<NewsletterType>;
  token: Scalars['String'];
};

export enum NewsletterType {
  DealOfTheWeek = 'DEAL_OF_THE_WEEK',
  Hotelplan = 'HOTELPLAN',
  MigrosFerien = 'MIGROS_FERIEN',
  TourismePourTous = 'TOURISME_POUR_TOUS',
  TourismePourTousHiking = 'TOURISME_POUR_TOUS_HIKING',
  TourismePourTousSports = 'TOURISME_POUR_TOUS_SPORTS',
  Travelhouse = 'TRAVELHOUSE',
}

export type NewsletterUnsubscriptionResponse = {
  __typename?: 'NewsletterUnsubscriptionResponse';
  email: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type BookmarkGroupRenameResponse = {
  __typename?: 'BookmarkGroupRenameResponse';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};

export type ResendTravelDocumentRequestInput = {
  bookingNumber: Scalars['String'];
  email: Scalars['String'];
  travelDocumentType: TravelDocumentType;
};

export enum TravelDocumentType {
  CancelInvoice = 'CANCEL_INVOICE',
  Document = 'DOCUMENT',
  Invoice = 'INVOICE',
}

export type ResendTravelDocumentResponse = {
  __typename?: 'ResendTravelDocumentResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type EmailAdvertisingRevocationResponse = {
  __typename?: 'EmailAdvertisingRevocationResponse';
  email: Scalars['String'];
  status: Status;
};

export type AgencyAppointmentInputInput = {
  agencyId: Scalars['String'];
  callbackTime: Array<AgencyAppointmentCallbackTimeInput>;
  comment: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  recaptchaToken: Scalars['String'];
  salutation: Salutation;
};

export type AgencyAppointmentCallbackTimeInput = {
  dayType: AgencyAppointmentCallbackTimeDayType;
  from: Scalars['String'];
  to: Scalars['String'];
};

export enum AgencyAppointmentCallbackTimeDayType {
  Saturday = 'SATURDAY',
  Weekday = 'WEEKDAY',
}

export type AgencyAppointmentResponse = {
  __typename?: 'AgencyAppointmentResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type ContactFormRequestInput = {
  comment: Scalars['String'];
  company?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firm?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  formType: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  recaptchaToken: Scalars['String'];
  salutation: Salutation;
  subject: Scalars['String'];
  subscribe?: InputMaybe<Scalars['Boolean']>;
};

export type ContactFormResponse = {
  __typename?: 'ContactFormResponse';
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type UpdateEmergencyPhoneRequestInput = {
  bookingId: Scalars['String'];
  emergencyPhoneNumber: Scalars['String'];
};

export type UpdateEmergencyPhoneResponse = {
  __typename?: 'UpdateEmergencyPhoneResponse';
  emergencyPhone?: Maybe<PhoneDetails>;
  message?: Maybe<Scalars['String']>;
  status: Status;
};

export type AccountPersonalDataInput = {
  addressDetails?: InputMaybe<AddressDetailsInputInput>;
  agency?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileDetails?: InputMaybe<PhoneDetailsInput>;
  nationality?: InputMaybe<Scalars['String']>;
  phoneDetails?: InputMaybe<PhoneDetailsInput>;
  salutation: Salutation;
};

export type AddressDetailsInputInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type PhoneDetailsInput = {
  country?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type ViewedProductHistoryResponse = {
  __typename?: 'ViewedProductHistoryResponse';
  message?: Maybe<Scalars['String']>;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: ResolverTypeWrapper<{}>;
  RequestContextInput: RequestContextInput;
  String: ResolverTypeWrapper<Scalars['String']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  DeviceType: DeviceType;
  PageLanguage: PageLanguage;
  AccessBookingContext: ResolverTypeWrapper<AccessBookingContext>;
  AccessBookingPageResponse: ResolverTypeWrapper<AccessBookingPageResponse>;
  AccessBookingFlightInformation: ResolverTypeWrapper<AccessBookingFlightInformation>;
  PageMetaData: ResolverTypeWrapper<PageMetaData>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Map_String_StringScalar: ResolverTypeWrapper<
    Scalars['Map_String_StringScalar']
  >;
  AccessBookingPageTransferInfo: ResolverTypeWrapper<
    Omit<AccessBookingPageTransferInfo, 'buttonLink'> & {
      buttonLink?: Maybe<ResolversTypes['Link']>;
    }
  >;
  ImageCriteriaInput: ImageCriteriaInput;
  ImageResizingType: ImageResizingType;
  Image: ResolverTypeWrapper<Image>;
  ResizedImage: ResolverTypeWrapper<ResizedImage>;
  Link: ResolversTypes['ExternalLink'] | ResolversTypes['InternalLink'];
  ExternalLink: ResolverTypeWrapper<ExternalLink>;
  OpenLinkMethod: OpenLinkMethod;
  InternalLink: ResolverTypeWrapper<InternalLink>;
  PageType: PageType;
  AccessBookingTravelDocument: ResolverTypeWrapper<AccessBookingTravelDocument>;
  GetBookingByDataRequestInput: GetBookingByDataRequestInput;
  BookingDetailsResponse: ResolverTypeWrapper<BookingDetailsResponse>;
  AccessRestrictionStatus: AccessRestrictionStatus;
  BookingDetails: ResolverTypeWrapper<BookingDetails>;
  PaymentMethod: ResolverTypeWrapper<PaymentMethod>;
  LocalDate: ResolverTypeWrapper<Scalars['LocalDate']>;
  BookingItem: ResolverTypeWrapper<BookingItem>;
  BookingStatus: BookingStatus;
  FlightDestinationInfo: ResolverTypeWrapper<FlightDestinationInfo>;
  Airport: ResolverTypeWrapper<Airport>;
  GeoCoordinates: ResolverTypeWrapper<GeoCoordinates>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  HotelDestinationInfo: ResolverTypeWrapper<HotelDestinationInfo>;
  BreadcrumbsComponent: ResolverTypeWrapper<BreadcrumbsComponent>;
  Breadcrumb: ResolverTypeWrapper<Breadcrumb>;
  ProductClass: ProductClass;
  TravelType: TravelType;
  BookingExtraService: ResolverTypeWrapper<BookingExtraService>;
  Traveller: ResolverTypeWrapper<Traveller>;
  CancellationServiceInfo: ResolverTypeWrapper<CancellationServiceInfo>;
  PaymentRecord: ResolverTypeWrapper<PaymentRecord>;
  Price: ResolverTypeWrapper<Price>;
  PaymentStatus: PaymentStatus;
  CarRentalDetails: ResolverTypeWrapper<CarRentalDetails>;
  CarDetails: ResolverTypeWrapper<CarDetails>;
  CarDriver: ResolverTypeWrapper<CarDriver>;
  LocalTime: ResolverTypeWrapper<Scalars['LocalTime']>;
  Agency: ResolverTypeWrapper<
    Omit<Agency, 'dynamicComponents'> & {
      dynamicComponents: Array<ResolversTypes['AgencyDynamicComponent']>;
    }
  >;
  AddressDetails: ResolverTypeWrapper<AddressDetails>;
  AgencyBrand: AgencyBrand;
  AgencyDynamicComponent:
    | ResolversTypes['AgencyRecommendationGroup']
    | ResolversTypes['MediaGallery']
    | ResolversTypes['TextComponent'];
  AgencyRecommendationGroup: ResolverTypeWrapper<
    Omit<AgencyRecommendationGroup, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['AgencyRecommendationItemDeprecated']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  AgencyRecommendationItemDeprecated:
    | ResolversTypes['BD4TravelRecommendation']
    | ResolversTypes['BlogArticleRecommendationItem']
    | ResolversTypes['GeoRecommendationItem']
    | ResolversTypes['MarketingRecommendationItem']
    | ResolversTypes['ProductRecommendationItem']
    | ResolversTypes['ThemeRecommendationItem'];
  BD4TravelRecommendation: ResolverTypeWrapper<Bd4TravelRecommendation>;
  DisrupterObject: ResolverTypeWrapper<DisrupterObject>;
  ProductFeature: ResolverTypeWrapper<ProductFeature>;
  ProductFeatureGroup: ResolverTypeWrapper<ProductFeatureGroup>;
  GeoObject: ResolverTypeWrapper<GeoObject>;
  GeoType: GeoType;
  Room: ResolverTypeWrapper<Room>;
  ProductType: ProductType;
  WishlistItem: ResolverTypeWrapper<WishlistItem>;
  BlogArticleRecommendationItem: ResolverTypeWrapper<
    Omit<BlogArticleRecommendationItem, 'link'> & {
      link: ResolversTypes['Link'];
    }
  >;
  GeoRecommendationItem: ResolverTypeWrapper<GeoRecommendationItem>;
  MarketingRecommendationItem: ResolverTypeWrapper<
    Omit<MarketingRecommendationItem, 'link' | 'preview'> & {
      link: ResolversTypes['Link'];
      preview: ResolversTypes['Media'];
    }
  >;
  Media:
    | ResolversTypes['ExternalMediaItem']
    | ResolversTypes['ImageMediaItem']
    | ResolversTypes['MusicMediaItem']
    | ResolversTypes['PdfMediaItem']
    | ResolversTypes['VideoMediaItem'];
  ExternalMediaItem: ResolverTypeWrapper<
    Omit<ExternalMediaItem, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  ImageMediaItem: ResolverTypeWrapper<
    Omit<ImageMediaItem, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  MusicMediaItem: ResolverTypeWrapper<
    Omit<MusicMediaItem, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  PdfMediaItem: ResolverTypeWrapper<
    Omit<PdfMediaItem, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  VideoMediaItem: ResolverTypeWrapper<
    Omit<VideoMediaItem, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  ProductRecommendationItem: ResolverTypeWrapper<ProductRecommendationItem>;
  ThemeRecommendationItem: ResolverTypeWrapper<ThemeRecommendationItem>;
  RecommendationTracking: ResolversTypes['BD4TravelRecommendationTracking'];
  BD4TravelRecommendationTracking: ResolverTypeWrapper<Bd4TravelRecommendationTracking>;
  BD4TravelRecommendationInfo: ResolverTypeWrapper<Bd4TravelRecommendationInfo>;
  BD4TravelRecommendationStatus: Bd4TravelRecommendationStatus;
  MediaGallery: ResolverTypeWrapper<
    Omit<MediaGallery, 'mediaItems'> & {
      mediaItems: Array<ResolversTypes['Media']>;
    }
  >;
  TextComponent: ResolverTypeWrapper<TextComponent>;
  OpeningHours: ResolverTypeWrapper<OpeningHours>;
  Shift: ResolverTypeWrapper<Shift>;
  RequestButtonComponent: ResolverTypeWrapper<RequestButtonComponent>;
  Review: ResolverTypeWrapper<Review>;
  SpecialOpeningHours: ResolverTypeWrapper<SpecialOpeningHours>;
  AccountPersonalDataResponse: ResolverTypeWrapper<AccountPersonalDataResponse>;
  PhoneDetails: ResolverTypeWrapper<PhoneDetails>;
  Salutation: Salutation;
  DestinationInfo: ResolverTypeWrapper<DestinationInfo>;
  FlightBookingDetails: ResolverTypeWrapper<FlightBookingDetails>;
  Flight: ResolverTypeWrapper<Flight>;
  FlightBaggageInfo: ResolverTypeWrapper<FlightBaggageInfo>;
  FlightSegment: ResolverTypeWrapper<FlightSegment>;
  Airline: ResolverTypeWrapper<Airline>;
  FlightClass: FlightClass;
  FlightDirection: FlightDirection;
  HotelBookingDetails: ResolverTypeWrapper<HotelBookingDetails>;
  CruiseInfo: ResolverTypeWrapper<CruiseInfo>;
  BookingHotelRoom: ResolverTypeWrapper<BookingHotelRoom>;
  BookingBoardType: ResolverTypeWrapper<BookingBoardType>;
  BookingRoomType: ResolverTypeWrapper<BookingRoomType>;
  RoundtripInfo: ResolverTypeWrapper<RoundtripInfo>;
  PaymentCondition: ResolverTypeWrapper<PaymentCondition>;
  RestPayment: ResolverTypeWrapper<RestPayment>;
  PaymentInformationSection: ResolverTypeWrapper<PaymentInformationSection>;
  AvailablePaymentAction: AvailablePaymentAction;
  ProductStatus: ProductStatus;
  BookingTransfer: ResolverTypeWrapper<BookingTransfer>;
  ServiceStatus: ServiceStatus;
  TransferDate: ResolverTypeWrapper<TransferDate>;
  TransferDetailInfo: ResolverTypeWrapper<
    Omit<TransferDetailInfo, 'from' | 'to'> & {
      from?: Maybe<ResolversTypes['TransferPointInfoUnion']>;
      to?: Maybe<ResolversTypes['TransferPointInfoUnion']>;
    }
  >;
  TransferPointInfoUnion:
    | ResolversTypes['TransferFlightInfo']
    | ResolversTypes['TransferHotelInfo'];
  TransferFlightInfo: ResolverTypeWrapper<TransferFlightInfo>;
  TransferHotelInfo: ResolverTypeWrapper<TransferHotelInfo>;
  TransferInfo: ResolverTypeWrapper<TransferInfo>;
  TravellerInfo: ResolverTypeWrapper<TravellerInfo>;
  BookingDetailsHotelOffer: ResolverTypeWrapper<BookingDetailsHotelOffer>;
  Status: Status;
  BookingCancellationInfoResponse: ResolverTypeWrapper<BookingCancellationInfoResponse>;
  AccountContext: ResolverTypeWrapper<AccountContext>;
  AuthMethodListResponse: ResolverTypeWrapper<AuthMethodListResponse>;
  AuthMethod: ResolverTypeWrapper<AuthMethod>;
  AuthMethodType: AuthMethodType;
  AccountPage: ResolverTypeWrapper<AccountPage>;
  CheckUpsellServiceFromBasketInput: CheckUpsellServiceFromBasketInput;
  CheckBasketResponse: ResolverTypeWrapper<CheckBasketResponse>;
  UpsellTransferService: ResolverTypeWrapper<UpsellTransferService>;
  AccountLoginPage: ResolverTypeWrapper<AccountLoginPage>;
  AccountMyProfilePage: ResolverTypeWrapper<AccountMyProfilePage>;
  PageInput: PageInput;
  BookingResponse: ResolverTypeWrapper<BookingResponse>;
  Page: ResolverTypeWrapper<Page>;
  SubscriptionType: SubscriptionType;
  TravelComponentResponse: ResolverTypeWrapper<TravelComponentResponse>;
  TravelCompanion: ResolverTypeWrapper<TravelCompanion>;
  UpsellTransferResponse: ResolverTypeWrapper<UpsellTransferResponse>;
  UpsellTransfer: ResolverTypeWrapper<UpsellTransfer>;
  UpsellTransferPrice: ResolverTypeWrapper<UpsellTransferPrice>;
  UserParamsFromTokenResponse: ResolverTypeWrapper<UserParamsFromTokenResponse>;
  HashMap_String_StringScalar: ResolverTypeWrapper<
    Scalars['HashMap_String_StringScalar']
  >;
  AgencyContact: ResolverTypeWrapper<AgencyContact>;
  AgencyContactPhone: ResolverTypeWrapper<AgencyContactPhone>;
  AgencyOverviewPageContext: ResolverTypeWrapper<
    Omit<AgencyOverviewPageContext, 'dynamicComponents'> & {
      dynamicComponents: Array<ResolversTypes['AgencyDynamicComponent']>;
    }
  >;
  GeoCoordinatesInput: GeoCoordinatesInput;
  AgencyAutocompleteCriteriaInputInput: AgencyAutocompleteCriteriaInputInput;
  AgencyAutocomplete: ResolverTypeWrapper<
    Omit<AgencyAutocomplete, 'objects'> & {
      objects: Array<ResolversTypes['AgencyAutocompleteSuggestion']>;
    }
  >;
  AgencyAutocompleteSuggestion:
    | ResolversTypes['Agency']
    | ResolversTypes['MapSuggestion'];
  MapSuggestion: ResolverTypeWrapper<MapSuggestion>;
  MapSuggestionType: MapSuggestionType;
  AgencySearchCriteriaInput: AgencySearchCriteriaInput;
  TravellersInput: TravellersInput;
  TravellersDistributionInput: TravellersDistributionInput;
  RoomDistributionInput: RoomDistributionInput;
  TravelPeriodCriteriaInput: TravelPeriodCriteriaInput;
  SearchPeriodType: SearchPeriodType;
  AgencyOverviewInfoComponent: ResolverTypeWrapper<AgencyOverviewInfoComponent>;
  AuthChannelType: AuthChannelType;
  BlogArticle: ResolverTypeWrapper<BlogArticle>;
  EmployeeBox: ResolverTypeWrapper<
    Omit<EmployeeBox, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  ImagePlacement: ImagePlacement;
  EmployeeImagePlacement: EmployeeImagePlacement;
  EmployeeSocialProfile: ResolverTypeWrapper<
    Omit<EmployeeSocialProfile, 'link'> & {
      link?: Maybe<ResolversTypes['Link']>;
    }
  >;
  BlogOverview: ResolverTypeWrapper<BlogOverview>;
  BlogArticles: ResolverTypeWrapper<BlogArticles>;
  BlogArticleItem: ResolverTypeWrapper<BlogArticleItem>;
  BookingCancellationResultRequestInput: BookingCancellationResultRequestInput;
  BookingCancellationResultResponse: ResolverTypeWrapper<BookingCancellationResultResponse>;
  BookingPaymentResultRequestInput: BookingPaymentResultRequestInput;
  BookingPaymentResultResponse: ResolverTypeWrapper<BookingPaymentResultResponse>;
  BookmarkContext: ResolverTypeWrapper<BookmarkContext>;
  BookmarkQuantityResponse: ResolverTypeWrapper<BookmarkQuantityResponse>;
  BookmarkPage: ResolverTypeWrapper<
    Omit<BookmarkPage, 'buttonLink'> & {
      buttonLink?: Maybe<ResolversTypes['Link']>;
    }
  >;
  AllBookmarkGroupResponse: ResolverTypeWrapper<AllBookmarkGroupResponse>;
  BookmarkGroup: ResolverTypeWrapper<BookmarkGroup>;
  BookmarkItem: ResolverTypeWrapper<BookmarkItem>;
  BookmarkType: BookmarkType;
  ProductInfoB2B: ResolverTypeWrapper<ProductInfoB2B>;
  Booster: ResolverTypeWrapper<Booster>;
  BoosterFrequency: BoosterFrequency;
  CatalogOverviewContext: ResolverTypeWrapper<CatalogOverviewContext>;
  Catalog: ResolverTypeWrapper<Catalog>;
  CatalogType: CatalogType;
  CatalogOverviewInfoComponent: ResolverTypeWrapper<CatalogOverviewInfoComponent>;
  GlobalSearchStateInput: GlobalSearchStateInput;
  ComponentsQueryInput: ComponentsQueryInput;
  DynamicComponent:
    | ResolversTypes['AccordionComponent']
    | ResolversTypes['AgencyContactComponent']
    | ResolversTypes['AgencyRecommendation']
    | ResolversTypes['BD4TravelRecommendationGroup']
    | ResolversTypes['BenefitsComponent']
    | ResolversTypes['BlogArticleRecommendation']
    | ResolversTypes['BrandsComponent']
    | ResolversTypes['ClimateComponent']
    | ResolversTypes['ContactModuleComponent']
    | ResolversTypes['EmployeesComponent']
    | ResolversTypes['FaqComponent']
    | ResolversTypes['FlightWithoutPriceRecommendation']
    | ResolversTypes['FlightWithPriceRecommendation']
    | ResolversTypes['GeoChildrenComponent']
    | ResolversTypes['GeoGalleryComponent']
    | ResolversTypes['GeoRecommendation']
    | ResolversTypes['HeroMediaGallery']
    | ResolversTypes['ImageTextComponent']
    | ResolversTypes['LinkListComponent']
    | ResolversTypes['MarketingRecommendation']
    | ResolversTypes['MarketingTeaserComponent']
    | ResolversTypes['MediaGallery']
    | ResolversTypes['NewsArticlesComponent']
    | ResolversTypes['NewsletterSignupComponent']
    | ResolversTypes['OfferButtonComponent']
    | ResolversTypes['OffersMapPlaceholderComponent']
    | ResolversTypes['ProductGalleryWithMapComponent']
    | ResolversTypes['ProductRecommendation']
    | ResolversTypes['RequestButtonComponent']
    | ResolversTypes['ResortTopHotelsComponent']
    | ResolversTypes['SrlButtonPlaceholderComponent']
    | ResolversTypes['SrlMapPinsComponent']
    | ResolversTypes['StaticBlogRecommendation']
    | ResolversTypes['StaticGeoRecommendation']
    | ResolversTypes['StaticProductRecommendation']
    | ResolversTypes['StaticThemeRecommendation']
    | ResolversTypes['TextComponent']
    | ResolversTypes['ThemeRecommendation']
    | ResolversTypes['UspBoxesComponent'];
  AccordionComponent: ResolverTypeWrapper<
    Omit<AccordionComponent, 'components'> & {
      components: Array<ResolversTypes['DynamicComponent']>;
    }
  >;
  AgencyContactComponent: ResolverTypeWrapper<AgencyContactComponent>;
  AgencyRecommendation: ResolverTypeWrapper<AgencyRecommendation>;
  AgencyRecommendationItem: ResolverTypeWrapper<AgencyRecommendationItem>;
  BD4TravelRecommendationGroup: ResolverTypeWrapper<Bd4TravelRecommendationGroup>;
  BenefitsComponent: ResolverTypeWrapper<
    Omit<BenefitsComponent, 'buttonLink'> & {
      buttonLink: ResolversTypes['Link'];
    }
  >;
  BenefitItem: ResolverTypeWrapper<BenefitItem>;
  BlogArticleRecommendation: ResolverTypeWrapper<BlogArticleRecommendation>;
  BrandsComponent: ResolverTypeWrapper<BrandsComponent>;
  BrandBox: ResolverTypeWrapper<
    Omit<BrandBox, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  ClimateComponent: ResolverTypeWrapper<ClimateComponent>;
  ClimateChart: ResolverTypeWrapper<ClimateChart>;
  ClimateChartEntry: ResolverTypeWrapper<ClimateChartEntry>;
  ClimateChartType: ClimateChartType;
  ContactModuleComponent: ResolverTypeWrapper<
    Omit<ContactModuleComponent, 'contactTypeBases'> & {
      contactTypeBases: Array<ResolversTypes['ContactTypeUnion']>;
    }
  >;
  ContactTypeUnion:
    | ResolversTypes['ContactType']
    | ResolversTypes['ContactTypePhone'];
  ContactType: ResolverTypeWrapper<
    Omit<ContactType, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  ContactTypePhone: ResolverTypeWrapper<ContactTypePhone>;
  EmployeesComponent: ResolverTypeWrapper<EmployeesComponent>;
  FaqComponent: ResolverTypeWrapper<FaqComponent>;
  FlightWithoutPriceRecommendation: ResolverTypeWrapper<FlightWithoutPriceRecommendation>;
  FlightRecommendationWithoutPriceItem: ResolverTypeWrapper<
    Omit<FlightRecommendationWithoutPriceItem, 'link' | 'media'> & {
      link: ResolversTypes['Link'];
      media: ResolversTypes['Media'];
    }
  >;
  FlightWithPriceRecommendation: ResolverTypeWrapper<FlightWithPriceRecommendation>;
  FlightRecommendationWithPriceItem: ResolverTypeWrapper<
    Omit<FlightRecommendationWithPriceItem, 'media'> & {
      media: ResolversTypes['Media'];
    }
  >;
  FlightOffer: ResolverTypeWrapper<FlightOffer>;
  GeoChildrenComponent: ResolverTypeWrapper<GeoChildrenComponent>;
  GeoChildComponent: ResolverTypeWrapper<GeoChildComponent>;
  GeoGalleryComponent: ResolverTypeWrapper<
    Omit<GeoGalleryComponent, 'mediaItems'> & {
      mediaItems: Array<ResolversTypes['Media']>;
    }
  >;
  GeoRecommendation: ResolverTypeWrapper<GeoRecommendation>;
  HeroMediaGallery: ResolverTypeWrapper<
    Omit<HeroMediaGallery, 'mediaItems'> & {
      mediaItems: Array<ResolversTypes['HeroMedia']>;
    }
  >;
  HeroMedia:
    | ResolversTypes['ImageMediaItem']
    | ResolversTypes['VideoMediaItem'];
  ImageTextComponent: ResolverTypeWrapper<ImageTextComponent>;
  LinkListComponent: ResolverTypeWrapper<LinkListComponent>;
  LinkListItem: ResolverTypeWrapper<
    Omit<LinkListItem, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  MarketingRecommendation: ResolverTypeWrapper<MarketingRecommendation>;
  MarketingTeaserComponent: ResolverTypeWrapper<
    Omit<MarketingTeaserComponent, 'buttonLink'> & {
      buttonLink: ResolversTypes['Link'];
    }
  >;
  NewsArticlesComponent: ResolverTypeWrapper<
    Omit<NewsArticlesComponent, 'buttonLink'> & {
      buttonLink?: Maybe<ResolversTypes['Link']>;
    }
  >;
  NewsArticle: ResolverTypeWrapper<
    Omit<NewsArticle, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  NewsletterSignupComponent: ResolverTypeWrapper<NewsletterSignupComponent>;
  OfferButtonComponent: ResolverTypeWrapper<OfferButtonComponent>;
  OffersMapPlaceholderComponent: ResolverTypeWrapper<OffersMapPlaceholderComponent>;
  ProductGalleryWithMapComponent: ResolverTypeWrapper<ProductGalleryWithMapComponent>;
  ProductImageGroup: ResolverTypeWrapper<ProductImageGroup>;
  ProductImageItem: ResolverTypeWrapper<ProductImageItem>;
  RelatedRoundtrips: ResolverTypeWrapper<RelatedRoundtrips>;
  RelatedRoundtripItem: ResolverTypeWrapper<RelatedRoundtripItem>;
  RoutePoint: ResolverTypeWrapper<RoutePoint>;
  ProductRecommendation: ResolverTypeWrapper<ProductRecommendation>;
  ResortTopHotelsComponent: ResolverTypeWrapper<ResortTopHotelsComponent>;
  SrlButtonPlaceholderComponent: ResolverTypeWrapper<SrlButtonPlaceholderComponent>;
  SrlMapPinsComponent: ResolverTypeWrapper<SrlMapPinsComponent>;
  SrlMapGeoPin: ResolverTypeWrapper<SrlMapGeoPin>;
  SrlMapProductPin: ResolverTypeWrapper<SrlMapProductPin>;
  StaticBlogRecommendation: ResolverTypeWrapper<StaticBlogRecommendation>;
  StaticGeoRecommendation: ResolverTypeWrapper<StaticGeoRecommendation>;
  StaticGeoRecommendationItem: ResolverTypeWrapper<StaticGeoRecommendationItem>;
  StaticProductRecommendation: ResolverTypeWrapper<StaticProductRecommendation>;
  StaticProductRecommendationItem: ResolverTypeWrapper<StaticProductRecommendationItem>;
  ProductFilter: ResolverTypeWrapper<ProductFilter>;
  StaticThemeRecommendation: ResolverTypeWrapper<StaticThemeRecommendation>;
  StaticThemeRecommendationItem: ResolverTypeWrapper<StaticThemeRecommendationItem>;
  ThemeRecommendation: ResolverTypeWrapper<ThemeRecommendation>;
  UspBoxesComponent: ResolverTypeWrapper<UspBoxesComponent>;
  UspBox: ResolverTypeWrapper<
    Omit<UspBox, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  ContactPageContext: ResolverTypeWrapper<
    Omit<ContactPageContext, 'dynamicComponents'> & {
      dynamicComponents: Array<ResolversTypes['ContactDynamicComponent']>;
    }
  >;
  ContactDynamicComponent:
    | ResolversTypes['ContactRecommendationGroup']
    | ResolversTypes['MediaGallery']
    | ResolversTypes['TextComponent'];
  ContactRecommendationGroup: ResolverTypeWrapper<
    Omit<ContactRecommendationGroup, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['ContactRecommendationItem']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  ContactRecommendationItem: ResolversTypes['MarketingRecommendationItem'];
  ContactForm: ResolverTypeWrapper<ContactForm>;
  CookieDisclaimer: ResolverTypeWrapper<CookieDisclaimer>;
  EmailAdvertisingRevocationPageContext: ResolverTypeWrapper<EmailAdvertisingRevocationPageContext>;
  EmailAdvertisingStaticContent: ResolverTypeWrapper<EmailAdvertisingStaticContent>;
  EmailAdvertisingStaticContentInfo: ResolverTypeWrapper<EmailAdvertisingStaticContentInfo>;
  EmailAdvertisingGetEmailFromTokenResponse: ResolverTypeWrapper<EmailAdvertisingGetEmailFromTokenResponse>;
  EmailAdvertisingGetTokenFromEmailResponse: ResolverTypeWrapper<EmailAdvertisingGetTokenFromEmailResponse>;
  EmailAdvertisingRevocationConfirmationPageContext: ResolverTypeWrapper<
    Omit<
      EmailAdvertisingRevocationConfirmationPageContext,
      'dynamicComponents'
    > & {
      dynamicComponents: Array<
        ResolversTypes['EmailAdvertisingDynamicComponent']
      >;
    }
  >;
  EmailAdvertisingDynamicComponent: ResolversTypes['EmailAdvertisingRecommendationGroup'];
  EmailAdvertisingRecommendationGroup: ResolverTypeWrapper<
    Omit<EmailAdvertisingRecommendationGroup, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['EmailAdvertisingRecommendationItem']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  EmailAdvertisingRecommendationItem:
    | ResolversTypes['GeoRecommendationItem']
    | ResolversTypes['ThemeRecommendationItem'];
  FlightHomeContext: ResolverTypeWrapper<
    Omit<FlightHomeContext, 'dynamicComponents' | 'searchControl'> & {
      dynamicComponents: Array<ResolversTypes['FlightHomeDynamicComponent']>;
      searchControl: ResolversTypes['SearchControlUnion'];
    }
  >;
  AutocompleteCriteriaInput: AutocompleteCriteriaInput;
  AutocompleteComponent: ResolverTypeWrapper<AutocompleteComponent>;
  TravelDestination: ResolverTypeWrapper<TravelDestination>;
  GeoLocation: ResolverTypeWrapper<GeoLocation>;
  ThemeDestinationInformation: ResolverTypeWrapper<ThemeDestinationInformation>;
  TravelDestinationType: TravelDestinationType;
  FlightHomeSearchCriteriaInput: FlightHomeSearchCriteriaInput;
  FlightSearchControlComponentInput: FlightSearchControlComponentInput;
  FlightPartitionInput: FlightPartitionInput;
  AirportInput: AirportInput;
  ExactTravelPeriodInput: ExactTravelPeriodInput;
  FlightType: FlightType;
  FlightHomeDynamicComponent:
    | ResolversTypes['FlightHomeRecommendationGroup']
    | ResolversTypes['MediaGallery']
    | ResolversTypes['TextComponent'];
  FlightHomeRecommendationGroup: ResolverTypeWrapper<
    Omit<FlightHomeRecommendationGroup, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['FlightHomeRecommendationItem']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  FlightHomeRecommendationItem:
    | ResolversTypes['BD4TravelRecommendation']
    | ResolversTypes['FlightRecommendationWithoutPriceItem']
    | ResolversTypes['FlightRecommendationWithPriceItem']
    | ResolversTypes['GeoRecommendationItem']
    | ResolversTypes['ThemeRecommendationItem'];
  SearchControlUnion:
    | ResolversTypes['FlightSearchControlComponent']
    | ResolversTypes['SearchControlComponent'];
  FlightSearchControlComponent: ResolverTypeWrapper<FlightSearchControlComponent>;
  FlightPartition: ResolverTypeWrapper<FlightPartition>;
  ExactTravelPeriod: ResolverTypeWrapper<ExactTravelPeriod>;
  Travellers: ResolverTypeWrapper<Travellers>;
  SearchControlComponent: ResolverTypeWrapper<
    Omit<SearchControlComponent, 'travelPeriod'> & {
      travelPeriod: ResolversTypes['TravelPeriodUnion'];
    }
  >;
  TravelPeriodComponent: ResolverTypeWrapper<TravelPeriodComponent>;
  FlexibleTravelPeriod: ResolverTypeWrapper<FlexibleTravelPeriod>;
  TravellersDistribution: ResolverTypeWrapper<TravellersDistribution>;
  RoomDistribution: ResolverTypeWrapper<RoomDistribution>;
  TravelPeriodUnion:
    | ResolversTypes['ExactTravelPeriod']
    | ResolversTypes['FlexibleTravelPeriod'];
  FlightSrlContext: ResolverTypeWrapper<
    Omit<FlightSrlContext, 'dynamicComponents'> & {
      dynamicComponents: Array<ResolversTypes['FlightSrlDynamicComponent']>;
    }
  >;
  FlightCheckoutComponent: ResolverTypeWrapper<FlightCheckoutComponent>;
  FlightCheckoutStatus: FlightCheckoutStatus;
  FlightSrlContainer: ResolverTypeWrapper<FlightSrlContainer>;
  FlightSrlSearchCriteriaInput: FlightSrlSearchCriteriaInput;
  FlightSrlFilterCriteriaInput: FlightSrlFilterCriteriaInput;
  FlightSortInput: FlightSortInput;
  FlightSortDirection: FlightSortDirection;
  FlightSortField: FlightSortField;
  FlightSrlComponent: ResolverTypeWrapper<FlightSrlComponent>;
  FlightStopOverDuration: ResolverTypeWrapper<FlightStopOverDuration>;
  FlightSrlDynamicComponent: ResolversTypes['FlightSrlRecommendationGroup'];
  FlightSrlRecommendationGroup: ResolverTypeWrapper<
    Omit<FlightSrlRecommendationGroup, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['FlightSrlRecommendationItem']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  FlightSrlRecommendationItem: ResolversTypes['BD4TravelRecommendation'];
  FilterComponent: ResolverTypeWrapper<FilterComponent>;
  CheckboxFilterComponent: ResolverTypeWrapper<CheckboxFilterComponent>;
  FilterItem: ResolverTypeWrapper<FilterItem>;
  RadiobuttonFilterComponent: ResolverTypeWrapper<RadiobuttonFilterComponent>;
  SliderFilterComponent: ResolverTypeWrapper<SliderFilterComponent>;
  SingleValueFilterComponent: ResolverTypeWrapper<SingleValueFilterComponent>;
  DoubleRangeSliderFilterComponent: ResolverTypeWrapper<DoubleRangeSliderFilterComponent>;
  RadiusFilterComponent: ResolverTypeWrapper<RadiusFilterComponent>;
  WaitingScreen: ResolverTypeWrapper<WaitingScreen>;
  GeoCriteriaInput: GeoCriteriaInput;
  SrlFilterCriteriaInput: SrlFilterCriteriaInput;
  SrlRadiusCriteriaInput: SrlRadiusCriteriaInput;
  SrlGroupSorting: SrlGroupSorting;
  SrlProductSorting: SrlProductSorting;
  TravelDestinationInput: TravelDestinationInput;
  GeoLocationInput: GeoLocationInput;
  GeoObjectInput: GeoObjectInput;
  ThemeDestinationInformationInput: ThemeDestinationInformationInput;
  GeoContext: ResolverTypeWrapper<
    Omit<
      GeoContext,
      'children' | 'dynamicComponents' | 'geoStaticComponents' | 'searchControl'
    > & {
      children: ResolversTypes['GeoChildrenComponentInterface'];
      dynamicComponents: Array<ResolversTypes['GeoObjectPageComponent']>;
      geoStaticComponents?: Maybe<
        Array<Maybe<ResolversTypes['DynamicComponent']>>
      >;
      searchControl: ResolversTypes['SearchControlUnion'];
    }
  >;
  GeoChildrenComponentInterface:
    | ResolversTypes['GeoChildrenComponent']
    | ResolversTypes['ResortTopHotelsComponent'];
  GeoChildrenOffersComponent: ResolverTypeWrapper<GeoChildrenOffersComponent>;
  GeoChildrenOffer: ResolverTypeWrapper<GeoChildrenOffer>;
  GeoOfferInfo: ResolverTypeWrapper<GeoOfferInfo>;
  GeoDefaultGeoRecommendationsComponent: ResolverTypeWrapper<GeoDefaultGeoRecommendationsComponent>;
  GeoObjectPageComponent: ResolversTypes['GeoObjectRecommendationGroupComponent'];
  GeoObjectRecommendationGroupComponent: ResolverTypeWrapper<
    Omit<GeoObjectRecommendationGroupComponent, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['GeoObjectRecommendationItem']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  GeoObjectRecommendationItem:
    | ResolversTypes['BD4TravelRecommendation']
    | ResolversTypes['BlogArticleRecommendationItem']
    | ResolversTypes['GeoRecommendationItem']
    | ResolversTypes['MarketingRecommendationItem']
    | ResolversTypes['ProductRecommendationItem']
    | ResolversTypes['ThemeRecommendationItem'];
  GeoProduct: ResolverTypeWrapper<GeoProduct>;
  MainFilterComponent: ResolverTypeWrapper<MainFilterComponent>;
  SubGeoFilterComponent: ResolverTypeWrapper<SubGeoFilterComponent>;
  SubGeoItem: ResolverTypeWrapper<SubGeoItem>;
  GeoInfoComponent: ResolverTypeWrapper<GeoInfoComponent>;
  GeoFeatureGroup: ResolverTypeWrapper<GeoFeatureGroup>;
  GeoFeature: ResolverTypeWrapper<GeoFeature>;
  AccordionItem: ResolverTypeWrapper<AccordionItem>;
  InpageNavigationItem: ResolverTypeWrapper<InpageNavigationItem>;
  ProductImageGallery: ResolverTypeWrapper<ProductImageGallery>;
  ProductImage: ResolverTypeWrapper<ProductImage>;
  SeoChildrenComponent: ResolverTypeWrapper<SeoChildrenComponent>;
  SeoGeoChildren: ResolverTypeWrapper<SeoGeoChildren>;
  GeoSiblingsComponent: ResolverTypeWrapper<GeoSiblingsComponent>;
  GeoTheme: ResolverTypeWrapper<GeoTheme>;
  GeoOverviewContext: ResolverTypeWrapper<
    Omit<GeoOverviewContext, 'dynamicComponents'> & {
      dynamicComponents: Array<ResolversTypes['GeoOverviewPageComponent']>;
    }
  >;
  GeoOverviewChildComponent: ResolverTypeWrapper<GeoOverviewChildComponent>;
  GeoOverviewChildOfferInfo: ResolverTypeWrapper<GeoOverviewChildOfferInfo>;
  GeoOverviewPageComponent: ResolversTypes['GeoOverviewRecommendationGroupComponent'];
  GeoOverviewRecommendationGroupComponent: ResolverTypeWrapper<
    Omit<GeoOverviewRecommendationGroupComponent, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['GeoOverviewRecommendationItem']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  GeoOverviewRecommendationItem:
    | ResolversTypes['GeoRecommendationItem']
    | ResolversTypes['ThemeRecommendationItem'];
  GeoHierarchyComponent: ResolverTypeWrapper<GeoHierarchyComponent>;
  GeoHierarchyContinent: ResolverTypeWrapper<GeoHierarchyContinent>;
  GeoHierarchyCountry: ResolverTypeWrapper<GeoHierarchyCountry>;
  HelpOverlayData: ResolverTypeWrapper<
    Omit<HelpOverlayData, 'helpOverlayBoxes'> & {
      helpOverlayBoxes?: Maybe<Array<ResolversTypes['HelpOverlayBoxBase']>>;
    }
  >;
  HelpOverlayBoxBase:
    | ResolversTypes['HelpOverlayBox']
    | ResolversTypes['HelpOverlayBoxChat']
    | ResolversTypes['HelpOverlayBoxContact'];
  HelpOverlayBox: ResolverTypeWrapper<
    Omit<HelpOverlayBox, 'targetUrl'> & {
      targetUrl?: Maybe<ResolversTypes['Link']>;
    }
  >;
  HelpOverlayBoxChat: ResolverTypeWrapper<
    Omit<HelpOverlayBoxChat, 'targetUrl'> & {
      targetUrl?: Maybe<ResolversTypes['Link']>;
    }
  >;
  HelpOverlayBoxContact: ResolverTypeWrapper<
    Omit<HelpOverlayBoxContact, 'targetUrl'> & {
      targetUrl?: Maybe<ResolversTypes['Link']>;
    }
  >;
  HistoryContext: ResolverTypeWrapper<
    Omit<HistoryContext, 'recentSearches' | 'recentSearchesByTravelType'> & {
      recentSearches: Array<ResolversTypes['HistoryRecordUnion']>;
      recentSearchesByTravelType: Array<ResolversTypes['HistoryRecordUnion']>;
    }
  >;
  LastSeenRequestInput: LastSeenRequestInput;
  LastSeenResponse: ResolverTypeWrapper<LastSeenResponse>;
  LastSeenItem: ResolverTypeWrapper<LastSeenItem>;
  HistoryFlightRecord: ResolverTypeWrapper<HistoryFlightRecord>;
  HistoryFlightResponse: ResolverTypeWrapper<HistoryFlightResponse>;
  HistoryUserRecord: ResolverTypeWrapper<HistoryUserRecord>;
  HistoryUserResponse: ResolverTypeWrapper<HistoryUserResponse>;
  HistoryProductRecord: ResolverTypeWrapper<HistoryProductRecord>;
  HistoryProductResponse: ResolverTypeWrapper<HistoryProductResponse>;
  HistoryRecordUnion:
    | ResolversTypes['HistoryFlightRecord']
    | ResolversTypes['HistorySrlRecord'];
  HistorySrlRecord: ResolverTypeWrapper<HistorySrlRecord>;
  HistoryUnionResponse: ResolverTypeWrapper<
    Omit<HistoryUnionResponse, 'recordUnions'> & {
      recordUnions?: Maybe<Array<ResolversTypes['HistoryRecordUnion']>>;
    }
  >;
  HistorySrlResponse: ResolverTypeWrapper<HistorySrlResponse>;
  HolidayFinderPageContext: ResolverTypeWrapper<HolidayFinderPageContext>;
  HolidayFinderStartVotingRequestInput: HolidayFinderStartVotingRequestInput;
  HolidayFinderVotingResponse: ResolverTypeWrapper<HolidayFinderVotingResponse>;
  HolidayFinderInfo: ResolverTypeWrapper<HolidayFinderInfo>;
  HolidayFinderProduct: ResolverTypeWrapper<HolidayFinderProduct>;
  HolidayFinderOffer: ResolverTypeWrapper<HolidayFinderOffer>;
  HolidayFinderTracking: ResolverTypeWrapper<HolidayFinderTracking>;
  HolidayFinderLandingPage: ResolverTypeWrapper<HolidayFinderLandingPage>;
  HolidayFinderFinishVotingRequestInput: HolidayFinderFinishVotingRequestInput;
  HolidayFinderPage: ResolverTypeWrapper<HolidayFinderPage>;
  HolidayFinderSearchRequestInput: HolidayFinderSearchRequestInput;
  HolidayFinderSearchResponse: ResolverTypeWrapper<HolidayFinderSearchResponse>;
  HomeContext: ResolverTypeWrapper<
    Omit<HomeContext, 'dynamicComponents' | 'searchControl'> & {
      dynamicComponents: Array<ResolversTypes['HomeDynamicComponent']>;
      searchControl: ResolversTypes['SearchControlUnion'];
    }
  >;
  HomeSearchCriteriaInput: HomeSearchCriteriaInput;
  HomeSearchControlCriteriaInput: HomeSearchControlCriteriaInput;
  HomeDynamicComponent:
    | ResolversTypes['BrandsComponent']
    | ResolversTypes['EmployeesComponent']
    | ResolversTypes['FaqComponent']
    | ResolversTypes['HeroMediaGallery']
    | ResolversTypes['HomeRecommendationGroup']
    | ResolversTypes['LinkListComponent']
    | ResolversTypes['MediaGallery']
    | ResolversTypes['NewsArticlesComponent']
    | ResolversTypes['TextComponent']
    | ResolversTypes['UspBoxesComponent'];
  HomeRecommendationGroup: ResolverTypeWrapper<
    Omit<HomeRecommendationGroup, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['HomeRecommendationItem']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  HomeRecommendationItem:
    | ResolversTypes['BD4TravelRecommendation']
    | ResolversTypes['GeoRecommendationItem']
    | ResolversTypes['MarketingRecommendationItem']
    | ResolversTypes['ProductRecommendationItem']
    | ResolversTypes['ThemeRecommendationItem'];
  MoreVisibilityStaticComponent: ResolverTypeWrapper<MoreVisibilityStaticComponent>;
  MoreVisibleItem: ResolverTypeWrapper<
    Omit<MoreVisibleItem, 'travelPeriod'> & {
      travelPeriod: ResolversTypes['TravelPeriodUnion'];
    }
  >;
  MoreVisibleItemType: MoreVisibleItemType;
  HomeStaticContent: ResolverTypeWrapper<HomeStaticContent>;
  HomeTitle: ResolverTypeWrapper<HomeTitle>;
  HomeTotalProductCount: ResolverTypeWrapper<HomeTotalProductCount>;
  NewsArticlePage: ResolverTypeWrapper<
    Omit<NewsArticlePage, 'components'> & {
      components: Array<ResolversTypes['StaticPageComponent']>;
    }
  >;
  BusinessUnit: ResolverTypeWrapper<BusinessUnit>;
  StaticPageComponent:
    | ResolversTypes['BrandsComponent']
    | ResolversTypes['EmployeesComponent']
    | ResolversTypes['FaqComponent']
    | ResolversTypes['HeroMediaGallery']
    | ResolversTypes['LinkListComponent']
    | ResolversTypes['MediaGallery']
    | ResolversTypes['NewsArticlesComponent']
    | ResolversTypes['StaticRecommendationGroup']
    | ResolversTypes['TextComponent']
    | ResolversTypes['UspBoxesComponent'];
  StaticRecommendationGroup: ResolverTypeWrapper<
    Omit<StaticRecommendationGroup, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['StaticRecommendationItem']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  StaticRecommendationItem:
    | ResolversTypes['BD4TravelRecommendation']
    | ResolversTypes['MarketingRecommendationItem'];
  StaticPageStatus: StaticPageStatus;
  NewsArticlesOverviewPage: ResolverTypeWrapper<
    Omit<NewsArticlesOverviewPage, 'components'> & {
      components: Array<ResolversTypes['StaticPageComponent']>;
    }
  >;
  NewsArticlesFilter: ResolverTypeWrapper<NewsArticlesFilter>;
  NewsArticlesSearchCriteriaInput: NewsArticlesSearchCriteriaInput;
  NewsArticlesOverview: ResolverTypeWrapper<NewsArticlesOverview>;
  NewsletterConfirmationContext: ResolverTypeWrapper<
    Omit<NewsletterConfirmationContext, 'dynamicContent'> & {
      dynamicContent: Array<ResolversTypes['NewsletterDynamicComponent']>;
    }
  >;
  NewsletterInfoComponent: ResolverTypeWrapper<NewsletterInfoComponent>;
  NewsletterDynamicComponent: ResolversTypes['NewsletterRecommendationGroup'];
  NewsletterRecommendationGroup: ResolverTypeWrapper<
    Omit<NewsletterRecommendationGroup, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['NewsletterRecommendationItem']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  NewsletterRecommendationItem:
    | ResolversTypes['GeoRecommendationItem']
    | ResolversTypes['ThemeRecommendationItem'];
  NewsletterFinalizationContext: ResolverTypeWrapper<
    Omit<NewsletterFinalizationContext, 'dynamicContent'> & {
      dynamicContent: Array<ResolversTypes['NewsletterDynamicComponent']>;
    }
  >;
  NewsletterSubscriptionContext: ResolverTypeWrapper<NewsletterSubscriptionContext>;
  NewsletterSubscriptionTokenInputInput: NewsletterSubscriptionTokenInputInput;
  NewsletterSubscriptionTokenResponse: ResolverTypeWrapper<NewsletterSubscriptionTokenResponse>;
  NewsletterUnsubscriptionContext: ResolverTypeWrapper<NewsletterUnsubscriptionContext>;
  NewsletterGetEmailFromTokenResponse: ResolverTypeWrapper<NewsletterGetEmailFromTokenResponse>;
  NewsletterGetTokenFromEmailResponse: ResolverTypeWrapper<NewsletterGetTokenFromEmailResponse>;
  NewsletterUnsubscriptionFinalizationContext: ResolverTypeWrapper<
    Omit<NewsletterUnsubscriptionFinalizationContext, 'dynamicContent'> & {
      dynamicContent: Array<ResolversTypes['NewsletterDynamicComponent']>;
    }
  >;
  Notification: ResolverTypeWrapper<Notification>;
  MaintenanceNotificationType: MaintenanceNotificationType;
  MaintenanceNotificationShowMode: MaintenanceNotificationShowMode;
  NotificationInfo: ResolverTypeWrapper<NotificationInfo>;
  Organization: ResolverTypeWrapper<Organization>;
  OrlContext: ResolverTypeWrapper<OrlContext>;
  OrlCheckoutComponent: ResolverTypeWrapper<OrlCheckoutComponent>;
  SingleOrlItem: ResolverTypeWrapper<SingleOrlItem>;
  OrlRoom: ResolverTypeWrapper<OrlRoom>;
  AlternativeRoom: AlternativeRoom;
  BoardType: ResolverTypeWrapper<BoardType>;
  RoomType: ResolverTypeWrapper<RoomType>;
  OrlCheckoutStatus: OrlCheckoutStatus;
  PdpSearchCriteriaInput: PdpSearchCriteriaInput;
  PdpFilterCriteriaInput: PdpFilterCriteriaInput;
  GroupPageInput: GroupPageInput;
  PdpSearchControlCriteriaInput: PdpSearchControlCriteriaInput;
  OrlSortInput: OrlSortInput;
  OrlSortField: OrlSortField;
  OrlIncludedInPriceComponent: ResolverTypeWrapper<OrlIncludedInPriceComponent>;
  PdpPriceDateOverviewComponent: ResolverTypeWrapper<PdpPriceDateOverviewComponent>;
  PDOItem: ResolverTypeWrapper<PdoItem>;
  OrlPriceExplanationComponent: ResolverTypeWrapper<OrlPriceExplanationComponent>;
  OrlPriceExplanation: ResolverTypeWrapper<OrlPriceExplanation>;
  OrlPartnerPricing: OrlPartnerPricing;
  QuickHotelInfoComponent: ResolverTypeWrapper<QuickHotelInfoComponent>;
  QuickHotelInfoPoint: ResolverTypeWrapper<QuickHotelInfoPoint>;
  OrlSearchContainer: ResolverTypeWrapper<OrlSearchContainer>;
  OrlFlightAlternative: ResolverTypeWrapper<OrlFlightAlternative>;
  OrlGroupListComponent: ResolverTypeWrapper<OrlGroupListComponent>;
  GroupOrlItem: ResolverTypeWrapper<GroupOrlItem>;
  GroupOrlItemHeadingType: GroupOrlItemHeadingType;
  OrlSingleListComponent: ResolverTypeWrapper<OrlSingleListComponent>;
  PageB2BLoginData: ResolverTypeWrapper<PageB2BLoginData>;
  PageFooterData: ResolverTypeWrapper<
    Omit<PageFooterData, 'navigationMenu'> & {
      navigationMenu: Array<ResolversTypes['Navigable']>;
    }
  >;
  IconMenuItem: ResolverTypeWrapper<
    Omit<IconMenuItem, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  ContactInformation: ResolverTypeWrapper<ContactInformation>;
  Navigable: ResolversTypes['Menu'] | ResolversTypes['TextMenuItem'];
  Menu: ResolverTypeWrapper<
    Omit<Menu, 'target'> & { target?: Maybe<ResolversTypes['Link']> }
  >;
  TextMenuItem: ResolverTypeWrapper<
    Omit<TextMenuItem, 'link'> & { link: ResolversTypes['Link'] }
  >;
  IconType: IconType;
  ReasonsOfConfidence: ResolverTypeWrapper<
    Omit<ReasonsOfConfidence, 'link'> & { link?: Maybe<ResolversTypes['Link']> }
  >;
  PageHeaderData: ResolverTypeWrapper<
    Omit<PageHeaderData, 'navigationMenu'> & {
      navigationMenu: Array<ResolversTypes['Navigable']>;
    }
  >;
  StaticPageSearchCriteriaInput: StaticPageSearchCriteriaInput;
  PageNotFound404Data: ResolverTypeWrapper<
    Omit<PageNotFound404Data, 'components'> & {
      components: Array<ResolversTypes['StaticPageComponent']>;
    }
  >;
  PdpContext: ResolverTypeWrapper<
    Omit<PdpContext, 'dynamicComponents'> & {
      dynamicComponents: Array<ResolversTypes['PdpPageComponent']>;
    }
  >;
  PdpContainer: ResolverTypeWrapper<PdpContainer>;
  PdpDescriptionComponent: ResolverTypeWrapper<
    Omit<PdpDescriptionComponent, 'productStaticComponents'> & {
      productStaticComponents?: Maybe<
        Array<Maybe<ResolversTypes['DynamicComponent']>>
      >;
    }
  >;
  PdpInformationGroup: ResolverTypeWrapper<PdpInformationGroup>;
  PdpInformation: ResolverTypeWrapper<PdpInformation>;
  Co2Footprint: ResolverTypeWrapper<Co2Footprint>;
  PdpFeatureRating: ResolverTypeWrapper<PdpFeatureRating>;
  PdpDestinationInfoComponent: ResolverTypeWrapper<PdpDestinationInfoComponent>;
  DestinationFeatureGroup: ResolverTypeWrapper<DestinationFeatureGroup>;
  DestinationFeature: ResolverTypeWrapper<DestinationFeature>;
  PdpOverviewComponent: ResolverTypeWrapper<PdpOverviewComponent>;
  TripAdvisorRating: ResolverTypeWrapper<TripAdvisorRating>;
  PdpTripAdvisorComponent: ResolverTypeWrapper<PdpTripAdvisorComponent>;
  TripAdvisorReview: ResolverTypeWrapper<TripAdvisorReview>;
  TripAdvisorSubrating: ResolverTypeWrapper<TripAdvisorSubrating>;
  PdpHotelDestinationInfoComponent: ResolverTypeWrapper<PdpHotelDestinationInfoComponent>;
  HotelDestinationMapInfo: ResolverTypeWrapper<HotelDestinationMapInfo>;
  PdpPageComponent: ResolversTypes['PdpRecommendationGroup'];
  PdpRecommendationGroup: ResolverTypeWrapper<
    Omit<PdpRecommendationGroup, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['PdpRecommendationItem']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  PdpRecommendationItem:
    | ResolversTypes['BD4TravelRecommendation']
    | ResolversTypes['ProductRecommendationItem'];
  PdpMapComponent: ResolverTypeWrapper<PdpMapComponent>;
  PdpMapHotel: ResolverTypeWrapper<PdpMapHotel>;
  PdpMoreOffersButton: ResolverTypeWrapper<PdpMoreOffersButton>;
  PopUpMenuData: ResolverTypeWrapper<PopUpMenuData>;
  SearchOverlay: ResolverTypeWrapper<SearchOverlay>;
  SrlContext: ResolverTypeWrapper<
    Omit<SrlContext, 'dynamicComponents'> & {
      dynamicComponents: Array<ResolversTypes['SrlPageComponent']>;
    }
  >;
  SrlBD4TravelRecommendationsComponent: ResolverTypeWrapper<SrlBd4TravelRecommendationsComponent>;
  SrlSearchCriteriaInput: SrlSearchCriteriaInput;
  SrlSearchControlCriteriaInput: SrlSearchControlCriteriaInput;
  SrlPageComponent: ResolversTypes['SrlRecommendationGroupComponent'];
  SrlRecommendationGroupComponent: ResolverTypeWrapper<
    Omit<SrlRecommendationGroupComponent, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['SrlRecommendationItem']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  SrlRecommendationItem:
    | ResolversTypes['BD4TravelRecommendation']
    | ResolversTypes['GeoRecommendationItem']
    | ResolversTypes['MarketingRecommendationItem']
    | ResolversTypes['ProductRecommendationItem']
    | ResolversTypes['ThemeRecommendationItem'];
  SrlFullTextSearchResultComponent: ResolverTypeWrapper<SrlFullTextSearchResultComponent>;
  SrlFullTextSearchResult: ResolverTypeWrapper<SrlFullTextSearchResult>;
  FullTextSearchItem: ResolverTypeWrapper<FullTextSearchItem>;
  SrlProductInfoB2B: ResolverTypeWrapper<SrlProductInfoB2B>;
  SrlProductInfoB2C: ResolverTypeWrapper<SrlProductInfoB2C>;
  AgencySrlFullTextSearchResult: ResolverTypeWrapper<AgencySrlFullTextSearchResult>;
  AgencyFullTextSearchItem: ResolverTypeWrapper<AgencyFullTextSearchItem>;
  SearchTypeEnum: SearchTypeEnum;
  SrlGhostOffersComponent: ResolverTypeWrapper<SrlGhostOffersComponent>;
  SrlGhostOfferItem: ResolverTypeWrapper<SrlGhostOfferItem>;
  SrlResultContext: ResolverTypeWrapper<
    Omit<SrlResultContext, 'any' | 'group' | 'mapProductCards' | 'single'> & {
      any: ResolversTypes['SrlAnyResult'];
      group: ResolversTypes['SrlGroupOrEmptyResult'];
      mapProductCards: ResolversTypes['SrlSingleOrEmptyResult'];
      single: ResolversTypes['SrlSingleOrEmptyResult'];
    }
  >;
  SrlAnyResult:
    | ResolversTypes['SrlEmptyResult']
    | ResolversTypes['SrlGeoGroupResult']
    | ResolversTypes['SrlSingleResult'];
  SrlEmptyResult: ResolverTypeWrapper<SrlEmptyResult>;
  SrlGeoGroupResult: ResolverTypeWrapper<SrlGeoGroupResult>;
  SrlGeoItem: ResolverTypeWrapper<
    Omit<SrlGeoItem, 'previewItems'> & {
      previewItems?: Maybe<Array<ResolversTypes['SrlSingleItem']>>;
    }
  >;
  SrlSingleItem: ResolversTypes['NudgeItem'] | ResolversTypes['SrlProductItem'];
  NudgeItem: ResolverTypeWrapper<NudgeItem>;
  SrlProductItem: ResolverTypeWrapper<SrlProductItem>;
  SrlGeoGroupItem: ResolverTypeWrapper<
    Omit<SrlGeoGroupItem, 'items'> & {
      items: Array<ResolversTypes['SrlGroupItem']>;
    }
  >;
  SrlGroupItem: ResolversTypes['NudgeItem'] | ResolversTypes['SrlGeoItem'];
  SrlSingleResult: ResolverTypeWrapper<
    Omit<SrlSingleResult, 'items'> & {
      items: Array<ResolversTypes['SrlSingleItem']>;
    }
  >;
  SrlDestinationGroupsResult: ResolverTypeWrapper<SrlDestinationGroupsResult>;
  ContinentGroupItem: ResolverTypeWrapper<ContinentGroupItem>;
  CountryGroupItem: ResolverTypeWrapper<CountryGroupItem>;
  DestinationGroupItem: ResolverTypeWrapper<DestinationGroupItem>;
  SrlGroupOrEmptyResult:
    | ResolversTypes['SrlEmptyResult']
    | ResolversTypes['SrlGeoGroupResult'];
  SrlSingleOrEmptyResult:
    | ResolversTypes['SrlEmptyResult']
    | ResolversTypes['SrlSingleResult'];
  SrlSearchControlsContext: ResolverTypeWrapper<SrlSearchControlsContext>;
  SrlSortComponent: ResolverTypeWrapper<SrlSortComponent>;
  SrlPrimarySort: SrlPrimarySort;
  SrlSubGeoFilterComponent: ResolverTypeWrapper<SrlSubGeoFilterComponent>;
  SrlSubGeoItem: ResolverTypeWrapper<SrlSubGeoItem>;
  SrlStaticContent: ResolverTypeWrapper<SrlStaticContent>;
  StaticContext: ResolverTypeWrapper<
    Omit<StaticContext, 'components'> & {
      components: Array<ResolversTypes['StaticPageComponent']>;
    }
  >;
  ThemeContext: ResolverTypeWrapper<
    Omit<ThemeContext, 'dynamicContent' | 'searchControl'> & {
      dynamicContent: Array<ResolversTypes['ThemePageDynamicComponent']>;
      searchControl: ResolversTypes['SearchControlUnion'];
    }
  >;
  ThemeSearchCriteriaInput: ThemeSearchCriteriaInput;
  ThemePageDynamicComponent:
    | ResolversTypes['MediaGallery']
    | ResolversTypes['TextComponent']
    | ResolversTypes['ThemePageRecommendationGroup'];
  ThemePageRecommendationGroup: ResolverTypeWrapper<
    Omit<ThemePageRecommendationGroup, 'items' | 'tracking'> & {
      items: Array<ResolversTypes['ThemePageRecommendationItem']>;
      tracking?: Maybe<ResolversTypes['RecommendationTracking']>;
    }
  >;
  ThemePageRecommendationItem:
    | ResolversTypes['BD4TravelRecommendation']
    | ResolversTypes['BlogArticleRecommendationItem']
    | ResolversTypes['GeoRecommendationItem']
    | ResolversTypes['MarketingRecommendationItem']
    | ResolversTypes['ProductRecommendationItem']
    | ResolversTypes['ThemeRecommendationItem'];
  ThemeStaticContent: ResolverTypeWrapper<
    Omit<ThemeStaticContent, 'themeStaticComponents'> & {
      themeStaticComponents?: Maybe<
        Array<Maybe<ResolversTypes['DynamicComponent']>>
      >;
    }
  >;
  ThemeGeoContext: ResolverTypeWrapper<ThemeGeoContext>;
  ThemeGeoPage: ResolverTypeWrapper<ThemeGeoPage>;
  ThemeGeoProductRecommendation: ResolverTypeWrapper<ThemeGeoProductRecommendation>;
  ThemeGeoThemeRecommendation: ResolverTypeWrapper<ThemeGeoThemeRecommendation>;
  ThemeOverviewContext: ResolverTypeWrapper<
    Omit<ThemeOverviewContext, 'searchControl'> & {
      searchControl: ResolversTypes['SearchControlUnion'];
    }
  >;
  ThemeOverviewPage: ResolverTypeWrapper<ThemeOverviewPage>;
  ThemePreviewComponent: ResolverTypeWrapper<ThemePreviewComponent>;
  WishlistContext: ResolverTypeWrapper<WishlistContext>;
  WishlistSearchCriteriaInput: WishlistSearchCriteriaInput;
  WishlistFiltersCriteriaInput: WishlistFiltersCriteriaInput;
  WishlistSearchControlCriteriaInput: WishlistSearchControlCriteriaInput;
  WishlistActiveComponent: ResolverTypeWrapper<WishlistActiveComponent>;
  WishlistProductItem: ResolverTypeWrapper<WishlistProductItem>;
  WishlistOffer: ResolverTypeWrapper<WishlistOffer>;
  WishlistOfferRoom: ResolverTypeWrapper<WishlistOfferRoom>;
  WishlistProduct: ResolverTypeWrapper<WishlistProduct>;
  WishlistProductItemType: WishlistProductItemType;
  WishlistOverviewComponent: ResolverTypeWrapper<WishlistOverviewComponent>;
  Wishlist: ResolverTypeWrapper<Wishlist>;
  Mutation: ResolverTypeWrapper<{}>;
  AddBookingRequestInput: AddBookingRequestInput;
  AddBookingResponse: ResolverTypeWrapper<AddBookingResponse>;
  BookmarkRequestItemInput: BookmarkRequestItemInput;
  BookmarkBulkResponse: ResolverTypeWrapper<BookmarkBulkResponse>;
  FlightHistoryResponse: ResolverTypeWrapper<FlightHistoryResponse>;
  SrlHistoryResponse: ResolverTypeWrapper<SrlHistoryResponse>;
  WishlistAddMultipleToWishlistResponse: ResolverTypeWrapper<WishlistAddMultipleToWishlistResponse>;
  WishlistOfferIdInput: WishlistOfferIdInput;
  OrlHistoryResponse: ResolverTypeWrapper<OrlHistoryResponse>;
  SharedBookmarkGroupResponse: ResolverTypeWrapper<SharedBookmarkGroupResponse>;
  WishlistMutationResponse: ResolverTypeWrapper<WishlistMutationResponse>;
  WishlistAddToWishlistResponse: ResolverTypeWrapper<WishlistAddToWishlistResponse>;
  TravelCompanionInput: TravelCompanionInput;
  AddTravelCompanionResponse: ResolverTypeWrapper<AddTravelCompanionResponse>;
  AddUpsellTransferToBasketRequestInput: AddUpsellTransferToBasketRequestInput;
  AddUpsellTransferToBasketResponse: ResolverTypeWrapper<AddUpsellTransferToBasketResponse>;
  AddViewedHistoryResponse: ResolverTypeWrapper<AddViewedHistoryResponse>;
  AccountMutationResponse: ResolverTypeWrapper<AccountMutationResponse>;
  BookingCancellationResponse: ResolverTypeWrapper<
    Omit<BookingCancellationResponse, 'payload'> & {
      payload?: Maybe<ResolversTypes['BookingCancellationPayloadUnion']>;
    }
  >;
  BookingCancellationCode: BookingCancellationCode;
  BookingCancellationPayloadUnion: ResolversTypes['BookingCancellationConfirmationPayload'];
  BookingCancellationConfirmationPayload: ResolverTypeWrapper<BookingCancellationConfirmationPayload>;
  ChangePaymentMethodRequestInput: ChangePaymentMethodRequestInput;
  ChangePaymentMethodResponse: ResolverTypeWrapper<ChangePaymentMethodResponse>;
  BookmarkResponse: ResolverTypeWrapper<BookmarkResponse>;
  BookmarkGroupCreateResponse: ResolverTypeWrapper<BookmarkGroupCreateResponse>;
  CatalogOrderInput: CatalogOrderInput;
  CatalogOrderResponse: ResolverTypeWrapper<CatalogOrderResponse>;
  NewsletterSubscriptionInputInput: NewsletterSubscriptionInputInput;
  NewsletterSubscriptionResponse: ResolverTypeWrapper<NewsletterSubscriptionResponse>;
  NewsletterSubscription: ResolverTypeWrapper<NewsletterSubscription>;
  SubscriptionState: SubscriptionState;
  BookmarkGroupDeleteResponse: ResolverTypeWrapper<BookmarkGroupDeleteResponse>;
  DeleteHistoryResponse: ResolverTypeWrapper<DeleteHistoryResponse>;
  DeleteUpsellServiceFromBasketInput: DeleteUpsellServiceFromBasketInput;
  AccessBookingCancellationFinalizationResponse: ResolverTypeWrapper<AccessBookingCancellationFinalizationResponse>;
  BookingCancellationFinalizationResponse: ResolverTypeWrapper<BookingCancellationFinalizationResponse>;
  UserFinalizationRequestInput: UserFinalizationRequestInput;
  UserFinalizationResponse: ResolverTypeWrapper<UserFinalizationResponse>;
  HolidayFinderVoteRequestInput: HolidayFinderVoteRequestInput;
  HolidayFinderRatingInput: HolidayFinderRatingInput;
  AccessBookingCancellationResponse: ResolverTypeWrapper<AccessBookingCancellationResponse>;
  InitPaymentRequestInput: InitPaymentRequestInput;
  PaymentFraudInput: PaymentFraudInput;
  DeviceInput: DeviceInput;
  BrowserInput: BrowserInput;
  InitPaymentResponse: ResolverTypeWrapper<InitPaymentResponse>;
  BookmarkPdfUploadRequestInput: BookmarkPdfUploadRequestInput;
  BookmarkPdfUploadResponse: ResolverTypeWrapper<BookmarkPdfUploadResponse>;
  BookmarkPdfUploadInfo: ResolverTypeWrapper<BookmarkPdfUploadInfo>;
  UserRegistrationRequestInput: UserRegistrationRequestInput;
  UserRegistrationResponse: ResolverTypeWrapper<UserRegistrationResponse>;
  UserRegistrationCode: UserRegistrationCode;
  NewsletterUnsubscriptionInputInput: NewsletterUnsubscriptionInputInput;
  NewsletterType: NewsletterType;
  NewsletterUnsubscriptionResponse: ResolverTypeWrapper<NewsletterUnsubscriptionResponse>;
  BookmarkGroupRenameResponse: ResolverTypeWrapper<BookmarkGroupRenameResponse>;
  ResendTravelDocumentRequestInput: ResendTravelDocumentRequestInput;
  TravelDocumentType: TravelDocumentType;
  ResendTravelDocumentResponse: ResolverTypeWrapper<ResendTravelDocumentResponse>;
  EmailAdvertisingRevocationResponse: ResolverTypeWrapper<EmailAdvertisingRevocationResponse>;
  AgencyAppointmentInputInput: AgencyAppointmentInputInput;
  AgencyAppointmentCallbackTimeInput: AgencyAppointmentCallbackTimeInput;
  AgencyAppointmentCallbackTimeDayType: AgencyAppointmentCallbackTimeDayType;
  AgencyAppointmentResponse: ResolverTypeWrapper<AgencyAppointmentResponse>;
  ContactFormRequestInput: ContactFormRequestInput;
  ContactFormResponse: ResolverTypeWrapper<ContactFormResponse>;
  UpdateEmergencyPhoneRequestInput: UpdateEmergencyPhoneRequestInput;
  UpdateEmergencyPhoneResponse: ResolverTypeWrapper<UpdateEmergencyPhoneResponse>;
  AccountPersonalDataInput: AccountPersonalDataInput;
  AddressDetailsInputInput: AddressDetailsInputInput;
  PhoneDetailsInput: PhoneDetailsInput;
  ViewedProductHistoryResponse: ResolverTypeWrapper<ViewedProductHistoryResponse>;
  UNREPRESENTABLE: ResolverTypeWrapper<Scalars['UNREPRESENTABLE']>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Query: {};
  RequestContextInput: RequestContextInput;
  String: Scalars['String'];
  Int: Scalars['Int'];
  AccessBookingContext: AccessBookingContext;
  AccessBookingPageResponse: AccessBookingPageResponse;
  AccessBookingFlightInformation: AccessBookingFlightInformation;
  PageMetaData: PageMetaData;
  Boolean: Scalars['Boolean'];
  Map_String_StringScalar: Scalars['Map_String_StringScalar'];
  AccessBookingPageTransferInfo: Omit<
    AccessBookingPageTransferInfo,
    'buttonLink'
  > & { buttonLink?: Maybe<ResolversParentTypes['Link']> };
  ImageCriteriaInput: ImageCriteriaInput;
  Image: Image;
  ResizedImage: ResizedImage;
  Link:
    | ResolversParentTypes['ExternalLink']
    | ResolversParentTypes['InternalLink'];
  ExternalLink: ExternalLink;
  InternalLink: InternalLink;
  AccessBookingTravelDocument: AccessBookingTravelDocument;
  GetBookingByDataRequestInput: GetBookingByDataRequestInput;
  BookingDetailsResponse: BookingDetailsResponse;
  BookingDetails: BookingDetails;
  PaymentMethod: PaymentMethod;
  LocalDate: Scalars['LocalDate'];
  BookingItem: BookingItem;
  FlightDestinationInfo: FlightDestinationInfo;
  Airport: Airport;
  GeoCoordinates: GeoCoordinates;
  Float: Scalars['Float'];
  HotelDestinationInfo: HotelDestinationInfo;
  BreadcrumbsComponent: BreadcrumbsComponent;
  Breadcrumb: Breadcrumb;
  BookingExtraService: BookingExtraService;
  Traveller: Traveller;
  CancellationServiceInfo: CancellationServiceInfo;
  PaymentRecord: PaymentRecord;
  Price: Price;
  CarRentalDetails: CarRentalDetails;
  CarDetails: CarDetails;
  CarDriver: CarDriver;
  LocalTime: Scalars['LocalTime'];
  Agency: Omit<Agency, 'dynamicComponents'> & {
    dynamicComponents: Array<ResolversParentTypes['AgencyDynamicComponent']>;
  };
  AddressDetails: AddressDetails;
  AgencyDynamicComponent:
    | ResolversParentTypes['AgencyRecommendationGroup']
    | ResolversParentTypes['MediaGallery']
    | ResolversParentTypes['TextComponent'];
  AgencyRecommendationGroup: Omit<
    AgencyRecommendationGroup,
    'items' | 'tracking'
  > & {
    items: Array<ResolversParentTypes['AgencyRecommendationItemDeprecated']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  AgencyRecommendationItemDeprecated:
    | ResolversParentTypes['BD4TravelRecommendation']
    | ResolversParentTypes['BlogArticleRecommendationItem']
    | ResolversParentTypes['GeoRecommendationItem']
    | ResolversParentTypes['MarketingRecommendationItem']
    | ResolversParentTypes['ProductRecommendationItem']
    | ResolversParentTypes['ThemeRecommendationItem'];
  BD4TravelRecommendation: Bd4TravelRecommendation;
  DisrupterObject: DisrupterObject;
  ProductFeature: ProductFeature;
  ProductFeatureGroup: ProductFeatureGroup;
  GeoObject: GeoObject;
  Room: Room;
  WishlistItem: WishlistItem;
  BlogArticleRecommendationItem: Omit<BlogArticleRecommendationItem, 'link'> & {
    link: ResolversParentTypes['Link'];
  };
  GeoRecommendationItem: GeoRecommendationItem;
  MarketingRecommendationItem: Omit<
    MarketingRecommendationItem,
    'link' | 'preview'
  > & {
    link: ResolversParentTypes['Link'];
    preview: ResolversParentTypes['Media'];
  };
  Media:
    | ResolversParentTypes['ExternalMediaItem']
    | ResolversParentTypes['ImageMediaItem']
    | ResolversParentTypes['MusicMediaItem']
    | ResolversParentTypes['PdfMediaItem']
    | ResolversParentTypes['VideoMediaItem'];
  ExternalMediaItem: Omit<ExternalMediaItem, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  ImageMediaItem: Omit<ImageMediaItem, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  MusicMediaItem: Omit<MusicMediaItem, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  PdfMediaItem: Omit<PdfMediaItem, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  VideoMediaItem: Omit<VideoMediaItem, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  ProductRecommendationItem: ProductRecommendationItem;
  ThemeRecommendationItem: ThemeRecommendationItem;
  RecommendationTracking: ResolversParentTypes['BD4TravelRecommendationTracking'];
  BD4TravelRecommendationTracking: Bd4TravelRecommendationTracking;
  BD4TravelRecommendationInfo: Bd4TravelRecommendationInfo;
  MediaGallery: Omit<MediaGallery, 'mediaItems'> & {
    mediaItems: Array<ResolversParentTypes['Media']>;
  };
  TextComponent: TextComponent;
  OpeningHours: OpeningHours;
  Shift: Shift;
  RequestButtonComponent: RequestButtonComponent;
  Review: Review;
  SpecialOpeningHours: SpecialOpeningHours;
  AccountPersonalDataResponse: AccountPersonalDataResponse;
  PhoneDetails: PhoneDetails;
  DestinationInfo: DestinationInfo;
  FlightBookingDetails: FlightBookingDetails;
  Flight: Flight;
  FlightBaggageInfo: FlightBaggageInfo;
  FlightSegment: FlightSegment;
  Airline: Airline;
  HotelBookingDetails: HotelBookingDetails;
  CruiseInfo: CruiseInfo;
  BookingHotelRoom: BookingHotelRoom;
  BookingBoardType: BookingBoardType;
  BookingRoomType: BookingRoomType;
  RoundtripInfo: RoundtripInfo;
  PaymentCondition: PaymentCondition;
  RestPayment: RestPayment;
  PaymentInformationSection: PaymentInformationSection;
  BookingTransfer: BookingTransfer;
  TransferDate: TransferDate;
  TransferDetailInfo: Omit<TransferDetailInfo, 'from' | 'to'> & {
    from?: Maybe<ResolversParentTypes['TransferPointInfoUnion']>;
    to?: Maybe<ResolversParentTypes['TransferPointInfoUnion']>;
  };
  TransferPointInfoUnion:
    | ResolversParentTypes['TransferFlightInfo']
    | ResolversParentTypes['TransferHotelInfo'];
  TransferFlightInfo: TransferFlightInfo;
  TransferHotelInfo: TransferHotelInfo;
  TransferInfo: TransferInfo;
  TravellerInfo: TravellerInfo;
  BookingDetailsHotelOffer: BookingDetailsHotelOffer;
  BookingCancellationInfoResponse: BookingCancellationInfoResponse;
  AccountContext: AccountContext;
  AuthMethodListResponse: AuthMethodListResponse;
  AuthMethod: AuthMethod;
  AccountPage: AccountPage;
  CheckUpsellServiceFromBasketInput: CheckUpsellServiceFromBasketInput;
  CheckBasketResponse: CheckBasketResponse;
  UpsellTransferService: UpsellTransferService;
  AccountLoginPage: AccountLoginPage;
  AccountMyProfilePage: AccountMyProfilePage;
  PageInput: PageInput;
  BookingResponse: BookingResponse;
  Page: Page;
  TravelComponentResponse: TravelComponentResponse;
  TravelCompanion: TravelCompanion;
  UpsellTransferResponse: UpsellTransferResponse;
  UpsellTransfer: UpsellTransfer;
  UpsellTransferPrice: UpsellTransferPrice;
  UserParamsFromTokenResponse: UserParamsFromTokenResponse;
  HashMap_String_StringScalar: Scalars['HashMap_String_StringScalar'];
  AgencyContact: AgencyContact;
  AgencyContactPhone: AgencyContactPhone;
  AgencyOverviewPageContext: Omit<
    AgencyOverviewPageContext,
    'dynamicComponents'
  > & {
    dynamicComponents: Array<ResolversParentTypes['AgencyDynamicComponent']>;
  };
  GeoCoordinatesInput: GeoCoordinatesInput;
  AgencyAutocompleteCriteriaInputInput: AgencyAutocompleteCriteriaInputInput;
  AgencyAutocomplete: Omit<AgencyAutocomplete, 'objects'> & {
    objects: Array<ResolversParentTypes['AgencyAutocompleteSuggestion']>;
  };
  AgencyAutocompleteSuggestion:
    | ResolversParentTypes['Agency']
    | ResolversParentTypes['MapSuggestion'];
  MapSuggestion: MapSuggestion;
  AgencySearchCriteriaInput: AgencySearchCriteriaInput;
  TravellersInput: TravellersInput;
  TravellersDistributionInput: TravellersDistributionInput;
  RoomDistributionInput: RoomDistributionInput;
  TravelPeriodCriteriaInput: TravelPeriodCriteriaInput;
  AgencyOverviewInfoComponent: AgencyOverviewInfoComponent;
  BlogArticle: BlogArticle;
  EmployeeBox: Omit<EmployeeBox, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  EmployeeSocialProfile: Omit<EmployeeSocialProfile, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  BlogOverview: BlogOverview;
  BlogArticles: BlogArticles;
  BlogArticleItem: BlogArticleItem;
  BookingCancellationResultRequestInput: BookingCancellationResultRequestInput;
  BookingCancellationResultResponse: BookingCancellationResultResponse;
  BookingPaymentResultRequestInput: BookingPaymentResultRequestInput;
  BookingPaymentResultResponse: BookingPaymentResultResponse;
  BookmarkContext: BookmarkContext;
  BookmarkQuantityResponse: BookmarkQuantityResponse;
  BookmarkPage: Omit<BookmarkPage, 'buttonLink'> & {
    buttonLink?: Maybe<ResolversParentTypes['Link']>;
  };
  AllBookmarkGroupResponse: AllBookmarkGroupResponse;
  BookmarkGroup: BookmarkGroup;
  BookmarkItem: BookmarkItem;
  ProductInfoB2B: ProductInfoB2B;
  Booster: Booster;
  CatalogOverviewContext: CatalogOverviewContext;
  Catalog: Catalog;
  CatalogOverviewInfoComponent: CatalogOverviewInfoComponent;
  GlobalSearchStateInput: GlobalSearchStateInput;
  ComponentsQueryInput: ComponentsQueryInput;
  DynamicComponent:
    | ResolversParentTypes['AccordionComponent']
    | ResolversParentTypes['AgencyContactComponent']
    | ResolversParentTypes['AgencyRecommendation']
    | ResolversParentTypes['BD4TravelRecommendationGroup']
    | ResolversParentTypes['BenefitsComponent']
    | ResolversParentTypes['BlogArticleRecommendation']
    | ResolversParentTypes['BrandsComponent']
    | ResolversParentTypes['ClimateComponent']
    | ResolversParentTypes['ContactModuleComponent']
    | ResolversParentTypes['EmployeesComponent']
    | ResolversParentTypes['FaqComponent']
    | ResolversParentTypes['FlightWithoutPriceRecommendation']
    | ResolversParentTypes['FlightWithPriceRecommendation']
    | ResolversParentTypes['GeoChildrenComponent']
    | ResolversParentTypes['GeoGalleryComponent']
    | ResolversParentTypes['GeoRecommendation']
    | ResolversParentTypes['HeroMediaGallery']
    | ResolversParentTypes['ImageTextComponent']
    | ResolversParentTypes['LinkListComponent']
    | ResolversParentTypes['MarketingRecommendation']
    | ResolversParentTypes['MarketingTeaserComponent']
    | ResolversParentTypes['MediaGallery']
    | ResolversParentTypes['NewsArticlesComponent']
    | ResolversParentTypes['NewsletterSignupComponent']
    | ResolversParentTypes['OfferButtonComponent']
    | ResolversParentTypes['OffersMapPlaceholderComponent']
    | ResolversParentTypes['ProductGalleryWithMapComponent']
    | ResolversParentTypes['ProductRecommendation']
    | ResolversParentTypes['RequestButtonComponent']
    | ResolversParentTypes['ResortTopHotelsComponent']
    | ResolversParentTypes['SrlButtonPlaceholderComponent']
    | ResolversParentTypes['SrlMapPinsComponent']
    | ResolversParentTypes['StaticBlogRecommendation']
    | ResolversParentTypes['StaticGeoRecommendation']
    | ResolversParentTypes['StaticProductRecommendation']
    | ResolversParentTypes['StaticThemeRecommendation']
    | ResolversParentTypes['TextComponent']
    | ResolversParentTypes['ThemeRecommendation']
    | ResolversParentTypes['UspBoxesComponent'];
  AccordionComponent: Omit<AccordionComponent, 'components'> & {
    components: Array<ResolversParentTypes['DynamicComponent']>;
  };
  AgencyContactComponent: AgencyContactComponent;
  AgencyRecommendation: AgencyRecommendation;
  AgencyRecommendationItem: AgencyRecommendationItem;
  BD4TravelRecommendationGroup: Bd4TravelRecommendationGroup;
  BenefitsComponent: Omit<BenefitsComponent, 'buttonLink'> & {
    buttonLink: ResolversParentTypes['Link'];
  };
  BenefitItem: BenefitItem;
  BlogArticleRecommendation: BlogArticleRecommendation;
  BrandsComponent: BrandsComponent;
  BrandBox: Omit<BrandBox, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  ClimateComponent: ClimateComponent;
  ClimateChart: ClimateChart;
  ClimateChartEntry: ClimateChartEntry;
  ContactModuleComponent: Omit<ContactModuleComponent, 'contactTypeBases'> & {
    contactTypeBases: Array<ResolversParentTypes['ContactTypeUnion']>;
  };
  ContactTypeUnion:
    | ResolversParentTypes['ContactType']
    | ResolversParentTypes['ContactTypePhone'];
  ContactType: Omit<ContactType, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  ContactTypePhone: ContactTypePhone;
  EmployeesComponent: EmployeesComponent;
  FaqComponent: FaqComponent;
  FlightWithoutPriceRecommendation: FlightWithoutPriceRecommendation;
  FlightRecommendationWithoutPriceItem: Omit<
    FlightRecommendationWithoutPriceItem,
    'link' | 'media'
  > & {
    link: ResolversParentTypes['Link'];
    media: ResolversParentTypes['Media'];
  };
  FlightWithPriceRecommendation: FlightWithPriceRecommendation;
  FlightRecommendationWithPriceItem: Omit<
    FlightRecommendationWithPriceItem,
    'media'
  > & { media: ResolversParentTypes['Media'] };
  FlightOffer: FlightOffer;
  GeoChildrenComponent: GeoChildrenComponent;
  GeoChildComponent: GeoChildComponent;
  GeoGalleryComponent: Omit<GeoGalleryComponent, 'mediaItems'> & {
    mediaItems: Array<ResolversParentTypes['Media']>;
  };
  GeoRecommendation: GeoRecommendation;
  HeroMediaGallery: Omit<HeroMediaGallery, 'mediaItems'> & {
    mediaItems: Array<ResolversParentTypes['HeroMedia']>;
  };
  HeroMedia:
    | ResolversParentTypes['ImageMediaItem']
    | ResolversParentTypes['VideoMediaItem'];
  ImageTextComponent: ImageTextComponent;
  LinkListComponent: LinkListComponent;
  LinkListItem: Omit<LinkListItem, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  MarketingRecommendation: MarketingRecommendation;
  MarketingTeaserComponent: Omit<MarketingTeaserComponent, 'buttonLink'> & {
    buttonLink: ResolversParentTypes['Link'];
  };
  NewsArticlesComponent: Omit<NewsArticlesComponent, 'buttonLink'> & {
    buttonLink?: Maybe<ResolversParentTypes['Link']>;
  };
  NewsArticle: Omit<NewsArticle, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  NewsletterSignupComponent: NewsletterSignupComponent;
  OfferButtonComponent: OfferButtonComponent;
  OffersMapPlaceholderComponent: OffersMapPlaceholderComponent;
  ProductGalleryWithMapComponent: ProductGalleryWithMapComponent;
  ProductImageGroup: ProductImageGroup;
  ProductImageItem: ProductImageItem;
  RelatedRoundtrips: RelatedRoundtrips;
  RelatedRoundtripItem: RelatedRoundtripItem;
  RoutePoint: RoutePoint;
  ProductRecommendation: ProductRecommendation;
  ResortTopHotelsComponent: ResortTopHotelsComponent;
  SrlButtonPlaceholderComponent: SrlButtonPlaceholderComponent;
  SrlMapPinsComponent: SrlMapPinsComponent;
  SrlMapGeoPin: SrlMapGeoPin;
  SrlMapProductPin: SrlMapProductPin;
  StaticBlogRecommendation: StaticBlogRecommendation;
  StaticGeoRecommendation: StaticGeoRecommendation;
  StaticGeoRecommendationItem: StaticGeoRecommendationItem;
  StaticProductRecommendation: StaticProductRecommendation;
  StaticProductRecommendationItem: StaticProductRecommendationItem;
  ProductFilter: ProductFilter;
  StaticThemeRecommendation: StaticThemeRecommendation;
  StaticThemeRecommendationItem: StaticThemeRecommendationItem;
  ThemeRecommendation: ThemeRecommendation;
  UspBoxesComponent: UspBoxesComponent;
  UspBox: Omit<UspBox, 'link'> & { link?: Maybe<ResolversParentTypes['Link']> };
  ContactPageContext: Omit<ContactPageContext, 'dynamicComponents'> & {
    dynamicComponents: Array<ResolversParentTypes['ContactDynamicComponent']>;
  };
  ContactDynamicComponent:
    | ResolversParentTypes['ContactRecommendationGroup']
    | ResolversParentTypes['MediaGallery']
    | ResolversParentTypes['TextComponent'];
  ContactRecommendationGroup: Omit<
    ContactRecommendationGroup,
    'items' | 'tracking'
  > & {
    items: Array<ResolversParentTypes['ContactRecommendationItem']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  ContactRecommendationItem: ResolversParentTypes['MarketingRecommendationItem'];
  ContactForm: ContactForm;
  CookieDisclaimer: CookieDisclaimer;
  EmailAdvertisingRevocationPageContext: EmailAdvertisingRevocationPageContext;
  EmailAdvertisingStaticContent: EmailAdvertisingStaticContent;
  EmailAdvertisingStaticContentInfo: EmailAdvertisingStaticContentInfo;
  EmailAdvertisingGetEmailFromTokenResponse: EmailAdvertisingGetEmailFromTokenResponse;
  EmailAdvertisingGetTokenFromEmailResponse: EmailAdvertisingGetTokenFromEmailResponse;
  EmailAdvertisingRevocationConfirmationPageContext: Omit<
    EmailAdvertisingRevocationConfirmationPageContext,
    'dynamicComponents'
  > & {
    dynamicComponents: Array<
      ResolversParentTypes['EmailAdvertisingDynamicComponent']
    >;
  };
  EmailAdvertisingDynamicComponent: ResolversParentTypes['EmailAdvertisingRecommendationGroup'];
  EmailAdvertisingRecommendationGroup: Omit<
    EmailAdvertisingRecommendationGroup,
    'items' | 'tracking'
  > & {
    items: Array<ResolversParentTypes['EmailAdvertisingRecommendationItem']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  EmailAdvertisingRecommendationItem:
    | ResolversParentTypes['GeoRecommendationItem']
    | ResolversParentTypes['ThemeRecommendationItem'];
  FlightHomeContext: Omit<
    FlightHomeContext,
    'dynamicComponents' | 'searchControl'
  > & {
    dynamicComponents: Array<
      ResolversParentTypes['FlightHomeDynamicComponent']
    >;
    searchControl: ResolversParentTypes['SearchControlUnion'];
  };
  AutocompleteCriteriaInput: AutocompleteCriteriaInput;
  AutocompleteComponent: AutocompleteComponent;
  TravelDestination: TravelDestination;
  GeoLocation: GeoLocation;
  ThemeDestinationInformation: ThemeDestinationInformation;
  FlightHomeSearchCriteriaInput: FlightHomeSearchCriteriaInput;
  FlightSearchControlComponentInput: FlightSearchControlComponentInput;
  FlightPartitionInput: FlightPartitionInput;
  AirportInput: AirportInput;
  ExactTravelPeriodInput: ExactTravelPeriodInput;
  FlightHomeDynamicComponent:
    | ResolversParentTypes['FlightHomeRecommendationGroup']
    | ResolversParentTypes['MediaGallery']
    | ResolversParentTypes['TextComponent'];
  FlightHomeRecommendationGroup: Omit<
    FlightHomeRecommendationGroup,
    'items' | 'tracking'
  > & {
    items: Array<ResolversParentTypes['FlightHomeRecommendationItem']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  FlightHomeRecommendationItem:
    | ResolversParentTypes['BD4TravelRecommendation']
    | ResolversParentTypes['FlightRecommendationWithoutPriceItem']
    | ResolversParentTypes['FlightRecommendationWithPriceItem']
    | ResolversParentTypes['GeoRecommendationItem']
    | ResolversParentTypes['ThemeRecommendationItem'];
  SearchControlUnion:
    | ResolversParentTypes['FlightSearchControlComponent']
    | ResolversParentTypes['SearchControlComponent'];
  FlightSearchControlComponent: FlightSearchControlComponent;
  FlightPartition: FlightPartition;
  ExactTravelPeriod: ExactTravelPeriod;
  Travellers: Travellers;
  SearchControlComponent: Omit<SearchControlComponent, 'travelPeriod'> & {
    travelPeriod: ResolversParentTypes['TravelPeriodUnion'];
  };
  TravelPeriodComponent: TravelPeriodComponent;
  FlexibleTravelPeriod: FlexibleTravelPeriod;
  TravellersDistribution: TravellersDistribution;
  RoomDistribution: RoomDistribution;
  TravelPeriodUnion:
    | ResolversParentTypes['ExactTravelPeriod']
    | ResolversParentTypes['FlexibleTravelPeriod'];
  FlightSrlContext: Omit<FlightSrlContext, 'dynamicComponents'> & {
    dynamicComponents: Array<ResolversParentTypes['FlightSrlDynamicComponent']>;
  };
  FlightCheckoutComponent: FlightCheckoutComponent;
  FlightSrlContainer: FlightSrlContainer;
  FlightSrlSearchCriteriaInput: FlightSrlSearchCriteriaInput;
  FlightSrlFilterCriteriaInput: FlightSrlFilterCriteriaInput;
  FlightSortInput: FlightSortInput;
  FlightSrlComponent: FlightSrlComponent;
  FlightStopOverDuration: FlightStopOverDuration;
  FlightSrlDynamicComponent: ResolversParentTypes['FlightSrlRecommendationGroup'];
  FlightSrlRecommendationGroup: Omit<
    FlightSrlRecommendationGroup,
    'items' | 'tracking'
  > & {
    items: Array<ResolversParentTypes['FlightSrlRecommendationItem']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  FlightSrlRecommendationItem: ResolversParentTypes['BD4TravelRecommendation'];
  FilterComponent: FilterComponent;
  CheckboxFilterComponent: CheckboxFilterComponent;
  FilterItem: FilterItem;
  RadiobuttonFilterComponent: RadiobuttonFilterComponent;
  SliderFilterComponent: SliderFilterComponent;
  SingleValueFilterComponent: SingleValueFilterComponent;
  DoubleRangeSliderFilterComponent: DoubleRangeSliderFilterComponent;
  RadiusFilterComponent: RadiusFilterComponent;
  WaitingScreen: WaitingScreen;
  GeoCriteriaInput: GeoCriteriaInput;
  SrlFilterCriteriaInput: SrlFilterCriteriaInput;
  SrlRadiusCriteriaInput: SrlRadiusCriteriaInput;
  TravelDestinationInput: TravelDestinationInput;
  GeoLocationInput: GeoLocationInput;
  GeoObjectInput: GeoObjectInput;
  ThemeDestinationInformationInput: ThemeDestinationInformationInput;
  GeoContext: Omit<
    GeoContext,
    'children' | 'dynamicComponents' | 'geoStaticComponents' | 'searchControl'
  > & {
    children: ResolversParentTypes['GeoChildrenComponentInterface'];
    dynamicComponents: Array<ResolversParentTypes['GeoObjectPageComponent']>;
    geoStaticComponents?: Maybe<
      Array<Maybe<ResolversParentTypes['DynamicComponent']>>
    >;
    searchControl: ResolversParentTypes['SearchControlUnion'];
  };
  GeoChildrenComponentInterface:
    | ResolversParentTypes['GeoChildrenComponent']
    | ResolversParentTypes['ResortTopHotelsComponent'];
  GeoChildrenOffersComponent: GeoChildrenOffersComponent;
  GeoChildrenOffer: GeoChildrenOffer;
  GeoOfferInfo: GeoOfferInfo;
  GeoDefaultGeoRecommendationsComponent: GeoDefaultGeoRecommendationsComponent;
  GeoObjectPageComponent: ResolversParentTypes['GeoObjectRecommendationGroupComponent'];
  GeoObjectRecommendationGroupComponent: Omit<
    GeoObjectRecommendationGroupComponent,
    'items' | 'tracking'
  > & {
    items: Array<ResolversParentTypes['GeoObjectRecommendationItem']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  GeoObjectRecommendationItem:
    | ResolversParentTypes['BD4TravelRecommendation']
    | ResolversParentTypes['BlogArticleRecommendationItem']
    | ResolversParentTypes['GeoRecommendationItem']
    | ResolversParentTypes['MarketingRecommendationItem']
    | ResolversParentTypes['ProductRecommendationItem']
    | ResolversParentTypes['ThemeRecommendationItem'];
  GeoProduct: GeoProduct;
  MainFilterComponent: MainFilterComponent;
  SubGeoFilterComponent: SubGeoFilterComponent;
  SubGeoItem: SubGeoItem;
  GeoInfoComponent: GeoInfoComponent;
  GeoFeatureGroup: GeoFeatureGroup;
  GeoFeature: GeoFeature;
  AccordionItem: AccordionItem;
  InpageNavigationItem: InpageNavigationItem;
  ProductImageGallery: ProductImageGallery;
  ProductImage: ProductImage;
  SeoChildrenComponent: SeoChildrenComponent;
  SeoGeoChildren: SeoGeoChildren;
  GeoSiblingsComponent: GeoSiblingsComponent;
  GeoTheme: GeoTheme;
  GeoOverviewContext: Omit<GeoOverviewContext, 'dynamicComponents'> & {
    dynamicComponents: Array<ResolversParentTypes['GeoOverviewPageComponent']>;
  };
  GeoOverviewChildComponent: GeoOverviewChildComponent;
  GeoOverviewChildOfferInfo: GeoOverviewChildOfferInfo;
  GeoOverviewPageComponent: ResolversParentTypes['GeoOverviewRecommendationGroupComponent'];
  GeoOverviewRecommendationGroupComponent: Omit<
    GeoOverviewRecommendationGroupComponent,
    'items' | 'tracking'
  > & {
    items: Array<ResolversParentTypes['GeoOverviewRecommendationItem']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  GeoOverviewRecommendationItem:
    | ResolversParentTypes['GeoRecommendationItem']
    | ResolversParentTypes['ThemeRecommendationItem'];
  GeoHierarchyComponent: GeoHierarchyComponent;
  GeoHierarchyContinent: GeoHierarchyContinent;
  GeoHierarchyCountry: GeoHierarchyCountry;
  HelpOverlayData: Omit<HelpOverlayData, 'helpOverlayBoxes'> & {
    helpOverlayBoxes?: Maybe<Array<ResolversParentTypes['HelpOverlayBoxBase']>>;
  };
  HelpOverlayBoxBase:
    | ResolversParentTypes['HelpOverlayBox']
    | ResolversParentTypes['HelpOverlayBoxChat']
    | ResolversParentTypes['HelpOverlayBoxContact'];
  HelpOverlayBox: Omit<HelpOverlayBox, 'targetUrl'> & {
    targetUrl?: Maybe<ResolversParentTypes['Link']>;
  };
  HelpOverlayBoxChat: Omit<HelpOverlayBoxChat, 'targetUrl'> & {
    targetUrl?: Maybe<ResolversParentTypes['Link']>;
  };
  HelpOverlayBoxContact: Omit<HelpOverlayBoxContact, 'targetUrl'> & {
    targetUrl?: Maybe<ResolversParentTypes['Link']>;
  };
  HistoryContext: Omit<
    HistoryContext,
    'recentSearches' | 'recentSearchesByTravelType'
  > & {
    recentSearches: Array<ResolversParentTypes['HistoryRecordUnion']>;
    recentSearchesByTravelType: Array<
      ResolversParentTypes['HistoryRecordUnion']
    >;
  };
  LastSeenRequestInput: LastSeenRequestInput;
  LastSeenResponse: LastSeenResponse;
  LastSeenItem: LastSeenItem;
  HistoryFlightRecord: HistoryFlightRecord;
  HistoryFlightResponse: HistoryFlightResponse;
  HistoryUserRecord: HistoryUserRecord;
  HistoryUserResponse: HistoryUserResponse;
  HistoryProductRecord: HistoryProductRecord;
  HistoryProductResponse: HistoryProductResponse;
  HistoryRecordUnion:
    | ResolversParentTypes['HistoryFlightRecord']
    | ResolversParentTypes['HistorySrlRecord'];
  HistorySrlRecord: HistorySrlRecord;
  HistoryUnionResponse: Omit<HistoryUnionResponse, 'recordUnions'> & {
    recordUnions?: Maybe<Array<ResolversParentTypes['HistoryRecordUnion']>>;
  };
  HistorySrlResponse: HistorySrlResponse;
  HolidayFinderPageContext: HolidayFinderPageContext;
  HolidayFinderStartVotingRequestInput: HolidayFinderStartVotingRequestInput;
  HolidayFinderVotingResponse: HolidayFinderVotingResponse;
  HolidayFinderInfo: HolidayFinderInfo;
  HolidayFinderProduct: HolidayFinderProduct;
  HolidayFinderOffer: HolidayFinderOffer;
  HolidayFinderTracking: HolidayFinderTracking;
  HolidayFinderLandingPage: HolidayFinderLandingPage;
  HolidayFinderFinishVotingRequestInput: HolidayFinderFinishVotingRequestInput;
  HolidayFinderPage: HolidayFinderPage;
  HolidayFinderSearchRequestInput: HolidayFinderSearchRequestInput;
  HolidayFinderSearchResponse: HolidayFinderSearchResponse;
  HomeContext: Omit<HomeContext, 'dynamicComponents' | 'searchControl'> & {
    dynamicComponents: Array<ResolversParentTypes['HomeDynamicComponent']>;
    searchControl: ResolversParentTypes['SearchControlUnion'];
  };
  HomeSearchCriteriaInput: HomeSearchCriteriaInput;
  HomeSearchControlCriteriaInput: HomeSearchControlCriteriaInput;
  HomeDynamicComponent:
    | ResolversParentTypes['BrandsComponent']
    | ResolversParentTypes['EmployeesComponent']
    | ResolversParentTypes['FaqComponent']
    | ResolversParentTypes['HeroMediaGallery']
    | ResolversParentTypes['HomeRecommendationGroup']
    | ResolversParentTypes['LinkListComponent']
    | ResolversParentTypes['MediaGallery']
    | ResolversParentTypes['NewsArticlesComponent']
    | ResolversParentTypes['TextComponent']
    | ResolversParentTypes['UspBoxesComponent'];
  HomeRecommendationGroup: Omit<
    HomeRecommendationGroup,
    'items' | 'tracking'
  > & {
    items: Array<ResolversParentTypes['HomeRecommendationItem']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  HomeRecommendationItem:
    | ResolversParentTypes['BD4TravelRecommendation']
    | ResolversParentTypes['GeoRecommendationItem']
    | ResolversParentTypes['MarketingRecommendationItem']
    | ResolversParentTypes['ProductRecommendationItem']
    | ResolversParentTypes['ThemeRecommendationItem'];
  MoreVisibilityStaticComponent: MoreVisibilityStaticComponent;
  MoreVisibleItem: Omit<MoreVisibleItem, 'travelPeriod'> & {
    travelPeriod: ResolversParentTypes['TravelPeriodUnion'];
  };
  HomeStaticContent: HomeStaticContent;
  HomeTitle: HomeTitle;
  HomeTotalProductCount: HomeTotalProductCount;
  NewsArticlePage: Omit<NewsArticlePage, 'components'> & {
    components: Array<ResolversParentTypes['StaticPageComponent']>;
  };
  BusinessUnit: BusinessUnit;
  StaticPageComponent:
    | ResolversParentTypes['BrandsComponent']
    | ResolversParentTypes['EmployeesComponent']
    | ResolversParentTypes['FaqComponent']
    | ResolversParentTypes['HeroMediaGallery']
    | ResolversParentTypes['LinkListComponent']
    | ResolversParentTypes['MediaGallery']
    | ResolversParentTypes['NewsArticlesComponent']
    | ResolversParentTypes['StaticRecommendationGroup']
    | ResolversParentTypes['TextComponent']
    | ResolversParentTypes['UspBoxesComponent'];
  StaticRecommendationGroup: Omit<
    StaticRecommendationGroup,
    'items' | 'tracking'
  > & {
    items: Array<ResolversParentTypes['StaticRecommendationItem']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  StaticRecommendationItem:
    | ResolversParentTypes['BD4TravelRecommendation']
    | ResolversParentTypes['MarketingRecommendationItem'];
  NewsArticlesOverviewPage: Omit<NewsArticlesOverviewPage, 'components'> & {
    components: Array<ResolversParentTypes['StaticPageComponent']>;
  };
  NewsArticlesFilter: NewsArticlesFilter;
  NewsArticlesSearchCriteriaInput: NewsArticlesSearchCriteriaInput;
  NewsArticlesOverview: NewsArticlesOverview;
  NewsletterConfirmationContext: Omit<
    NewsletterConfirmationContext,
    'dynamicContent'
  > & {
    dynamicContent: Array<ResolversParentTypes['NewsletterDynamicComponent']>;
  };
  NewsletterInfoComponent: NewsletterInfoComponent;
  NewsletterDynamicComponent: ResolversParentTypes['NewsletterRecommendationGroup'];
  NewsletterRecommendationGroup: Omit<
    NewsletterRecommendationGroup,
    'items' | 'tracking'
  > & {
    items: Array<ResolversParentTypes['NewsletterRecommendationItem']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  NewsletterRecommendationItem:
    | ResolversParentTypes['GeoRecommendationItem']
    | ResolversParentTypes['ThemeRecommendationItem'];
  NewsletterFinalizationContext: Omit<
    NewsletterFinalizationContext,
    'dynamicContent'
  > & {
    dynamicContent: Array<ResolversParentTypes['NewsletterDynamicComponent']>;
  };
  NewsletterSubscriptionContext: NewsletterSubscriptionContext;
  NewsletterSubscriptionTokenInputInput: NewsletterSubscriptionTokenInputInput;
  NewsletterSubscriptionTokenResponse: NewsletterSubscriptionTokenResponse;
  NewsletterUnsubscriptionContext: NewsletterUnsubscriptionContext;
  NewsletterGetEmailFromTokenResponse: NewsletterGetEmailFromTokenResponse;
  NewsletterGetTokenFromEmailResponse: NewsletterGetTokenFromEmailResponse;
  NewsletterUnsubscriptionFinalizationContext: Omit<
    NewsletterUnsubscriptionFinalizationContext,
    'dynamicContent'
  > & {
    dynamicContent: Array<ResolversParentTypes['NewsletterDynamicComponent']>;
  };
  Notification: Notification;
  NotificationInfo: NotificationInfo;
  Organization: Organization;
  OrlContext: OrlContext;
  OrlCheckoutComponent: OrlCheckoutComponent;
  SingleOrlItem: SingleOrlItem;
  OrlRoom: OrlRoom;
  BoardType: BoardType;
  RoomType: RoomType;
  PdpSearchCriteriaInput: PdpSearchCriteriaInput;
  PdpFilterCriteriaInput: PdpFilterCriteriaInput;
  GroupPageInput: GroupPageInput;
  PdpSearchControlCriteriaInput: PdpSearchControlCriteriaInput;
  OrlSortInput: OrlSortInput;
  OrlIncludedInPriceComponent: OrlIncludedInPriceComponent;
  PdpPriceDateOverviewComponent: PdpPriceDateOverviewComponent;
  PDOItem: PdoItem;
  OrlPriceExplanationComponent: OrlPriceExplanationComponent;
  OrlPriceExplanation: OrlPriceExplanation;
  QuickHotelInfoComponent: QuickHotelInfoComponent;
  QuickHotelInfoPoint: QuickHotelInfoPoint;
  OrlSearchContainer: OrlSearchContainer;
  OrlFlightAlternative: OrlFlightAlternative;
  OrlGroupListComponent: OrlGroupListComponent;
  GroupOrlItem: GroupOrlItem;
  OrlSingleListComponent: OrlSingleListComponent;
  PageB2BLoginData: PageB2BLoginData;
  PageFooterData: Omit<PageFooterData, 'navigationMenu'> & {
    navigationMenu: Array<ResolversParentTypes['Navigable']>;
  };
  IconMenuItem: Omit<IconMenuItem, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  ContactInformation: ContactInformation;
  Navigable:
    | ResolversParentTypes['Menu']
    | ResolversParentTypes['TextMenuItem'];
  Menu: Omit<Menu, 'target'> & { target?: Maybe<ResolversParentTypes['Link']> };
  TextMenuItem: Omit<TextMenuItem, 'link'> & {
    link: ResolversParentTypes['Link'];
  };
  ReasonsOfConfidence: Omit<ReasonsOfConfidence, 'link'> & {
    link?: Maybe<ResolversParentTypes['Link']>;
  };
  PageHeaderData: Omit<PageHeaderData, 'navigationMenu'> & {
    navigationMenu: Array<ResolversParentTypes['Navigable']>;
  };
  StaticPageSearchCriteriaInput: StaticPageSearchCriteriaInput;
  PageNotFound404Data: Omit<PageNotFound404Data, 'components'> & {
    components: Array<ResolversParentTypes['StaticPageComponent']>;
  };
  PdpContext: Omit<PdpContext, 'dynamicComponents'> & {
    dynamicComponents: Array<ResolversParentTypes['PdpPageComponent']>;
  };
  PdpContainer: PdpContainer;
  PdpDescriptionComponent: Omit<
    PdpDescriptionComponent,
    'productStaticComponents'
  > & {
    productStaticComponents?: Maybe<
      Array<Maybe<ResolversParentTypes['DynamicComponent']>>
    >;
  };
  PdpInformationGroup: PdpInformationGroup;
  PdpInformation: PdpInformation;
  Co2Footprint: Co2Footprint;
  PdpFeatureRating: PdpFeatureRating;
  PdpDestinationInfoComponent: PdpDestinationInfoComponent;
  DestinationFeatureGroup: DestinationFeatureGroup;
  DestinationFeature: DestinationFeature;
  PdpOverviewComponent: PdpOverviewComponent;
  TripAdvisorRating: TripAdvisorRating;
  PdpTripAdvisorComponent: PdpTripAdvisorComponent;
  TripAdvisorReview: TripAdvisorReview;
  TripAdvisorSubrating: TripAdvisorSubrating;
  PdpHotelDestinationInfoComponent: PdpHotelDestinationInfoComponent;
  HotelDestinationMapInfo: HotelDestinationMapInfo;
  PdpPageComponent: ResolversParentTypes['PdpRecommendationGroup'];
  PdpRecommendationGroup: Omit<PdpRecommendationGroup, 'items' | 'tracking'> & {
    items: Array<ResolversParentTypes['PdpRecommendationItem']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  PdpRecommendationItem:
    | ResolversParentTypes['BD4TravelRecommendation']
    | ResolversParentTypes['ProductRecommendationItem'];
  PdpMapComponent: PdpMapComponent;
  PdpMapHotel: PdpMapHotel;
  PdpMoreOffersButton: PdpMoreOffersButton;
  PopUpMenuData: PopUpMenuData;
  SearchOverlay: SearchOverlay;
  SrlContext: Omit<SrlContext, 'dynamicComponents'> & {
    dynamicComponents: Array<ResolversParentTypes['SrlPageComponent']>;
  };
  SrlBD4TravelRecommendationsComponent: SrlBd4TravelRecommendationsComponent;
  SrlSearchCriteriaInput: SrlSearchCriteriaInput;
  SrlSearchControlCriteriaInput: SrlSearchControlCriteriaInput;
  SrlPageComponent: ResolversParentTypes['SrlRecommendationGroupComponent'];
  SrlRecommendationGroupComponent: Omit<
    SrlRecommendationGroupComponent,
    'items' | 'tracking'
  > & {
    items: Array<ResolversParentTypes['SrlRecommendationItem']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  SrlRecommendationItem:
    | ResolversParentTypes['BD4TravelRecommendation']
    | ResolversParentTypes['GeoRecommendationItem']
    | ResolversParentTypes['MarketingRecommendationItem']
    | ResolversParentTypes['ProductRecommendationItem']
    | ResolversParentTypes['ThemeRecommendationItem'];
  SrlFullTextSearchResultComponent: SrlFullTextSearchResultComponent;
  SrlFullTextSearchResult: SrlFullTextSearchResult;
  FullTextSearchItem: FullTextSearchItem;
  SrlProductInfoB2B: SrlProductInfoB2B;
  SrlProductInfoB2C: SrlProductInfoB2C;
  AgencySrlFullTextSearchResult: AgencySrlFullTextSearchResult;
  AgencyFullTextSearchItem: AgencyFullTextSearchItem;
  SrlGhostOffersComponent: SrlGhostOffersComponent;
  SrlGhostOfferItem: SrlGhostOfferItem;
  SrlResultContext: Omit<
    SrlResultContext,
    'any' | 'group' | 'mapProductCards' | 'single'
  > & {
    any: ResolversParentTypes['SrlAnyResult'];
    group: ResolversParentTypes['SrlGroupOrEmptyResult'];
    mapProductCards: ResolversParentTypes['SrlSingleOrEmptyResult'];
    single: ResolversParentTypes['SrlSingleOrEmptyResult'];
  };
  SrlAnyResult:
    | ResolversParentTypes['SrlEmptyResult']
    | ResolversParentTypes['SrlGeoGroupResult']
    | ResolversParentTypes['SrlSingleResult'];
  SrlEmptyResult: SrlEmptyResult;
  SrlGeoGroupResult: SrlGeoGroupResult;
  SrlGeoItem: Omit<SrlGeoItem, 'previewItems'> & {
    previewItems?: Maybe<Array<ResolversParentTypes['SrlSingleItem']>>;
  };
  SrlSingleItem:
    | ResolversParentTypes['NudgeItem']
    | ResolversParentTypes['SrlProductItem'];
  NudgeItem: NudgeItem;
  SrlProductItem: SrlProductItem;
  SrlGeoGroupItem: Omit<SrlGeoGroupItem, 'items'> & {
    items: Array<ResolversParentTypes['SrlGroupItem']>;
  };
  SrlGroupItem:
    | ResolversParentTypes['NudgeItem']
    | ResolversParentTypes['SrlGeoItem'];
  SrlSingleResult: Omit<SrlSingleResult, 'items'> & {
    items: Array<ResolversParentTypes['SrlSingleItem']>;
  };
  SrlDestinationGroupsResult: SrlDestinationGroupsResult;
  ContinentGroupItem: ContinentGroupItem;
  CountryGroupItem: CountryGroupItem;
  DestinationGroupItem: DestinationGroupItem;
  SrlGroupOrEmptyResult:
    | ResolversParentTypes['SrlEmptyResult']
    | ResolversParentTypes['SrlGeoGroupResult'];
  SrlSingleOrEmptyResult:
    | ResolversParentTypes['SrlEmptyResult']
    | ResolversParentTypes['SrlSingleResult'];
  SrlSearchControlsContext: SrlSearchControlsContext;
  SrlSortComponent: SrlSortComponent;
  SrlSubGeoFilterComponent: SrlSubGeoFilterComponent;
  SrlSubGeoItem: SrlSubGeoItem;
  SrlStaticContent: SrlStaticContent;
  StaticContext: Omit<StaticContext, 'components'> & {
    components: Array<ResolversParentTypes['StaticPageComponent']>;
  };
  ThemeContext: Omit<ThemeContext, 'dynamicContent' | 'searchControl'> & {
    dynamicContent: Array<ResolversParentTypes['ThemePageDynamicComponent']>;
    searchControl: ResolversParentTypes['SearchControlUnion'];
  };
  ThemeSearchCriteriaInput: ThemeSearchCriteriaInput;
  ThemePageDynamicComponent:
    | ResolversParentTypes['MediaGallery']
    | ResolversParentTypes['TextComponent']
    | ResolversParentTypes['ThemePageRecommendationGroup'];
  ThemePageRecommendationGroup: Omit<
    ThemePageRecommendationGroup,
    'items' | 'tracking'
  > & {
    items: Array<ResolversParentTypes['ThemePageRecommendationItem']>;
    tracking?: Maybe<ResolversParentTypes['RecommendationTracking']>;
  };
  ThemePageRecommendationItem:
    | ResolversParentTypes['BD4TravelRecommendation']
    | ResolversParentTypes['BlogArticleRecommendationItem']
    | ResolversParentTypes['GeoRecommendationItem']
    | ResolversParentTypes['MarketingRecommendationItem']
    | ResolversParentTypes['ProductRecommendationItem']
    | ResolversParentTypes['ThemeRecommendationItem'];
  ThemeStaticContent: Omit<ThemeStaticContent, 'themeStaticComponents'> & {
    themeStaticComponents?: Maybe<
      Array<Maybe<ResolversParentTypes['DynamicComponent']>>
    >;
  };
  ThemeGeoContext: ThemeGeoContext;
  ThemeGeoPage: ThemeGeoPage;
  ThemeGeoProductRecommendation: ThemeGeoProductRecommendation;
  ThemeGeoThemeRecommendation: ThemeGeoThemeRecommendation;
  ThemeOverviewContext: Omit<ThemeOverviewContext, 'searchControl'> & {
    searchControl: ResolversParentTypes['SearchControlUnion'];
  };
  ThemeOverviewPage: ThemeOverviewPage;
  ThemePreviewComponent: ThemePreviewComponent;
  WishlistContext: WishlistContext;
  WishlistSearchCriteriaInput: WishlistSearchCriteriaInput;
  WishlistFiltersCriteriaInput: WishlistFiltersCriteriaInput;
  WishlistSearchControlCriteriaInput: WishlistSearchControlCriteriaInput;
  WishlistActiveComponent: WishlistActiveComponent;
  WishlistProductItem: WishlistProductItem;
  WishlistOffer: WishlistOffer;
  WishlistOfferRoom: WishlistOfferRoom;
  WishlistProduct: WishlistProduct;
  WishlistOverviewComponent: WishlistOverviewComponent;
  Wishlist: Wishlist;
  Mutation: {};
  AddBookingRequestInput: AddBookingRequestInput;
  AddBookingResponse: AddBookingResponse;
  BookmarkRequestItemInput: BookmarkRequestItemInput;
  BookmarkBulkResponse: BookmarkBulkResponse;
  FlightHistoryResponse: FlightHistoryResponse;
  SrlHistoryResponse: SrlHistoryResponse;
  WishlistAddMultipleToWishlistResponse: WishlistAddMultipleToWishlistResponse;
  WishlistOfferIdInput: WishlistOfferIdInput;
  OrlHistoryResponse: OrlHistoryResponse;
  SharedBookmarkGroupResponse: SharedBookmarkGroupResponse;
  WishlistMutationResponse: WishlistMutationResponse;
  WishlistAddToWishlistResponse: WishlistAddToWishlistResponse;
  TravelCompanionInput: TravelCompanionInput;
  AddTravelCompanionResponse: AddTravelCompanionResponse;
  AddUpsellTransferToBasketRequestInput: AddUpsellTransferToBasketRequestInput;
  AddUpsellTransferToBasketResponse: AddUpsellTransferToBasketResponse;
  AddViewedHistoryResponse: AddViewedHistoryResponse;
  AccountMutationResponse: AccountMutationResponse;
  BookingCancellationResponse: Omit<BookingCancellationResponse, 'payload'> & {
    payload?: Maybe<ResolversParentTypes['BookingCancellationPayloadUnion']>;
  };
  BookingCancellationPayloadUnion: ResolversParentTypes['BookingCancellationConfirmationPayload'];
  BookingCancellationConfirmationPayload: BookingCancellationConfirmationPayload;
  ChangePaymentMethodRequestInput: ChangePaymentMethodRequestInput;
  ChangePaymentMethodResponse: ChangePaymentMethodResponse;
  BookmarkResponse: BookmarkResponse;
  BookmarkGroupCreateResponse: BookmarkGroupCreateResponse;
  CatalogOrderInput: CatalogOrderInput;
  CatalogOrderResponse: CatalogOrderResponse;
  NewsletterSubscriptionInputInput: NewsletterSubscriptionInputInput;
  NewsletterSubscriptionResponse: NewsletterSubscriptionResponse;
  NewsletterSubscription: NewsletterSubscription;
  BookmarkGroupDeleteResponse: BookmarkGroupDeleteResponse;
  DeleteHistoryResponse: DeleteHistoryResponse;
  DeleteUpsellServiceFromBasketInput: DeleteUpsellServiceFromBasketInput;
  AccessBookingCancellationFinalizationResponse: AccessBookingCancellationFinalizationResponse;
  BookingCancellationFinalizationResponse: BookingCancellationFinalizationResponse;
  UserFinalizationRequestInput: UserFinalizationRequestInput;
  UserFinalizationResponse: UserFinalizationResponse;
  HolidayFinderVoteRequestInput: HolidayFinderVoteRequestInput;
  HolidayFinderRatingInput: HolidayFinderRatingInput;
  AccessBookingCancellationResponse: AccessBookingCancellationResponse;
  InitPaymentRequestInput: InitPaymentRequestInput;
  PaymentFraudInput: PaymentFraudInput;
  DeviceInput: DeviceInput;
  BrowserInput: BrowserInput;
  InitPaymentResponse: InitPaymentResponse;
  BookmarkPdfUploadRequestInput: BookmarkPdfUploadRequestInput;
  BookmarkPdfUploadResponse: BookmarkPdfUploadResponse;
  BookmarkPdfUploadInfo: BookmarkPdfUploadInfo;
  UserRegistrationRequestInput: UserRegistrationRequestInput;
  UserRegistrationResponse: UserRegistrationResponse;
  NewsletterUnsubscriptionInputInput: NewsletterUnsubscriptionInputInput;
  NewsletterUnsubscriptionResponse: NewsletterUnsubscriptionResponse;
  BookmarkGroupRenameResponse: BookmarkGroupRenameResponse;
  ResendTravelDocumentRequestInput: ResendTravelDocumentRequestInput;
  ResendTravelDocumentResponse: ResendTravelDocumentResponse;
  EmailAdvertisingRevocationResponse: EmailAdvertisingRevocationResponse;
  AgencyAppointmentInputInput: AgencyAppointmentInputInput;
  AgencyAppointmentCallbackTimeInput: AgencyAppointmentCallbackTimeInput;
  AgencyAppointmentResponse: AgencyAppointmentResponse;
  ContactFormRequestInput: ContactFormRequestInput;
  ContactFormResponse: ContactFormResponse;
  UpdateEmergencyPhoneRequestInput: UpdateEmergencyPhoneRequestInput;
  UpdateEmergencyPhoneResponse: UpdateEmergencyPhoneResponse;
  AccountPersonalDataInput: AccountPersonalDataInput;
  AddressDetailsInputInput: AddressDetailsInputInput;
  PhoneDetailsInput: PhoneDetailsInput;
  ViewedProductHistoryResponse: ViewedProductHistoryResponse;
  UNREPRESENTABLE: Scalars['UNREPRESENTABLE'];
};

export type SpecifiedByDirectiveArgs = {
  url: Scalars['String'];
};

export type SpecifiedByDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = SpecifiedByDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  accessBooking?: Resolver<
    ResolversTypes['AccessBookingContext'],
    ParentType,
    ContextType,
    RequireFields<QueryAccessBookingArgs, 'context'>
  >;
  account?: Resolver<
    ResolversTypes['AccountContext'],
    ParentType,
    ContextType,
    RequireFields<QueryAccountArgs, 'context'>
  >;
  agency?: Resolver<
    ResolversTypes['Agency'],
    ParentType,
    ContextType,
    RequireFields<QueryAgencyArgs, 'id'>
  >;
  agencyContact?: Resolver<
    ResolversTypes['AgencyContact'],
    ParentType,
    ContextType,
    RequireFields<QueryAgencyContactArgs, 'id'>
  >;
  agencyOverview?: Resolver<
    ResolversTypes['AgencyOverviewPageContext'],
    ParentType,
    ContextType,
    Partial<QueryAgencyOverviewArgs>
  >;
  authAccessToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  authB2BOverlay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  authChannelType?: Resolver<
    ResolversTypes['AuthChannelType'],
    ParentType,
    ContextType
  >;
  blogArticle?: Resolver<
    ResolversTypes['BlogArticle'],
    ParentType,
    ContextType,
    RequireFields<QueryBlogArticleArgs, 'context' | 'id'>
  >;
  blogOverview?: Resolver<
    ResolversTypes['BlogOverview'],
    ParentType,
    ContextType,
    RequireFields<QueryBlogOverviewArgs, 'context'>
  >;
  bookingAfterCancellation?: Resolver<
    ResolversTypes['BookingCancellationResultResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryBookingAfterCancellationArgs, 'request'>
  >;
  bookingAfterPayment?: Resolver<
    ResolversTypes['BookingPaymentResultResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryBookingAfterPaymentArgs, 'bookingPaymentResultRequest'>
  >;
  bookmark?: Resolver<
    ResolversTypes['BookmarkContext'],
    ParentType,
    ContextType,
    Partial<QueryBookmarkArgs>
  >;
  bookmarkPage?: Resolver<
    ResolversTypes['BookmarkPage'],
    ParentType,
    ContextType,
    Partial<QueryBookmarkPageArgs>
  >;
  boosters?: Resolver<
    Array<ResolversTypes['Booster']>,
    ParentType,
    ContextType,
    RequireFields<QueryBoostersArgs, 'pageType'>
  >;
  catalogOverview?: Resolver<
    ResolversTypes['CatalogOverviewContext'],
    ParentType,
    ContextType,
    Partial<QueryCatalogOverviewArgs>
  >;
  checkBookmarkStatus?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<QueryCheckBookmarkStatusArgs, 'objectId' | 'pageType'>
  >;
  components?: Resolver<
    Array<ResolversTypes['DynamicComponent']>,
    ParentType,
    ContextType,
    RequireFields<QueryComponentsArgs, 'input' | 'requestContext'>
  >;
  contact?: Resolver<
    ResolversTypes['ContactPageContext'],
    ParentType,
    ContextType,
    Partial<QueryContactArgs>
  >;
  cookieDisclaimer?: Resolver<
    ResolversTypes['CookieDisclaimer'],
    ParentType,
    ContextType,
    Partial<QueryCookieDisclaimerArgs>
  >;
  emailAdvertisingRevocation?: Resolver<
    ResolversTypes['EmailAdvertisingRevocationPageContext'],
    ParentType,
    ContextType,
    Partial<QueryEmailAdvertisingRevocationArgs>
  >;
  emailAdvertisingRevocationConfirmation?: Resolver<
    ResolversTypes['EmailAdvertisingRevocationConfirmationPageContext'],
    ParentType,
    ContextType,
    Partial<QueryEmailAdvertisingRevocationConfirmationArgs>
  >;
  flightHome?: Resolver<
    ResolversTypes['FlightHomeContext'],
    ParentType,
    ContextType,
    Partial<QueryFlightHomeArgs>
  >;
  flightSrl?: Resolver<
    ResolversTypes['FlightSrlContext'],
    ParentType,
    ContextType,
    Partial<QueryFlightSrlArgs>
  >;
  flightWaitingScreen?: Resolver<
    ResolversTypes['WaitingScreen'],
    ParentType,
    ContextType,
    Partial<QueryFlightWaitingScreenArgs>
  >;
  geo?: Resolver<
    ResolversTypes['GeoContext'],
    ParentType,
    ContextType,
    RequireFields<QueryGeoArgs, 'id'>
  >;
  geoOverview?: Resolver<
    ResolversTypes['GeoOverviewContext'],
    ParentType,
    ContextType,
    Partial<QueryGeoOverviewArgs>
  >;
  helpOverlayData?: Resolver<
    ResolversTypes['HelpOverlayData'],
    ParentType,
    ContextType,
    Partial<QueryHelpOverlayDataArgs>
  >;
  history?: Resolver<
    ResolversTypes['HistoryContext'],
    ParentType,
    ContextType,
    Partial<QueryHistoryArgs>
  >;
  holidayFinder?: Resolver<
    ResolversTypes['HolidayFinderPageContext'],
    ParentType,
    ContextType,
    RequireFields<QueryHolidayFinderArgs, 'context'>
  >;
  home?: Resolver<
    ResolversTypes['HomeContext'],
    ParentType,
    ContextType,
    RequireFields<QueryHomeArgs, 'deviceType'>
  >;
  initialORLUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  metaSearcherSearchParam?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  myAccountWaitingScreen?: Resolver<
    ResolversTypes['WaitingScreen'],
    ParentType,
    ContextType,
    Partial<QueryMyAccountWaitingScreenArgs>
  >;
  newsArticlePage?: Resolver<
    ResolversTypes['NewsArticlePage'],
    ParentType,
    ContextType,
    RequireFields<QueryNewsArticlePageArgs, 'context' | 'id'>
  >;
  newsArticlesOverview?: Resolver<
    ResolversTypes['NewsArticlesOverviewPage'],
    ParentType,
    ContextType,
    Partial<QueryNewsArticlesOverviewArgs>
  >;
  newsletterConfirmation?: Resolver<
    ResolversTypes['NewsletterConfirmationContext'],
    ParentType,
    ContextType,
    Partial<QueryNewsletterConfirmationArgs>
  >;
  newsletterFinalization?: Resolver<
    ResolversTypes['NewsletterFinalizationContext'],
    ParentType,
    ContextType,
    Partial<QueryNewsletterFinalizationArgs>
  >;
  newsletterSubscription?: Resolver<
    ResolversTypes['NewsletterSubscriptionContext'],
    ParentType,
    ContextType,
    Partial<QueryNewsletterSubscriptionArgs>
  >;
  newsletterUnsubscription?: Resolver<
    ResolversTypes['NewsletterUnsubscriptionContext'],
    ParentType,
    ContextType,
    Partial<QueryNewsletterUnsubscriptionArgs>
  >;
  newsletterUnsubscriptionFinalization?: Resolver<
    ResolversTypes['NewsletterUnsubscriptionFinalizationContext'],
    ParentType,
    ContextType,
    Partial<QueryNewsletterUnsubscriptionFinalizationArgs>
  >;
  notificationData?: Resolver<
    Array<ResolversTypes['Notification']>,
    ParentType,
    ContextType,
    Partial<QueryNotificationDataArgs>
  >;
  notificationInfoData?: Resolver<
    ResolversTypes['NotificationInfo'],
    ParentType,
    ContextType,
    RequireFields<QueryNotificationInfoDataArgs, 'context'>
  >;
  organization?: Resolver<
    ResolversTypes['Organization'],
    ParentType,
    ContextType,
    Partial<QueryOrganizationArgs>
  >;
  orl?: Resolver<
    ResolversTypes['OrlContext'],
    ParentType,
    ContextType,
    Partial<QueryOrlArgs>
  >;
  pageB2BLogin?: Resolver<
    ResolversTypes['PageB2BLoginData'],
    ParentType,
    ContextType,
    Partial<QueryPageB2BLoginArgs>
  >;
  pageFooter?: Resolver<
    ResolversTypes['PageFooterData'],
    ParentType,
    ContextType,
    Partial<QueryPageFooterArgs>
  >;
  pageHeader?: Resolver<
    ResolversTypes['PageHeaderData'],
    ParentType,
    ContextType,
    Partial<QueryPageHeaderArgs>
  >;
  pageNotFound404?: Resolver<
    ResolversTypes['PageNotFound404Data'],
    ParentType,
    ContextType,
    Partial<QueryPageNotFound404Args>
  >;
  pdp?: Resolver<
    ResolversTypes['PdpContext'],
    ParentType,
    ContextType,
    Partial<QueryPdpArgs>
  >;
  popUpMenu?: Resolver<
    ResolversTypes['PopUpMenuData'],
    ParentType,
    ContextType,
    Partial<QueryPopUpMenuArgs>
  >;
  productWaitingScreen?: Resolver<
    ResolversTypes['WaitingScreen'],
    ParentType,
    ContextType,
    Partial<QueryProductWaitingScreenArgs>
  >;
  requestButton?: Resolver<
    Maybe<ResolversTypes['RequestButtonComponent']>,
    ParentType,
    ContextType,
    RequireFields<QueryRequestButtonArgs, 'pageType' | 'requestContext'>
  >;
  searchOverlay?: Resolver<
    ResolversTypes['SearchOverlay'],
    ParentType,
    ContextType,
    RequireFields<QuerySearchOverlayArgs, 'context'>
  >;
  srl?: Resolver<
    ResolversTypes['SrlContext'],
    ParentType,
    ContextType,
    Partial<QuerySrlArgs>
  >;
  static?: Resolver<
    ResolversTypes['StaticContext'],
    ParentType,
    ContextType,
    RequireFields<QueryStaticArgs, 'url'>
  >;
  theme?: Resolver<
    ResolversTypes['ThemeContext'],
    ParentType,
    ContextType,
    RequireFields<QueryThemeArgs, 'id'>
  >;
  themeGeo?: Resolver<
    ResolversTypes['ThemeGeoContext'],
    ParentType,
    ContextType,
    RequireFields<QueryThemeGeoArgs, 'geoId' | 'themeId'>
  >;
  themeOverview?: Resolver<
    ResolversTypes['ThemeOverviewContext'],
    ParentType,
    ContextType,
    Partial<QueryThemeOverviewArgs>
  >;
  wishlist?: Resolver<
    ResolversTypes['WishlistContext'],
    ParentType,
    ContextType,
    RequireFields<QueryWishlistArgs, 'context'>
  >;
  wishlistItem?: Resolver<
    Maybe<ResolversTypes['WishlistItem']>,
    ParentType,
    ContextType,
    RequireFields<QueryWishlistItemArgs, 'id'>
  >;
};

export type AccessBookingContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessBookingContext'] = ResolversParentTypes['AccessBookingContext']
> = {
  accessBookingDetailPage?: Resolver<
    ResolversTypes['AccessBookingPageResponse'],
    ParentType,
    ContextType
  >;
  bookingByData?: Resolver<
    ResolversTypes['BookingDetailsResponse'],
    ParentType,
    ContextType,
    RequireFields<AccessBookingContextBookingByDataArgs, 'request'>
  >;
  bookingCancellationInfo?: Resolver<
    ResolversTypes['BookingCancellationInfoResponse'],
    ParentType,
    ContextType,
    RequireFields<AccessBookingContextBookingCancellationInfoArgs, 'bookingId'>
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessBookingPageResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessBookingPageResponse'] = ResolversParentTypes['AccessBookingPageResponse']
> = {
  activitiesSectionTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bookingDetailSectionTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bookingHeaderSectionTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cancellationSectionButtonText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cancellationSectionText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cancellationSectionTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  flightInformationSection?: Resolver<
    Maybe<ResolversTypes['AccessBookingFlightInformation']>,
    ParentType,
    ContextType
  >;
  flightInformationSectionTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mainTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  paymentInformationSectionTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transferInformationSection?: Resolver<
    Maybe<ResolversTypes['AccessBookingPageTransferInfo']>,
    ParentType,
    ContextType
  >;
  travelDocumentSection?: Resolver<
    Maybe<ResolversTypes['AccessBookingTravelDocument']>,
    ParentType,
    ContextType
  >;
  travelDocumentSectionTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessBookingFlightInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessBookingFlightInformation'] = ResolversParentTypes['AccessBookingFlightInformation']
> = {
  additionalFlightInformation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  checkInTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  chickInText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageMetaDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageMetaData'] = ResolversParentTypes['PageMetaData']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  keywordsTag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  noFollow?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  openGraphProps?: Resolver<
    ResolversTypes['Map_String_StringScalar'],
    ParentType,
    ContextType
  >;
  sitemap?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface MapStringStringScalarScalarConfig
  extends GraphQLScalarTypeConfig<
    ResolversTypes['Map_String_StringScalar'],
    any
  > {
  name: 'Map_String_StringScalar';
}

export type AccessBookingPageTransferInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessBookingPageTransferInfo'] = ResolversParentTypes['AccessBookingPageTransferInfo']
> = {
  accessBookingTransferInformationImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<
      AccessBookingPageTransferInfoAccessBookingTransferInformationImageArgs,
      'criteria'
    >
  >;
  buttonLink?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  buttonTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']
> = {
  alt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  copyright?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  desaturated?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  resized?: Resolver<
    Array<ResolversTypes['ResizedImage']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResizedImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResizedImage'] = ResolversParentTypes['ResizedImage']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Link'] = ResolversParentTypes['Link']
> = {
  __resolveType: TypeResolveFn<
    'ExternalLink' | 'InternalLink',
    ParentType,
    ContextType
  >;
};

export type ExternalLinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExternalLink'] = ResolversParentTypes['ExternalLink']
> = {
  caption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  openMethod?: Resolver<
    ResolversTypes['OpenLinkMethod'],
    ParentType,
    ContextType
  >;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InternalLinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InternalLink'] = ResolversParentTypes['InternalLink']
> = {
  caption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  objectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  targetPageType?: Resolver<
    ResolversTypes['PageType'],
    ParentType,
    ContextType
  >;
  uri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessBookingTravelDocumentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessBookingTravelDocument'] = ResolversParentTypes['AccessBookingTravelDocument']
> = {
  buttonTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingDetailsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingDetailsResponse'] = ResolversParentTypes['BookingDetailsResponse']
> = {
  accessRestrictionStatus?: Resolver<
    Maybe<ResolversTypes['AccessRestrictionStatus']>,
    ParentType,
    ContextType
  >;
  bookingDetails?: Resolver<
    Maybe<ResolversTypes['BookingDetails']>,
    ParentType,
    ContextType
  >;
  hideActivities?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingDetails'] = ResolversParentTypes['BookingDetails']
> = {
  allowedPaymentMethods?: Resolver<
    Maybe<Array<ResolversTypes['PaymentMethod']>>,
    ParentType,
    ContextType
  >;
  arrivalDate?: Resolver<ResolversTypes['LocalDate'], ParentType, ContextType>;
  baseErrorResponse?: Resolver<
    Maybe<ResolversTypes['BookingItem']>,
    ParentType,
    ContextType
  >;
  bookingExtraServices?: Resolver<
    Maybe<Array<ResolversTypes['BookingExtraService']>>,
    ParentType,
    ContextType
  >;
  bookingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bookingNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bookingStatus?: Resolver<
    ResolversTypes['BookingStatus'],
    ParentType,
    ContextType
  >;
  cancellationDate?: Resolver<
    Maybe<ResolversTypes['LocalDate']>,
    ParentType,
    ContextType
  >;
  cancellationRules?: Resolver<
    Maybe<Array<ResolversTypes['CancellationServiceInfo']>>,
    ParentType,
    ContextType
  >;
  cancelledBookingPayment?: Resolver<
    Maybe<ResolversTypes['PaymentRecord']>,
    ParentType,
    ContextType
  >;
  cancelSearchLink?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  carRentalDetailList?: Resolver<
    Maybe<Array<ResolversTypes['CarRentalDetails']>>,
    ParentType,
    ContextType
  >;
  contactInfo?: Resolver<
    Maybe<ResolversTypes['Agency']>,
    ParentType,
    ContextType
  >;
  creationDate?: Resolver<
    Maybe<ResolversTypes['LocalDate']>,
    ParentType,
    ContextType
  >;
  customer?: Resolver<
    Maybe<ResolversTypes['AccountPersonalDataResponse']>,
    ParentType,
    ContextType
  >;
  customerErrata?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  departureDate?: Resolver<
    ResolversTypes['LocalDate'],
    ParentType,
    ContextType
  >;
  destination?: Resolver<
    Maybe<ResolversTypes['DestinationInfo']>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emergencyPhone?: Resolver<
    Maybe<ResolversTypes['PhoneDetails']>,
    ParentType,
    ContextType
  >;
  flightBookingDetails?: Resolver<
    Maybe<Array<ResolversTypes['FlightBookingDetails']>>,
    ParentType,
    ContextType
  >;
  flightType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  hotels?: Resolver<
    Maybe<Array<ResolversTypes['HotelBookingDetails']>>,
    ParentType,
    ContextType
  >;
  paymentCondition?: Resolver<
    Maybe<ResolversTypes['PaymentCondition']>,
    ParentType,
    ContextType
  >;
  paymentInformationSection?: Resolver<
    Maybe<ResolversTypes['PaymentInformationSection']>,
    ParentType,
    ContextType
  >;
  paymentMethod?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  productStatus?: Resolver<
    Maybe<ResolversTypes['ProductStatus']>,
    ParentType,
    ContextType
  >;
  totalPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  transfers?: Resolver<
    Maybe<Array<ResolversTypes['BookingTransfer']>>,
    ParentType,
    ContextType
  >;
  travellerInfos?: Resolver<
    Maybe<Array<ResolversTypes['TravellerInfo']>>,
    ParentType,
    ContextType
  >;
  travelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType>;
  zurichAirportHotelOffer?: Resolver<
    Maybe<ResolversTypes['BookingDetailsHotelOffer']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethod'] = ResolversParentTypes['PaymentMethod']
> = {
  paymentId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  paymentName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LocalDateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['LocalDate'], any> {
  name: 'LocalDate';
}

export type BookingItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingItem'] = ResolversParentTypes['BookingItem']
> = {
  arrivalDate?: Resolver<ResolversTypes['LocalDate'], ParentType, ContextType>;
  boardType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bookingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bookingItemImage?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<BookingItemBookingItemImageArgs, 'criteria'>
  >;
  bookingStatus?: Resolver<
    ResolversTypes['BookingStatus'],
    ParentType,
    ContextType
  >;
  departureDate?: Resolver<
    ResolversTypes['LocalDate'],
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  flightDestinationInfo?: Resolver<
    Maybe<ResolversTypes['FlightDestinationInfo']>,
    ParentType,
    ContextType
  >;
  giataId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hotelDestinationInfo?: Resolver<
    Maybe<ResolversTypes['HotelDestinationInfo']>,
    ParentType,
    ContextType
  >;
  link?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productBreadcrumbs?: Resolver<
    Maybe<ResolversTypes['BreadcrumbsComponent']>,
    ParentType,
    ContextType
  >;
  productClass?: Resolver<
    Maybe<ResolversTypes['ProductClass']>,
    ParentType,
    ContextType
  >;
  travelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightDestinationInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightDestinationInfo'] = ResolversParentTypes['FlightDestinationInfo']
> = {
  arrivalAirport?: Resolver<
    Maybe<ResolversTypes['Airport']>,
    ParentType,
    ContextType
  >;
  departureAirport?: Resolver<
    Maybe<ResolversTypes['Airport']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AirportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Airport'] = ResolversParentTypes['Airport']
> = {
  coordinates?: Resolver<
    Maybe<ResolversTypes['GeoCoordinates']>,
    ParentType,
    ContextType
  >;
  iata?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoCoordinatesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoCoordinates'] = ResolversParentTypes['GeoCoordinates']
> = {
  latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HotelDestinationInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HotelDestinationInfo'] = ResolversParentTypes['HotelDestinationInfo']
> = {
  coordinates?: Resolver<
    Maybe<ResolversTypes['GeoCoordinates']>,
    ParentType,
    ContextType
  >;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destination?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BreadcrumbsComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BreadcrumbsComponent'] = ResolversParentTypes['BreadcrumbsComponent']
> = {
  breadcrumbs?: Resolver<
    Array<ResolversTypes['Breadcrumb']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BreadcrumbResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Breadcrumb'] = ResolversParentTypes['Breadcrumb']
> = {
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingExtraServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingExtraService'] = ResolversParentTypes['BookingExtraService']
> = {
  detailedDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shortDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  travellers?: Resolver<
    Maybe<Array<ResolversTypes['Traveller']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravellerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Traveller'] = ResolversParentTypes['Traveller']
> = {
  dateOfBirth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ref?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancellationServiceInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CancellationServiceInfo'] = ResolversParentTypes['CancellationServiceInfo']
> = {
  descriptions?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  endDate?: Resolver<
    Maybe<ResolversTypes['LocalDate']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<
    Maybe<ResolversTypes['LocalDate']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentRecord'] = ResolversParentTypes['PaymentRecord']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentDone?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  paymentStatus?: Resolver<
    Maybe<ResolversTypes['PaymentStatus']>,
    ParentType,
    ContextType
  >;
  paymentStatusAmount?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Price'] = ResolversParentTypes['Price']
> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarRentalDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CarRentalDetails'] = ResolversParentTypes['CarRentalDetails']
> = {
  carDetails?: Resolver<
    Maybe<ResolversTypes['CarDetails']>,
    ParentType,
    ContextType
  >;
  carDriver?: Resolver<
    Maybe<ResolversTypes['CarDriver']>,
    ParentType,
    ContextType
  >;
  carName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  carRentalImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<CarRentalDetailsCarRentalImageArgs, 'criteria'>
  >;
  companyName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  dropoffDate?: Resolver<
    Maybe<ResolversTypes['LocalDate']>,
    ParentType,
    ContextType
  >;
  dropoffLocation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  dropoffTime?: Resolver<
    Maybe<ResolversTypes['LocalTime']>,
    ParentType,
    ContextType
  >;
  pickupDate?: Resolver<
    Maybe<ResolversTypes['LocalDate']>,
    ParentType,
    ContextType
  >;
  pickupLocation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pickupTime?: Resolver<
    Maybe<ResolversTypes['LocalTime']>,
    ParentType,
    ContextType
  >;
  rentalConditions?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CarDetails'] = ResolversParentTypes['CarDetails']
> = {
  availAirCondition?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  gear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfDoors?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarDriverResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CarDriver'] = ResolversParentTypes['CarDriver']
> = {
  dateOfBirth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LocalTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['LocalTime'], any> {
  name: 'LocalTime';
}

export type AgencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Agency'] = ResolversParentTypes['Agency']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addressDetails?: Resolver<
    ResolversTypes['AddressDetails'],
    ParentType,
    ContextType
  >;
  appointmentFormText?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  appointmentFormTitle?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  appointmentLink?: Resolver<
    ResolversTypes['ExternalLink'],
    ParentType,
    ContextType
  >;
  appointmentText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appointmentTextBgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  appointmentTextBgImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<AgencyAppointmentTextBgImageArgs, 'criteria'>
  >;
  appointmentTextFgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  appointmentTitle?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  availableOffline?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  brand?: Resolver<ResolversTypes['AgencyBrand'], ParentType, ContextType>;
  breadcrumbs?: Resolver<
    Maybe<ResolversTypes['BreadcrumbsComponent']>,
    ParentType,
    ContextType
  >;
  coordinates?: Resolver<
    Maybe<ResolversTypes['GeoCoordinates']>,
    ParentType,
    ContextType
  >;
  dynamicComponents?: Resolver<
    Array<ResolversTypes['AgencyDynamicComponent']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formTextBgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  formTextBgImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<AgencyFormTextBgImageArgs, 'criteria'>
  >;
  formTextFgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<AgencyImageArgs, 'criteria'>
  >;
  images?: Resolver<
    Array<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<AgencyImagesArgs, 'criteria'>
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  opened?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  openingHours?: Resolver<
    Array<ResolversTypes['OpeningHours']>,
    ParentType,
    ContextType
  >;
  phone?: Resolver<ResolversTypes['ExternalLink'], ParentType, ContextType>;
  requestButton?: Resolver<
    ResolversTypes['RequestButtonComponent'],
    ParentType,
    ContextType
  >;
  review?: Resolver<Maybe<ResolversTypes['Review']>, ParentType, ContextType>;
  specialOpeningHours?: Resolver<
    Maybe<Array<ResolversTypes['SpecialOpeningHours']>>,
    ParentType,
    ContextType
  >;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddressDetails'] = ResolversParentTypes['AddressDetails']
> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencyDynamicComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyDynamicComponent'] = ResolversParentTypes['AgencyDynamicComponent']
> = {
  __resolveType: TypeResolveFn<
    'AgencyRecommendationGroup' | 'MediaGallery' | 'TextComponent',
    ParentType,
    ContextType
  >;
};

export type AgencyRecommendationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyRecommendationGroup'] = ResolversParentTypes['AgencyRecommendationGroup']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['AgencyRecommendationItemDeprecated']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencyRecommendationItemDeprecatedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyRecommendationItemDeprecated'] = ResolversParentTypes['AgencyRecommendationItemDeprecated']
> = {
  __resolveType: TypeResolveFn<
    | 'BD4TravelRecommendation'
    | 'BlogArticleRecommendationItem'
    | 'GeoRecommendationItem'
    | 'MarketingRecommendationItem'
    | 'ProductRecommendationItem'
    | 'ThemeRecommendationItem',
    ParentType,
    ContextType
  >;
};

export type Bd4TravelRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BD4TravelRecommendation'] = ResolversParentTypes['BD4TravelRecommendation']
> = {
  adults?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  children?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  departureDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  disrupter?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disrupterObject?: Resolver<
    Maybe<ResolversTypes['DisrupterObject']>,
    ParentType,
    ContextType
  >;
  distanceInfo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  features?: Resolver<
    Array<ResolversTypes['ProductFeature']>,
    ParentType,
    ContextType
  >;
  featureSummary?: Resolver<
    Array<ResolversTypes['ProductFeatureGroup']>,
    ParentType,
    ContextType
  >;
  fromTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geoBreadcrumbs?: Resolver<
    Array<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  giata?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hpRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<Bd4TravelRecommendationImageArgs, 'criteria'>
  >;
  inWishlist?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  mapZoom?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mealType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  productClass?: Resolver<
    ResolversTypes['ProductClass'],
    ParentType,
    ContextType
  >;
  productCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rooms?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Room']>>>,
    ParentType,
    ContextType
  >;
  taRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taReviews?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  transferIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  transferPrice?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  travelType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>;
  wishlistItem?: Resolver<
    Maybe<ResolversTypes['WishlistItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisrupterObjectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DisrupterObject'] = ResolversParentTypes['DisrupterObject']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  iconType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductFeature'] = ResolversParentTypes['ProductFeature']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductFeatureGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductFeatureGroup'] = ResolversParentTypes['ProductFeatureGroup']
> = {
  features?: Resolver<
    Array<ResolversTypes['ProductFeature']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoObjectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoObject'] = ResolversParentTypes['GeoObject']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['GeoType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoomResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Room'] = ResolversParentTypes['Room']
> = {
  boardType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bookingCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  roomType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WishlistItem'] = ResolversParentTypes['WishlistItem']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inWishlist?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlogArticleRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlogArticleRecommendationItem'] = ResolversParentTypes['BlogArticleRecommendationItem']
> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<BlogArticleRecommendationItemImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['Link'], ParentType, ContextType>;
  tag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoRecommendationItem'] = ResolversParentTypes['GeoRecommendationItem']
> = {
  adults?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  averageTemp?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  children?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disrupter?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disrupterObject?: Resolver<
    Maybe<ResolversTypes['DisrupterObject']>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  flightDuration?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  geoObject?: Resolver<ResolversTypes['GeoObject'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<GeoRecommendationItemImageArgs, 'criteria'>
  >;
  leadText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  mapZoom?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moreInfo?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  perPersonPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  travelType?: Resolver<
    Maybe<ResolversTypes['TravelType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketingRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketingRecommendationItem'] = ResolversParentTypes['MarketingRecommendationItem']
> = {
  link?: Resolver<ResolversTypes['Link'], ParentType, ContextType>;
  mainText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preview?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Media'] = ResolversParentTypes['Media']
> = {
  __resolveType: TypeResolveFn<
    | 'ExternalMediaItem'
    | 'ImageMediaItem'
    | 'MusicMediaItem'
    | 'PdfMediaItem'
    | 'VideoMediaItem',
    ParentType,
    ContextType
  >;
};

export type ExternalMediaItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExternalMediaItem'] = ResolversParentTypes['ExternalMediaItem']
> = {
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  preview?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<ExternalMediaItemPreviewArgs, 'criteria'>
  >;
  resource?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageMediaItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImageMediaItem'] = ResolversParentTypes['ImageMediaItem']
> = {
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<ImageMediaItemImageArgs, 'criteria'>
  >;
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MusicMediaItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MusicMediaItem'] = ResolversParentTypes['MusicMediaItem']
> = {
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  preview?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<MusicMediaItemPreviewArgs, 'criteria'>
  >;
  resource?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdfMediaItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdfMediaItem'] = ResolversParentTypes['PdfMediaItem']
> = {
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  preview?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<PdfMediaItemPreviewArgs, 'criteria'>
  >;
  resource?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoMediaItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VideoMediaItem'] = ResolversParentTypes['VideoMediaItem']
> = {
  copyright?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  preview?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<VideoMediaItemPreviewArgs, 'criteria'>
  >;
  ratio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  resource?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductRecommendationItem'] = ResolversParentTypes['ProductRecommendationItem']
> = {
  adults?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  children?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  departureDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  disrupter?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disrupterObject?: Resolver<
    Maybe<ResolversTypes['DisrupterObject']>,
    ParentType,
    ContextType
  >;
  distanceInfo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  features?: Resolver<
    Array<ResolversTypes['ProductFeature']>,
    ParentType,
    ContextType
  >;
  featureSummary?: Resolver<
    Array<ResolversTypes['ProductFeatureGroup']>,
    ParentType,
    ContextType
  >;
  fromTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geoBreadcrumbs?: Resolver<
    Array<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  giata?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hpRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<ProductRecommendationItemImageArgs, 'criteria'>
  >;
  inWishlist?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  mapZoom?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mealType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  productClass?: Resolver<
    ResolversTypes['ProductClass'],
    ParentType,
    ContextType
  >;
  productCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rooms?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Room']>>>,
    ParentType,
    ContextType
  >;
  taRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taReviews?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  transferIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  transferPrice?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  travelType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>;
  wishlistItem?: Resolver<
    Maybe<ResolversTypes['WishlistItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemeRecommendationItem'] = ResolversParentTypes['ThemeRecommendationItem']
> = {
  adults?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  children?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  destinationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disrupterObject?: Resolver<
    Maybe<ResolversTypes['DisrupterObject']>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<ThemeRecommendationItemImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalProductCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  travelType?: Resolver<
    Maybe<ResolversTypes['TravelType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecommendationTrackingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecommendationTracking'] = ResolversParentTypes['RecommendationTracking']
> = {
  __resolveType: TypeResolveFn<
    'BD4TravelRecommendationTracking',
    ParentType,
    ContextType
  >;
};

export type Bd4TravelRecommendationTrackingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BD4TravelRecommendationTracking'] = ResolversParentTypes['BD4TravelRecommendationTracking']
> = {
  info?: Resolver<
    Maybe<ResolversTypes['BD4TravelRecommendationInfo']>,
    ParentType,
    ContextType
  >;
  productCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  productIds?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  ptoken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['BD4TravelRecommendationStatus'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bd4TravelRecommendationInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BD4TravelRecommendationInfo'] = ResolversParentTypes['BD4TravelRecommendationInfo']
> = {
  modelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  placementId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  strategy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaGalleryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MediaGallery'] = ResolversParentTypes['MediaGallery']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<MediaGalleryBackgroundImageArgs, 'criteria'>
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mediaItems?: Resolver<
    Array<ResolversTypes['Media']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextComponent'] = ResolversParentTypes['TextComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<TextComponentBackgroundImageArgs, 'criteria'>
  >;
  bottomPadding?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  textWidth?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topPadding?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpeningHoursResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OpeningHours'] = ResolversParentTypes['OpeningHours']
> = {
  open?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shifts?: Resolver<Array<ResolversTypes['Shift']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Shift'] = ResolversParentTypes['Shift']
> = {
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestButtonComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RequestButtonComponent'] = ResolversParentTypes['RequestButtonComponent']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  link?: Resolver<ResolversTypes['ExternalLink'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Review'] = ResolversParentTypes['Review']
> = {
  averageRating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalReviewCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecialOpeningHoursResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SpecialOpeningHours'] = ResolversParentTypes['SpecialOpeningHours']
> = {
  closed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shifts?: Resolver<
    Maybe<Array<ResolversTypes['Shift']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountPersonalDataResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountPersonalDataResponse'] = ResolversParentTypes['AccountPersonalDataResponse']
> = {
  addressDetails?: Resolver<
    Maybe<ResolversTypes['AddressDetails']>,
    ParentType,
    ContextType
  >;
  agency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateOfBirth?: Resolver<
    Maybe<ResolversTypes['LocalDate']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileDetails?: Resolver<
    Maybe<ResolversTypes['PhoneDetails']>,
    ParentType,
    ContextType
  >;
  nationality?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  phoneDetails?: Resolver<
    Maybe<ResolversTypes['PhoneDetails']>,
    ParentType,
    ContextType
  >;
  salutation?: Resolver<
    Maybe<ResolversTypes['Salutation']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhoneDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PhoneDetails'] = ResolversParentTypes['PhoneDetails']
> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DestinationInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DestinationInfo'] = ResolversParentTypes['DestinationInfo']
> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destination?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  destinationCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightBookingDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightBookingDetails'] = ResolversParentTypes['FlightBookingDetails']
> = {
  flight?: Resolver<ResolversTypes['Flight'], ParentType, ContextType>;
  flightDirection?: Resolver<
    Maybe<ResolversTypes['FlightDirection']>,
    ParentType,
    ContextType
  >;
  serviceCheckInUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceExternalReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceInformationUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceProvider?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  travellers?: Resolver<
    Array<ResolversTypes['Traveller']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Flight'] = ResolversParentTypes['Flight']
> = {
  arrival?: Resolver<ResolversTypes['Airport'], ParentType, ContextType>;
  arrivalDate?: Resolver<ResolversTypes['LocalDate'], ParentType, ContextType>;
  arrivalTime?: Resolver<ResolversTypes['LocalTime'], ParentType, ContextType>;
  baggageInfos?: Resolver<
    Maybe<Array<ResolversTypes['FlightBaggageInfo']>>,
    ParentType,
    ContextType
  >;
  departure?: Resolver<ResolversTypes['Airport'], ParentType, ContextType>;
  departureDate?: Resolver<
    ResolversTypes['LocalDate'],
    ParentType,
    ContextType
  >;
  departureTime?: Resolver<
    ResolversTypes['LocalTime'],
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  overnight?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  segments?: Resolver<
    Array<ResolversTypes['FlightSegment']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightBaggageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightBaggageInfo'] = ResolversParentTypes['FlightBaggageInfo']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  measureUnit?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  quantity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightSegmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightSegment'] = ResolversParentTypes['FlightSegment']
> = {
  airline?: Resolver<ResolversTypes['Airline'], ParentType, ContextType>;
  airportWaiting?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  arrival?: Resolver<ResolversTypes['Airport'], ParentType, ContextType>;
  arrivalDate?: Resolver<ResolversTypes['LocalDate'], ParentType, ContextType>;
  arrivalTime?: Resolver<ResolversTypes['LocalTime'], ParentType, ContextType>;
  baggageInfos?: Resolver<
    Maybe<Array<ResolversTypes['FlightBaggageInfo']>>,
    ParentType,
    ContextType
  >;
  departure?: Resolver<ResolversTypes['Airport'], ParentType, ContextType>;
  departureDate?: Resolver<
    ResolversTypes['LocalDate'],
    ParentType,
    ContextType
  >;
  departureTime?: Resolver<
    ResolversTypes['LocalTime'],
    ParentType,
    ContextType
  >;
  flightClass?: Resolver<
    ResolversTypes['FlightClass'],
    ParentType,
    ContextType
  >;
  flightNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operatingAirline?: Resolver<
    Maybe<ResolversTypes['Airline']>,
    ParentType,
    ContextType
  >;
  segmentDuration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AirlineResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Airline'] = ResolversParentTypes['Airline']
> = {
  airlineLogo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iata?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HotelBookingDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HotelBookingDetails'] = ResolversParentTypes['HotelBookingDetails']
> = {
  bookingDetailsImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<HotelBookingDetailsBookingDetailsImageArgs, 'criteria'>
  >;
  checkinDate?: Resolver<ResolversTypes['LocalDate'], ParentType, ContextType>;
  checkoutDate?: Resolver<ResolversTypes['LocalDate'], ParentType, ContextType>;
  cruiseInfo?: Resolver<
    Maybe<ResolversTypes['CruiseInfo']>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giataId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hotelDestinationInfo?: Resolver<
    Maybe<ResolversTypes['HotelDestinationInfo']>,
    ParentType,
    ContextType
  >;
  hpRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  inGetYourGuideBlacklist?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productBreadcrumbs?: Resolver<
    Maybe<ResolversTypes['BreadcrumbsComponent']>,
    ParentType,
    ContextType
  >;
  productClass?: Resolver<
    Maybe<ResolversTypes['ProductClass']>,
    ParentType,
    ContextType
  >;
  rooms?: Resolver<
    Array<ResolversTypes['BookingHotelRoom']>,
    ParentType,
    ContextType
  >;
  roundtripInfo?: Resolver<
    Maybe<ResolversTypes['RoundtripInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CruiseInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CruiseInfo'] = ResolversParentTypes['CruiseInfo']
> = {
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  included?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingHotelRoomResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingHotelRoom'] = ResolversParentTypes['BookingHotelRoom']
> = {
  boardTypes?: Resolver<
    Array<ResolversTypes['BookingBoardType']>,
    ParentType,
    ContextType
  >;
  roomType?: Resolver<
    ResolversTypes['BookingRoomType'],
    ParentType,
    ContextType
  >;
  travellers?: Resolver<
    Array<ResolversTypes['Traveller']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingBoardTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingBoardType'] = ResolversParentTypes['BookingBoardType']
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingRoomTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingRoomType'] = ResolversParentTypes['BookingRoomType']
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoundtripInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoundtripInfo'] = ResolversParentTypes['RoundtripInfo']
> = {
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  included?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentConditionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentCondition'] = ResolversParentTypes['PaymentCondition']
> = {
  downPayment?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  openAmount?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  restPayments?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RestPayment']>>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RestPaymentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestPayment'] = ResolversParentTypes['RestPayment']
> = {
  date?: Resolver<Maybe<ResolversTypes['LocalDate']>, ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentInformationSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentInformationSection'] = ResolversParentTypes['PaymentInformationSection']
> = {
  down?: Resolver<ResolversTypes['PaymentRecord'], ParentType, ContextType>;
  initPaymentAmount?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  paymentAction?: Resolver<
    Maybe<ResolversTypes['AvailablePaymentAction']>,
    ParentType,
    ContextType
  >;
  payments?: Resolver<
    Maybe<Array<ResolversTypes['PaymentRecord']>>,
    ParentType,
    ContextType
  >;
  rest?: Resolver<ResolversTypes['PaymentRecord'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['PaymentRecord'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingTransferResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingTransfer'] = ResolversParentTypes['BookingTransfer']
> = {
  serviceStatus?: Resolver<
    ResolversTypes['ServiceStatus'],
    ParentType,
    ContextType
  >;
  transferDate?: Resolver<
    ResolversTypes['TransferDate'],
    ParentType,
    ContextType
  >;
  transferDetailInfo?: Resolver<
    ResolversTypes['TransferDetailInfo'],
    ParentType,
    ContextType
  >;
  transferInfo?: Resolver<
    ResolversTypes['TransferInfo'],
    ParentType,
    ContextType
  >;
  travellers?: Resolver<
    Array<ResolversTypes['Traveller']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransferDateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransferDate'] = ResolversParentTypes['TransferDate']
> = {
  endDate?: Resolver<
    Maybe<ResolversTypes['LocalDate']>,
    ParentType,
    ContextType
  >;
  startDate?: Resolver<
    Maybe<ResolversTypes['LocalDate']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransferDetailInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransferDetailInfo'] = ResolversParentTypes['TransferDetailInfo']
> = {
  from?: Resolver<
    Maybe<ResolversTypes['TransferPointInfoUnion']>,
    ParentType,
    ContextType
  >;
  to?: Resolver<
    Maybe<ResolversTypes['TransferPointInfoUnion']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransferPointInfoUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransferPointInfoUnion'] = ResolversParentTypes['TransferPointInfoUnion']
> = {
  __resolveType: TypeResolveFn<
    'TransferFlightInfo' | 'TransferHotelInfo',
    ParentType,
    ContextType
  >;
};

export type TransferFlightInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransferFlightInfo'] = ResolversParentTypes['TransferFlightInfo']
> = {
  airline?: Resolver<Maybe<ResolversTypes['Airline']>, ParentType, ContextType>;
  airport?: Resolver<Maybe<ResolversTypes['Airport']>, ParentType, ContextType>;
  flightNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransferHotelInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransferHotelInfo'] = ResolversParentTypes['TransferHotelInfo']
> = {
  giataId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransferInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransferInfo'] = ResolversParentTypes['TransferInfo']
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  direction?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transportType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravellerInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TravellerInfo'] = ResolversParentTypes['TravellerInfo']
> = {
  dateOfBirth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  insurance?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  salutation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingDetailsHotelOfferResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingDetailsHotelOffer'] = ResolversParentTypes['BookingDetailsHotelOffer']
> = {
  hotelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  waitingScreenImage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingCancellationInfoResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingCancellationInfoResponse'] = ResolversParentTypes['BookingCancellationInfoResponse']
> = {
  cancellable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  fee?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountContext'] = ResolversParentTypes['AccountContext']
> = {
  authMethodList?: Resolver<
    ResolversTypes['AuthMethodListResponse'],
    ParentType,
    ContextType
  >;
  bookingCancellationInfo?: Resolver<
    ResolversTypes['BookingCancellationInfoResponse'],
    ParentType,
    ContextType,
    RequireFields<AccountContextBookingCancellationInfoArgs, 'bookingId'>
  >;
  bookingDetails?: Resolver<
    ResolversTypes['BookingDetailsResponse'],
    ParentType,
    ContextType,
    RequireFields<AccountContextBookingDetailsArgs, 'bookingId'>
  >;
  bookingDetailsPage?: Resolver<
    ResolversTypes['AccountPage'],
    ParentType,
    ContextType
  >;
  checkUpsellServiceFromBasket?: Resolver<
    ResolversTypes['CheckBasketResponse'],
    ParentType,
    ContextType,
    RequireFields<AccountContextCheckUpsellServiceFromBasketArgs, 'request'>
  >;
  confirmationPage?: Resolver<
    ResolversTypes['AccountPage'],
    ParentType,
    ContextType
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<AccountContextHeroArgs, 'criteria'>
  >;
  info?: Resolver<ResolversTypes['TextComponent'], ParentType, ContextType>;
  loginPage?: Resolver<
    ResolversTypes['AccountLoginPage'],
    ParentType,
    ContextType
  >;
  myBookingsPage?: Resolver<
    ResolversTypes['AccountPage'],
    ParentType,
    ContextType
  >;
  myHistoryPage?: Resolver<
    ResolversTypes['AccountPage'],
    ParentType,
    ContextType
  >;
  myProfilePage?: Resolver<
    ResolversTypes['AccountMyProfilePage'],
    ParentType,
    ContextType
  >;
  pastBookings?: Resolver<
    ResolversTypes['BookingResponse'],
    ParentType,
    ContextType,
    RequireFields<AccountContextPastBookingsArgs, 'page'>
  >;
  personalData?: Resolver<
    ResolversTypes['AccountPersonalDataResponse'],
    ParentType,
    ContextType
  >;
  registrationPage?: Resolver<
    ResolversTypes['AccountPage'],
    ParentType,
    ContextType
  >;
  resetPasswordPage?: Resolver<
    ResolversTypes['AccountPage'],
    ParentType,
    ContextType
  >;
  subscription?: Resolver<
    Array<ResolversTypes['SubscriptionType']>,
    ParentType,
    ContextType
  >;
  travelCompanions?: Resolver<
    ResolversTypes['TravelComponentResponse'],
    ParentType,
    ContextType
  >;
  upcomingBookings?: Resolver<
    ResolversTypes['BookingResponse'],
    ParentType,
    ContextType,
    RequireFields<AccountContextUpcomingBookingsArgs, 'page'>
  >;
  upsellTransfers?: Resolver<
    ResolversTypes['UpsellTransferResponse'],
    ParentType,
    ContextType,
    RequireFields<AccountContextUpsellTransfersArgs, 'bookingId'>
  >;
  url?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType,
    RequireFields<AccountContextUrlArgs, 'pageType'>
  >;
  userParamsFromToken?: Resolver<
    ResolversTypes['UserParamsFromTokenResponse'],
    ParentType,
    ContextType,
    RequireFields<AccountContextUserParamsFromTokenArgs, 'token'>
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthMethodListResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthMethodListResponse'] = ResolversParentTypes['AuthMethodListResponse']
> = {
  list?: Resolver<Array<ResolversTypes['AuthMethod']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthMethodResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthMethod'] = ResolversParentTypes['AuthMethod']
> = {
  type?: Resolver<ResolversTypes['AuthMethodType'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountPage'] = ResolversParentTypes['AccountPage']
> = {
  mainText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mainTextBgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainTextBgImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<AccountPageMainTextBgImageArgs, 'criteria'>
  >;
  mainTextFgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pageMetaData?: Resolver<
    ResolversTypes['PageMetaData'],
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CheckBasketResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CheckBasketResponse'] = ResolversParentTypes['CheckBasketResponse']
> = {
  allowedPaymentMethods?: Resolver<
    Maybe<Array<ResolversTypes['PaymentMethod']>>,
    ParentType,
    ContextType
  >;
  customerErrata?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  openAmountPrice?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  transfers?: Resolver<
    Maybe<Array<ResolversTypes['UpsellTransferService']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpsellTransferServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpsellTransferService'] = ResolversParentTypes['UpsellTransferService']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  direction?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iataCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  travellers?: Resolver<
    Maybe<Array<ResolversTypes['Traveller']>>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountLoginPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountLoginPage'] = ResolversParentTypes['AccountLoginPage']
> = {
  loginTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mainTextBgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainTextBgImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<AccountLoginPageMainTextBgImageArgs, 'criteria'>
  >;
  mainTextFgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pageMetaData?: Resolver<
    ResolversTypes['PageMetaData'],
    ParentType,
    ContextType
  >;
  registerIntroText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  registerTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountMyProfilePageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountMyProfilePage'] = ResolversParentTypes['AccountMyProfilePage']
> = {
  countryList?: Resolver<
    Array<Maybe<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  pageMetaData?: Resolver<
    ResolversTypes['PageMetaData'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingResponse'] = ResolversParentTypes['BookingResponse']
> = {
  items?: Resolver<
    Array<ResolversTypes['BookingItem']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Page'] = ResolversParentTypes['Page']
> = {
  pageNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resultsPerPage?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  resultsTotal?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelComponentResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TravelComponentResponse'] = ResolversParentTypes['TravelComponentResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  travelCompanions?: Resolver<
    Array<ResolversTypes['TravelCompanion']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelCompanionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TravelCompanion'] = ResolversParentTypes['TravelCompanion']
> = {
  dateOfBirth?: Resolver<
    Maybe<ResolversTypes['LocalDate']>,
    ParentType,
    ContextType
  >;
  firstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nationality?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  salutation?: Resolver<
    Maybe<ResolversTypes['Salutation']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpsellTransferResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpsellTransferResponse'] = ResolversParentTypes['UpsellTransferResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  transfers?: Resolver<
    Array<ResolversTypes['UpsellTransfer']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpsellTransferResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpsellTransfer'] = ResolversParentTypes['UpsellTransfer']
> = {
  offerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<
    ResolversTypes['UpsellTransferPrice'],
    ParentType,
    ContextType
  >;
  searchServiceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transferInfo?: Resolver<
    ResolversTypes['TransferInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpsellTransferPriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpsellTransferPrice'] = ResolversParentTypes['UpsellTransferPrice']
> = {
  pricePerPerson?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  totalPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserParamsFromTokenResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserParamsFromTokenResponse'] = ResolversParentTypes['UserParamsFromTokenResponse']
> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  params?: Resolver<
    Maybe<ResolversTypes['HashMap_String_StringScalar']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['Status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface HashMapStringStringScalarScalarConfig
  extends GraphQLScalarTypeConfig<
    ResolversTypes['HashMap_String_StringScalar'],
    any
  > {
  name: 'HashMap_String_StringScalar';
}

export type AgencyContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyContact'] = ResolversParentTypes['AgencyContact']
> = {
  addressDetails?: Resolver<
    ResolversTypes['AddressDetails'],
    ParentType,
    ContextType
  >;
  appointmentLink?: Resolver<
    Maybe<ResolversTypes['ExternalLink']>,
    ParentType,
    ContextType
  >;
  emailLink?: Resolver<ResolversTypes['ExternalLink'], ParentType, ContextType>;
  openingHours?: Resolver<
    Array<ResolversTypes['OpeningHours']>,
    ParentType,
    ContextType
  >;
  phone?: Resolver<
    ResolversTypes['AgencyContactPhone'],
    ParentType,
    ContextType
  >;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencyContactPhoneResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyContactPhone'] = ResolversParentTypes['AgencyContactPhone']
> = {
  link?: Resolver<ResolversTypes['ExternalLink'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencyOverviewPageContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyOverviewPageContext'] = ResolversParentTypes['AgencyOverviewPageContext']
> = {
  agencies?: Resolver<
    Array<ResolversTypes['Agency']>,
    ParentType,
    ContextType,
    Partial<AgencyOverviewPageContextAgenciesArgs>
  >;
  autocomplete?: Resolver<
    ResolversTypes['AgencyAutocomplete'],
    ParentType,
    ContextType,
    RequireFields<AgencyOverviewPageContextAutocompleteArgs, 'criteria'>
  >;
  bd4TravelRecommendations?: Resolver<
    Array<ResolversTypes['BD4TravelRecommendation']>,
    ParentType,
    ContextType
  >;
  blogArticles?: Resolver<
    Array<ResolversTypes['BlogArticleRecommendationItem']>,
    ParentType,
    ContextType
  >;
  dynamicComponents?: Resolver<
    Array<ResolversTypes['AgencyDynamicComponent']>,
    ParentType,
    ContextType,
    Partial<AgencyOverviewPageContextDynamicComponentsArgs>
  >;
  information?: Resolver<
    ResolversTypes['TextComponent'],
    ParentType,
    ContextType
  >;
  informationOverview?: Resolver<
    ResolversTypes['AgencyOverviewInfoComponent'],
    ParentType,
    ContextType
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencyAutocompleteResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyAutocomplete'] = ResolversParentTypes['AgencyAutocomplete']
> = {
  objects?: Resolver<
    Array<ResolversTypes['AgencyAutocompleteSuggestion']>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencyAutocompleteSuggestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyAutocompleteSuggestion'] = ResolversParentTypes['AgencyAutocompleteSuggestion']
> = {
  __resolveType: TypeResolveFn<
    'Agency' | 'MapSuggestion',
    ParentType,
    ContextType
  >;
};

export type MapSuggestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MapSuggestion'] = ResolversParentTypes['MapSuggestion']
> = {
  center?: Resolver<ResolversTypes['GeoCoordinates'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MapSuggestionType'], ParentType, ContextType>;
  zoom?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencyOverviewInfoComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyOverviewInfoComponent'] = ResolversParentTypes['AgencyOverviewInfoComponent']
> = {
  breadcrumbs?: Resolver<
    Maybe<ResolversTypes['BreadcrumbsComponent']>,
    ParentType,
    ContextType
  >;
  flagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<AgencyOverviewInfoComponentImageArgs, 'criteria'>
  >;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  textBgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  textBgImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<AgencyOverviewInfoComponentTextBgImageArgs, 'criteria'>
  >;
  textFgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlogArticleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlogArticle'] = ResolversParentTypes['BlogArticle']
> = {
  author?: Resolver<ResolversTypes['EmployeeBox'], ParentType, ContextType>;
  breadcrumbs?: Resolver<
    ResolversTypes['BreadcrumbsComponent'],
    ParentType,
    ContextType
  >;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  flagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hero?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<BlogArticleHeroArgs, 'criteria'>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  tagline?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeBoxResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeBox'] = ResolversParentTypes['EmployeeBox']
> = {
  emailAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  hoverImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<EmployeeBoxHoverImageArgs, 'criteria'>
  >;
  image?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<EmployeeBoxImageArgs, 'criteria'>
  >;
  imagePlacement?: Resolver<
    Maybe<ResolversTypes['ImagePlacement']>,
    ParentType,
    ContextType
  >;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  placement?: Resolver<
    Maybe<ResolversTypes['EmployeeImagePlacement']>,
    ParentType,
    ContextType
  >;
  shortText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  socialProfiles?: Resolver<
    Array<ResolversTypes['EmployeeSocialProfile']>,
    ParentType,
    ContextType
  >;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeSocialProfileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeSocialProfile'] = ResolversParentTypes['EmployeeSocialProfile']
> = {
  icon?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<EmployeeSocialProfileIconArgs, 'criteria'>
  >;
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlogOverviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlogOverview'] = ResolversParentTypes['BlogOverview']
> = {
  articles?: Resolver<
    ResolversTypes['BlogArticles'],
    ParentType,
    ContextType,
    RequireFields<BlogOverviewArticlesArgs, 'pageNumber' | 'perPage'>
  >;
  breadcrumbs?: Resolver<
    ResolversTypes['BreadcrumbsComponent'],
    ParentType,
    ContextType
  >;
  flagline?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<BlogOverviewHeroArgs, 'criteria'>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  tagline?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlogArticlesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlogArticles'] = ResolversParentTypes['BlogArticles']
> = {
  articles?: Resolver<
    Array<ResolversTypes['BlogArticleItem']>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlogArticleItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlogArticleItem'] = ResolversParentTypes['BlogArticleItem']
> = {
  author?: Resolver<ResolversTypes['EmployeeBox'], ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['LocalDate']>, ParentType, ContextType>;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<BlogArticleItemHeroArgs, 'criteria'>
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leadText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mainTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingCancellationResultResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingCancellationResultResponse'] = ResolversParentTypes['BookingCancellationResultResponse']
> = {
  accessRestrictionStatus?: Resolver<
    Maybe<ResolversTypes['AccessRestrictionStatus']>,
    ParentType,
    ContextType
  >;
  bookingDetails?: Resolver<
    Maybe<ResolversTypes['BookingDetails']>,
    ParentType,
    ContextType
  >;
  hideActivities?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  tokenExpire?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingPaymentResultResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingPaymentResultResponse'] = ResolversParentTypes['BookingPaymentResultResponse']
> = {
  accessRestrictionStatus?: Resolver<
    Maybe<ResolversTypes['AccessRestrictionStatus']>,
    ParentType,
    ContextType
  >;
  bookingDetails?: Resolver<
    Maybe<ResolversTypes['BookingDetails']>,
    ParentType,
    ContextType
  >;
  hideActivities?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookmarkContext'] = ResolversParentTypes['BookmarkContext']
> = {
  bookmarkQuantity?: Resolver<
    ResolversTypes['BookmarkQuantityResponse'],
    ParentType,
    ContextType
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkQuantityResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookmarkQuantityResponse'] = ResolversParentTypes['BookmarkQuantityResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookmarkPage'] = ResolversParentTypes['BookmarkPage']
> = {
  allBookmarkGroups?: Resolver<
    ResolversTypes['AllBookmarkGroupResponse'],
    ParentType,
    ContextType,
    Partial<BookmarkPageAllBookmarkGroupsArgs>
  >;
  bookmarkItems?: Resolver<
    Array<Maybe<ResolversTypes['BookmarkItem']>>,
    ParentType,
    ContextType,
    Partial<BookmarkPageBookmarkItemsArgs>
  >;
  breadcrumbs?: Resolver<
    Maybe<ResolversTypes['BreadcrumbsComponent']>,
    ParentType,
    ContextType
  >;
  buttonLink?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  buttonText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  main?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AllBookmarkGroupResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AllBookmarkGroupResponse'] = ResolversParentTypes['AllBookmarkGroupResponse']
> = {
  bookmarkGroups?: Resolver<
    Maybe<Array<ResolversTypes['BookmarkGroup']>>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookmarkGroup'] = ResolversParentTypes['BookmarkGroup']
> = {
  bookmarksCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<BookmarkGroupGroupImageArgs, 'criteria'>
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shareUrl?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookmarkItem'] = ResolversParentTypes['BookmarkItem']
> = {
  bookmarkType?: Resolver<
    ResolversTypes['BookmarkType'],
    ParentType,
    ContextType
  >;
  breadcrumbs?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  disrupters?: Resolver<
    Array<ResolversTypes['DisrupterObject']>,
    ParentType,
    ContextType
  >;
  googleStaticMapImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<BookmarkItemGoogleStaticMapImageArgs, 'criteria'>
  >;
  image?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<BookmarkItemImageArgs, 'criteria'>
  >;
  leadText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productInfoB2B?: Resolver<
    Maybe<ResolversTypes['ProductInfoB2B']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductInfoB2BResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductInfoB2B'] = ResolversParentTypes['ProductInfoB2B']
> = {
  duration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceFrom?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  route?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  typeFlags?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BoosterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Booster'] = ResolversParentTypes['Booster']
> = {
  delay?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  frequency?: Resolver<
    ResolversTypes['BoosterFrequency'],
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogOverviewContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogOverviewContext'] = ResolversParentTypes['CatalogOverviewContext']
> = {
  catalogs?: Resolver<
    Array<ResolversTypes['Catalog']>,
    ParentType,
    ContextType
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<CatalogOverviewContextHeroArgs, 'criteria'>
  >;
  information?: Resolver<
    ResolversTypes['TextComponent'],
    ParentType,
    ContextType
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  overviewInformation?: Resolver<
    ResolversTypes['CatalogOverviewInfoComponent'],
    ParentType,
    ContextType
  >;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Catalog'] = ResolversParentTypes['Catalog']
> = {
  canBeOrderedOnline?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  downloadLink?: Resolver<
    ResolversTypes['ExternalLink'],
    ParentType,
    ContextType
  >;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<CatalogImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['ExternalLink'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CatalogType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogOverviewInfoComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogOverviewInfoComponent'] = ResolversParentTypes['CatalogOverviewInfoComponent']
> = {
  breadcrumbs?: Resolver<
    Maybe<ResolversTypes['BreadcrumbsComponent']>,
    ParentType,
    ContextType
  >;
  flagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<CatalogOverviewInfoComponentImageArgs, 'criteria'>
  >;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  textBgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  textBgImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<CatalogOverviewInfoComponentTextBgImageArgs, 'criteria'>
  >;
  textFgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DynamicComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DynamicComponent'] = ResolversParentTypes['DynamicComponent']
> = {
  __resolveType: TypeResolveFn<
    | 'AccordionComponent'
    | 'AgencyContactComponent'
    | 'AgencyRecommendation'
    | 'BD4TravelRecommendationGroup'
    | 'BenefitsComponent'
    | 'BlogArticleRecommendation'
    | 'BrandsComponent'
    | 'ClimateComponent'
    | 'ContactModuleComponent'
    | 'EmployeesComponent'
    | 'FaqComponent'
    | 'FlightWithoutPriceRecommendation'
    | 'FlightWithPriceRecommendation'
    | 'GeoChildrenComponent'
    | 'GeoGalleryComponent'
    | 'GeoRecommendation'
    | 'HeroMediaGallery'
    | 'ImageTextComponent'
    | 'LinkListComponent'
    | 'MarketingRecommendation'
    | 'MarketingTeaserComponent'
    | 'MediaGallery'
    | 'NewsArticlesComponent'
    | 'NewsletterSignupComponent'
    | 'OfferButtonComponent'
    | 'OffersMapPlaceholderComponent'
    | 'ProductGalleryWithMapComponent'
    | 'ProductRecommendation'
    | 'RequestButtonComponent'
    | 'ResortTopHotelsComponent'
    | 'SrlButtonPlaceholderComponent'
    | 'SrlMapPinsComponent'
    | 'StaticBlogRecommendation'
    | 'StaticGeoRecommendation'
    | 'StaticProductRecommendation'
    | 'StaticThemeRecommendation'
    | 'TextComponent'
    | 'ThemeRecommendation'
    | 'UspBoxesComponent',
    ParentType,
    ContextType
  >;
};

export type AccordionComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccordionComponent'] = ResolversParentTypes['AccordionComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bottomBackgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bottomFontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bottomMainText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bottomMainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  components?: Resolver<
    Array<ResolversTypes['DynamicComponent']>,
    ParentType,
    ContextType
  >;
  topBackgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  topFontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  topMainText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  topMainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencyContactComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyContactComponent'] = ResolversParentTypes['AgencyContactComponent']
> = {
  address?: Resolver<ResolversTypes['AddressDetails'], ParentType, ContextType>;
  agencyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  image?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<AgencyContactComponentImageArgs, 'criteria'>
  >;
  openingHours?: Resolver<
    Array<ResolversTypes['OpeningHours']>,
    ParentType,
    ContextType
  >;
  phone?: Resolver<ResolversTypes['ExternalLink'], ParentType, ContextType>;
  requestButton?: Resolver<
    ResolversTypes['RequestButtonComponent'],
    ParentType,
    ContextType
  >;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencyRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyRecommendation'] = ResolversParentTypes['AgencyRecommendation']
> = {
  backgroundColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fontColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  items?: Resolver<
    Array<ResolversTypes['AgencyRecommendationItem']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencyRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyRecommendationItem'] = ResolversParentTypes['AgencyRecommendationItem']
> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<AgencyRecommendationItemImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Bd4TravelRecommendationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BD4TravelRecommendationGroup'] = ResolversParentTypes['BD4TravelRecommendationGroup']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<Bd4TravelRecommendationGroupBackgroundImageArgs, 'criteria'>
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['BD4TravelRecommendation']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['BD4TravelRecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BenefitsComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BenefitsComponent'] = ResolversParentTypes['BenefitsComponent']
> = {
  buttonLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  buttonLink?: Resolver<ResolversTypes['Link'], ParentType, ContextType>;
  items?: Resolver<
    Array<ResolversTypes['BenefitItem']>,
    ParentType,
    ContextType
  >;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BenefitItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BenefitItem'] = ResolversParentTypes['BenefitItem']
> = {
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlogArticleRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlogArticleRecommendation'] = ResolversParentTypes['BlogArticleRecommendation']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<BlogArticleRecommendationBackgroundImageArgs, 'criteria'>
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['BlogArticleRecommendationItem']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandsComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandsComponent'] = ResolversParentTypes['BrandsComponent']
> = {
  brands?: Resolver<Array<ResolversTypes['BrandBox']>, ParentType, ContextType>;
  brandsTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandBoxResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandBox'] = ResolversParentTypes['BrandBox']
> = {
  image?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<BrandBoxImageArgs, 'criteria'>
  >;
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClimateComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClimateComponent'] = ResolversParentTypes['ClimateComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  charts?: Resolver<
    Array<ResolversTypes['ClimateChart']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sunHoursPerYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClimateChartResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClimateChart'] = ResolversParentTypes['ClimateChart']
> = {
  entries?: Resolver<
    Array<ResolversTypes['ClimateChartEntry']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ClimateChartType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClimateChartEntryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClimateChartEntry'] = ResolversParentTypes['ClimateChartEntry']
> = {
  month?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactModuleComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactModuleComponent'] = ResolversParentTypes['ContactModuleComponent']
> = {
  contactTypeBases?: Resolver<
    Array<ResolversTypes['ContactTypeUnion']>,
    ParentType,
    ContextType
  >;
  flagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactTypeUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactTypeUnion'] = ResolversParentTypes['ContactTypeUnion']
> = {
  __resolveType: TypeResolveFn<
    'ContactType' | 'ContactTypePhone',
    ParentType,
    ContextType
  >;
};

export type ContactTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactType'] = ResolversParentTypes['ContactType']
> = {
  icon?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<ContactTypeIconArgs, 'criteria'>
  >;
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactTypePhoneResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactTypePhone'] = ResolversParentTypes['ContactTypePhone']
> = {
  icon?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<ContactTypePhoneIconArgs, 'criteria'>
  >;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeesComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeesComponent'] = ResolversParentTypes['EmployeesComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employees?: Resolver<
    Array<ResolversTypes['EmployeeBox']>,
    ParentType,
    ContextType
  >;
  employeesTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FaqComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FaqComponent'] = ResolversParentTypes['FaqComponent']
> = {
  items?: Resolver<
    Array<ResolversTypes['TextComponent']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightWithoutPriceRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightWithoutPriceRecommendation'] = ResolversParentTypes['FlightWithoutPriceRecommendation']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<
      FlightWithoutPriceRecommendationBackgroundImageArgs,
      'criteria'
    >
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['FlightRecommendationWithoutPriceItem']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightRecommendationWithoutPriceItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightRecommendationWithoutPriceItem'] = ResolversParentTypes['FlightRecommendationWithoutPriceItem']
> = {
  link?: Resolver<ResolversTypes['Link'], ParentType, ContextType>;
  media?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightWithPriceRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightWithPriceRecommendation'] = ResolversParentTypes['FlightWithPriceRecommendation']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<FlightWithPriceRecommendationBackgroundImageArgs, 'criteria'>
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['FlightRecommendationWithPriceItem']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightRecommendationWithPriceItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightRecommendationWithPriceItem'] = ResolversParentTypes['FlightRecommendationWithPriceItem']
> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  media?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  offer?: Resolver<ResolversTypes['FlightOffer'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightOfferResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightOffer'] = ResolversParentTypes['FlightOffer']
> = {
  forwardFlight?: Resolver<ResolversTypes['Flight'], ParentType, ContextType>;
  offerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pricePerPerson?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  returnFlight?: Resolver<
    Maybe<ResolversTypes['Flight']>,
    ParentType,
    ContextType
  >;
  totalPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  waitingScreenImage?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoChildrenComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoChildrenComponent'] = ResolversParentTypes['GeoChildrenComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  children?: Resolver<
    Array<ResolversTypes['GeoChildComponent']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoChildComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoChildComponent'] = ResolversParentTypes['GeoChildComponent']
> = {
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<GeoChildComponentImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoGalleryComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoGalleryComponent'] = ResolversParentTypes['GeoGalleryComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaItems?: Resolver<
    Array<ResolversTypes['Media']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoRecommendation'] = ResolversParentTypes['GeoRecommendation']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<GeoRecommendationBackgroundImageArgs, 'criteria'>
  >;
  displayType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['GeoRecommendationItem']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  titleFontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HeroMediaGalleryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HeroMediaGallery'] = ResolversParentTypes['HeroMediaGallery']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  flagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainTextFontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mediaItems?: Resolver<
    Array<ResolversTypes['HeroMedia']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HeroMediaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HeroMedia'] = ResolversParentTypes['HeroMedia']
> = {
  __resolveType: TypeResolveFn<
    'ImageMediaItem' | 'VideoMediaItem',
    ParentType,
    ContextType
  >;
};

export type ImageTextComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImageTextComponent'] = ResolversParentTypes['ImageTextComponent']
> = {
  image?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<ImageTextComponentImageArgs, 'criteria'>
  >;
  imagePlacement?: Resolver<
    ResolversTypes['ImagePlacement'],
    ParentType,
    ContextType
  >;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkListComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinkListComponent'] = ResolversParentTypes['LinkListComponent']
> = {
  links?: Resolver<
    Array<ResolversTypes['LinkListItem']>,
    ParentType,
    ContextType
  >;
  linksTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkListItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinkListItem'] = ResolversParentTypes['LinkListItem']
> = {
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketingRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketingRecommendation'] = ResolversParentTypes['MarketingRecommendation']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<MarketingRecommendationBackgroundImageArgs, 'criteria'>
  >;
  displayType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['MarketingRecommendationItem']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  titleFontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketingTeaserComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketingTeaserComponent'] = ResolversParentTypes['MarketingTeaserComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundColorAlignment?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundTheme?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bottomPadding?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  buttonLink?: Resolver<ResolversTypes['Link'], ParentType, ContextType>;
  buttonTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logoImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<MarketingTeaserComponentLogoImageArgs, 'criteria'>
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teaserImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<MarketingTeaserComponentTeaserImageArgs, 'criteria'>
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topPadding?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsArticlesComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsArticlesComponent'] = ResolversParentTypes['NewsArticlesComponent']
> = {
  buttonCaption?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  buttonLink?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  newsArticles?: Resolver<
    Array<ResolversTypes['NewsArticle']>,
    ParentType,
    ContextType
  >;
  newsArticlesTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsArticleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsArticle'] = ResolversParentTypes['NewsArticle']
> = {
  businessUnit?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<NewsArticleImageArgs, 'criteria'>
  >;
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterSignupComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterSignupComponent'] = ResolversParentTypes['NewsletterSignupComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  buttonText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  disclaimerText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  errorHandlingText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  image?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<NewsletterSignupComponentImageArgs, 'criteria'>
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  successMessageText?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  successMessageTextIcon?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<
      NewsletterSignupComponentSuccessMessageTextIconArgs,
      'criteria'
    >
  >;
  successMessageTitle?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  titleText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OfferButtonComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OfferButtonComponent'] = ResolversParentTypes['OfferButtonComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OffersMapPlaceholderComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OffersMapPlaceholderComponent'] = ResolversParentTypes['OffersMapPlaceholderComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchQuery?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductGalleryWithMapComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductGalleryWithMapComponent'] = ResolversParentTypes['ProductGalleryWithMapComponent']
> = {
  imageGroups?: Resolver<
    Array<ResolversTypes['ProductImageGroup']>,
    ParentType,
    ContextType
  >;
  images?: Resolver<
    Array<ResolversTypes['ProductImageItem']>,
    ParentType,
    ContextType
  >;
  relatedRoundtrips?: Resolver<
    Maybe<ResolversTypes['RelatedRoundtrips']>,
    ParentType,
    ContextType
  >;
  routePoints?: Resolver<
    Maybe<Array<ResolversTypes['RoutePoint']>>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductImageGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductImageGroup'] = ResolversParentTypes['ProductImageGroup']
> = {
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  imageItems?: Resolver<
    Array<ResolversTypes['ProductImageItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductImageItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductImageItem'] = ResolversParentTypes['ProductImageItem']
> = {
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  disrupters?: Resolver<
    Maybe<Array<ResolversTypes['DisrupterObject']>>,
    ParentType,
    ContextType
  >;
  image?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<ProductImageItemImageArgs, 'criteria'>
  >;
  productId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelatedRoundtripsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedRoundtrips'] = ResolversParentTypes['RelatedRoundtrips']
> = {
  coordinates?: Resolver<
    Maybe<ResolversTypes['GeoCoordinates']>,
    ParentType,
    ContextType
  >;
  roundtrips?: Resolver<
    Maybe<Array<ResolversTypes['RelatedRoundtripItem']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelatedRoundtripItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedRoundtripItem'] = ResolversParentTypes['RelatedRoundtripItem']
> = {
  image?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<RelatedRoundtripItemImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productInfoB2B?: Resolver<
    Maybe<ResolversTypes['ProductInfoB2B']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoutePointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoutePoint'] = ResolversParentTypes['RoutePoint']
> = {
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductRecommendation'] = ResolversParentTypes['ProductRecommendation']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<ProductRecommendationBackgroundImageArgs, 'criteria'>
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['ProductRecommendationItem']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResortTopHotelsComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResortTopHotelsComponent'] = ResolversParentTypes['ResortTopHotelsComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topHotels?: Resolver<
    Array<ResolversTypes['ProductRecommendationItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlButtonPlaceholderComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlButtonPlaceholderComponent'] = ResolversParentTypes['SrlButtonPlaceholderComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlMapPinsComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlMapPinsComponent'] = ResolversParentTypes['SrlMapPinsComponent']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cheapestGeo?: Resolver<
    Maybe<ResolversTypes['SrlMapGeoPin']>,
    ParentType,
    ContextType
  >;
  cheapestProduct?: Resolver<
    Maybe<ResolversTypes['SrlMapProductPin']>,
    ParentType,
    ContextType
  >;
  geoPins?: Resolver<
    Array<ResolversTypes['SrlMapGeoPin']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nearestAirports?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Airport']>>>,
    ParentType,
    ContextType
  >;
  productPins?: Resolver<
    Array<ResolversTypes['SrlMapProductPin']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlMapGeoPinResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlMapGeoPin'] = ResolversParentTypes['SrlMapGeoPin']
> = {
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  geo?: Resolver<ResolversTypes['GeoObject'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  zoom?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlMapProductPinResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlMapProductPin'] = ResolversParentTypes['SrlMapProductPin']
> = {
  bestseller?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  top?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  zoom?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaticBlogRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaticBlogRecommendation'] = ResolversParentTypes['StaticBlogRecommendation']
> = {
  articles?: Resolver<
    Array<ResolversTypes['BlogArticleItem']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaticGeoRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaticGeoRecommendation'] = ResolversParentTypes['StaticGeoRecommendation']
> = {
  backgroundColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fontColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  items?: Resolver<
    Array<ResolversTypes['StaticGeoRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaticGeoRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaticGeoRecommendationItem'] = ResolversParentTypes['StaticGeoRecommendationItem']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<StaticGeoRecommendationItemImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaticProductRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaticProductRecommendation'] = ResolversParentTypes['StaticProductRecommendation']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['StaticProductRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productFilters?: Resolver<
    Maybe<Array<ResolversTypes['ProductFilter']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaticProductRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaticProductRecommendationItem'] = ResolversParentTypes['StaticProductRecommendationItem']
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  breadcrumbs?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  disrupters?: Resolver<
    Maybe<Array<ResolversTypes['DisrupterObject']>>,
    ParentType,
    ContextType
  >;
  features?: Resolver<
    Array<ResolversTypes['ProductFeature']>,
    ParentType,
    ContextType
  >;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<StaticProductRecommendationItemImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  route?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  routePoints?: Resolver<
    Maybe<Array<ResolversTypes['RoutePoint']>>,
    ParentType,
    ContextType
  >;
  teaser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductFilter'] = ResolversParentTypes['ProductFilter']
> = {
  objectId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  objectName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaticThemeRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaticThemeRecommendation'] = ResolversParentTypes['StaticThemeRecommendation']
> = {
  backgroundColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  items?: Resolver<
    Array<ResolversTypes['StaticThemeRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaticThemeRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaticThemeRecommendationItem'] = ResolversParentTypes['StaticThemeRecommendationItem']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<StaticThemeRecommendationItemImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemeRecommendation'] = ResolversParentTypes['ThemeRecommendation']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<ThemeRecommendationBackgroundImageArgs, 'criteria'>
  >;
  displayType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['ThemeRecommendationItem']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UspBoxesComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UspBoxesComponent'] = ResolversParentTypes['UspBoxesComponent']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  boxes?: Resolver<Array<ResolversTypes['UspBox']>, ParentType, ContextType>;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  uspTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UspBoxResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UspBox'] = ResolversParentTypes['UspBox']
> = {
  icon?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<UspBoxIconArgs, 'criteria'>
  >;
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactPageContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactPageContext'] = ResolversParentTypes['ContactPageContext']
> = {
  dynamicComponents?: Resolver<
    Array<ResolversTypes['ContactDynamicComponent']>,
    ParentType,
    ContextType
  >;
  forms?: Resolver<
    Array<ResolversTypes['ContactForm']>,
    ParentType,
    ContextType
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<ContactPageContextHeroArgs, 'criteria'>
  >;
  information?: Resolver<
    ResolversTypes['TextComponent'],
    ParentType,
    ContextType
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactDynamicComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactDynamicComponent'] = ResolversParentTypes['ContactDynamicComponent']
> = {
  __resolveType: TypeResolveFn<
    'ContactRecommendationGroup' | 'MediaGallery' | 'TextComponent',
    ParentType,
    ContextType
  >;
};

export type ContactRecommendationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactRecommendationGroup'] = ResolversParentTypes['ContactRecommendationGroup']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['ContactRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactRecommendationItem'] = ResolversParentTypes['ContactRecommendationItem']
> = {
  __resolveType: TypeResolveFn<
    'MarketingRecommendationItem',
    ParentType,
    ContextType
  >;
};

export type ContactFormResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactForm'] = ResolversParentTypes['ContactForm']
> = {
  placeholder?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  showForm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CookieDisclaimerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CookieDisclaimer'] = ResolversParentTypes['CookieDisclaimer']
> = {
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailAdvertisingRevocationPageContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailAdvertisingRevocationPageContext'] = ResolversParentTypes['EmailAdvertisingRevocationPageContext']
> = {
  content?: Resolver<
    ResolversTypes['EmailAdvertisingStaticContent'],
    ParentType,
    ContextType
  >;
  contentInfo?: Resolver<
    ResolversTypes['EmailAdvertisingStaticContentInfo'],
    ParentType,
    ContextType
  >;
  emailFromToken?: Resolver<
    ResolversTypes['EmailAdvertisingGetEmailFromTokenResponse'],
    ParentType,
    ContextType,
    RequireFields<
      EmailAdvertisingRevocationPageContextEmailFromTokenArgs,
      'token'
    >
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  tokenFromEmail?: Resolver<
    ResolversTypes['EmailAdvertisingGetTokenFromEmailResponse'],
    ParentType,
    ContextType,
    RequireFields<
      EmailAdvertisingRevocationPageContextTokenFromEmailArgs,
      'email'
    >
  >;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailAdvertisingStaticContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailAdvertisingStaticContent'] = ResolversParentTypes['EmailAdvertisingStaticContent']
> = {
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<EmailAdvertisingStaticContentHeroArgs, 'criteria'>
  >;
  info?: Resolver<ResolversTypes['TextComponent'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailAdvertisingStaticContentInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailAdvertisingStaticContentInfo'] = ResolversParentTypes['EmailAdvertisingStaticContentInfo']
> = {
  breadcrumbs?: Resolver<
    Maybe<ResolversTypes['BreadcrumbsComponent']>,
    ParentType,
    ContextType
  >;
  flagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<EmailAdvertisingStaticContentInfoImageArgs, 'criteria'>
  >;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  textBgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  textBgImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<EmailAdvertisingStaticContentInfoTextBgImageArgs, 'criteria'>
  >;
  textFgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailAdvertisingGetEmailFromTokenResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailAdvertisingGetEmailFromTokenResponse'] = ResolversParentTypes['EmailAdvertisingGetEmailFromTokenResponse']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailAdvertisingGetTokenFromEmailResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailAdvertisingGetTokenFromEmailResponse'] = ResolversParentTypes['EmailAdvertisingGetTokenFromEmailResponse']
> = {
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailAdvertisingRevocationConfirmationPageContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailAdvertisingRevocationConfirmationPageContext'] = ResolversParentTypes['EmailAdvertisingRevocationConfirmationPageContext']
> = {
  content?: Resolver<
    ResolversTypes['EmailAdvertisingStaticContent'],
    ParentType,
    ContextType
  >;
  contentInfo?: Resolver<
    ResolversTypes['EmailAdvertisingStaticContentInfo'],
    ParentType,
    ContextType
  >;
  dynamicComponents?: Resolver<
    Array<ResolversTypes['EmailAdvertisingDynamicComponent']>,
    ParentType,
    ContextType
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailAdvertisingDynamicComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailAdvertisingDynamicComponent'] = ResolversParentTypes['EmailAdvertisingDynamicComponent']
> = {
  __resolveType: TypeResolveFn<
    'EmailAdvertisingRecommendationGroup',
    ParentType,
    ContextType
  >;
};

export type EmailAdvertisingRecommendationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailAdvertisingRecommendationGroup'] = ResolversParentTypes['EmailAdvertisingRecommendationGroup']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['EmailAdvertisingRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailAdvertisingRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailAdvertisingRecommendationItem'] = ResolversParentTypes['EmailAdvertisingRecommendationItem']
> = {
  __resolveType: TypeResolveFn<
    'GeoRecommendationItem' | 'ThemeRecommendationItem',
    ParentType,
    ContextType
  >;
};

export type FlightHomeContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightHomeContext'] = ResolversParentTypes['FlightHomeContext']
> = {
  autocomplete?: Resolver<
    ResolversTypes['AutocompleteComponent'],
    ParentType,
    ContextType,
    RequireFields<FlightHomeContextAutocompleteArgs, 'criteria'>
  >;
  dynamicComponents?: Resolver<
    Array<ResolversTypes['FlightHomeDynamicComponent']>,
    ParentType,
    ContextType,
    Partial<FlightHomeContextDynamicComponentsArgs>
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<FlightHomeContextHeroArgs, 'criteria'>
  >;
  info?: Resolver<ResolversTypes['TextComponent'], ParentType, ContextType>;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  searchControl?: Resolver<
    ResolversTypes['SearchControlUnion'],
    ParentType,
    ContextType,
    Partial<FlightHomeContextSearchControlArgs>
  >;
  url?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType,
    RequireFields<FlightHomeContextUrlArgs, 'criteria' | 'targetPageType'>
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AutocompleteComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AutocompleteComponent'] = ResolversParentTypes['AutocompleteComponent']
> = {
  destinations?: Resolver<
    Array<ResolversTypes['TravelDestination']>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelDestinationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TravelDestination'] = ResolversParentTypes['TravelDestination']
> = {
  documentId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<
    Maybe<ResolversTypes['GeoLocation']>,
    ParentType,
    ContextType
  >;
  mythosCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  themeInformation?: Resolver<
    Maybe<ResolversTypes['ThemeDestinationInformation']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<
    ResolversTypes['TravelDestinationType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoLocation'] = ResolversParentTypes['GeoLocation']
> = {
  airport?: Resolver<Maybe<ResolversTypes['Airport']>, ParentType, ContextType>;
  continent?: Resolver<
    Maybe<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  coordinates?: Resolver<
    Maybe<ResolversTypes['GeoCoordinates']>,
    ParentType,
    ContextType
  >;
  country?: Resolver<
    Maybe<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  countryGroup?: Resolver<
    Maybe<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  destination?: Resolver<
    Maybe<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  region?: Resolver<
    Maybe<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  resort?: Resolver<
    Maybe<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeDestinationInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemeDestinationInformation'] = ResolversParentTypes['ThemeDestinationInformation']
> = {
  plural?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  singular?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightHomeDynamicComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightHomeDynamicComponent'] = ResolversParentTypes['FlightHomeDynamicComponent']
> = {
  __resolveType: TypeResolveFn<
    'FlightHomeRecommendationGroup' | 'MediaGallery' | 'TextComponent',
    ParentType,
    ContextType
  >;
};

export type FlightHomeRecommendationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightHomeRecommendationGroup'] = ResolversParentTypes['FlightHomeRecommendationGroup']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['FlightHomeRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightHomeRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightHomeRecommendationItem'] = ResolversParentTypes['FlightHomeRecommendationItem']
> = {
  __resolveType: TypeResolveFn<
    | 'BD4TravelRecommendation'
    | 'FlightRecommendationWithoutPriceItem'
    | 'FlightRecommendationWithPriceItem'
    | 'GeoRecommendationItem'
    | 'ThemeRecommendationItem',
    ParentType,
    ContextType
  >;
};

export type SearchControlUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchControlUnion'] = ResolversParentTypes['SearchControlUnion']
> = {
  __resolveType: TypeResolveFn<
    'FlightSearchControlComponent' | 'SearchControlComponent',
    ParentType,
    ContextType
  >;
};

export type FlightSearchControlComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightSearchControlComponent'] = ResolversParentTypes['FlightSearchControlComponent']
> = {
  flightPartitions?: Resolver<
    Maybe<Array<ResolversTypes['FlightPartition']>>,
    ParentType,
    ContextType
  >;
  flightType?: Resolver<ResolversTypes['FlightType'], ParentType, ContextType>;
  travellers?: Resolver<ResolversTypes['Travellers'], ParentType, ContextType>;
  travelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightPartitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightPartition'] = ResolversParentTypes['FlightPartition']
> = {
  arrivalAirport?: Resolver<
    Maybe<ResolversTypes['Airport']>,
    ParentType,
    ContextType
  >;
  departureAirport?: Resolver<
    Maybe<ResolversTypes['Airport']>,
    ParentType,
    ContextType
  >;
  flightClass?: Resolver<
    ResolversTypes['FlightClass'],
    ParentType,
    ContextType
  >;
  travelPeriod?: Resolver<
    ResolversTypes['ExactTravelPeriod'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExactTravelPeriodResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExactTravelPeriod'] = ResolversParentTypes['ExactTravelPeriod']
> = {
  departureDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  returnDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravellersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Travellers'] = ResolversParentTypes['Travellers']
> = {
  adults?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  childrenDobs?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchControlComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchControlComponent'] = ResolversParentTypes['SearchControlComponent']
> = {
  destinations?: Resolver<
    Maybe<Array<ResolversTypes['TravelDestination']>>,
    ParentType,
    ContextType
  >;
  period?: Resolver<
    ResolversTypes['TravelPeriodComponent'],
    ParentType,
    ContextType
  >;
  rooms?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  travellers?: Resolver<ResolversTypes['Travellers'], ParentType, ContextType>;
  travellersDistribution?: Resolver<
    Maybe<ResolversTypes['TravellersDistribution']>,
    ParentType,
    ContextType
  >;
  travelPeriod?: Resolver<
    ResolversTypes['TravelPeriodUnion'],
    ParentType,
    ContextType
  >;
  travelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelPeriodComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TravelPeriodComponent'] = ResolversParentTypes['TravelPeriodComponent']
> = {
  exactPeriod?: Resolver<
    ResolversTypes['ExactTravelPeriod'],
    ParentType,
    ContextType
  >;
  flexiblePeriod?: Resolver<
    ResolversTypes['FlexibleTravelPeriod'],
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['SearchPeriodType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlexibleTravelPeriodResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlexibleTravelPeriod'] = ResolversParentTypes['FlexibleTravelPeriod']
> = {
  departureDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returnDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravellersDistributionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TravellersDistribution'] = ResolversParentTypes['TravellersDistribution']
> = {
  rooms?: Resolver<
    Array<ResolversTypes['RoomDistribution']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoomDistributionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoomDistribution'] = ResolversParentTypes['RoomDistribution']
> = {
  adultsAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  childrenIdx?: Resolver<
    Maybe<Array<ResolversTypes['Int']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelPeriodUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TravelPeriodUnion'] = ResolversParentTypes['TravelPeriodUnion']
> = {
  __resolveType: TypeResolveFn<
    'ExactTravelPeriod' | 'FlexibleTravelPeriod',
    ParentType,
    ContextType
  >;
};

export type FlightSrlContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightSrlContext'] = ResolversParentTypes['FlightSrlContext']
> = {
  autocomplete?: Resolver<
    ResolversTypes['AutocompleteComponent'],
    ParentType,
    ContextType,
    RequireFields<FlightSrlContextAutocompleteArgs, 'criteria'>
  >;
  checkout?: Resolver<
    ResolversTypes['FlightCheckoutComponent'],
    ParentType,
    ContextType,
    RequireFields<FlightSrlContextCheckoutArgs, 'backUrl' | 'offerId'>
  >;
  content?: Resolver<
    ResolversTypes['FlightSrlContainer'],
    ParentType,
    ContextType
  >;
  dynamicComponents?: Resolver<
    Array<ResolversTypes['FlightSrlDynamicComponent']>,
    ParentType,
    ContextType,
    RequireFields<FlightSrlContextDynamicComponentsArgs, 'searchCriteria'>
  >;
  filters?: Resolver<
    ResolversTypes['FilterComponent'],
    ParentType,
    ContextType,
    Partial<FlightSrlContextFiltersArgs>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  searchControl?: Resolver<
    ResolversTypes['FlightSearchControlComponent'],
    ParentType,
    ContextType,
    Partial<FlightSrlContextSearchControlArgs>
  >;
  url?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType,
    RequireFields<FlightSrlContextUrlArgs, 'criteria'>
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightCheckoutComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightCheckoutComponent'] = ResolversParentTypes['FlightCheckoutComponent']
> = {
  changedOffer?: Resolver<
    Maybe<ResolversTypes['FlightOffer']>,
    ParentType,
    ContextType
  >;
  checkoutStatus?: Resolver<
    ResolversTypes['FlightCheckoutStatus'],
    ParentType,
    ContextType
  >;
  flightChanged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  link?: Resolver<
    Maybe<ResolversTypes['ExternalLink']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priceChanged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightSrlContainerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightSrlContainer'] = ResolversParentTypes['FlightSrlContainer']
> = {
  flights?: Resolver<
    ResolversTypes['FlightSrlComponent'],
    ParentType,
    ContextType,
    RequireFields<FlightSrlContainerFlightsArgs, 'flightSrlSearchCriteria'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightSrlComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightSrlComponent'] = ResolversParentTypes['FlightSrlComponent']
> = {
  cheapestPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isCacheEmpty?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  offers?: Resolver<
    Array<ResolversTypes['FlightOffer']>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  stopOverDuration?: Resolver<
    ResolversTypes['FlightStopOverDuration'],
    ParentType,
    ContextType
  >;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightStopOverDurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightStopOverDuration'] = ResolversParentTypes['FlightStopOverDuration']
> = {
  maxDuration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minDuration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightSrlDynamicComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightSrlDynamicComponent'] = ResolversParentTypes['FlightSrlDynamicComponent']
> = {
  __resolveType: TypeResolveFn<
    'FlightSrlRecommendationGroup',
    ParentType,
    ContextType
  >;
};

export type FlightSrlRecommendationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightSrlRecommendationGroup'] = ResolversParentTypes['FlightSrlRecommendationGroup']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['FlightSrlRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightSrlRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightSrlRecommendationItem'] = ResolversParentTypes['FlightSrlRecommendationItem']
> = {
  __resolveType: TypeResolveFn<
    'BD4TravelRecommendation',
    ParentType,
    ContextType
  >;
};

export type FilterComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FilterComponent'] = ResolversParentTypes['FilterComponent']
> = {
  accommodationSize?: Resolver<
    Maybe<ResolversTypes['CheckboxFilterComponent']>,
    ParentType,
    ContextType
  >;
  adultsFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  arrivalAirports?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  arrivalWeekdays?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  beachFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  boardTypes?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  childrenFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  cityFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  clubHotelsFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  cultureFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  customerSegmentFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  departureAirports?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  departureFlightTime?: Resolver<
    ResolversTypes['RadiobuttonFilterComponent'],
    ParentType,
    ContextType
  >;
  departureWeekdays?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  directFlightArrivalTime?: Resolver<
    ResolversTypes['RadiobuttonFilterComponent'],
    ParentType,
    ContextType
  >;
  directFlightDepartureTime?: Resolver<
    ResolversTypes['RadiobuttonFilterComponent'],
    ParentType,
    ContextType
  >;
  distanceFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  ECarChargingFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  environmentFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  flightAirlines?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  flightDuration?: Resolver<
    ResolversTypes['SliderFilterComponent'],
    ParentType,
    ContextType
  >;
  flightProvider?: Resolver<
    Maybe<ResolversTypes['SingleValueFilterComponent']>,
    ParentType,
    ContextType
  >;
  flightStopOverDuration?: Resolver<
    ResolversTypes['DoubleRangeSliderFilterComponent'],
    ParentType,
    ContextType
  >;
  hotelEntertainmentFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  hotelFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  hpRating?: Resolver<
    ResolversTypes['RadiobuttonFilterComponent'],
    ParentType,
    ContextType
  >;
  luxuryFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  mainFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  maxPrice?: Resolver<
    ResolversTypes['SliderFilterComponent'],
    ParentType,
    ContextType
  >;
  minPrice?: Resolver<
    ResolversTypes['SliderFilterComponent'],
    ParentType,
    ContextType
  >;
  nationalParkFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  nightlifeFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  petsFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  popularFilters?: Resolver<
    Maybe<ResolversTypes['CheckboxFilterComponent']>,
    ParentType,
    ContextType
  >;
  productCode?: Resolver<
    Maybe<ResolversTypes['SingleValueFilterComponent']>,
    ParentType,
    ContextType
  >;
  provider?: Resolver<
    Maybe<ResolversTypes['SingleValueFilterComponent']>,
    ParentType,
    ContextType
  >;
  radius?: Resolver<
    Maybe<ResolversTypes['RadiusFilterComponent']>,
    ParentType,
    ContextType
  >;
  refundableFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  resortFlairFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  resortNatureFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  resortShoppingFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  restaurantFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  returnFlightArrivalTime?: Resolver<
    ResolversTypes['RadiobuttonFilterComponent'],
    ParentType,
    ContextType
  >;
  returnFlightDepartureTime?: Resolver<
    ResolversTypes['RadiobuttonFilterComponent'],
    ParentType,
    ContextType
  >;
  returnFlightTime?: Resolver<
    ResolversTypes['RadiobuttonFilterComponent'],
    ParentType,
    ContextType
  >;
  roomTypes?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  roundtripFeatures?: Resolver<
    Maybe<ResolversTypes['CheckboxFilterComponent']>,
    ParentType,
    ContextType
  >;
  shipFeatures?: Resolver<
    Maybe<ResolversTypes['CheckboxFilterComponent']>,
    ParentType,
    ContextType
  >;
  sportFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  stopOvers?: Resolver<
    ResolversTypes['RadiobuttonFilterComponent'],
    ParentType,
    ContextType
  >;
  summerSportFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  sustainableFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  taRating?: Resolver<
    ResolversTypes['RadiobuttonFilterComponent'],
    ParentType,
    ContextType
  >;
  waterSportFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  wellnessFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  winterSportFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  wlanFeatures?: Resolver<
    ResolversTypes['CheckboxFilterComponent'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CheckboxFilterComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CheckboxFilterComponent'] = ResolversParentTypes['CheckboxFilterComponent']
> = {
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  options?: Resolver<
    Array<ResolversTypes['FilterItem']>,
    ParentType,
    ContextType
  >;
  selected?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilterItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FilterItem'] = ResolversParentTypes['FilterItem']
> = {
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RadiobuttonFilterComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RadiobuttonFilterComponent'] = ResolversParentTypes['RadiobuttonFilterComponent']
> = {
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  options?: Resolver<
    Array<ResolversTypes['FilterItem']>,
    ParentType,
    ContextType
  >;
  selected?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SliderFilterComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SliderFilterComponent'] = ResolversParentTypes['SliderFilterComponent']
> = {
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  selected?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SingleValueFilterComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SingleValueFilterComponent'] = ResolversParentTypes['SingleValueFilterComponent']
> = {
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DoubleRangeSliderFilterComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DoubleRangeSliderFilterComponent'] = ResolversParentTypes['DoubleRangeSliderFilterComponent']
> = {
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  maxSelected?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minSelected?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RadiusFilterComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RadiusFilterComponent'] = ResolversParentTypes['RadiusFilterComponent']
> = {
  center?: Resolver<ResolversTypes['GeoCoordinates'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  radius?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WaitingScreenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WaitingScreen'] = ResolversParentTypes['WaitingScreen']
> = {
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoContext'] = ResolversParentTypes['GeoContext']
> = {
  autocomplete?: Resolver<
    ResolversTypes['AutocompleteComponent'],
    ParentType,
    ContextType,
    Partial<GeoContextAutocompleteArgs>
  >;
  blogArticles?: Resolver<
    Array<ResolversTypes['BlogArticleItem']>,
    ParentType,
    ContextType
  >;
  bookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  children?: Resolver<
    ResolversTypes['GeoChildrenComponentInterface'],
    ParentType,
    ContextType
  >;
  childrenOffers?: Resolver<
    Maybe<ResolversTypes['GeoChildrenOffersComponent']>,
    ParentType,
    ContextType,
    Partial<GeoContextChildrenOffersArgs>
  >;
  climateData?: Resolver<
    Maybe<ResolversTypes['ClimateComponent']>,
    ParentType,
    ContextType
  >;
  componentsBasedStructure?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  defaultRecommendations?: Resolver<
    Maybe<ResolversTypes['GeoDefaultGeoRecommendationsComponent']>,
    ParentType,
    ContextType,
    Partial<GeoContextDefaultRecommendationsArgs>
  >;
  dynamicComponents?: Resolver<
    Array<ResolversTypes['GeoObjectPageComponent']>,
    ParentType,
    ContextType,
    Partial<GeoContextDynamicComponentsArgs>
  >;
  extraHotels?: Resolver<
    Array<ResolversTypes['GeoProduct']>,
    ParentType,
    ContextType
  >;
  filters?: Resolver<
    ResolversTypes['MainFilterComponent'],
    ParentType,
    ContextType,
    Partial<GeoContextFiltersArgs>
  >;
  galleryWithMap?: Resolver<
    Maybe<ResolversTypes['ProductGalleryWithMapComponent']>,
    ParentType,
    ContextType
  >;
  geoProductFilters?: Resolver<
    Maybe<Array<ResolversTypes['ProductFilter']>>,
    ParentType,
    ContextType
  >;
  geoStaticComponents?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['DynamicComponent']>>>,
    ParentType,
    ContextType
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<GeoContextHeroArgs, 'criteria'>
  >;
  information?: Resolver<
    ResolversTypes['GeoInfoComponent'],
    ParentType,
    ContextType
  >;
  inpageNavigation?: Resolver<
    Maybe<Array<ResolversTypes['InpageNavigationItem']>>,
    ParentType,
    ContextType
  >;
  mapAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mediaGallery?: Resolver<
    ResolversTypes['MediaGallery'],
    ParentType,
    ContextType
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  productGallery?: Resolver<
    Maybe<ResolversTypes['ProductImageGallery']>,
    ParentType,
    ContextType
  >;
  products?: Resolver<
    Array<ResolversTypes['GeoProduct']>,
    ParentType,
    ContextType
  >;
  regularHotels?: Resolver<
    Array<ResolversTypes['GeoProduct']>,
    ParentType,
    ContextType
  >;
  relatedRoundtrips?: Resolver<
    Maybe<Array<ResolversTypes['RelatedRoundtrips']>>,
    ParentType,
    ContextType
  >;
  requestButton?: Resolver<
    Maybe<ResolversTypes['RequestButtonComponent']>,
    ParentType,
    ContextType
  >;
  searchControl?: Resolver<
    ResolversTypes['SearchControlUnion'],
    ParentType,
    ContextType,
    Partial<GeoContextSearchControlArgs>
  >;
  seoChildren?: Resolver<
    Maybe<ResolversTypes['SeoChildrenComponent']>,
    ParentType,
    ContextType,
    Partial<GeoContextSeoChildrenArgs>
  >;
  siblings?: Resolver<
    Maybe<ResolversTypes['GeoSiblingsComponent']>,
    ParentType,
    ContextType
  >;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  themes?: Resolver<Array<ResolversTypes['GeoTheme']>, ParentType, ContextType>;
  url?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType,
    Partial<GeoContextUrlArgs>
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoChildrenComponentInterfaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoChildrenComponentInterface'] = ResolversParentTypes['GeoChildrenComponentInterface']
> = {
  __resolveType: TypeResolveFn<
    'GeoChildrenComponent' | 'ResortTopHotelsComponent',
    ParentType,
    ContextType
  >;
};

export type GeoChildrenOffersComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoChildrenOffersComponent'] = ResolversParentTypes['GeoChildrenOffersComponent']
> = {
  childrenOffers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['GeoChildrenOffer']>>>,
    ParentType,
    ContextType
  >;
  page?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoChildrenOfferResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoChildrenOffer'] = ResolversParentTypes['GeoChildrenOffer']
> = {
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<GeoChildrenOfferImageArgs, 'criteria'>
  >;
  leadText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offerInfo?: Resolver<
    Maybe<ResolversTypes['GeoOfferInfo']>,
    ParentType,
    ContextType
  >;
  productsTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoOfferInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoOfferInfo'] = ResolversParentTypes['GeoOfferInfo']
> = {
  cheapestPrice?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  numberOfNightsInfo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  productsTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  travelTypeInfo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoDefaultGeoRecommendationsComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoDefaultGeoRecommendationsComponent'] = ResolversParentTypes['GeoDefaultGeoRecommendationsComponent']
> = {
  items?: Resolver<
    Array<ResolversTypes['GeoRecommendationItem']>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoObjectPageComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoObjectPageComponent'] = ResolversParentTypes['GeoObjectPageComponent']
> = {
  __resolveType: TypeResolveFn<
    'GeoObjectRecommendationGroupComponent',
    ParentType,
    ContextType
  >;
};

export type GeoObjectRecommendationGroupComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoObjectRecommendationGroupComponent'] = ResolversParentTypes['GeoObjectRecommendationGroupComponent']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['GeoObjectRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoObjectRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoObjectRecommendationItem'] = ResolversParentTypes['GeoObjectRecommendationItem']
> = {
  __resolveType: TypeResolveFn<
    | 'BD4TravelRecommendation'
    | 'BlogArticleRecommendationItem'
    | 'GeoRecommendationItem'
    | 'MarketingRecommendationItem'
    | 'ProductRecommendationItem'
    | 'ThemeRecommendationItem',
    ParentType,
    ContextType
  >;
};

export type GeoProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoProduct'] = ResolversParentTypes['GeoProduct']
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  breadcrumbs?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  disrupters?: Resolver<
    Maybe<Array<ResolversTypes['DisrupterObject']>>,
    ParentType,
    ContextType
  >;
  features?: Resolver<
    Array<ResolversTypes['ProductFeature']>,
    ParentType,
    ContextType
  >;
  googleStaticMapImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<GeoProductGoogleStaticMapImageArgs, 'criteria'>
  >;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<GeoProductImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productInfoB2B?: Resolver<
    Maybe<ResolversTypes['ProductInfoB2B']>,
    ParentType,
    ContextType
  >;
  route?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  routePoints?: Resolver<
    Maybe<Array<ResolversTypes['RoutePoint']>>,
    ParentType,
    ContextType
  >;
  teaser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MainFilterComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MainFilterComponent'] = ResolversParentTypes['MainFilterComponent']
> = {
  mainFilter?: Resolver<
    ResolversTypes['FilterComponent'],
    ParentType,
    ContextType
  >;
  subGeoFilter?: Resolver<
    ResolversTypes['SubGeoFilterComponent'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubGeoFilterComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubGeoFilterComponent'] = ResolversParentTypes['SubGeoFilterComponent']
> = {
  items?: Resolver<
    Maybe<Array<ResolversTypes['SubGeoItem']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubGeoItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubGeoItem'] = ResolversParentTypes['SubGeoItem']
> = {
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FILTER_ATTRIBUTE_CAPTION?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  FILTER_ATTRIBUTE_ID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  FILTER_ATTRIBUTE_PRODUCT_COUNT?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  FILTER_ATTRIBUTE_SELECTED?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<SubGeoItemImageArgs, 'criteria'>
  >;
  productCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  selected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoInfoComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoInfoComponent'] = ResolversParentTypes['GeoInfoComponent']
> = {
  attributes?: Resolver<
    Maybe<Array<ResolversTypes['GeoFeatureGroup']>>,
    ParentType,
    ContextType
  >;
  bestTravelPeriodInformation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bestTravelPeriodInformationAccordion?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AccordionItem']>>>,
    ParentType,
    ContextType
  >;
  bestTravelPeriodText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bestTravelPeriodTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  breadcrumbs?: Resolver<
    Maybe<ResolversTypes['BreadcrumbsComponent']>,
    ParentType,
    ContextType
  >;
  coordinates?: Resolver<
    Maybe<ResolversTypes['GeoCoordinates']>,
    ParentType,
    ContextType
  >;
  facts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  flightDuration?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  highlightsText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  highlightsTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  leadText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leadTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  link?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  mostPopularTravelPeriod?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameH1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offerInfo?: Resolver<
    Maybe<ResolversTypes['GeoOfferInfo']>,
    ParentType,
    ContextType
  >;
  seoText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoFeatureGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoFeatureGroup'] = ResolversParentTypes['GeoFeatureGroup']
> = {
  details?: Resolver<
    Array<ResolversTypes['GeoFeature']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoFeature'] = ResolversParentTypes['GeoFeature']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccordionItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccordionItem'] = ResolversParentTypes['AccordionItem']
> = {
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InpageNavigationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InpageNavigationItem'] = ResolversParentTypes['InpageNavigationItem']
> = {
  anchor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductImageGalleryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductImageGallery'] = ResolversParentTypes['ProductImageGallery']
> = {
  images?: Resolver<
    Array<ResolversTypes['ProductImage']>,
    ParentType,
    ContextType
  >;
  productsImages?: Resolver<
    Array<ResolversTypes['ProductImage']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductImage'] = ResolversParentTypes['ProductImage']
> = {
  image?: Resolver<ResolversTypes['ImageMediaItem'], ParentType, ContextType>;
  images?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ImageMediaItem']>>>,
    ParentType,
    ContextType
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  productInfoB2B?: Resolver<
    Maybe<ResolversTypes['ProductInfoB2B']>,
    ParentType,
    ContextType
  >;
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeoChildrenComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeoChildrenComponent'] = ResolversParentTypes['SeoChildrenComponent']
> = {
  childrenOffers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SeoGeoChildren']>>>,
    ParentType,
    ContextType
  >;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeoGeoChildrenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeoGeoChildren'] = ResolversParentTypes['SeoGeoChildren']
> = {
  link?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoSiblingsComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoSiblingsComponent'] = ResolversParentTypes['GeoSiblingsComponent']
> = {
  page?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType>;
  siblings?: Resolver<
    Array<ResolversTypes['GeoChildComponent']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoThemeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoTheme'] = ResolversParentTypes['GeoTheme']
> = {
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<GeoThemeImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoOverviewContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoOverviewContext'] = ResolversParentTypes['GeoOverviewContext']
> = {
  blogArticles?: Resolver<
    Array<ResolversTypes['BlogArticleRecommendationItem']>,
    ParentType,
    ContextType
  >;
  blogArticlesTitle?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  breadcrumbs?: Resolver<
    Array<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  children?: Resolver<
    Array<ResolversTypes['GeoOverviewChildComponent']>,
    ParentType,
    ContextType
  >;
  dynamicComponents?: Resolver<
    Array<ResolversTypes['GeoOverviewPageComponent']>,
    ParentType,
    ContextType
  >;
  geoHierarchy?: Resolver<
    ResolversTypes['GeoHierarchyComponent'],
    ParentType,
    ContextType
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<GeoOverviewContextHeroArgs, 'criteria'>
  >;
  information?: Resolver<
    ResolversTypes['TextComponent'],
    ParentType,
    ContextType
  >;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  pageBreadcrumbs?: Resolver<
    ResolversTypes['BreadcrumbsComponent'],
    ParentType,
    ContextType
  >;
  sharingOverlayDefaultImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<GeoOverviewContextSharingOverlayDefaultImageArgs, 'criteria'>
  >;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoOverviewChildComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoOverviewChildComponent'] = ResolversParentTypes['GeoOverviewChildComponent']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<GeoOverviewChildComponentImageArgs, 'criteria'>
  >;
  lastChild?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  offerInfo?: Resolver<
    Maybe<ResolversTypes['GeoOverviewChildOfferInfo']>,
    ParentType,
    ContextType
  >;
  srlLink?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoOverviewChildOfferInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoOverviewChildOfferInfo'] = ResolversParentTypes['GeoOverviewChildOfferInfo']
> = {
  cheapestPrice?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  destinationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  example?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfNightsInfo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  travelTypeInfo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoOverviewPageComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoOverviewPageComponent'] = ResolversParentTypes['GeoOverviewPageComponent']
> = {
  __resolveType: TypeResolveFn<
    'GeoOverviewRecommendationGroupComponent',
    ParentType,
    ContextType
  >;
};

export type GeoOverviewRecommendationGroupComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoOverviewRecommendationGroupComponent'] = ResolversParentTypes['GeoOverviewRecommendationGroupComponent']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['GeoOverviewRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoOverviewRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoOverviewRecommendationItem'] = ResolversParentTypes['GeoOverviewRecommendationItem']
> = {
  __resolveType: TypeResolveFn<
    'GeoRecommendationItem' | 'ThemeRecommendationItem',
    ParentType,
    ContextType
  >;
};

export type GeoHierarchyComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoHierarchyComponent'] = ResolversParentTypes['GeoHierarchyComponent']
> = {
  continents?: Resolver<
    Array<ResolversTypes['GeoHierarchyContinent']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoHierarchyContinentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoHierarchyContinent'] = ResolversParentTypes['GeoHierarchyContinent']
> = {
  countries?: Resolver<
    Array<ResolversTypes['GeoHierarchyCountry']>,
    ParentType,
    ContextType
  >;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<GeoHierarchyContinentImageArgs, 'criteria'>
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoHierarchyCountryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoHierarchyCountry'] = ResolversParentTypes['GeoHierarchyCountry']
> = {
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HelpOverlayDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HelpOverlayData'] = ResolversParentTypes['HelpOverlayData']
> = {
  helpOverlayBoxes?: Resolver<
    Maybe<Array<ResolversTypes['HelpOverlayBoxBase']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HelpOverlayBoxBaseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HelpOverlayBoxBase'] = ResolversParentTypes['HelpOverlayBoxBase']
> = {
  __resolveType: TypeResolveFn<
    'HelpOverlayBox' | 'HelpOverlayBoxChat' | 'HelpOverlayBoxContact',
    ParentType,
    ContextType
  >;
};

export type HelpOverlayBoxResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HelpOverlayBox'] = ResolversParentTypes['HelpOverlayBox']
> = {
  icon?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<HelpOverlayBoxIconArgs, 'criteria'>
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  targetUrl?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HelpOverlayBoxChatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HelpOverlayBoxChat'] = ResolversParentTypes['HelpOverlayBoxChat']
> = {
  icon?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<HelpOverlayBoxChatIconArgs, 'criteria'>
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  targetUrl?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HelpOverlayBoxContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HelpOverlayBoxContact'] = ResolversParentTypes['HelpOverlayBoxContact']
> = {
  additionalInformation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  icon?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<HelpOverlayBoxContactIconArgs, 'criteria'>
  >;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  targetUrl?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryContext'] = ResolversParentTypes['HistoryContext']
> = {
  lastSeenHotels?: Resolver<
    ResolversTypes['LastSeenResponse'],
    ParentType,
    ContextType,
    Partial<HistoryContextLastSeenHotelsArgs>
  >;
  recentFlightSearches?: Resolver<
    Array<ResolversTypes['HistoryFlightRecord']>,
    ParentType,
    ContextType,
    Partial<HistoryContextRecentFlightSearchesArgs>
  >;
  recentFlightSearchesWithPage?: Resolver<
    ResolversTypes['HistoryFlightResponse'],
    ParentType,
    ContextType,
    RequireFields<HistoryContextRecentFlightSearchesWithPageArgs, 'page'>
  >;
  recentFullTextSearches?: Resolver<
    Array<ResolversTypes['HistoryUserRecord']>,
    ParentType,
    ContextType,
    Partial<HistoryContextRecentFullTextSearchesArgs>
  >;
  recentFullTextSearchesWithPage?: Resolver<
    ResolversTypes['HistoryUserResponse'],
    ParentType,
    ContextType,
    RequireFields<HistoryContextRecentFullTextSearchesWithPageArgs, 'page'>
  >;
  recentProducts?: Resolver<
    Array<ResolversTypes['HistoryProductRecord']>,
    ParentType,
    ContextType,
    Partial<HistoryContextRecentProductsArgs>
  >;
  recentProductsWithPage?: Resolver<
    ResolversTypes['HistoryProductResponse'],
    ParentType,
    ContextType,
    RequireFields<HistoryContextRecentProductsWithPageArgs, 'page'>
  >;
  recentSearches?: Resolver<
    Array<ResolversTypes['HistoryRecordUnion']>,
    ParentType,
    ContextType,
    Partial<HistoryContextRecentSearchesArgs>
  >;
  recentSearchesByTravelType?: Resolver<
    Array<ResolversTypes['HistoryRecordUnion']>,
    ParentType,
    ContextType,
    RequireFields<HistoryContextRecentSearchesByTravelTypeArgs, 'travelType'>
  >;
  recentSearchesByTravelTypeWithPage?: Resolver<
    ResolversTypes['HistoryUnionResponse'],
    ParentType,
    ContextType,
    RequireFields<
      HistoryContextRecentSearchesByTravelTypeWithPageArgs,
      'page' | 'travelType'
    >
  >;
  recentSearchesWithPage?: Resolver<
    ResolversTypes['HistoryUnionResponse'],
    ParentType,
    ContextType,
    RequireFields<HistoryContextRecentSearchesWithPageArgs, 'page'>
  >;
  recentSrlSearches?: Resolver<
    Array<ResolversTypes['HistorySrlRecord']>,
    ParentType,
    ContextType,
    Partial<HistoryContextRecentSrlSearchesArgs>
  >;
  recentSrlSearchesWithPage?: Resolver<
    ResolversTypes['HistorySrlResponse'],
    ParentType,
    ContextType,
    RequireFields<HistoryContextRecentSrlSearchesWithPageArgs, 'page'>
  >;
  recentViewed?: Resolver<
    Array<ResolversTypes['HistoryUserRecord']>,
    ParentType,
    ContextType,
    Partial<HistoryContextRecentViewedArgs>
  >;
  recentViewedWithPage?: Resolver<
    ResolversTypes['HistoryUserResponse'],
    ParentType,
    ContextType,
    RequireFields<HistoryContextRecentViewedWithPageArgs, 'page'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LastSeenResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LastSeenResponse'] = ResolversParentTypes['LastSeenResponse']
> = {
  lastSeenItems?: Resolver<
    Maybe<Array<ResolversTypes['LastSeenItem']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LastSeenItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LastSeenItem'] = ResolversParentTypes['LastSeenItem']
> = {
  hero?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<LastSeenItemHeroArgs, 'criteria'>
  >;
  hpRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  locations?: Resolver<
    Maybe<Array<ResolversTypes['GeoObject']>>,
    ParentType,
    ContextType
  >;
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryFlightRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryFlightRecord'] = ResolversParentTypes['HistoryFlightRecord']
> = {
  flightPartitions?: Resolver<
    Maybe<Array<ResolversTypes['FlightPartition']>>,
    ParentType,
    ContextType
  >;
  flightType?: Resolver<ResolversTypes['FlightType'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  travellers?: Resolver<ResolversTypes['Travellers'], ParentType, ContextType>;
  travelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryFlightResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryFlightResponse'] = ResolversParentTypes['HistoryFlightResponse']
> = {
  flightRecords?: Resolver<
    Maybe<Array<ResolversTypes['HistoryFlightRecord']>>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryUserRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryUserRecord'] = ResolversParentTypes['HistoryUserRecord']
> = {
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryUserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryUserResponse'] = ResolversParentTypes['HistoryUserResponse']
> = {
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  userRecords?: Resolver<
    Maybe<Array<ResolversTypes['HistoryUserRecord']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryProductRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryProductRecord'] = ResolversParentTypes['HistoryProductRecord']
> = {
  destinations?: Resolver<
    Maybe<Array<ResolversTypes['TravelDestination']>>,
    ParentType,
    ContextType
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  productCase?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  travelType?: Resolver<
    Maybe<ResolversTypes['TravelType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryProductResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryProductResponse'] = ResolversParentTypes['HistoryProductResponse']
> = {
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  productRecords?: Resolver<
    Maybe<Array<ResolversTypes['HistoryProductRecord']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryRecordUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryRecordUnion'] = ResolversParentTypes['HistoryRecordUnion']
> = {
  __resolveType: TypeResolveFn<
    'HistoryFlightRecord' | 'HistorySrlRecord',
    ParentType,
    ContextType
  >;
};

export type HistorySrlRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistorySrlRecord'] = ResolversParentTypes['HistorySrlRecord']
> = {
  destinations?: Resolver<
    Maybe<Array<ResolversTypes['TravelDestination']>>,
    ParentType,
    ContextType
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  period?: Resolver<
    ResolversTypes['TravelPeriodComponent'],
    ParentType,
    ContextType
  >;
  rooms?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  travellers?: Resolver<ResolversTypes['Travellers'], ParentType, ContextType>;
  travelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryUnionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryUnionResponse'] = ResolversParentTypes['HistoryUnionResponse']
> = {
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  recordUnions?: Resolver<
    Maybe<Array<ResolversTypes['HistoryRecordUnion']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistorySrlResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistorySrlResponse'] = ResolversParentTypes['HistorySrlResponse']
> = {
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  srlRecords?: Resolver<
    Maybe<Array<ResolversTypes['HistorySrlRecord']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HolidayFinderPageContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HolidayFinderPageContext'] = ResolversParentTypes['HolidayFinderPageContext']
> = {
  initialOffers?: Resolver<
    ResolversTypes['HolidayFinderVotingResponse'],
    ParentType,
    ContextType,
    RequireFields<HolidayFinderPageContextInitialOffersArgs, 'request'>
  >;
  landingPage?: Resolver<
    ResolversTypes['HolidayFinderLandingPage'],
    ParentType,
    ContextType
  >;
  pageMetaData?: Resolver<
    ResolversTypes['PageMetaData'],
    ParentType,
    ContextType
  >;
  resultOffers?: Resolver<
    ResolversTypes['HolidayFinderVotingResponse'],
    ParentType,
    ContextType,
    RequireFields<HolidayFinderPageContextResultOffersArgs, 'request'>
  >;
  resultPage?: Resolver<
    ResolversTypes['HolidayFinderPage'],
    ParentType,
    ContextType
  >;
  searchResult?: Resolver<
    ResolversTypes['HolidayFinderSearchResponse'],
    ParentType,
    ContextType,
    RequireFields<HolidayFinderPageContextSearchResultArgs, 'request'>
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  votingPage?: Resolver<
    ResolversTypes['HolidayFinderPage'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HolidayFinderVotingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HolidayFinderVotingResponse'] = ResolversParentTypes['HolidayFinderVotingResponse']
> = {
  info?: Resolver<ResolversTypes['HolidayFinderInfo'], ParentType, ContextType>;
  lowestPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  products?: Resolver<
    Array<ResolversTypes['HolidayFinderProduct']>,
    ParentType,
    ContextType
  >;
  ptoken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  tracking?: Resolver<
    ResolversTypes['HolidayFinderTracking'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HolidayFinderInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HolidayFinderInfo'] = ResolversParentTypes['HolidayFinderInfo']
> = {
  continueVoting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  elementSetId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HolidayFinderProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HolidayFinderProduct'] = ResolversParentTypes['HolidayFinderProduct']
> = {
  breadcrumbs?: Resolver<
    Array<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  featureSummary?: Resolver<
    Array<ResolversTypes['ProductFeatureGroup']>,
    ParentType,
    ContextType
  >;
  giataId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hpRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<HolidayFinderProductImageArgs, 'criteria'>
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offers?: Resolver<
    Array<ResolversTypes['HolidayFinderOffer']>,
    ParentType,
    ContextType
  >;
  productClass?: Resolver<
    ResolversTypes['ProductClass'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HolidayFinderOfferResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HolidayFinderOffer'] = ResolversParentTypes['HolidayFinderOffer']
> = {
  departureDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inWishlist?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  offerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pricePerPerson?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  returnDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>;
  wishlistItem?: Resolver<
    Maybe<ResolversTypes['WishlistItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HolidayFinderTrackingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HolidayFinderTracking'] = ResolversParentTypes['HolidayFinderTracking']
> = {
  elementSetId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  votesCollected?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HolidayFinderLandingPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HolidayFinderLandingPage'] = ResolversParentTypes['HolidayFinderLandingPage']
> = {
  children?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<HolidayFinderLandingPageHeroArgs, 'criteria'>
  >;
  info?: Resolver<ResolversTypes['TextComponent'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  travelPeriod?: Resolver<
    ResolversTypes['TravelPeriodComponent'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HolidayFinderPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HolidayFinderPage'] = ResolversParentTypes['HolidayFinderPage']
> = {
  info?: Resolver<ResolversTypes['TextComponent'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HolidayFinderSearchResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HolidayFinderSearchResponse'] = ResolversParentTypes['HolidayFinderSearchResponse']
> = {
  lowestPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  products?: Resolver<
    Array<ResolversTypes['HolidayFinderProduct']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomeContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HomeContext'] = ResolversParentTypes['HomeContext']
> = {
  autocomplete?: Resolver<
    ResolversTypes['AutocompleteComponent'],
    ParentType,
    ContextType,
    RequireFields<HomeContextAutocompleteArgs, 'criteria'>
  >;
  dynamicComponents?: Resolver<
    Array<ResolversTypes['HomeDynamicComponent']>,
    ParentType,
    ContextType,
    Partial<HomeContextDynamicComponentsArgs>
  >;
  link?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType,
    RequireFields<HomeContextLinkArgs, 'searchCriteria' | 'targetPageType'>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  moreVisibleComponent?: Resolver<
    ResolversTypes['MoreVisibilityStaticComponent'],
    ParentType,
    ContextType,
    Partial<HomeContextMoreVisibleComponentArgs>
  >;
  searchControl?: Resolver<
    ResolversTypes['SearchControlUnion'],
    ParentType,
    ContextType,
    Partial<HomeContextSearchControlArgs>
  >;
  staticContent?: Resolver<
    ResolversTypes['HomeStaticContent'],
    ParentType,
    ContextType
  >;
  totalProductCount?: Resolver<
    ResolversTypes['HomeTotalProductCount'],
    ParentType,
    ContextType,
    Partial<HomeContextTotalProductCountArgs>
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomeDynamicComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HomeDynamicComponent'] = ResolversParentTypes['HomeDynamicComponent']
> = {
  __resolveType: TypeResolveFn<
    | 'BrandsComponent'
    | 'EmployeesComponent'
    | 'FaqComponent'
    | 'HeroMediaGallery'
    | 'HomeRecommendationGroup'
    | 'LinkListComponent'
    | 'MediaGallery'
    | 'NewsArticlesComponent'
    | 'TextComponent'
    | 'UspBoxesComponent',
    ParentType,
    ContextType
  >;
};

export type HomeRecommendationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HomeRecommendationGroup'] = ResolversParentTypes['HomeRecommendationGroup']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['HomeRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomeRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HomeRecommendationItem'] = ResolversParentTypes['HomeRecommendationItem']
> = {
  __resolveType: TypeResolveFn<
    | 'BD4TravelRecommendation'
    | 'GeoRecommendationItem'
    | 'MarketingRecommendationItem'
    | 'ProductRecommendationItem'
    | 'ThemeRecommendationItem',
    ParentType,
    ContextType
  >;
};

export type MoreVisibilityStaticComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MoreVisibilityStaticComponent'] = ResolversParentTypes['MoreVisibilityStaticComponent']
> = {
  bd4TravelRecommendation?: Resolver<
    ResolversTypes['BD4TravelRecommendationGroup'],
    ParentType,
    ContextType
  >;
  moreVisibleItems?: Resolver<
    Maybe<Array<ResolversTypes['MoreVisibleItem']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MoreVisibleItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MoreVisibleItem'] = ResolversParentTypes['MoreVisibleItem']
> = {
  actionLink?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType
  >;
  itemType?: Resolver<
    ResolversTypes['MoreVisibleItemType'],
    ParentType,
    ContextType
  >;
  locations?: Resolver<
    Maybe<Array<ResolversTypes['GeoObject']>>,
    ParentType,
    ContextType
  >;
  moreVisibleItemImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<MoreVisibleItemMoreVisibleItemImageArgs, 'criteria'>
  >;
  rooms?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  travellers?: Resolver<ResolversTypes['Travellers'], ParentType, ContextType>;
  travelPeriod?: Resolver<
    ResolversTypes['TravelPeriodUnion'],
    ParentType,
    ContextType
  >;
  travelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomeStaticContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HomeStaticContent'] = ResolversParentTypes['HomeStaticContent']
> = {
  barColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<HomeStaticContentHeroArgs, 'criteria'>
  >;
  info?: Resolver<ResolversTypes['TextComponent'], ParentType, ContextType>;
  macroPicture?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<HomeStaticContentMacroPictureArgs, 'criteria'>
  >;
  sharingOverlayDefaultImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<HomeStaticContentSharingOverlayDefaultImageArgs, 'criteria'>
  >;
  title?: Resolver<ResolversTypes['HomeTitle'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomeTitleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HomeTitle'] = ResolversParentTypes['HomeTitle']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomeTotalProductCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HomeTotalProductCount'] = ResolversParentTypes['HomeTotalProductCount']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsArticlePageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsArticlePage'] = ResolversParentTypes['NewsArticlePage']
> = {
  businessUnit?: Resolver<
    ResolversTypes['BusinessUnit'],
    ParentType,
    ContextType
  >;
  components?: Resolver<
    Array<ResolversTypes['StaticPageComponent']>,
    ParentType,
    ContextType
  >;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<NewsArticlePageImageArgs, 'criteria'>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  shortText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['StaticPageStatus'],
    ParentType,
    ContextType
  >;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BusinessUnitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BusinessUnit'] = ResolversParentTypes['BusinessUnit']
> = {
  contact?: Resolver<
    Maybe<ResolversTypes['EmployeeBox']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logo?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<BusinessUnitLogoArgs, 'criteria'>
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaticPageComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaticPageComponent'] = ResolversParentTypes['StaticPageComponent']
> = {
  __resolveType: TypeResolveFn<
    | 'BrandsComponent'
    | 'EmployeesComponent'
    | 'FaqComponent'
    | 'HeroMediaGallery'
    | 'LinkListComponent'
    | 'MediaGallery'
    | 'NewsArticlesComponent'
    | 'StaticRecommendationGroup'
    | 'TextComponent'
    | 'UspBoxesComponent',
    ParentType,
    ContextType
  >;
};

export type StaticRecommendationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaticRecommendationGroup'] = ResolversParentTypes['StaticRecommendationGroup']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['StaticRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaticRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaticRecommendationItem'] = ResolversParentTypes['StaticRecommendationItem']
> = {
  __resolveType: TypeResolveFn<
    'BD4TravelRecommendation' | 'MarketingRecommendationItem',
    ParentType,
    ContextType
  >;
};

export type NewsArticlesOverviewPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsArticlesOverviewPage'] = ResolversParentTypes['NewsArticlesOverviewPage']
> = {
  components?: Resolver<
    Array<ResolversTypes['StaticPageComponent']>,
    ParentType,
    ContextType
  >;
  filter?: Resolver<
    ResolversTypes['NewsArticlesFilter'],
    ParentType,
    ContextType
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  overview?: Resolver<
    ResolversTypes['NewsArticlesOverview'],
    ParentType,
    ContextType,
    RequireFields<NewsArticlesOverviewPageOverviewArgs, 'criteria'>
  >;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsArticlesFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsArticlesFilter'] = ResolversParentTypes['NewsArticlesFilter']
> = {
  businessUnits?: Resolver<
    Array<ResolversTypes['SingleValueFilterComponent']>,
    ParentType,
    ContextType
  >;
  selectedBusinessUnit?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  selectedYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  years?: Resolver<
    Array<ResolversTypes['SingleValueFilterComponent']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsArticlesOverviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsArticlesOverview'] = ResolversParentTypes['NewsArticlesOverview']
> = {
  newsArticles?: Resolver<
    Array<ResolversTypes['NewsArticle']>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterConfirmationContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterConfirmationContext'] = ResolversParentTypes['NewsletterConfirmationContext']
> = {
  content?: Resolver<ResolversTypes['TextComponent'], ParentType, ContextType>;
  contentInfo?: Resolver<
    ResolversTypes['NewsletterInfoComponent'],
    ParentType,
    ContextType
  >;
  dynamicContent?: Resolver<
    Array<ResolversTypes['NewsletterDynamicComponent']>,
    ParentType,
    ContextType
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<NewsletterConfirmationContextHeroArgs, 'criteria'>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterInfoComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterInfoComponent'] = ResolversParentTypes['NewsletterInfoComponent']
> = {
  breadcrumbs?: Resolver<
    Maybe<ResolversTypes['BreadcrumbsComponent']>,
    ParentType,
    ContextType
  >;
  flagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<NewsletterInfoComponentImageArgs, 'criteria'>
  >;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  textBgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  textBgImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<NewsletterInfoComponentTextBgImageArgs, 'criteria'>
  >;
  textFgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterDynamicComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterDynamicComponent'] = ResolversParentTypes['NewsletterDynamicComponent']
> = {
  __resolveType: TypeResolveFn<
    'NewsletterRecommendationGroup',
    ParentType,
    ContextType
  >;
};

export type NewsletterRecommendationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterRecommendationGroup'] = ResolversParentTypes['NewsletterRecommendationGroup']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['NewsletterRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterRecommendationItem'] = ResolversParentTypes['NewsletterRecommendationItem']
> = {
  __resolveType: TypeResolveFn<
    'GeoRecommendationItem' | 'ThemeRecommendationItem',
    ParentType,
    ContextType
  >;
};

export type NewsletterFinalizationContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterFinalizationContext'] = ResolversParentTypes['NewsletterFinalizationContext']
> = {
  content?: Resolver<ResolversTypes['TextComponent'], ParentType, ContextType>;
  contentInfo?: Resolver<
    ResolversTypes['NewsletterInfoComponent'],
    ParentType,
    ContextType
  >;
  dynamicContent?: Resolver<
    Array<ResolversTypes['NewsletterDynamicComponent']>,
    ParentType,
    ContextType
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<NewsletterFinalizationContextHeroArgs, 'criteria'>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterSubscriptionContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterSubscriptionContext'] = ResolversParentTypes['NewsletterSubscriptionContext']
> = {
  content?: Resolver<ResolversTypes['TextComponent'], ParentType, ContextType>;
  contentInfo?: Resolver<
    ResolversTypes['NewsletterInfoComponent'],
    ParentType,
    ContextType
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<NewsletterSubscriptionContextHeroArgs, 'criteria'>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  subscriptionTokenFromUserData?: Resolver<
    ResolversTypes['NewsletterSubscriptionTokenResponse'],
    ParentType,
    ContextType,
    RequireFields<
      NewsletterSubscriptionContextSubscriptionTokenFromUserDataArgs,
      'userData'
    >
  >;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterSubscriptionTokenResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterSubscriptionTokenResponse'] = ResolversParentTypes['NewsletterSubscriptionTokenResponse']
> = {
  confirmationUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterUnsubscriptionContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterUnsubscriptionContext'] = ResolversParentTypes['NewsletterUnsubscriptionContext']
> = {
  content?: Resolver<ResolversTypes['TextComponent'], ParentType, ContextType>;
  contentInfo?: Resolver<
    ResolversTypes['NewsletterInfoComponent'],
    ParentType,
    ContextType
  >;
  emailFromToken?: Resolver<
    ResolversTypes['NewsletterGetEmailFromTokenResponse'],
    ParentType,
    ContextType,
    RequireFields<NewsletterUnsubscriptionContextEmailFromTokenArgs, 'token'>
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<NewsletterUnsubscriptionContextHeroArgs, 'criteria'>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  tokenFromEmail?: Resolver<
    ResolversTypes['NewsletterGetTokenFromEmailResponse'],
    ParentType,
    ContextType,
    RequireFields<NewsletterUnsubscriptionContextTokenFromEmailArgs, 'email'>
  >;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterGetEmailFromTokenResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterGetEmailFromTokenResponse'] = ResolversParentTypes['NewsletterGetEmailFromTokenResponse']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterGetTokenFromEmailResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterGetTokenFromEmailResponse'] = ResolversParentTypes['NewsletterGetTokenFromEmailResponse']
> = {
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterUnsubscriptionFinalizationContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterUnsubscriptionFinalizationContext'] = ResolversParentTypes['NewsletterUnsubscriptionFinalizationContext']
> = {
  content?: Resolver<ResolversTypes['TextComponent'], ParentType, ContextType>;
  contentInfo?: Resolver<
    ResolversTypes['NewsletterInfoComponent'],
    ParentType,
    ContextType
  >;
  dynamicContent?: Resolver<
    Array<ResolversTypes['NewsletterDynamicComponent']>,
    ParentType,
    ContextType
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<
      NewsletterUnsubscriptionFinalizationContextHeroArgs,
      'criteria'
    >
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  closable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  notificationType?: Resolver<
    Maybe<ResolversTypes['MaintenanceNotificationType']>,
    ParentType,
    ContextType
  >;
  showIfClosed?: Resolver<
    Maybe<ResolversTypes['MaintenanceNotificationShowMode']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationInfo'] = ResolversParentTypes['NotificationInfo']
> = {
  icon?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<NotificationInfoIconArgs, 'criteria'>
  >;
  mainText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']
> = {
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrlContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrlContext'] = ResolversParentTypes['OrlContext']
> = {
  checkout?: Resolver<
    ResolversTypes['OrlCheckoutComponent'],
    ParentType,
    ContextType,
    RequireFields<OrlContextCheckoutArgs, 'backUrl' | 'deviceType' | 'offerId'>
  >;
  filters?: Resolver<
    ResolversTypes['FilterComponent'],
    ParentType,
    ContextType,
    Partial<OrlContextFiltersArgs>
  >;
  includedInPrice?: Resolver<
    ResolversTypes['OrlIncludedInPriceComponent'],
    ParentType,
    ContextType
  >;
  priceDateOverview?: Resolver<
    Maybe<ResolversTypes['PdpPriceDateOverviewComponent']>,
    ParentType,
    ContextType,
    Partial<OrlContextPriceDateOverviewArgs>
  >;
  priceExplanation?: Resolver<
    ResolversTypes['OrlPriceExplanationComponent'],
    ParentType,
    ContextType,
    RequireFields<OrlContextPriceExplanationArgs, 'criteria'>
  >;
  quickHotelInfo?: Resolver<
    ResolversTypes['QuickHotelInfoComponent'],
    ParentType,
    ContextType,
    Partial<OrlContextQuickHotelInfoArgs>
  >;
  searchControl?: Resolver<
    ResolversTypes['SearchControlComponent'],
    ParentType,
    ContextType,
    Partial<OrlContextSearchControlArgs>
  >;
  searchResult?: Resolver<
    ResolversTypes['OrlSearchContainer'],
    ParentType,
    ContextType,
    RequireFields<OrlContextSearchResultArgs, 'criteria'>
  >;
  url?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType,
    Partial<OrlContextUrlArgs>
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrlCheckoutComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrlCheckoutComponent'] = ResolversParentTypes['OrlCheckoutComponent']
> = {
  changedOffer?: Resolver<
    Maybe<ResolversTypes['SingleOrlItem']>,
    ParentType,
    ContextType
  >;
  checkoutStatus?: Resolver<
    ResolversTypes['OrlCheckoutStatus'],
    ParentType,
    ContextType
  >;
  flightChanged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  link?: Resolver<
    Maybe<ResolversTypes['ExternalLink']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priceChanged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SingleOrlItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SingleOrlItem'] = ResolversParentTypes['SingleOrlItem']
> = {
  board?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  departureDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  flightAlternativesAvailable?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  giataId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inboundFlight?: Resolver<
    Maybe<ResolversTypes['Flight']>,
    ParentType,
    ContextType
  >;
  inWishlist?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastMinute?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  outboundFlight?: Resolver<
    Maybe<ResolversTypes['Flight']>,
    ParentType,
    ContextType
  >;
  pricePerPerson?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  productClass?: Resolver<
    ResolversTypes['ProductClass'],
    ParentType,
    ContextType
  >;
  productCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rooms?: Resolver<Array<ResolversTypes['OrlRoom']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['LocalDate'], ParentType, ContextType>;
  totalPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  transferIncluded?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  transferPrice?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  travelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType>;
  waitingScreenImage?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  wishlistItem?: Resolver<
    Maybe<ResolversTypes['WishlistItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrlRoomResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrlRoom'] = ResolversParentTypes['OrlRoom']
> = {
  adults?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  alternativeRoomAvailability?: Resolver<
    Maybe<ResolversTypes['AlternativeRoom']>,
    ParentType,
    ContextType
  >;
  boardType?: Resolver<ResolversTypes['BoardType'], ParentType, ContextType>;
  children?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  roomType?: Resolver<ResolversTypes['RoomType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BoardTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BoardType'] = ResolversParentTypes['BoardType']
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoomTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoomType'] = ResolversParentTypes['RoomType']
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrlIncludedInPriceComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrlIncludedInPriceComponent'] = ResolversParentTypes['OrlIncludedInPriceComponent']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  text?: Resolver<
    Maybe<ResolversTypes['TextComponent']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpPriceDateOverviewComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpPriceDateOverviewComponent'] = ResolversParentTypes['PdpPriceDateOverviewComponent']
> = {
  items?: Resolver<Array<ResolversTypes['PDOItem']>, ParentType, ContextType>;
  orlItems?: Resolver<
    Maybe<Array<ResolversTypes['SingleOrlItem']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdoItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PDOItem'] = ResolversParentTypes['PDOItem']
> = {
  adults?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  board?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  children?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  departureDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  pax?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pricePerPerson?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  productClass?: Resolver<
    ResolversTypes['ProductClass'],
    ParentType,
    ContextType
  >;
  returnDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrlPriceExplanationComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrlPriceExplanationComponent'] = ResolversParentTypes['OrlPriceExplanationComponent']
> = {
  explanation?: Resolver<
    Maybe<ResolversTypes['OrlPriceExplanation']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrlPriceExplanationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrlPriceExplanation'] = ResolversParentTypes['OrlPriceExplanation']
> = {
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hpLogo?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<OrlPriceExplanationHpLogoArgs, 'criteria'>
  >;
  hpPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  meal?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  partnerLogo?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<OrlPriceExplanationPartnerLogoArgs, 'criteria'>
  >;
  partnerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  partnerPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  partnerPricing?: Resolver<
    ResolversTypes['OrlPartnerPricing'],
    ParentType,
    ContextType
  >;
  room?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  travelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuickHotelInfoComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuickHotelInfoComponent'] = ResolversParentTypes['QuickHotelInfoComponent']
> = {
  points?: Resolver<
    Maybe<Array<ResolversTypes['QuickHotelInfoPoint']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuickHotelInfoPointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuickHotelInfoPoint'] = ResolversParentTypes['QuickHotelInfoPoint']
> = {
  pointId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pointText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrlSearchContainerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrlSearchContainer'] = ResolversParentTypes['OrlSearchContainer']
> = {
  flightAlternatives?: Resolver<
    Array<ResolversTypes['OrlFlightAlternative']>,
    ParentType,
    ContextType,
    RequireFields<OrlSearchContainerFlightAlternativesArgs, 'offerId'>
  >;
  group?: Resolver<
    ResolversTypes['OrlGroupListComponent'],
    ParentType,
    ContextType
  >;
  single?: Resolver<
    ResolversTypes['OrlSingleListComponent'],
    ParentType,
    ContextType,
    Partial<OrlSearchContainerSingleArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrlFlightAlternativeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrlFlightAlternative'] = ResolversParentTypes['OrlFlightAlternative']
> = {
  inboundFlight?: Resolver<
    Maybe<ResolversTypes['Flight']>,
    ParentType,
    ContextType
  >;
  lastMinute?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  offerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outboundFlight?: Resolver<ResolversTypes['Flight'], ParentType, ContextType>;
  pricePerPerson?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  totalPrice?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  waitingScreenImage?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrlGroupListComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrlGroupListComponent'] = ResolversParentTypes['OrlGroupListComponent']
> = {
  alternativeSearchUrl?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  altTravelType?: Resolver<
    ResolversTypes['TravelType'],
    ParentType,
    ContextType
  >;
  groups?: Resolver<
    Array<ResolversTypes['GroupOrlItem']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupOrlItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupOrlItem'] = ResolversParentTypes['GroupOrlItem']
> = {
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  heading?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  headingType?: Resolver<
    ResolversTypes['GroupOrlItemHeadingType'],
    ParentType,
    ContextType
  >;
  offers?: Resolver<
    Array<ResolversTypes['SingleOrlItem']>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrlSingleListComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrlSingleListComponent'] = ResolversParentTypes['OrlSingleListComponent']
> = {
  alternativeSearchUrl?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  altTravelType?: Resolver<
    ResolversTypes['TravelType'],
    ParentType,
    ContextType
  >;
  cheapestPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offers?: Resolver<
    Array<ResolversTypes['SingleOrlItem']>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageB2BLoginDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageB2BLoginData'] = ResolversParentTypes['PageB2BLoginData']
> = {
  mainText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mainTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pageMetaData?: Resolver<
    ResolversTypes['PageMetaData'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageFooterDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageFooterData'] = ResolversParentTypes['PageFooterData']
> = {
  brandLogos?: Resolver<
    Maybe<Array<ResolversTypes['IconMenuItem']>>,
    ParentType,
    ContextType
  >;
  contactInformation?: Resolver<
    Maybe<ResolversTypes['ContactInformation']>,
    ParentType,
    ContextType
  >;
  language?: Resolver<ResolversTypes['IconMenuItem'], ParentType, ContextType>;
  logo?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<PageFooterDataLogoArgs, 'criteria'>
  >;
  navigationMenu?: Resolver<
    Array<ResolversTypes['Navigable']>,
    ParentType,
    ContextType
  >;
  paymentMethods?: Resolver<
    Array<ResolversTypes['IconMenuItem']>,
    ParentType,
    ContextType
  >;
  reasonsOfConfidence?: Resolver<
    Maybe<ResolversTypes['ReasonsOfConfidence']>,
    ParentType,
    ContextType
  >;
  socialMedia?: Resolver<
    Array<ResolversTypes['IconMenuItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IconMenuItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IconMenuItem'] = ResolversParentTypes['IconMenuItem']
> = {
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<IconMenuItemIconArgs, 'criteria'>
  >;
  iconId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactInformation'] = ResolversParentTypes['ContactInformation']
> = {
  emailAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  emailTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  openingHours?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  phoneTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NavigableResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Navigable'] = ResolversParentTypes['Navigable']
> = {
  __resolveType: TypeResolveFn<
    'Menu' | 'TextMenuItem',
    ParentType,
    ContextType
  >;
};

export type MenuResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Menu'] = ResolversParentTypes['Menu']
> = {
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  entries?: Resolver<
    Array<ResolversTypes['TextMenuItem']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  target?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextMenuItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextMenuItem'] = ResolversParentTypes['TextMenuItem']
> = {
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  iconType?: Resolver<
    Maybe<ResolversTypes['IconType']>,
    ParentType,
    ContextType
  >;
  link?: Resolver<ResolversTypes['Link'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReasonsOfConfidenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReasonsOfConfidence'] = ResolversParentTypes['ReasonsOfConfidence']
> = {
  link?: Resolver<Maybe<ResolversTypes['Link']>, ParentType, ContextType>;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageHeaderDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageHeaderData'] = ResolversParentTypes['PageHeaderData']
> = {
  contactInformation?: Resolver<
    Maybe<ResolversTypes['ContactInformation']>,
    ParentType,
    ContextType
  >;
  logo?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<PageHeaderDataLogoArgs, 'criteria'>
  >;
  navigationMenu?: Resolver<
    Array<ResolversTypes['Navigable']>,
    ParentType,
    ContextType
  >;
  selectedPhone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageNotFound404DataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageNotFound404Data'] = ResolversParentTypes['PageNotFound404Data']
> = {
  components?: Resolver<
    Array<ResolversTypes['StaticPageComponent']>,
    ParentType,
    ContextType
  >;
  mainText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mainTextBgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainTextBgImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<PageNotFound404DataMainTextBgImageArgs, 'criteria'>
  >;
  mainTextFgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mainTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pageMetaData?: Resolver<
    ResolversTypes['PageMetaData'],
    ParentType,
    ContextType
  >;
  sitemap?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpContext'] = ResolversParentTypes['PdpContext']
> = {
  autocomplete?: Resolver<
    Maybe<ResolversTypes['AutocompleteComponent']>,
    ParentType,
    ContextType,
    RequireFields<PdpContextAutocompleteArgs, 'criteria'>
  >;
  blogArticles?: Resolver<
    Array<ResolversTypes['BlogArticleItem']>,
    ParentType,
    ContextType
  >;
  bookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  content?: Resolver<
    ResolversTypes['PdpContainer'],
    ParentType,
    ContextType,
    Partial<PdpContextContentArgs>
  >;
  destinationHotels?: Resolver<
    ResolversTypes['PdpHotelDestinationInfoComponent'],
    ParentType,
    ContextType
  >;
  dynamicComponents?: Resolver<
    Array<ResolversTypes['PdpPageComponent']>,
    ParentType,
    ContextType,
    Partial<PdpContextDynamicComponentsArgs>
  >;
  map?: Resolver<ResolversTypes['PdpMapComponent'], ParentType, ContextType>;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  moreOffersLink?: Resolver<
    ResolversTypes['PdpMoreOffersButton'],
    ParentType,
    ContextType,
    Partial<PdpContextMoreOffersLinkArgs>
  >;
  priceDateOverview?: Resolver<
    ResolversTypes['PdpPriceDateOverviewComponent'],
    ParentType,
    ContextType
  >;
  quickHotelInfo?: Resolver<
    ResolversTypes['QuickHotelInfoComponent'],
    ParentType,
    ContextType
  >;
  searchControl?: Resolver<
    ResolversTypes['SearchControlComponent'],
    ParentType,
    ContextType,
    Partial<PdpContextSearchControlArgs>
  >;
  url?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType,
    Partial<PdpContextUrlArgs>
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpContainerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpContainer'] = ResolversParentTypes['PdpContainer']
> = {
  description?: Resolver<
    ResolversTypes['PdpDescriptionComponent'],
    ParentType,
    ContextType
  >;
  destinationInfo?: Resolver<
    ResolversTypes['PdpDestinationInfoComponent'],
    ParentType,
    ContextType
  >;
  overview?: Resolver<
    ResolversTypes['PdpOverviewComponent'],
    ParentType,
    ContextType
  >;
  tripAdvisor?: Resolver<
    Maybe<ResolversTypes['PdpTripAdvisorComponent']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpDescriptionComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpDescriptionComponent'] = ResolversParentTypes['PdpDescriptionComponent']
> = {
  arrivalDeparture?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  b2bInformationGroups?: Resolver<
    Array<ResolversTypes['PdpInformationGroup']>,
    ParentType,
    ContextType
  >;
  co2Footprint?: Resolver<
    Maybe<ResolversTypes['Co2Footprint']>,
    ParentType,
    ContextType
  >;
  countryProducts?: Resolver<
    ResolversTypes['StaticProductRecommendation'],
    ParentType,
    ContextType
  >;
  countryThemes?: Resolver<
    ResolversTypes['StaticThemeRecommendation'],
    ParentType,
    ContextType
  >;
  extraOrdinary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  featureRatings?: Resolver<
    Array<ResolversTypes['PdpFeatureRating']>,
    ParentType,
    ContextType
  >;
  galleryWithMap?: Resolver<
    ResolversTypes['ProductGalleryWithMapComponent'],
    ParentType,
    ContextType
  >;
  googleStaticMapImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<PdpDescriptionComponentGoogleStaticMapImageArgs, 'criteria'>
  >;
  hotelCoordinates?: Resolver<
    Maybe<ResolversTypes['GeoCoordinates']>,
    ParentType,
    ContextType
  >;
  includedInPrice?: Resolver<
    Maybe<ResolversTypes['TextComponent']>,
    ParentType,
    ContextType
  >;
  leadText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nearestAirport?: Resolver<
    Maybe<ResolversTypes['Airport']>,
    ParentType,
    ContextType
  >;
  overnightStayAccommodation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  productFeatures?: Resolver<
    Array<ResolversTypes['ProductFeatureGroup']>,
    ParentType,
    ContextType
  >;
  productStaticComponents?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['DynamicComponent']>>>,
    ParentType,
    ContextType
  >;
  programm?: Resolver<
    Maybe<ResolversTypes['TextComponent']>,
    ParentType,
    ContextType
  >;
  requestButton?: Resolver<
    Maybe<ResolversTypes['RequestButtonComponent']>,
    ParentType,
    ContextType
  >;
  route?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  sellingPropositions?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  specialities?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  staticMaps?: Resolver<
    Array<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<PdpDescriptionComponentStaticMapsArgs, 'criteria'>
  >;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  textBlocks?: Resolver<
    Array<ResolversTypes['TextComponent']>,
    ParentType,
    ContextType
  >;
  transportInfo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpInformationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpInformationGroup'] = ResolversParentTypes['PdpInformationGroup']
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  information?: Resolver<
    Maybe<Array<ResolversTypes['TextComponent']>>,
    ParentType,
    ContextType
  >;
  informationSection?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PdpInformation']>>>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pdfLinks?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['InternalLink']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpInformation'] = ResolversParentTypes['PdpInformation']
> = {
  flags?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Co2FootprintResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Co2Footprint'] = ResolversParentTypes['Co2Footprint']
> = {
  accommodationEmissionPercentage?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  flightEmissionPercentage?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  specialitiesEmissionPercentage?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  totalEmission?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transportEmissionPercentage?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpFeatureRatingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpFeatureRating'] = ResolversParentTypes['PdpFeatureRating']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpDestinationInfoComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpDestinationInfoComponent'] = ResolversParentTypes['PdpDestinationInfoComponent']
> = {
  attributes?: Resolver<
    Array<ResolversTypes['GeoFeatureGroup']>,
    ParentType,
    ContextType
  >;
  climate?: Resolver<
    Maybe<ResolversTypes['ClimateComponent']>,
    ParentType,
    ContextType
  >;
  destinationAttributes?: Resolver<
    Maybe<Array<ResolversTypes['DestinationFeatureGroup']>>,
    ParentType,
    ContextType
  >;
  destinationFacts?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  destinationImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<PdpDestinationInfoComponentDestinationImageArgs, 'criteria'>
  >;
  destinationLeadText?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  destinationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  destinationText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  flightDuration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mediaGallery?: Resolver<
    ResolversTypes['MediaGallery'],
    ParentType,
    ContextType
  >;
  mostPopularTravelPeriod?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  resortFacts?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  resortImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<PdpDestinationInfoComponentResortImageArgs, 'criteria'>
  >;
  resortLeadText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resortText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DestinationFeatureGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DestinationFeatureGroup'] = ResolversParentTypes['DestinationFeatureGroup']
> = {
  details?: Resolver<
    Array<ResolversTypes['DestinationFeature']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DestinationFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DestinationFeature'] = ResolversParentTypes['DestinationFeature']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpOverviewComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpOverviewComponent'] = ResolversParentTypes['PdpOverviewComponent']
> = {
  attributeSummary?: Resolver<
    Array<ResolversTypes['ProductFeature']>,
    ParentType,
    ContextType
  >;
  breadcrumbsExtended?: Resolver<
    ResolversTypes['BreadcrumbsComponent'],
    ParentType,
    ContextType
  >;
  disrupters?: Resolver<
    Array<ResolversTypes['DisrupterObject']>,
    ParentType,
    ContextType
  >;
  featuresSummary?: Resolver<
    Array<ResolversTypes['ProductFeatureGroup']>,
    ParentType,
    ContextType
  >;
  headerImage?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<PdpOverviewComponentHeaderImageArgs, 'criteria'>
  >;
  hpRating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['GeoLocation'], ParentType, ContextType>;
  media?: Resolver<ResolversTypes['MediaGallery'], ParentType, ContextType>;
  moreOffers?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  productBreadcrumbs?: Resolver<
    ResolversTypes['BreadcrumbsComponent'],
    ParentType,
    ContextType
  >;
  productClass?: Resolver<
    ResolversTypes['ProductClass'],
    ParentType,
    ContextType
  >;
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taRating?: Resolver<
    Maybe<ResolversTypes['TripAdvisorRating']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TripAdvisorRatingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TripAdvisorRating'] = ResolversParentTypes['TripAdvisorRating']
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpTripAdvisorComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpTripAdvisorComponent'] = ResolversParentTypes['PdpTripAdvisorComponent']
> = {
  reviews?: Resolver<
    Array<ResolversTypes['TripAdvisorReview']>,
    ParentType,
    ContextType
  >;
  subratings?: Resolver<
    Array<ResolversTypes['TripAdvisorSubrating']>,
    ParentType,
    ContextType
  >;
  taRating?: Resolver<
    ResolversTypes['TripAdvisorRating'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TripAdvisorReviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TripAdvisorReview'] = ResolversParentTypes['TripAdvisorReview']
> = {
  author?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<
    Maybe<ResolversTypes['TextComponent']>,
    ParentType,
    ContextType
  >;
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subratings?: Resolver<
    Maybe<Array<ResolversTypes['TripAdvisorSubrating']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TripAdvisorSubratingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TripAdvisorSubrating'] = ResolversParentTypes['TripAdvisorSubrating']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpHotelDestinationInfoComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpHotelDestinationInfoComponent'] = ResolversParentTypes['PdpHotelDestinationInfoComponent']
> = {
  hotelDestinationInfos?: Resolver<
    Array<ResolversTypes['HotelDestinationMapInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HotelDestinationMapInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HotelDestinationMapInfo'] = ResolversParentTypes['HotelDestinationMapInfo']
> = {
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<HotelDestinationMapInfoImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpPageComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpPageComponent'] = ResolversParentTypes['PdpPageComponent']
> = {
  __resolveType: TypeResolveFn<
    'PdpRecommendationGroup',
    ParentType,
    ContextType
  >;
};

export type PdpRecommendationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpRecommendationGroup'] = ResolversParentTypes['PdpRecommendationGroup']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['PdpRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpRecommendationItem'] = ResolversParentTypes['PdpRecommendationItem']
> = {
  __resolveType: TypeResolveFn<
    'BD4TravelRecommendation' | 'ProductRecommendationItem',
    ParentType,
    ContextType
  >;
};

export type PdpMapComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpMapComponent'] = ResolversParentTypes['PdpMapComponent']
> = {
  hotel?: Resolver<ResolversTypes['PdpMapHotel'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpMapHotelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpMapHotel'] = ResolversParentTypes['PdpMapHotel']
> = {
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdpMoreOffersButtonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PdpMoreOffersButton'] = ResolversParentTypes['PdpMoreOffersButton']
> = {
  geoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PopUpMenuDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PopUpMenuData'] = ResolversParentTypes['PopUpMenuData']
> = {
  items?: Resolver<
    Array<ResolversTypes['IconMenuItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchOverlayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchOverlay'] = ResolversParentTypes['SearchOverlay']
> = {
  keywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  mostSearched?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  searchTrends?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlContext'] = ResolversParentTypes['SrlContext']
> = {
  autocomplete?: Resolver<
    Maybe<ResolversTypes['AutocompleteComponent']>,
    ParentType,
    ContextType,
    RequireFields<SrlContextAutocompleteArgs, 'criteria'>
  >;
  bD4TravelRecommendationsComponent?: Resolver<
    ResolversTypes['SrlBD4TravelRecommendationsComponent'],
    ParentType,
    ContextType,
    RequireFields<
      SrlContextBd4TravelRecommendationsComponentArgs,
      'currentUri' | 'encodedCriteria'
    >
  >;
  breadcrumbs?: Resolver<
    ResolversTypes['BreadcrumbsComponent'],
    ParentType,
    ContextType
  >;
  dynamicComponents?: Resolver<
    Array<ResolversTypes['SrlPageComponent']>,
    ParentType,
    ContextType,
    RequireFields<SrlContextDynamicComponentsArgs, 'searchCriteria'>
  >;
  fullTextSearch?: Resolver<
    ResolversTypes['SrlFullTextSearchResultComponent'],
    ParentType,
    ContextType,
    RequireFields<SrlContextFullTextSearchArgs, 'keywords'>
  >;
  ghostOffers?: Resolver<
    ResolversTypes['SrlGhostOffersComponent'],
    ParentType,
    ContextType,
    RequireFields<SrlContextGhostOffersArgs, 'searchCriteria'>
  >;
  link?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType,
    RequireFields<SrlContextLinkArgs, 'searchCriteria'>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  search?: Resolver<
    ResolversTypes['SrlResultContext'],
    ParentType,
    ContextType,
    RequireFields<SrlContextSearchArgs, 'searchCriteria'>
  >;
  searchControls?: Resolver<
    ResolversTypes['SrlSearchControlsContext'],
    ParentType,
    ContextType,
    Partial<SrlContextSearchControlsArgs>
  >;
  sharingOverlayDefaultImage?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<SrlContextSharingOverlayDefaultImageArgs, 'criteria'>
  >;
  staticContent?: Resolver<
    ResolversTypes['SrlStaticContent'],
    ParentType,
    ContextType
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlBd4TravelRecommendationsComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlBD4TravelRecommendationsComponent'] = ResolversParentTypes['SrlBD4TravelRecommendationsComponent']
> = {
  BD4TravelRecommendationAccordion?: Resolver<
    Maybe<ResolversTypes['BD4TravelRecommendationGroup']>,
    ParentType,
    ContextType
  >;
  BD4TravelRecommendationBasic?: Resolver<
    Maybe<ResolversTypes['BD4TravelRecommendationGroup']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlPageComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlPageComponent'] = ResolversParentTypes['SrlPageComponent']
> = {
  __resolveType: TypeResolveFn<
    'SrlRecommendationGroupComponent',
    ParentType,
    ContextType
  >;
};

export type SrlRecommendationGroupComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlRecommendationGroupComponent'] = ResolversParentTypes['SrlRecommendationGroupComponent']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['SrlRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlRecommendationItem'] = ResolversParentTypes['SrlRecommendationItem']
> = {
  __resolveType: TypeResolveFn<
    | 'BD4TravelRecommendation'
    | 'GeoRecommendationItem'
    | 'MarketingRecommendationItem'
    | 'ProductRecommendationItem'
    | 'ThemeRecommendationItem',
    ParentType,
    ContextType
  >;
};

export type SrlFullTextSearchResultComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlFullTextSearchResultComponent'] = ResolversParentTypes['SrlFullTextSearchResultComponent']
> = {
  adventuretravel?: Resolver<
    ResolversTypes['SrlFullTextSearchResult'],
    ParentType,
    ContextType,
    RequireFields<SrlFullTextSearchResultComponentAdventuretravelArgs, 'page'>
  >;
  agenciesWithDetails?: Resolver<
    ResolversTypes['AgencySrlFullTextSearchResult'],
    ParentType,
    ContextType,
    RequireFields<
      SrlFullTextSearchResultComponentAgenciesWithDetailsArgs,
      'page'
    >
  >;
  autoSuggest?: Resolver<
    ResolversTypes['SrlFullTextSearchResult'],
    ParentType,
    ContextType,
    RequireFields<
      SrlFullTextSearchResultComponentAutoSuggestArgs,
      'page' | 'types'
    >
  >;
  blogArticles?: Resolver<
    ResolversTypes['SrlFullTextSearchResult'],
    ParentType,
    ContextType,
    RequireFields<SrlFullTextSearchResultComponentBlogArticlesArgs, 'page'>
  >;
  extraHotels?: Resolver<
    ResolversTypes['SrlFullTextSearchResult'],
    ParentType,
    ContextType,
    RequireFields<SrlFullTextSearchResultComponentExtraHotelsArgs, 'page'>
  >;
  geo?: Resolver<
    ResolversTypes['SrlFullTextSearchResult'],
    ParentType,
    ContextType,
    RequireFields<SrlFullTextSearchResultComponentGeoArgs, 'page'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  other?: Resolver<
    ResolversTypes['SrlFullTextSearchResult'],
    ParentType,
    ContextType,
    RequireFields<SrlFullTextSearchResultComponentOtherArgs, 'page'>
  >;
  regularHotels?: Resolver<
    ResolversTypes['SrlFullTextSearchResult'],
    ParentType,
    ContextType,
    RequireFields<SrlFullTextSearchResultComponentRegularHotelsArgs, 'page'>
  >;
  roundtrip?: Resolver<
    ResolversTypes['SrlFullTextSearchResult'],
    ParentType,
    ContextType,
    RequireFields<SrlFullTextSearchResultComponentRoundtripArgs, 'page'>
  >;
  themes?: Resolver<
    ResolversTypes['SrlFullTextSearchResult'],
    ParentType,
    ContextType,
    RequireFields<SrlFullTextSearchResultComponentThemesArgs, 'page'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlFullTextSearchResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlFullTextSearchResult'] = ResolversParentTypes['SrlFullTextSearchResult']
> = {
  items?: Resolver<
    Array<ResolversTypes['FullTextSearchItem']>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FullTextSearchItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FullTextSearchItem'] = ResolversParentTypes['FullTextSearchItem']
> = {
  bookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  breadcrumbs?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  debugInfo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disrupters?: Resolver<
    Maybe<Array<ResolversTypes['DisrupterObject']>>,
    ParentType,
    ContextType
  >;
  googleStaticMapImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<FullTextSearchItemGoogleStaticMapImageArgs, 'criteria'>
  >;
  highlightedText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<FullTextSearchItemImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  mainText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productInfoB2B?: Resolver<
    Maybe<ResolversTypes['SrlProductInfoB2B']>,
    ParentType,
    ContextType
  >;
  productInfoB2C?: Resolver<
    Maybe<ResolversTypes['SrlProductInfoB2C']>,
    ParentType,
    ContextType
  >;
  routePoints?: Resolver<
    Maybe<Array<ResolversTypes['RoutePoint']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlProductInfoB2BResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlProductInfoB2B'] = ResolversParentTypes['SrlProductInfoB2B']
> = {
  duration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lead?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceFrom?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  route?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  typeFlags?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlProductInfoB2CResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlProductInfoB2C'] = ResolversParentTypes['SrlProductInfoB2C']
> = {
  lead?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  typeFlags?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencySrlFullTextSearchResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencySrlFullTextSearchResult'] = ResolversParentTypes['AgencySrlFullTextSearchResult']
> = {
  items?: Resolver<
    Array<ResolversTypes['AgencyFullTextSearchItem']>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencyFullTextSearchItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyFullTextSearchItem'] = ResolversParentTypes['AgencyFullTextSearchItem']
> = {
  addressDetails?: Resolver<
    Maybe<ResolversTypes['AddressDetails']>,
    ParentType,
    ContextType
  >;
  bookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  breadcrumbs?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  debugInfo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disrupters?: Resolver<
    Maybe<Array<ResolversTypes['DisrupterObject']>>,
    ParentType,
    ContextType
  >;
  googleStaticMapImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<AgencyFullTextSearchItemGoogleStaticMapImageArgs, 'criteria'>
  >;
  highlightedText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<AgencyFullTextSearchItemImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  mainText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  opened?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  productInfoB2B?: Resolver<
    Maybe<ResolversTypes['SrlProductInfoB2B']>,
    ParentType,
    ContextType
  >;
  productInfoB2C?: Resolver<
    Maybe<ResolversTypes['SrlProductInfoB2C']>,
    ParentType,
    ContextType
  >;
  routePoints?: Resolver<
    Maybe<Array<ResolversTypes['RoutePoint']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlGhostOffersComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlGhostOffersComponent'] = ResolversParentTypes['SrlGhostOffersComponent']
> = {
  items?: Resolver<
    Array<ResolversTypes['SrlGhostOfferItem']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlGhostOfferItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlGhostOfferItem'] = ResolversParentTypes['SrlGhostOfferItem']
> = {
  disrupter?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disrupterObject?: Resolver<
    Maybe<ResolversTypes['DisrupterObject']>,
    ParentType,
    ContextType
  >;
  fromTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geoBreadcrumbs?: Resolver<
    Array<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  giata?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hpRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<SrlGhostOfferItemImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productClass?: Resolver<
    ResolversTypes['ProductClass'],
    ParentType,
    ContextType
  >;
  travelType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlResultContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlResultContext'] = ResolversParentTypes['SrlResultContext']
> = {
  any?: Resolver<
    ResolversTypes['SrlAnyResult'],
    ParentType,
    ContextType,
    Partial<SrlResultContextAnyArgs>
  >;
  destinationGroup?: Resolver<
    ResolversTypes['SrlDestinationGroupsResult'],
    ParentType,
    ContextType
  >;
  geoGroup?: Resolver<
    ResolversTypes['SrlGeoGroupItem'],
    ParentType,
    ContextType,
    RequireFields<SrlResultContextGeoGroupArgs, 'groupId' | 'page'>
  >;
  group?: Resolver<
    ResolversTypes['SrlGroupOrEmptyResult'],
    ParentType,
    ContextType,
    Partial<SrlResultContextGroupArgs>
  >;
  mapPins?: Resolver<
    ResolversTypes['SrlMapPinsComponent'],
    ParentType,
    ContextType
  >;
  mapProductCards?: Resolver<
    ResolversTypes['SrlSingleOrEmptyResult'],
    ParentType,
    ContextType,
    RequireFields<SrlResultContextMapProductCardsArgs, 'productIds'>
  >;
  single?: Resolver<
    ResolversTypes['SrlSingleOrEmptyResult'],
    ParentType,
    ContextType,
    Partial<SrlResultContextSingleArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlAnyResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlAnyResult'] = ResolversParentTypes['SrlAnyResult']
> = {
  __resolveType: TypeResolveFn<
    'SrlEmptyResult' | 'SrlGeoGroupResult' | 'SrlSingleResult',
    ParentType,
    ContextType
  >;
};

export type SrlEmptyResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlEmptyResult'] = ResolversParentTypes['SrlEmptyResult']
> = {
  alternativeSearchUrl?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  altTravelType?: Resolver<
    ResolversTypes['TravelType'],
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlGeoGroupResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlGeoGroupResult'] = ResolversParentTypes['SrlGeoGroupResult']
> = {
  cheapestGroup?: Resolver<
    ResolversTypes['SrlGeoItem'],
    ParentType,
    ContextType
  >;
  cheapestPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  groups?: Resolver<
    Array<ResolversTypes['SrlGeoGroupItem']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productsTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  showAllProducts?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType
  >;
  sourceGeoObject?: Resolver<
    Maybe<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlGeoItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlGeoItem'] = ResolversParentTypes['SrlGeoItem']
> = {
  adults?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  averageTemp?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  children?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disrupter?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disrupterObject?: Resolver<
    Maybe<ResolversTypes['DisrupterObject']>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  flightDuration?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  geoObject?: Resolver<ResolversTypes['GeoObject'], ParentType, ContextType>;
  geoSearchObjects?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['GeoObject']>>>,
    ParentType,
    ContextType
  >;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<SrlGeoItemImageArgs, 'criteria'>
  >;
  leadText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  mapZoom?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moreInfo?: Resolver<
    Maybe<ResolversTypes['InternalLink']>,
    ParentType,
    ContextType
  >;
  page?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType>;
  perPersonPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  previewItems?: Resolver<
    Maybe<Array<ResolversTypes['SrlSingleItem']>>,
    ParentType,
    ContextType
  >;
  travelType?: Resolver<
    Maybe<ResolversTypes['TravelType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlSingleItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlSingleItem'] = ResolversParentTypes['SrlSingleItem']
> = {
  __resolveType: TypeResolveFn<
    'NudgeItem' | 'SrlProductItem',
    ParentType,
    ContextType
  >;
};

export type NudgeItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NudgeItem'] = ResolversParentTypes['NudgeItem']
> = {
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlProductItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlProductItem'] = ResolversParentTypes['SrlProductItem']
> = {
  adults?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  children?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  departureDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  disrupter?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disrupterObject?: Resolver<
    Maybe<ResolversTypes['DisrupterObject']>,
    ParentType,
    ContextType
  >;
  distanceInfo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  features?: Resolver<
    Array<ResolversTypes['ProductFeature']>,
    ParentType,
    ContextType
  >;
  featureSummary?: Resolver<
    Array<ResolversTypes['ProductFeatureGroup']>,
    ParentType,
    ContextType
  >;
  fromTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geoBreadcrumbs?: Resolver<
    Array<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  giata?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hpRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<SrlProductItemImageArgs, 'criteria'>
  >;
  inWishlist?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  mapZoom?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mealType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  productClass?: Resolver<
    ResolversTypes['ProductClass'],
    ParentType,
    ContextType
  >;
  productCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rooms?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Room']>>>,
    ParentType,
    ContextType
  >;
  taRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taReviews?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  transferIncluded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  transferPrice?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  travelType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>;
  wishlistItem?: Resolver<
    Maybe<ResolversTypes['WishlistItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlGeoGroupItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlGeoGroupItem'] = ResolversParentTypes['SrlGeoGroupItem']
> = {
  geoObject?: Resolver<ResolversTypes['GeoObject'], ParentType, ContextType>;
  items?: Resolver<
    Array<ResolversTypes['SrlGroupItem']>,
    ParentType,
    ContextType
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  nudges?: Resolver<
    Array<ResolversTypes['NudgeItem']>,
    ParentType,
    ContextType
  >;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlGroupItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlGroupItem'] = ResolversParentTypes['SrlGroupItem']
> = {
  __resolveType: TypeResolveFn<
    'NudgeItem' | 'SrlGeoItem',
    ParentType,
    ContextType
  >;
};

export type SrlSingleResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlSingleResult'] = ResolversParentTypes['SrlSingleResult']
> = {
  cheapestProduct?: Resolver<
    ResolversTypes['SrlProductItem'],
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['SrlSingleItem']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  productsTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlDestinationGroupsResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlDestinationGroupsResult'] = ResolversParentTypes['SrlDestinationGroupsResult']
> = {
  continentGroupItems?: Resolver<
    Array<ResolversTypes['ContinentGroupItem']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContinentGroupItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContinentGroupItem'] = ResolversParentTypes['ContinentGroupItem']
> = {
  countryGroupItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CountryGroupItem']>>>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryGroupItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountryGroupItem'] = ResolversParentTypes['CountryGroupItem']
> = {
  destinations?: Resolver<
    Array<Maybe<ResolversTypes['DestinationGroupItem']>>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DestinationGroupItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DestinationGroupItem'] = ResolversParentTypes['DestinationGroupItem']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlGroupOrEmptyResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlGroupOrEmptyResult'] = ResolversParentTypes['SrlGroupOrEmptyResult']
> = {
  __resolveType: TypeResolveFn<
    'SrlEmptyResult' | 'SrlGeoGroupResult',
    ParentType,
    ContextType
  >;
};

export type SrlSingleOrEmptyResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlSingleOrEmptyResult'] = ResolversParentTypes['SrlSingleOrEmptyResult']
> = {
  __resolveType: TypeResolveFn<
    'SrlEmptyResult' | 'SrlSingleResult',
    ParentType,
    ContextType
  >;
};

export type SrlSearchControlsContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlSearchControlsContext'] = ResolversParentTypes['SrlSearchControlsContext']
> = {
  forceSingleView?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  mainFilter?: Resolver<
    ResolversTypes['FilterComponent'],
    ParentType,
    ContextType
  >;
  searchControl?: Resolver<
    ResolversTypes['SearchControlComponent'],
    ParentType,
    ContextType
  >;
  sort?: Resolver<ResolversTypes['SrlSortComponent'], ParentType, ContextType>;
  subGeoFilter?: Resolver<
    ResolversTypes['SrlSubGeoFilterComponent'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlSortComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlSortComponent'] = ResolversParentTypes['SrlSortComponent']
> = {
  groupSorting?: Resolver<
    ResolversTypes['SrlGroupSorting'],
    ParentType,
    ContextType
  >;
  primarySort?: Resolver<
    ResolversTypes['SrlPrimarySort'],
    ParentType,
    ContextType
  >;
  productSorting?: Resolver<
    ResolversTypes['SrlProductSorting'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlSubGeoFilterComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlSubGeoFilterComponent'] = ResolversParentTypes['SrlSubGeoFilterComponent']
> = {
  items?: Resolver<
    Maybe<Array<ResolversTypes['SrlSubGeoItem']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlSubGeoItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlSubGeoItem'] = ResolversParentTypes['SrlSubGeoItem']
> = {
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<SrlSubGeoItemImageArgs, 'criteria'>
  >;
  productCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  selected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlStaticContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlStaticContent'] = ResolversParentTypes['SrlStaticContent']
> = {
  zeroResultText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaticContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaticContext'] = ResolversParentTypes['StaticContext']
> = {
  components?: Resolver<
    Array<ResolversTypes['StaticPageComponent']>,
    ParentType,
    ContextType
  >;
  disrupterObject?: Resolver<
    Maybe<ResolversTypes['DisrupterObject']>,
    ParentType,
    ContextType
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<StaticContextHeroArgs, 'criteria'>
  >;
  lang?: Resolver<ResolversTypes['PageLanguage'], ParentType, ContextType>;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  pageBreadcrumbs?: Resolver<
    Maybe<ResolversTypes['BreadcrumbsComponent']>,
    ParentType,
    ContextType
  >;
  removeHeaderFooter?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  sharingOverlayDefaultImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<StaticContextSharingOverlayDefaultImageArgs, 'criteria'>
  >;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['StaticPageStatus'],
    ParentType,
    ContextType
  >;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  textBgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  textBgImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<StaticContextTextBgImageArgs, 'criteria'>
  >;
  textFgColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemeContext'] = ResolversParentTypes['ThemeContext']
> = {
  autocomplete?: Resolver<
    ResolversTypes['AutocompleteComponent'],
    ParentType,
    ContextType,
    RequireFields<ThemeContextAutocompleteArgs, 'criteria'>
  >;
  bookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dynamicContent?: Resolver<
    Array<ResolversTypes['ThemePageDynamicComponent']>,
    ParentType,
    ContextType,
    RequireFields<ThemeContextDynamicContentArgs, 'searchCriteria'>
  >;
  filters?: Resolver<
    ResolversTypes['MainFilterComponent'],
    ParentType,
    ContextType,
    Partial<ThemeContextFiltersArgs>
  >;
  link?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType,
    RequireFields<ThemeContextLinkArgs, 'targetPageType'>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  searchControl?: Resolver<
    ResolversTypes['SearchControlUnion'],
    ParentType,
    ContextType,
    Partial<ThemeContextSearchControlArgs>
  >;
  staticContent?: Resolver<
    ResolversTypes['ThemeStaticContent'],
    ParentType,
    ContextType,
    Partial<ThemeContextStaticContentArgs>
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemePageDynamicComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemePageDynamicComponent'] = ResolversParentTypes['ThemePageDynamicComponent']
> = {
  __resolveType: TypeResolveFn<
    'MediaGallery' | 'TextComponent' | 'ThemePageRecommendationGroup',
    ParentType,
    ContextType
  >;
};

export type ThemePageRecommendationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemePageRecommendationGroup'] = ResolversParentTypes['ThemePageRecommendationGroup']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fontColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  items?: Resolver<
    Array<ResolversTypes['ThemePageRecommendationItem']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tracking?: Resolver<
    Maybe<ResolversTypes['RecommendationTracking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemePageRecommendationItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemePageRecommendationItem'] = ResolversParentTypes['ThemePageRecommendationItem']
> = {
  __resolveType: TypeResolveFn<
    | 'BD4TravelRecommendation'
    | 'BlogArticleRecommendationItem'
    | 'GeoRecommendationItem'
    | 'MarketingRecommendationItem'
    | 'ProductRecommendationItem'
    | 'ThemeRecommendationItem',
    ParentType,
    ContextType
  >;
};

export type ThemeStaticContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemeStaticContent'] = ResolversParentTypes['ThemeStaticContent']
> = {
  disrupterObject?: Resolver<
    Maybe<ResolversTypes['DisrupterObject']>,
    ParentType,
    ContextType
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<ThemeStaticContentHeroArgs, 'criteria'>
  >;
  information?: Resolver<
    ResolversTypes['TextComponent'],
    ParentType,
    ContextType
  >;
  mapAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pageBreadcrumbs?: Resolver<
    Maybe<ResolversTypes['BreadcrumbsComponent']>,
    ParentType,
    ContextType
  >;
  requestButton?: Resolver<
    Maybe<ResolversTypes['RequestButtonComponent']>,
    ParentType,
    ContextType
  >;
  seoText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  themeStaticComponents?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['DynamicComponent']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeGeoContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemeGeoContext'] = ResolversParentTypes['ThemeGeoContext']
> = {
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  page?: Resolver<ResolversTypes['ThemeGeoPage'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeGeoPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemeGeoPage'] = ResolversParentTypes['ThemeGeoPage']
> = {
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<ThemeGeoPageHeroArgs, 'criteria'>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  pageBreadcrumbs?: Resolver<
    ResolversTypes['BreadcrumbsComponent'],
    ParentType,
    ContextType
  >;
  productRecommendations?: Resolver<
    Array<ResolversTypes['ThemeGeoProductRecommendation']>,
    ParentType,
    ContextType
  >;
  recommendationTitle?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  requestButton?: Resolver<
    ResolversTypes['RequestButtonComponent'],
    ParentType,
    ContextType
  >;
  tagline?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  themeRecommendations?: Resolver<
    Array<ResolversTypes['ThemeGeoThemeRecommendation']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeGeoProductRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemeGeoProductRecommendation'] = ResolversParentTypes['ThemeGeoProductRecommendation']
> = {
  breadcrumbs?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  disrupters?: Resolver<
    Array<ResolversTypes['DisrupterObject']>,
    ParentType,
    ContextType
  >;
  features?: Resolver<
    Array<ResolversTypes['ProductFeature']>,
    ParentType,
    ContextType
  >;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<ThemeGeoProductRecommendationImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  routePoints?: Resolver<
    Array<ResolversTypes['RoutePoint']>,
    ParentType,
    ContextType
  >;
  teaser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeGeoThemeRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemeGeoThemeRecommendation'] = ResolversParentTypes['ThemeGeoThemeRecommendation']
> = {
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<ThemeGeoThemeRecommendationImageArgs, 'criteria'>
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeOverviewContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemeOverviewContext'] = ResolversParentTypes['ThemeOverviewContext']
> = {
  autocompleteDestinations?: Resolver<
    ResolversTypes['AutocompleteComponent'],
    ParentType,
    ContextType,
    RequireFields<ThemeOverviewContextAutocompleteDestinationsArgs, 'criteria'>
  >;
  link?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType,
    RequireFields<ThemeOverviewContextLinkArgs, 'targetPageType'>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  page?: Resolver<ResolversTypes['ThemeOverviewPage'], ParentType, ContextType>;
  searchControl?: Resolver<
    ResolversTypes['SearchControlUnion'],
    ParentType,
    ContextType,
    Partial<ThemeOverviewContextSearchControlArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeOverviewPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemeOverviewPage'] = ResolversParentTypes['ThemeOverviewPage']
> = {
  blogArticle?: Resolver<
    Maybe<ResolversTypes['ThemePageRecommendationGroup']>,
    ParentType,
    ContextType
  >;
  blogArticleComponent?: Resolver<
    Maybe<ResolversTypes['BlogArticleRecommendation']>,
    ParentType,
    ContextType
  >;
  hero?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<ThemeOverviewPageHeroArgs, 'criteria'>
  >;
  information?: Resolver<
    Maybe<ResolversTypes['TextComponent']>,
    ParentType,
    ContextType
  >;
  pageBreadcrumbs?: Resolver<
    Maybe<ResolversTypes['BreadcrumbsComponent']>,
    ParentType,
    ContextType
  >;
  previews?: Resolver<
    Maybe<Array<ResolversTypes['ThemePreviewComponent']>>,
    ParentType,
    ContextType
  >;
  sharingOverlayDefaultImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<ThemeOverviewPageSharingOverlayDefaultImageArgs, 'criteria'>
  >;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemePreviewComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThemePreviewComponent'] = ResolversParentTypes['ThemePreviewComponent']
> = {
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<ThemePreviewComponentImageArgs, 'criteria'>
  >;
  searchLink?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType
  >;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  themeLink?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WishlistContext'] = ResolversParentTypes['WishlistContext']
> = {
  active?: Resolver<
    ResolversTypes['WishlistActiveComponent'],
    ParentType,
    ContextType,
    Partial<WishlistContextActiveArgs>
  >;
  activeOffer?: Resolver<
    ResolversTypes['WishlistActiveComponent'],
    ParentType,
    ContextType,
    RequireFields<WishlistContextActiveOfferArgs, 'productId'>
  >;
  filters?: Resolver<
    ResolversTypes['FilterComponent'],
    ParentType,
    ContextType,
    Partial<WishlistContextFiltersArgs>
  >;
  meta?: Resolver<ResolversTypes['PageMetaData'], ParentType, ContextType>;
  overview?: Resolver<
    ResolversTypes['WishlistOverviewComponent'],
    ParentType,
    ContextType
  >;
  productsAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  searchControl?: Resolver<
    ResolversTypes['SearchControlComponent'],
    ParentType,
    ContextType,
    Partial<WishlistContextSearchControlArgs>
  >;
  shareUrl?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType,
    Partial<WishlistContextShareUrlArgs>
  >;
  sharingOverlayDefaultImage?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<WishlistContextSharingOverlayDefaultImageArgs, 'criteria'>
  >;
  url?: Resolver<
    ResolversTypes['InternalLink'],
    ParentType,
    ContextType,
    Partial<WishlistContextUrlArgs>
  >;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistActiveComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WishlistActiveComponent'] = ResolversParentTypes['WishlistActiveComponent']
> = {
  items?: Resolver<
    Array<ResolversTypes['WishlistProductItem']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistProductItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WishlistProductItem'] = ResolversParentTypes['WishlistProductItem']
> = {
  changedOffer?: Resolver<
    Maybe<ResolversTypes['WishlistOffer']>,
    ParentType,
    ContextType
  >;
  link?: Resolver<ResolversTypes['InternalLink'], ParentType, ContextType>;
  offer?: Resolver<ResolversTypes['WishlistOffer'], ParentType, ContextType>;
  product?: Resolver<
    ResolversTypes['WishlistProduct'],
    ParentType,
    ContextType
  >;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<
    ResolversTypes['WishlistProductItemType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistOfferResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WishlistOffer'] = ResolversParentTypes['WishlistOffer']
> = {
  departureDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  flightAlternativesAvailable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inboundFlight?: Resolver<
    Maybe<ResolversTypes['Flight']>,
    ParentType,
    ContextType
  >;
  outboundFlight?: Resolver<
    Maybe<ResolversTypes['Flight']>,
    ParentType,
    ContextType
  >;
  pricePerPerson?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  productCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rooms?: Resolver<
    Array<ResolversTypes['WishlistOfferRoom']>,
    ParentType,
    ContextType
  >;
  totalPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  transferIncluded?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  transferPrice?: Resolver<
    Maybe<ResolversTypes['Price']>,
    ParentType,
    ContextType
  >;
  travelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType>;
  waitingScreenImage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  wishlistItem?: Resolver<
    Maybe<ResolversTypes['WishlistItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistOfferRoomResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WishlistOfferRoom'] = ResolversParentTypes['WishlistOfferRoom']
> = {
  adults?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  boardCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  boardDescription?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  children?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  roomCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roomDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WishlistProduct'] = ResolversParentTypes['WishlistProduct']
> = {
  breadcrumbs?: Resolver<
    Array<ResolversTypes['GeoObject']>,
    ParentType,
    ContextType
  >;
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  featureGroups?: Resolver<
    Array<ResolversTypes['ProductFeatureGroup']>,
    ParentType,
    ContextType
  >;
  featureSummary?: Resolver<
    Array<ResolversTypes['ProductFeatureGroup']>,
    ParentType,
    ContextType
  >;
  fromTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  giataId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hpRating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<WishlistProductImageArgs, 'criteria'>
  >;
  includedInPrice?: Resolver<
    Maybe<ResolversTypes['TextComponent']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productClass?: Resolver<
    ResolversTypes['ProductClass'],
    ParentType,
    ContextType
  >;
  sellingPropositions?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  taRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taReviews?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  travelType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  zoom?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistOverviewComponentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WishlistOverviewComponent'] = ResolversParentTypes['WishlistOverviewComponent']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  wishlists?: Resolver<
    Array<ResolversTypes['Wishlist']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Wishlist'] = ResolversParentTypes['Wishlist']
> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Image'],
    ParentType,
    ContextType,
    RequireFields<WishlistImageArgs, 'criteria'>
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productsAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  addBooking?: Resolver<
    ResolversTypes['AddBookingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddBookingArgs, 'addBookingRequest' | 'context'>
  >;
  addBookmark?: Resolver<
    ResolversTypes['BookmarkQuantityResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddBookmarkArgs, 'objectId' | 'pageType'>
  >;
  addBookmarks?: Resolver<
    ResolversTypes['BookmarkBulkResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddBookmarksArgs, 'bookmarks'>
  >;
  addFlightSrlHistory?: Resolver<
    ResolversTypes['FlightHistoryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddFlightSrlHistoryArgs, 'flightSrlSearchCriteria'>
  >;
  addFullTextSearchHistory?: Resolver<
    ResolversTypes['SrlHistoryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddFullTextSearchHistoryArgs, 'keywords'>
  >;
  addMultipleToActiveWishlist?: Resolver<
    ResolversTypes['WishlistAddMultipleToWishlistResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddMultipleToActiveWishlistArgs, 'offerIds'>
  >;
  addMultipleToActiveWishlistWithSource?: Resolver<
    ResolversTypes['WishlistAddMultipleToWishlistResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddMultipleToActiveWishlistWithSourceArgs, 'offerIds'>
  >;
  addOrlHistory?: Resolver<
    ResolversTypes['OrlHistoryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddOrlHistoryArgs, 'criteria' | 'productId'>
  >;
  addSharedBookmarkGroup?: Resolver<
    ResolversTypes['SharedBookmarkGroupResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddSharedBookmarkGroupArgs, 'bookmarkGroup'>
  >;
  addSharedWishlist?: Resolver<
    ResolversTypes['WishlistMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddSharedWishlistArgs, 'wishlistId'>
  >;
  addSrlHistory?: Resolver<
    ResolversTypes['SrlHistoryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddSrlHistoryArgs, 'searchCriteria'>
  >;
  addToActiveWishlist?: Resolver<
    ResolversTypes['WishlistAddToWishlistResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddToActiveWishlistArgs, 'offerId'>
  >;
  addToActiveWishlistWithSource?: Resolver<
    ResolversTypes['WishlistAddToWishlistResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddToActiveWishlistWithSourceArgs, 'offerId'>
  >;
  addTravelCompanion?: Resolver<
    ResolversTypes['AddTravelCompanionResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTravelCompanionArgs, 'travelCompanion'>
  >;
  addUpsellTransferToBasket?: Resolver<
    ResolversTypes['AddUpsellTransferToBasketResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddUpsellTransferToBasketArgs, 'context' | 'request'>
  >;
  addViewedHistory?: Resolver<
    ResolversTypes['AddViewedHistoryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddViewedHistoryArgs, 'objectId' | 'pageType'>
  >;
  anonymousLoggedIn?: Resolver<
    ResolversTypes['AccountMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAnonymousLoggedInArgs, 'anonymousToken' | 'context'>
  >;
  cancelBooking?: Resolver<
    ResolversTypes['BookingCancellationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCancelBookingArgs, 'bookingId' | 'context'>
  >;
  changePaymentMethod?: Resolver<
    ResolversTypes['ChangePaymentMethodResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationChangePaymentMethodArgs, 'request'>
  >;
  copyBookmark?: Resolver<
    ResolversTypes['BookmarkResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCopyBookmarkArgs,
      'groupIdFrom' | 'groupIdTo' | 'objectId' | 'pageType'
    >
  >;
  createBookmarkGroup?: Resolver<
    ResolversTypes['BookmarkGroupCreateResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateBookmarkGroupArgs, 'name'>
  >;
  createCatalogOrder?: Resolver<
    ResolversTypes['CatalogOrderResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCatalogOrderArgs, 'order'>
  >;
  createNewsletterSubscription?: Resolver<
    ResolversTypes['NewsletterSubscriptionResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateNewsletterSubscriptionArgs,
      'newsletterSubscription'
    >
  >;
  createWishlist?: Resolver<
    ResolversTypes['WishlistMutationResponse'],
    ParentType,
    ContextType,
    Partial<MutationCreateWishlistArgs>
  >;
  deleteBookmark?: Resolver<
    ResolversTypes['BookmarkQuantityResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteBookmarkArgs, 'objectId' | 'pageType'>
  >;
  deleteBookmarkGroup?: Resolver<
    ResolversTypes['BookmarkGroupDeleteResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteBookmarkGroupArgs, 'groupId'>
  >;
  deleteBookmarks?: Resolver<
    ResolversTypes['BookmarkBulkResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteBookmarksArgs, 'bookmarks'>
  >;
  deleteHistory?: Resolver<
    ResolversTypes['DeleteHistoryResponse'],
    ParentType,
    ContextType,
    Partial<MutationDeleteHistoryArgs>
  >;
  deleteTravelCompanion?: Resolver<
    ResolversTypes['AccountMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTravelCompanionArgs, 'id'>
  >;
  deleteUpsellServiceFromBasket?: Resolver<
    ResolversTypes['AccountMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteUpsellServiceFromBasketArgs,
      'context' | 'request'
    >
  >;
  deleteWishlist?: Resolver<
    ResolversTypes['WishlistMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWishlistArgs, 'wishlistId'>
  >;
  finalizeAccessBookingCancellation?: Resolver<
    ResolversTypes['AccessBookingCancellationFinalizationResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationFinalizeAccessBookingCancellationArgs,
      'cancellationToken'
    >
  >;
  finalizeBookingCancellation?: Resolver<
    ResolversTypes['BookingCancellationFinalizationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationFinalizeBookingCancellationArgs, 'context' | 'token'>
  >;
  finalizeSubscriptionByToken?: Resolver<
    ResolversTypes['NewsletterSubscriptionResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationFinalizeSubscriptionByTokenArgs, 'token'>
  >;
  finalizeUser?: Resolver<
    ResolversTypes['UserFinalizationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationFinalizeUserArgs, 'context' | 'request'>
  >;
  holidayFinderVote?: Resolver<
    ResolversTypes['HolidayFinderVotingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationHolidayFinderVoteArgs, 'request'>
  >;
  initBookingCancellation?: Resolver<
    ResolversTypes['AccessBookingCancellationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationInitBookingCancellationArgs, 'bookingId'>
  >;
  initPaymentMethod?: Resolver<
    ResolversTypes['InitPaymentResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationInitPaymentMethodArgs, 'initPaymentRequest'>
  >;
  initUpsellPaymentMethod?: Resolver<
    ResolversTypes['InitPaymentResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationInitUpsellPaymentMethodArgs,
      'context' | 'initPaymentRequest'
    >
  >;
  moveBookmark?: Resolver<
    ResolversTypes['BookmarkResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationMoveBookmarkArgs,
      'groupIdFrom' | 'groupIdTo' | 'objectId' | 'pageType'
    >
  >;
  moveToWishlist?: Resolver<
    ResolversTypes['WishlistMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationMoveToWishlistArgs, 'offerId' | 'wishlistId'>
  >;
  pdfUpload?: Resolver<
    ResolversTypes['BookmarkPdfUploadResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationPdfUploadArgs, 'request'>
  >;
  registerUser?: Resolver<
    ResolversTypes['UserRegistrationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRegisterUserArgs, 'context' | 'request'>
  >;
  removeFromActiveWishlist?: Resolver<
    ResolversTypes['WishlistMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveFromActiveWishlistArgs, 'offerId'>
  >;
  removeNewsletterSubscription?: Resolver<
    ResolversTypes['NewsletterUnsubscriptionResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationRemoveNewsletterSubscriptionArgs,
      'newsletterSubscription'
    >
  >;
  renameBookmarkGroup?: Resolver<
    ResolversTypes['BookmarkGroupRenameResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRenameBookmarkGroupArgs, 'groupId' | 'groupName'>
  >;
  renameWishlist?: Resolver<
    ResolversTypes['WishlistMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRenameWishlistArgs, 'newName' | 'wishlistId'>
  >;
  resendTravelDocument?: Resolver<
    ResolversTypes['ResendTravelDocumentResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationResendTravelDocumentArgs, 'request'>
  >;
  revokeEmailAdvertising?: Resolver<
    ResolversTypes['EmailAdvertisingRevocationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRevokeEmailAdvertisingArgs, 'token'>
  >;
  sendAgencyAppointmentRequest?: Resolver<
    ResolversTypes['AgencyAppointmentResponse'],
    ParentType,
    ContextType,
    Partial<MutationSendAgencyAppointmentRequestArgs>
  >;
  sendContactFormRequest?: Resolver<
    ResolversTypes['ContactFormResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSendContactFormRequestArgs, 'request'>
  >;
  setActiveWishlist?: Resolver<
    ResolversTypes['WishlistMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSetActiveWishlistArgs, 'wishlistId'>
  >;
  updateEmergencyPhone?: Resolver<
    ResolversTypes['UpdateEmergencyPhoneResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEmergencyPhoneArgs, 'request'>
  >;
  updatePersonalData?: Resolver<
    ResolversTypes['AccountMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePersonalDataArgs, 'personalDataInput'>
  >;
  updateSubscription?: Resolver<
    ResolversTypes['AccountMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSubscriptionArgs, 'subscriptionTypes'>
  >;
  updateTravelCompanion?: Resolver<
    ResolversTypes['AccountMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTravelCompanionArgs, 'travelCompanion'>
  >;
  viewedProductAddedToWishlist?: Resolver<
    ResolversTypes['ViewedProductHistoryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationViewedProductAddedToWishlistArgs, 'productId'>
  >;
  viewedProductCheckout?: Resolver<
    ResolversTypes['ViewedProductHistoryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationViewedProductCheckoutArgs, 'productId'>
  >;
  viewedProductVisited?: Resolver<
    ResolversTypes['ViewedProductHistoryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationViewedProductVisitedArgs, 'productId'>
  >;
};

export type AddBookingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddBookingResponse'] = ResolversParentTypes['AddBookingResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkBulkResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookmarkBulkResponse'] = ResolversParentTypes['BookmarkBulkResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightHistoryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FlightHistoryResponse'] = ResolversParentTypes['FlightHistoryResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrlHistoryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SrlHistoryResponse'] = ResolversParentTypes['SrlHistoryResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistAddMultipleToWishlistResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WishlistAddMultipleToWishlistResponse'] = ResolversParentTypes['WishlistAddMultipleToWishlistResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offers?: Resolver<
    Array<ResolversTypes['WishlistOffer']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrlHistoryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrlHistoryResponse'] = ResolversParentTypes['OrlHistoryResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SharedBookmarkGroupResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SharedBookmarkGroupResponse'] = ResolversParentTypes['SharedBookmarkGroupResponse']
> = {
  groupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WishlistMutationResponse'] = ResolversParentTypes['WishlistMutationResponse']
> = {
  activeChanged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sharedAvailable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WishlistAddToWishlistResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WishlistAddToWishlistResponse'] = ResolversParentTypes['WishlistAddToWishlistResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offer?: Resolver<
    Maybe<ResolversTypes['WishlistOffer']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddTravelCompanionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddTravelCompanionResponse'] = ResolversParentTypes['AddTravelCompanionResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  travelCompanion?: Resolver<
    Maybe<ResolversTypes['TravelCompanion']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddUpsellTransferToBasketResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddUpsellTransferToBasketResponse'] = ResolversParentTypes['AddUpsellTransferToBasketResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddViewedHistoryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddViewedHistoryResponse'] = ResolversParentTypes['AddViewedHistoryResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountMutationResponse'] = ResolversParentTypes['AccountMutationResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingCancellationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingCancellationResponse'] = ResolversParentTypes['BookingCancellationResponse']
> = {
  code?: Resolver<
    ResolversTypes['BookingCancellationCode'],
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payload?: Resolver<
    Maybe<ResolversTypes['BookingCancellationPayloadUnion']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingCancellationPayloadUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingCancellationPayloadUnion'] = ResolversParentTypes['BookingCancellationPayloadUnion']
> = {
  __resolveType: TypeResolveFn<
    'BookingCancellationConfirmationPayload',
    ParentType,
    ContextType
  >;
};

export type BookingCancellationConfirmationPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingCancellationConfirmationPayload'] = ResolversParentTypes['BookingCancellationConfirmationPayload']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChangePaymentMethodResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChangePaymentMethodResponse'] = ResolversParentTypes['ChangePaymentMethodResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookmarkResponse'] = ResolversParentTypes['BookmarkResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkGroupCreateResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookmarkGroupCreateResponse'] = ResolversParentTypes['BookmarkGroupCreateResponse']
> = {
  groupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogOrderResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogOrderResponse'] = ResolversParentTypes['CatalogOrderResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterSubscriptionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterSubscriptionResponse'] = ResolversParentTypes['NewsletterSubscriptionResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  subscription?: Resolver<
    ResolversTypes['NewsletterSubscription'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterSubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterSubscription'] = ResolversParentTypes['NewsletterSubscription']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  salutation?: Resolver<ResolversTypes['Salutation'], ParentType, ContextType>;
  state?: Resolver<
    ResolversTypes['SubscriptionState'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkGroupDeleteResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookmarkGroupDeleteResponse'] = ResolversParentTypes['BookmarkGroupDeleteResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteHistoryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteHistoryResponse'] = ResolversParentTypes['DeleteHistoryResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessBookingCancellationFinalizationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessBookingCancellationFinalizationResponse'] = ResolversParentTypes['AccessBookingCancellationFinalizationResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  tokenExpire?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingCancellationFinalizationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingCancellationFinalizationResponse'] = ResolversParentTypes['BookingCancellationFinalizationResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserFinalizationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserFinalizationResponse'] = ResolversParentTypes['UserFinalizationResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessBookingCancellationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessBookingCancellationResponse'] = ResolversParentTypes['AccessBookingCancellationResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InitPaymentResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InitPaymentResponse'] = ResolversParentTypes['InitPaymentResponse']
> = {
  httpMethod?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirectFormData?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  redirectUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkPdfUploadResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookmarkPdfUploadResponse'] = ResolversParentTypes['BookmarkPdfUploadResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uploadInfo?: Resolver<
    Maybe<Array<ResolversTypes['BookmarkPdfUploadInfo']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkPdfUploadInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookmarkPdfUploadInfo'] = ResolversParentTypes['BookmarkPdfUploadInfo']
> = {
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  productText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserRegistrationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserRegistrationResponse'] = ResolversParentTypes['UserRegistrationResponse']
> = {
  code?: Resolver<
    Maybe<ResolversTypes['UserRegistrationCode']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Status']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsletterUnsubscriptionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewsletterUnsubscriptionResponse'] = ResolversParentTypes['NewsletterUnsubscriptionResponse']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkGroupRenameResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookmarkGroupRenameResponse'] = ResolversParentTypes['BookmarkGroupRenameResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResendTravelDocumentResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResendTravelDocumentResponse'] = ResolversParentTypes['ResendTravelDocumentResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailAdvertisingRevocationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailAdvertisingRevocationResponse'] = ResolversParentTypes['EmailAdvertisingRevocationResponse']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgencyAppointmentResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyAppointmentResponse'] = ResolversParentTypes['AgencyAppointmentResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactFormResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactFormResponse'] = ResolversParentTypes['ContactFormResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateEmergencyPhoneResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateEmergencyPhoneResponse'] = ResolversParentTypes['UpdateEmergencyPhoneResponse']
> = {
  emergencyPhone?: Resolver<
    Maybe<ResolversTypes['PhoneDetails']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ViewedProductHistoryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ViewedProductHistoryResponse'] = ResolversParentTypes['ViewedProductHistoryResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UnrepresentableScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['UNREPRESENTABLE'], any> {
  name: 'UNREPRESENTABLE';
}

export type Resolvers<ContextType = any> = {
  Query?: QueryResolvers<ContextType>;
  AccessBookingContext?: AccessBookingContextResolvers<ContextType>;
  AccessBookingPageResponse?: AccessBookingPageResponseResolvers<ContextType>;
  AccessBookingFlightInformation?: AccessBookingFlightInformationResolvers<ContextType>;
  PageMetaData?: PageMetaDataResolvers<ContextType>;
  Map_String_StringScalar?: GraphQLScalarType;
  AccessBookingPageTransferInfo?: AccessBookingPageTransferInfoResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  ResizedImage?: ResizedImageResolvers<ContextType>;
  Link?: LinkResolvers<ContextType>;
  ExternalLink?: ExternalLinkResolvers<ContextType>;
  InternalLink?: InternalLinkResolvers<ContextType>;
  AccessBookingTravelDocument?: AccessBookingTravelDocumentResolvers<ContextType>;
  BookingDetailsResponse?: BookingDetailsResponseResolvers<ContextType>;
  BookingDetails?: BookingDetailsResolvers<ContextType>;
  PaymentMethod?: PaymentMethodResolvers<ContextType>;
  LocalDate?: GraphQLScalarType;
  BookingItem?: BookingItemResolvers<ContextType>;
  FlightDestinationInfo?: FlightDestinationInfoResolvers<ContextType>;
  Airport?: AirportResolvers<ContextType>;
  GeoCoordinates?: GeoCoordinatesResolvers<ContextType>;
  HotelDestinationInfo?: HotelDestinationInfoResolvers<ContextType>;
  BreadcrumbsComponent?: BreadcrumbsComponentResolvers<ContextType>;
  Breadcrumb?: BreadcrumbResolvers<ContextType>;
  BookingExtraService?: BookingExtraServiceResolvers<ContextType>;
  Traveller?: TravellerResolvers<ContextType>;
  CancellationServiceInfo?: CancellationServiceInfoResolvers<ContextType>;
  PaymentRecord?: PaymentRecordResolvers<ContextType>;
  Price?: PriceResolvers<ContextType>;
  CarRentalDetails?: CarRentalDetailsResolvers<ContextType>;
  CarDetails?: CarDetailsResolvers<ContextType>;
  CarDriver?: CarDriverResolvers<ContextType>;
  LocalTime?: GraphQLScalarType;
  Agency?: AgencyResolvers<ContextType>;
  AddressDetails?: AddressDetailsResolvers<ContextType>;
  AgencyDynamicComponent?: AgencyDynamicComponentResolvers<ContextType>;
  AgencyRecommendationGroup?: AgencyRecommendationGroupResolvers<ContextType>;
  AgencyRecommendationItemDeprecated?: AgencyRecommendationItemDeprecatedResolvers<ContextType>;
  BD4TravelRecommendation?: Bd4TravelRecommendationResolvers<ContextType>;
  DisrupterObject?: DisrupterObjectResolvers<ContextType>;
  ProductFeature?: ProductFeatureResolvers<ContextType>;
  ProductFeatureGroup?: ProductFeatureGroupResolvers<ContextType>;
  GeoObject?: GeoObjectResolvers<ContextType>;
  Room?: RoomResolvers<ContextType>;
  WishlistItem?: WishlistItemResolvers<ContextType>;
  BlogArticleRecommendationItem?: BlogArticleRecommendationItemResolvers<ContextType>;
  GeoRecommendationItem?: GeoRecommendationItemResolvers<ContextType>;
  MarketingRecommendationItem?: MarketingRecommendationItemResolvers<ContextType>;
  Media?: MediaResolvers<ContextType>;
  ExternalMediaItem?: ExternalMediaItemResolvers<ContextType>;
  ImageMediaItem?: ImageMediaItemResolvers<ContextType>;
  MusicMediaItem?: MusicMediaItemResolvers<ContextType>;
  PdfMediaItem?: PdfMediaItemResolvers<ContextType>;
  VideoMediaItem?: VideoMediaItemResolvers<ContextType>;
  ProductRecommendationItem?: ProductRecommendationItemResolvers<ContextType>;
  ThemeRecommendationItem?: ThemeRecommendationItemResolvers<ContextType>;
  RecommendationTracking?: RecommendationTrackingResolvers<ContextType>;
  BD4TravelRecommendationTracking?: Bd4TravelRecommendationTrackingResolvers<ContextType>;
  BD4TravelRecommendationInfo?: Bd4TravelRecommendationInfoResolvers<ContextType>;
  MediaGallery?: MediaGalleryResolvers<ContextType>;
  TextComponent?: TextComponentResolvers<ContextType>;
  OpeningHours?: OpeningHoursResolvers<ContextType>;
  Shift?: ShiftResolvers<ContextType>;
  RequestButtonComponent?: RequestButtonComponentResolvers<ContextType>;
  Review?: ReviewResolvers<ContextType>;
  SpecialOpeningHours?: SpecialOpeningHoursResolvers<ContextType>;
  AccountPersonalDataResponse?: AccountPersonalDataResponseResolvers<ContextType>;
  PhoneDetails?: PhoneDetailsResolvers<ContextType>;
  DestinationInfo?: DestinationInfoResolvers<ContextType>;
  FlightBookingDetails?: FlightBookingDetailsResolvers<ContextType>;
  Flight?: FlightResolvers<ContextType>;
  FlightBaggageInfo?: FlightBaggageInfoResolvers<ContextType>;
  FlightSegment?: FlightSegmentResolvers<ContextType>;
  Airline?: AirlineResolvers<ContextType>;
  HotelBookingDetails?: HotelBookingDetailsResolvers<ContextType>;
  CruiseInfo?: CruiseInfoResolvers<ContextType>;
  BookingHotelRoom?: BookingHotelRoomResolvers<ContextType>;
  BookingBoardType?: BookingBoardTypeResolvers<ContextType>;
  BookingRoomType?: BookingRoomTypeResolvers<ContextType>;
  RoundtripInfo?: RoundtripInfoResolvers<ContextType>;
  PaymentCondition?: PaymentConditionResolvers<ContextType>;
  RestPayment?: RestPaymentResolvers<ContextType>;
  PaymentInformationSection?: PaymentInformationSectionResolvers<ContextType>;
  BookingTransfer?: BookingTransferResolvers<ContextType>;
  TransferDate?: TransferDateResolvers<ContextType>;
  TransferDetailInfo?: TransferDetailInfoResolvers<ContextType>;
  TransferPointInfoUnion?: TransferPointInfoUnionResolvers<ContextType>;
  TransferFlightInfo?: TransferFlightInfoResolvers<ContextType>;
  TransferHotelInfo?: TransferHotelInfoResolvers<ContextType>;
  TransferInfo?: TransferInfoResolvers<ContextType>;
  TravellerInfo?: TravellerInfoResolvers<ContextType>;
  BookingDetailsHotelOffer?: BookingDetailsHotelOfferResolvers<ContextType>;
  BookingCancellationInfoResponse?: BookingCancellationInfoResponseResolvers<ContextType>;
  AccountContext?: AccountContextResolvers<ContextType>;
  AuthMethodListResponse?: AuthMethodListResponseResolvers<ContextType>;
  AuthMethod?: AuthMethodResolvers<ContextType>;
  AccountPage?: AccountPageResolvers<ContextType>;
  CheckBasketResponse?: CheckBasketResponseResolvers<ContextType>;
  UpsellTransferService?: UpsellTransferServiceResolvers<ContextType>;
  AccountLoginPage?: AccountLoginPageResolvers<ContextType>;
  AccountMyProfilePage?: AccountMyProfilePageResolvers<ContextType>;
  BookingResponse?: BookingResponseResolvers<ContextType>;
  Page?: PageResolvers<ContextType>;
  TravelComponentResponse?: TravelComponentResponseResolvers<ContextType>;
  TravelCompanion?: TravelCompanionResolvers<ContextType>;
  UpsellTransferResponse?: UpsellTransferResponseResolvers<ContextType>;
  UpsellTransfer?: UpsellTransferResolvers<ContextType>;
  UpsellTransferPrice?: UpsellTransferPriceResolvers<ContextType>;
  UserParamsFromTokenResponse?: UserParamsFromTokenResponseResolvers<ContextType>;
  HashMap_String_StringScalar?: GraphQLScalarType;
  AgencyContact?: AgencyContactResolvers<ContextType>;
  AgencyContactPhone?: AgencyContactPhoneResolvers<ContextType>;
  AgencyOverviewPageContext?: AgencyOverviewPageContextResolvers<ContextType>;
  AgencyAutocomplete?: AgencyAutocompleteResolvers<ContextType>;
  AgencyAutocompleteSuggestion?: AgencyAutocompleteSuggestionResolvers<ContextType>;
  MapSuggestion?: MapSuggestionResolvers<ContextType>;
  AgencyOverviewInfoComponent?: AgencyOverviewInfoComponentResolvers<ContextType>;
  BlogArticle?: BlogArticleResolvers<ContextType>;
  EmployeeBox?: EmployeeBoxResolvers<ContextType>;
  EmployeeSocialProfile?: EmployeeSocialProfileResolvers<ContextType>;
  BlogOverview?: BlogOverviewResolvers<ContextType>;
  BlogArticles?: BlogArticlesResolvers<ContextType>;
  BlogArticleItem?: BlogArticleItemResolvers<ContextType>;
  BookingCancellationResultResponse?: BookingCancellationResultResponseResolvers<ContextType>;
  BookingPaymentResultResponse?: BookingPaymentResultResponseResolvers<ContextType>;
  BookmarkContext?: BookmarkContextResolvers<ContextType>;
  BookmarkQuantityResponse?: BookmarkQuantityResponseResolvers<ContextType>;
  BookmarkPage?: BookmarkPageResolvers<ContextType>;
  AllBookmarkGroupResponse?: AllBookmarkGroupResponseResolvers<ContextType>;
  BookmarkGroup?: BookmarkGroupResolvers<ContextType>;
  BookmarkItem?: BookmarkItemResolvers<ContextType>;
  ProductInfoB2B?: ProductInfoB2BResolvers<ContextType>;
  Booster?: BoosterResolvers<ContextType>;
  CatalogOverviewContext?: CatalogOverviewContextResolvers<ContextType>;
  Catalog?: CatalogResolvers<ContextType>;
  CatalogOverviewInfoComponent?: CatalogOverviewInfoComponentResolvers<ContextType>;
  DynamicComponent?: DynamicComponentResolvers<ContextType>;
  AccordionComponent?: AccordionComponentResolvers<ContextType>;
  AgencyContactComponent?: AgencyContactComponentResolvers<ContextType>;
  AgencyRecommendation?: AgencyRecommendationResolvers<ContextType>;
  AgencyRecommendationItem?: AgencyRecommendationItemResolvers<ContextType>;
  BD4TravelRecommendationGroup?: Bd4TravelRecommendationGroupResolvers<ContextType>;
  BenefitsComponent?: BenefitsComponentResolvers<ContextType>;
  BenefitItem?: BenefitItemResolvers<ContextType>;
  BlogArticleRecommendation?: BlogArticleRecommendationResolvers<ContextType>;
  BrandsComponent?: BrandsComponentResolvers<ContextType>;
  BrandBox?: BrandBoxResolvers<ContextType>;
  ClimateComponent?: ClimateComponentResolvers<ContextType>;
  ClimateChart?: ClimateChartResolvers<ContextType>;
  ClimateChartEntry?: ClimateChartEntryResolvers<ContextType>;
  ContactModuleComponent?: ContactModuleComponentResolvers<ContextType>;
  ContactTypeUnion?: ContactTypeUnionResolvers<ContextType>;
  ContactType?: ContactTypeResolvers<ContextType>;
  ContactTypePhone?: ContactTypePhoneResolvers<ContextType>;
  EmployeesComponent?: EmployeesComponentResolvers<ContextType>;
  FaqComponent?: FaqComponentResolvers<ContextType>;
  FlightWithoutPriceRecommendation?: FlightWithoutPriceRecommendationResolvers<ContextType>;
  FlightRecommendationWithoutPriceItem?: FlightRecommendationWithoutPriceItemResolvers<ContextType>;
  FlightWithPriceRecommendation?: FlightWithPriceRecommendationResolvers<ContextType>;
  FlightRecommendationWithPriceItem?: FlightRecommendationWithPriceItemResolvers<ContextType>;
  FlightOffer?: FlightOfferResolvers<ContextType>;
  GeoChildrenComponent?: GeoChildrenComponentResolvers<ContextType>;
  GeoChildComponent?: GeoChildComponentResolvers<ContextType>;
  GeoGalleryComponent?: GeoGalleryComponentResolvers<ContextType>;
  GeoRecommendation?: GeoRecommendationResolvers<ContextType>;
  HeroMediaGallery?: HeroMediaGalleryResolvers<ContextType>;
  HeroMedia?: HeroMediaResolvers<ContextType>;
  ImageTextComponent?: ImageTextComponentResolvers<ContextType>;
  LinkListComponent?: LinkListComponentResolvers<ContextType>;
  LinkListItem?: LinkListItemResolvers<ContextType>;
  MarketingRecommendation?: MarketingRecommendationResolvers<ContextType>;
  MarketingTeaserComponent?: MarketingTeaserComponentResolvers<ContextType>;
  NewsArticlesComponent?: NewsArticlesComponentResolvers<ContextType>;
  NewsArticle?: NewsArticleResolvers<ContextType>;
  NewsletterSignupComponent?: NewsletterSignupComponentResolvers<ContextType>;
  OfferButtonComponent?: OfferButtonComponentResolvers<ContextType>;
  OffersMapPlaceholderComponent?: OffersMapPlaceholderComponentResolvers<ContextType>;
  ProductGalleryWithMapComponent?: ProductGalleryWithMapComponentResolvers<ContextType>;
  ProductImageGroup?: ProductImageGroupResolvers<ContextType>;
  ProductImageItem?: ProductImageItemResolvers<ContextType>;
  RelatedRoundtrips?: RelatedRoundtripsResolvers<ContextType>;
  RelatedRoundtripItem?: RelatedRoundtripItemResolvers<ContextType>;
  RoutePoint?: RoutePointResolvers<ContextType>;
  ProductRecommendation?: ProductRecommendationResolvers<ContextType>;
  ResortTopHotelsComponent?: ResortTopHotelsComponentResolvers<ContextType>;
  SrlButtonPlaceholderComponent?: SrlButtonPlaceholderComponentResolvers<ContextType>;
  SrlMapPinsComponent?: SrlMapPinsComponentResolvers<ContextType>;
  SrlMapGeoPin?: SrlMapGeoPinResolvers<ContextType>;
  SrlMapProductPin?: SrlMapProductPinResolvers<ContextType>;
  StaticBlogRecommendation?: StaticBlogRecommendationResolvers<ContextType>;
  StaticGeoRecommendation?: StaticGeoRecommendationResolvers<ContextType>;
  StaticGeoRecommendationItem?: StaticGeoRecommendationItemResolvers<ContextType>;
  StaticProductRecommendation?: StaticProductRecommendationResolvers<ContextType>;
  StaticProductRecommendationItem?: StaticProductRecommendationItemResolvers<ContextType>;
  ProductFilter?: ProductFilterResolvers<ContextType>;
  StaticThemeRecommendation?: StaticThemeRecommendationResolvers<ContextType>;
  StaticThemeRecommendationItem?: StaticThemeRecommendationItemResolvers<ContextType>;
  ThemeRecommendation?: ThemeRecommendationResolvers<ContextType>;
  UspBoxesComponent?: UspBoxesComponentResolvers<ContextType>;
  UspBox?: UspBoxResolvers<ContextType>;
  ContactPageContext?: ContactPageContextResolvers<ContextType>;
  ContactDynamicComponent?: ContactDynamicComponentResolvers<ContextType>;
  ContactRecommendationGroup?: ContactRecommendationGroupResolvers<ContextType>;
  ContactRecommendationItem?: ContactRecommendationItemResolvers<ContextType>;
  ContactForm?: ContactFormResolvers<ContextType>;
  CookieDisclaimer?: CookieDisclaimerResolvers<ContextType>;
  EmailAdvertisingRevocationPageContext?: EmailAdvertisingRevocationPageContextResolvers<ContextType>;
  EmailAdvertisingStaticContent?: EmailAdvertisingStaticContentResolvers<ContextType>;
  EmailAdvertisingStaticContentInfo?: EmailAdvertisingStaticContentInfoResolvers<ContextType>;
  EmailAdvertisingGetEmailFromTokenResponse?: EmailAdvertisingGetEmailFromTokenResponseResolvers<ContextType>;
  EmailAdvertisingGetTokenFromEmailResponse?: EmailAdvertisingGetTokenFromEmailResponseResolvers<ContextType>;
  EmailAdvertisingRevocationConfirmationPageContext?: EmailAdvertisingRevocationConfirmationPageContextResolvers<ContextType>;
  EmailAdvertisingDynamicComponent?: EmailAdvertisingDynamicComponentResolvers<ContextType>;
  EmailAdvertisingRecommendationGroup?: EmailAdvertisingRecommendationGroupResolvers<ContextType>;
  EmailAdvertisingRecommendationItem?: EmailAdvertisingRecommendationItemResolvers<ContextType>;
  FlightHomeContext?: FlightHomeContextResolvers<ContextType>;
  AutocompleteComponent?: AutocompleteComponentResolvers<ContextType>;
  TravelDestination?: TravelDestinationResolvers<ContextType>;
  GeoLocation?: GeoLocationResolvers<ContextType>;
  ThemeDestinationInformation?: ThemeDestinationInformationResolvers<ContextType>;
  FlightHomeDynamicComponent?: FlightHomeDynamicComponentResolvers<ContextType>;
  FlightHomeRecommendationGroup?: FlightHomeRecommendationGroupResolvers<ContextType>;
  FlightHomeRecommendationItem?: FlightHomeRecommendationItemResolvers<ContextType>;
  SearchControlUnion?: SearchControlUnionResolvers<ContextType>;
  FlightSearchControlComponent?: FlightSearchControlComponentResolvers<ContextType>;
  FlightPartition?: FlightPartitionResolvers<ContextType>;
  ExactTravelPeriod?: ExactTravelPeriodResolvers<ContextType>;
  Travellers?: TravellersResolvers<ContextType>;
  SearchControlComponent?: SearchControlComponentResolvers<ContextType>;
  TravelPeriodComponent?: TravelPeriodComponentResolvers<ContextType>;
  FlexibleTravelPeriod?: FlexibleTravelPeriodResolvers<ContextType>;
  TravellersDistribution?: TravellersDistributionResolvers<ContextType>;
  RoomDistribution?: RoomDistributionResolvers<ContextType>;
  TravelPeriodUnion?: TravelPeriodUnionResolvers<ContextType>;
  FlightSrlContext?: FlightSrlContextResolvers<ContextType>;
  FlightCheckoutComponent?: FlightCheckoutComponentResolvers<ContextType>;
  FlightSrlContainer?: FlightSrlContainerResolvers<ContextType>;
  FlightSrlComponent?: FlightSrlComponentResolvers<ContextType>;
  FlightStopOverDuration?: FlightStopOverDurationResolvers<ContextType>;
  FlightSrlDynamicComponent?: FlightSrlDynamicComponentResolvers<ContextType>;
  FlightSrlRecommendationGroup?: FlightSrlRecommendationGroupResolvers<ContextType>;
  FlightSrlRecommendationItem?: FlightSrlRecommendationItemResolvers<ContextType>;
  FilterComponent?: FilterComponentResolvers<ContextType>;
  CheckboxFilterComponent?: CheckboxFilterComponentResolvers<ContextType>;
  FilterItem?: FilterItemResolvers<ContextType>;
  RadiobuttonFilterComponent?: RadiobuttonFilterComponentResolvers<ContextType>;
  SliderFilterComponent?: SliderFilterComponentResolvers<ContextType>;
  SingleValueFilterComponent?: SingleValueFilterComponentResolvers<ContextType>;
  DoubleRangeSliderFilterComponent?: DoubleRangeSliderFilterComponentResolvers<ContextType>;
  RadiusFilterComponent?: RadiusFilterComponentResolvers<ContextType>;
  WaitingScreen?: WaitingScreenResolvers<ContextType>;
  GeoContext?: GeoContextResolvers<ContextType>;
  GeoChildrenComponentInterface?: GeoChildrenComponentInterfaceResolvers<ContextType>;
  GeoChildrenOffersComponent?: GeoChildrenOffersComponentResolvers<ContextType>;
  GeoChildrenOffer?: GeoChildrenOfferResolvers<ContextType>;
  GeoOfferInfo?: GeoOfferInfoResolvers<ContextType>;
  GeoDefaultGeoRecommendationsComponent?: GeoDefaultGeoRecommendationsComponentResolvers<ContextType>;
  GeoObjectPageComponent?: GeoObjectPageComponentResolvers<ContextType>;
  GeoObjectRecommendationGroupComponent?: GeoObjectRecommendationGroupComponentResolvers<ContextType>;
  GeoObjectRecommendationItem?: GeoObjectRecommendationItemResolvers<ContextType>;
  GeoProduct?: GeoProductResolvers<ContextType>;
  MainFilterComponent?: MainFilterComponentResolvers<ContextType>;
  SubGeoFilterComponent?: SubGeoFilterComponentResolvers<ContextType>;
  SubGeoItem?: SubGeoItemResolvers<ContextType>;
  GeoInfoComponent?: GeoInfoComponentResolvers<ContextType>;
  GeoFeatureGroup?: GeoFeatureGroupResolvers<ContextType>;
  GeoFeature?: GeoFeatureResolvers<ContextType>;
  AccordionItem?: AccordionItemResolvers<ContextType>;
  InpageNavigationItem?: InpageNavigationItemResolvers<ContextType>;
  ProductImageGallery?: ProductImageGalleryResolvers<ContextType>;
  ProductImage?: ProductImageResolvers<ContextType>;
  SeoChildrenComponent?: SeoChildrenComponentResolvers<ContextType>;
  SeoGeoChildren?: SeoGeoChildrenResolvers<ContextType>;
  GeoSiblingsComponent?: GeoSiblingsComponentResolvers<ContextType>;
  GeoTheme?: GeoThemeResolvers<ContextType>;
  GeoOverviewContext?: GeoOverviewContextResolvers<ContextType>;
  GeoOverviewChildComponent?: GeoOverviewChildComponentResolvers<ContextType>;
  GeoOverviewChildOfferInfo?: GeoOverviewChildOfferInfoResolvers<ContextType>;
  GeoOverviewPageComponent?: GeoOverviewPageComponentResolvers<ContextType>;
  GeoOverviewRecommendationGroupComponent?: GeoOverviewRecommendationGroupComponentResolvers<ContextType>;
  GeoOverviewRecommendationItem?: GeoOverviewRecommendationItemResolvers<ContextType>;
  GeoHierarchyComponent?: GeoHierarchyComponentResolvers<ContextType>;
  GeoHierarchyContinent?: GeoHierarchyContinentResolvers<ContextType>;
  GeoHierarchyCountry?: GeoHierarchyCountryResolvers<ContextType>;
  HelpOverlayData?: HelpOverlayDataResolvers<ContextType>;
  HelpOverlayBoxBase?: HelpOverlayBoxBaseResolvers<ContextType>;
  HelpOverlayBox?: HelpOverlayBoxResolvers<ContextType>;
  HelpOverlayBoxChat?: HelpOverlayBoxChatResolvers<ContextType>;
  HelpOverlayBoxContact?: HelpOverlayBoxContactResolvers<ContextType>;
  HistoryContext?: HistoryContextResolvers<ContextType>;
  LastSeenResponse?: LastSeenResponseResolvers<ContextType>;
  LastSeenItem?: LastSeenItemResolvers<ContextType>;
  HistoryFlightRecord?: HistoryFlightRecordResolvers<ContextType>;
  HistoryFlightResponse?: HistoryFlightResponseResolvers<ContextType>;
  HistoryUserRecord?: HistoryUserRecordResolvers<ContextType>;
  HistoryUserResponse?: HistoryUserResponseResolvers<ContextType>;
  HistoryProductRecord?: HistoryProductRecordResolvers<ContextType>;
  HistoryProductResponse?: HistoryProductResponseResolvers<ContextType>;
  HistoryRecordUnion?: HistoryRecordUnionResolvers<ContextType>;
  HistorySrlRecord?: HistorySrlRecordResolvers<ContextType>;
  HistoryUnionResponse?: HistoryUnionResponseResolvers<ContextType>;
  HistorySrlResponse?: HistorySrlResponseResolvers<ContextType>;
  HolidayFinderPageContext?: HolidayFinderPageContextResolvers<ContextType>;
  HolidayFinderVotingResponse?: HolidayFinderVotingResponseResolvers<ContextType>;
  HolidayFinderInfo?: HolidayFinderInfoResolvers<ContextType>;
  HolidayFinderProduct?: HolidayFinderProductResolvers<ContextType>;
  HolidayFinderOffer?: HolidayFinderOfferResolvers<ContextType>;
  HolidayFinderTracking?: HolidayFinderTrackingResolvers<ContextType>;
  HolidayFinderLandingPage?: HolidayFinderLandingPageResolvers<ContextType>;
  HolidayFinderPage?: HolidayFinderPageResolvers<ContextType>;
  HolidayFinderSearchResponse?: HolidayFinderSearchResponseResolvers<ContextType>;
  HomeContext?: HomeContextResolvers<ContextType>;
  HomeDynamicComponent?: HomeDynamicComponentResolvers<ContextType>;
  HomeRecommendationGroup?: HomeRecommendationGroupResolvers<ContextType>;
  HomeRecommendationItem?: HomeRecommendationItemResolvers<ContextType>;
  MoreVisibilityStaticComponent?: MoreVisibilityStaticComponentResolvers<ContextType>;
  MoreVisibleItem?: MoreVisibleItemResolvers<ContextType>;
  HomeStaticContent?: HomeStaticContentResolvers<ContextType>;
  HomeTitle?: HomeTitleResolvers<ContextType>;
  HomeTotalProductCount?: HomeTotalProductCountResolvers<ContextType>;
  NewsArticlePage?: NewsArticlePageResolvers<ContextType>;
  BusinessUnit?: BusinessUnitResolvers<ContextType>;
  StaticPageComponent?: StaticPageComponentResolvers<ContextType>;
  StaticRecommendationGroup?: StaticRecommendationGroupResolvers<ContextType>;
  StaticRecommendationItem?: StaticRecommendationItemResolvers<ContextType>;
  NewsArticlesOverviewPage?: NewsArticlesOverviewPageResolvers<ContextType>;
  NewsArticlesFilter?: NewsArticlesFilterResolvers<ContextType>;
  NewsArticlesOverview?: NewsArticlesOverviewResolvers<ContextType>;
  NewsletterConfirmationContext?: NewsletterConfirmationContextResolvers<ContextType>;
  NewsletterInfoComponent?: NewsletterInfoComponentResolvers<ContextType>;
  NewsletterDynamicComponent?: NewsletterDynamicComponentResolvers<ContextType>;
  NewsletterRecommendationGroup?: NewsletterRecommendationGroupResolvers<ContextType>;
  NewsletterRecommendationItem?: NewsletterRecommendationItemResolvers<ContextType>;
  NewsletterFinalizationContext?: NewsletterFinalizationContextResolvers<ContextType>;
  NewsletterSubscriptionContext?: NewsletterSubscriptionContextResolvers<ContextType>;
  NewsletterSubscriptionTokenResponse?: NewsletterSubscriptionTokenResponseResolvers<ContextType>;
  NewsletterUnsubscriptionContext?: NewsletterUnsubscriptionContextResolvers<ContextType>;
  NewsletterGetEmailFromTokenResponse?: NewsletterGetEmailFromTokenResponseResolvers<ContextType>;
  NewsletterGetTokenFromEmailResponse?: NewsletterGetTokenFromEmailResponseResolvers<ContextType>;
  NewsletterUnsubscriptionFinalizationContext?: NewsletterUnsubscriptionFinalizationContextResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationInfo?: NotificationInfoResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  OrlContext?: OrlContextResolvers<ContextType>;
  OrlCheckoutComponent?: OrlCheckoutComponentResolvers<ContextType>;
  SingleOrlItem?: SingleOrlItemResolvers<ContextType>;
  OrlRoom?: OrlRoomResolvers<ContextType>;
  BoardType?: BoardTypeResolvers<ContextType>;
  RoomType?: RoomTypeResolvers<ContextType>;
  OrlIncludedInPriceComponent?: OrlIncludedInPriceComponentResolvers<ContextType>;
  PdpPriceDateOverviewComponent?: PdpPriceDateOverviewComponentResolvers<ContextType>;
  PDOItem?: PdoItemResolvers<ContextType>;
  OrlPriceExplanationComponent?: OrlPriceExplanationComponentResolvers<ContextType>;
  OrlPriceExplanation?: OrlPriceExplanationResolvers<ContextType>;
  QuickHotelInfoComponent?: QuickHotelInfoComponentResolvers<ContextType>;
  QuickHotelInfoPoint?: QuickHotelInfoPointResolvers<ContextType>;
  OrlSearchContainer?: OrlSearchContainerResolvers<ContextType>;
  OrlFlightAlternative?: OrlFlightAlternativeResolvers<ContextType>;
  OrlGroupListComponent?: OrlGroupListComponentResolvers<ContextType>;
  GroupOrlItem?: GroupOrlItemResolvers<ContextType>;
  OrlSingleListComponent?: OrlSingleListComponentResolvers<ContextType>;
  PageB2BLoginData?: PageB2BLoginDataResolvers<ContextType>;
  PageFooterData?: PageFooterDataResolvers<ContextType>;
  IconMenuItem?: IconMenuItemResolvers<ContextType>;
  ContactInformation?: ContactInformationResolvers<ContextType>;
  Navigable?: NavigableResolvers<ContextType>;
  Menu?: MenuResolvers<ContextType>;
  TextMenuItem?: TextMenuItemResolvers<ContextType>;
  ReasonsOfConfidence?: ReasonsOfConfidenceResolvers<ContextType>;
  PageHeaderData?: PageHeaderDataResolvers<ContextType>;
  PageNotFound404Data?: PageNotFound404DataResolvers<ContextType>;
  PdpContext?: PdpContextResolvers<ContextType>;
  PdpContainer?: PdpContainerResolvers<ContextType>;
  PdpDescriptionComponent?: PdpDescriptionComponentResolvers<ContextType>;
  PdpInformationGroup?: PdpInformationGroupResolvers<ContextType>;
  PdpInformation?: PdpInformationResolvers<ContextType>;
  Co2Footprint?: Co2FootprintResolvers<ContextType>;
  PdpFeatureRating?: PdpFeatureRatingResolvers<ContextType>;
  PdpDestinationInfoComponent?: PdpDestinationInfoComponentResolvers<ContextType>;
  DestinationFeatureGroup?: DestinationFeatureGroupResolvers<ContextType>;
  DestinationFeature?: DestinationFeatureResolvers<ContextType>;
  PdpOverviewComponent?: PdpOverviewComponentResolvers<ContextType>;
  TripAdvisorRating?: TripAdvisorRatingResolvers<ContextType>;
  PdpTripAdvisorComponent?: PdpTripAdvisorComponentResolvers<ContextType>;
  TripAdvisorReview?: TripAdvisorReviewResolvers<ContextType>;
  TripAdvisorSubrating?: TripAdvisorSubratingResolvers<ContextType>;
  PdpHotelDestinationInfoComponent?: PdpHotelDestinationInfoComponentResolvers<ContextType>;
  HotelDestinationMapInfo?: HotelDestinationMapInfoResolvers<ContextType>;
  PdpPageComponent?: PdpPageComponentResolvers<ContextType>;
  PdpRecommendationGroup?: PdpRecommendationGroupResolvers<ContextType>;
  PdpRecommendationItem?: PdpRecommendationItemResolvers<ContextType>;
  PdpMapComponent?: PdpMapComponentResolvers<ContextType>;
  PdpMapHotel?: PdpMapHotelResolvers<ContextType>;
  PdpMoreOffersButton?: PdpMoreOffersButtonResolvers<ContextType>;
  PopUpMenuData?: PopUpMenuDataResolvers<ContextType>;
  SearchOverlay?: SearchOverlayResolvers<ContextType>;
  SrlContext?: SrlContextResolvers<ContextType>;
  SrlBD4TravelRecommendationsComponent?: SrlBd4TravelRecommendationsComponentResolvers<ContextType>;
  SrlPageComponent?: SrlPageComponentResolvers<ContextType>;
  SrlRecommendationGroupComponent?: SrlRecommendationGroupComponentResolvers<ContextType>;
  SrlRecommendationItem?: SrlRecommendationItemResolvers<ContextType>;
  SrlFullTextSearchResultComponent?: SrlFullTextSearchResultComponentResolvers<ContextType>;
  SrlFullTextSearchResult?: SrlFullTextSearchResultResolvers<ContextType>;
  FullTextSearchItem?: FullTextSearchItemResolvers<ContextType>;
  SrlProductInfoB2B?: SrlProductInfoB2BResolvers<ContextType>;
  SrlProductInfoB2C?: SrlProductInfoB2CResolvers<ContextType>;
  AgencySrlFullTextSearchResult?: AgencySrlFullTextSearchResultResolvers<ContextType>;
  AgencyFullTextSearchItem?: AgencyFullTextSearchItemResolvers<ContextType>;
  SrlGhostOffersComponent?: SrlGhostOffersComponentResolvers<ContextType>;
  SrlGhostOfferItem?: SrlGhostOfferItemResolvers<ContextType>;
  SrlResultContext?: SrlResultContextResolvers<ContextType>;
  SrlAnyResult?: SrlAnyResultResolvers<ContextType>;
  SrlEmptyResult?: SrlEmptyResultResolvers<ContextType>;
  SrlGeoGroupResult?: SrlGeoGroupResultResolvers<ContextType>;
  SrlGeoItem?: SrlGeoItemResolvers<ContextType>;
  SrlSingleItem?: SrlSingleItemResolvers<ContextType>;
  NudgeItem?: NudgeItemResolvers<ContextType>;
  SrlProductItem?: SrlProductItemResolvers<ContextType>;
  SrlGeoGroupItem?: SrlGeoGroupItemResolvers<ContextType>;
  SrlGroupItem?: SrlGroupItemResolvers<ContextType>;
  SrlSingleResult?: SrlSingleResultResolvers<ContextType>;
  SrlDestinationGroupsResult?: SrlDestinationGroupsResultResolvers<ContextType>;
  ContinentGroupItem?: ContinentGroupItemResolvers<ContextType>;
  CountryGroupItem?: CountryGroupItemResolvers<ContextType>;
  DestinationGroupItem?: DestinationGroupItemResolvers<ContextType>;
  SrlGroupOrEmptyResult?: SrlGroupOrEmptyResultResolvers<ContextType>;
  SrlSingleOrEmptyResult?: SrlSingleOrEmptyResultResolvers<ContextType>;
  SrlSearchControlsContext?: SrlSearchControlsContextResolvers<ContextType>;
  SrlSortComponent?: SrlSortComponentResolvers<ContextType>;
  SrlSubGeoFilterComponent?: SrlSubGeoFilterComponentResolvers<ContextType>;
  SrlSubGeoItem?: SrlSubGeoItemResolvers<ContextType>;
  SrlStaticContent?: SrlStaticContentResolvers<ContextType>;
  StaticContext?: StaticContextResolvers<ContextType>;
  ThemeContext?: ThemeContextResolvers<ContextType>;
  ThemePageDynamicComponent?: ThemePageDynamicComponentResolvers<ContextType>;
  ThemePageRecommendationGroup?: ThemePageRecommendationGroupResolvers<ContextType>;
  ThemePageRecommendationItem?: ThemePageRecommendationItemResolvers<ContextType>;
  ThemeStaticContent?: ThemeStaticContentResolvers<ContextType>;
  ThemeGeoContext?: ThemeGeoContextResolvers<ContextType>;
  ThemeGeoPage?: ThemeGeoPageResolvers<ContextType>;
  ThemeGeoProductRecommendation?: ThemeGeoProductRecommendationResolvers<ContextType>;
  ThemeGeoThemeRecommendation?: ThemeGeoThemeRecommendationResolvers<ContextType>;
  ThemeOverviewContext?: ThemeOverviewContextResolvers<ContextType>;
  ThemeOverviewPage?: ThemeOverviewPageResolvers<ContextType>;
  ThemePreviewComponent?: ThemePreviewComponentResolvers<ContextType>;
  WishlistContext?: WishlistContextResolvers<ContextType>;
  WishlistActiveComponent?: WishlistActiveComponentResolvers<ContextType>;
  WishlistProductItem?: WishlistProductItemResolvers<ContextType>;
  WishlistOffer?: WishlistOfferResolvers<ContextType>;
  WishlistOfferRoom?: WishlistOfferRoomResolvers<ContextType>;
  WishlistProduct?: WishlistProductResolvers<ContextType>;
  WishlistOverviewComponent?: WishlistOverviewComponentResolvers<ContextType>;
  Wishlist?: WishlistResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  AddBookingResponse?: AddBookingResponseResolvers<ContextType>;
  BookmarkBulkResponse?: BookmarkBulkResponseResolvers<ContextType>;
  FlightHistoryResponse?: FlightHistoryResponseResolvers<ContextType>;
  SrlHistoryResponse?: SrlHistoryResponseResolvers<ContextType>;
  WishlistAddMultipleToWishlistResponse?: WishlistAddMultipleToWishlistResponseResolvers<ContextType>;
  OrlHistoryResponse?: OrlHistoryResponseResolvers<ContextType>;
  SharedBookmarkGroupResponse?: SharedBookmarkGroupResponseResolvers<ContextType>;
  WishlistMutationResponse?: WishlistMutationResponseResolvers<ContextType>;
  WishlistAddToWishlistResponse?: WishlistAddToWishlistResponseResolvers<ContextType>;
  AddTravelCompanionResponse?: AddTravelCompanionResponseResolvers<ContextType>;
  AddUpsellTransferToBasketResponse?: AddUpsellTransferToBasketResponseResolvers<ContextType>;
  AddViewedHistoryResponse?: AddViewedHistoryResponseResolvers<ContextType>;
  AccountMutationResponse?: AccountMutationResponseResolvers<ContextType>;
  BookingCancellationResponse?: BookingCancellationResponseResolvers<ContextType>;
  BookingCancellationPayloadUnion?: BookingCancellationPayloadUnionResolvers<ContextType>;
  BookingCancellationConfirmationPayload?: BookingCancellationConfirmationPayloadResolvers<ContextType>;
  ChangePaymentMethodResponse?: ChangePaymentMethodResponseResolvers<ContextType>;
  BookmarkResponse?: BookmarkResponseResolvers<ContextType>;
  BookmarkGroupCreateResponse?: BookmarkGroupCreateResponseResolvers<ContextType>;
  CatalogOrderResponse?: CatalogOrderResponseResolvers<ContextType>;
  NewsletterSubscriptionResponse?: NewsletterSubscriptionResponseResolvers<ContextType>;
  NewsletterSubscription?: NewsletterSubscriptionResolvers<ContextType>;
  BookmarkGroupDeleteResponse?: BookmarkGroupDeleteResponseResolvers<ContextType>;
  DeleteHistoryResponse?: DeleteHistoryResponseResolvers<ContextType>;
  AccessBookingCancellationFinalizationResponse?: AccessBookingCancellationFinalizationResponseResolvers<ContextType>;
  BookingCancellationFinalizationResponse?: BookingCancellationFinalizationResponseResolvers<ContextType>;
  UserFinalizationResponse?: UserFinalizationResponseResolvers<ContextType>;
  AccessBookingCancellationResponse?: AccessBookingCancellationResponseResolvers<ContextType>;
  InitPaymentResponse?: InitPaymentResponseResolvers<ContextType>;
  BookmarkPdfUploadResponse?: BookmarkPdfUploadResponseResolvers<ContextType>;
  BookmarkPdfUploadInfo?: BookmarkPdfUploadInfoResolvers<ContextType>;
  UserRegistrationResponse?: UserRegistrationResponseResolvers<ContextType>;
  NewsletterUnsubscriptionResponse?: NewsletterUnsubscriptionResponseResolvers<ContextType>;
  BookmarkGroupRenameResponse?: BookmarkGroupRenameResponseResolvers<ContextType>;
  ResendTravelDocumentResponse?: ResendTravelDocumentResponseResolvers<ContextType>;
  EmailAdvertisingRevocationResponse?: EmailAdvertisingRevocationResponseResolvers<ContextType>;
  AgencyAppointmentResponse?: AgencyAppointmentResponseResolvers<ContextType>;
  ContactFormResponse?: ContactFormResponseResolvers<ContextType>;
  UpdateEmergencyPhoneResponse?: UpdateEmergencyPhoneResponseResolvers<ContextType>;
  ViewedProductHistoryResponse?: ViewedProductHistoryResponseResolvers<ContextType>;
  UNREPRESENTABLE?: GraphQLScalarType;
};

export type DirectiveResolvers<ContextType = any> = {
  specifiedBy?: SpecifiedByDirectiveResolver<any, any, ContextType>;
};
